import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Typography, Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AttachmentsInput from './AttachmentsInput';

const PageTradeRequirementsCard = ({ control }) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        mt: 3,
      }}
    >
      <CardHeader
        title={
          <Typography fontSize={20} fontWeight={500} colorscheme="heading">
            {t('Trade Requirements')}
          </Typography>
        }
        emphasis="true"
      />
      <CardContent
        sx={{
          pt: 0,
          pb: '16px !important',
        }}
      >
        <Typography fontSize={14}>
          {t(
            'If you have a trade requirements document you can upload it here for the seller.',
          )}
        </Typography>
        <Typography fontSize={16} fontWeight={600} colorscheme="heading" mt={2}>
          {t('Attach File')}
        </Typography>
        <Controller
          control={control}
          name="tradeRequirements"
          render={({ field, fieldState: { error } }) => (
            <Box id="field-tradeRequirements">
              <AttachmentsInput
                value={field.value}
                onChange={field.onChange}
                error={!!error}
              />
              {!!error || (field.value !== null && !field.value.url) ? (
                <Typography fontSize={14} colorscheme="status.error" mt={2}>
                  {t('Please attach your trade requirements file')}
                </Typography>
              ) : null}
            </Box>
          )}
        />
      </CardContent>
    </Card>
  );
};

PageTradeRequirementsCard.propTypes = {
  control: PropTypes.any,
};

export default PageTradeRequirementsCard;
