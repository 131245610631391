import axios from 'axios';
import { t } from 'i18next';
import notify from 'src/configs/notify';

export const apiGetPurchaseTrades = async (data) => {
  try {
    const response = await axios.get('/api/v1/trades/purchases', {
      params: data,
    });
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetSaleTrades = async (data) => {
  try {
    const response = await axios.get('/api/v1/trades/sales', {
      params: data,
    });
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiSubmitOrder = async (data) => {
  try {
    const response = await axios.post('/api/v1/trades/purchases', data);
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetTradeDetails = async (id) => {
  try {
    const response = await axios.get('/api/v1/trades/' + id);
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiRejectTrade = async (data) => {
  try {
    await axios.post(`/api/v1/trades/${data.id}/reject`, {
      reason: data.reason,
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiCreateTradeData = async ({ id, ...data }) => {
  try {
    const response = await axios.post(`/api/v1/trades/${id}/build`, data);
    notify({
      message: response.data.data.senderView
        ? 'Please annotate on which area the buyer needs to sign'
        : 'Your Trade has been submitted to the buyer, please wait for their response',
      type: 'success',
    });
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiAcceptTradeAgreements = async (data) => {
  try {
    const response = await axios.post(
      `/api/v1/trades/${data.id}/accept-agreements`,
    );
    notify({
      message: 'Please sign your name within the document',
      type: 'success',
    });
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiFinishTradeAgreements = async (data) => {
  try {
    const response = await axios.post(
      `/api/v1/trades/${data.id}/finish-agreements`,
    );
    notify({
      message: 'Please sign your name within the document',
      type: 'success',
    });
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiUpdateTradeStatus = async (data) => {
  try {
    await axios.patch(`/api/v1/trades/${data.id}/status`, {
      status: data.status,
    });
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiUploadInvoice = async ({ id, ...params }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/upload-invoice`, params);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiUpdateDocument = async ({ tradeId, documentId, file }) => {
  try {
    const response = await axios.patch(
      `/api/v1/trades/${tradeId}/documents/${documentId}`,
      {
        file: file,
      },
    );
    notify({
      message: 'You have updated the selected document successfully',
      type: 'success',
    });
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiUploadTradeReceipt = async ({ id, ...data }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/upload-receipt`, data);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiSendManufacturingDocuments = async ({ id, ...data }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/manufacturing-documents`, data);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiSendExportingDocuments = async ({ id, ...data }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/exporting-documents`, data);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiSendShippingDocuments = async ({ id, ...data }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/shipping-documents`, data);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiAcceptTradeInvoice = async ({ id }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/accept-invoice`);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiAcceptManufacturingDocuments = async ({ id }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/accept-manufacturing-documents`);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiAcceptExportingDocuments = async ({ id }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/accept-exporting-documents`);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiAcceptImportingDocuments = async ({ id }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/accept-importing-documents`);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiConfirmDelivery = async ({ id }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/confirm-delivery`);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiCompleteShipment = async ({ id }) => {
  try {
    await axios.post(`/api/v1/trades/${id}/complete-shipment`);
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiAssignMemberOnTrade = async (tradeId, memberId) => {
  try {
    await axios.post(`/api/v1/trades/${tradeId}/assign-member`, {
      member: memberId,
    });
    notify({
      message: 'Your request has been processed successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};
