import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { apiSyncDeviceTokens, apiSyncProfile } from 'src/apis/profile';
import { setMessagingDeviceToken } from 'src/redux/firebase/slice';
import notify from 'src/configs/notify';

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.auth?.id);
  const accessToken = useSelector((state) => state.auth?.accessToken);
  const firebase = useSelector((state) => state.firebase);

  useEffect(() => {
    if (!id || !accessToken) {
      return;
    }

    (async () => {
      await apiSyncProfile();
    })();
  }, [id, accessToken]);

  useEffect(() => {
    (async () => {
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        const messaging = getMessaging();
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });

        dispatch(setMessagingDeviceToken(token));

        const unsubscribe = onMessage(messaging, (payload) => {
          notify({
            message: payload.notification?.body,
            type: 'success',
          });
        });

        console.log('[Firebase messaging] Token generated:', token);

        return () => unsubscribe();
      } else {
        console.log('[Firebase messaging] Permission denied');
      }
    })();
  }, []);

  useEffect(() => {
    if (accessToken && firebase?.messagingDeviceToken) {
      apiSyncDeviceTokens(firebase.messagingDeviceToken);
    }
  }, [accessToken, firebase?.messagingDeviceToken]);

  if (id && accessToken) {
    return children;
  } else {
    return <Navigate to="/auth/login" replace />;
  }
};

ProtectedRoute.propTypes = {
  children: PropTypes.element,
};

export default ProtectedRoute;
