import React from 'react';
import PropTypes from 'prop-types';
import { IconButton as MuiIconButton, styled } from '@mui/material';
import { GChevronDown, GChevronUp } from './AppIcon';

const IconButton = styled(MuiIconButton)(() => ({
  width: 24,
  height: 24,
  '& > svg': {
    width: 18,
    height: 18,
  },
}));

const CollapseButton = ({ opened, onToggle }) => {
  return (
    <IconButton onClick={onToggle}>
      {opened ? <GChevronUp /> : <GChevronDown />}
    </IconButton>
  );
};

CollapseButton.propTypes = {
  opened: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default CollapseButton;
