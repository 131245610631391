import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Portal, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import RequireVerifyModal from './RequireVerifyModal';
import { GPlusIcon } from '../../../components/ui/AppIcon';

const PageHeader = ({ tab, onChangeTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [open, setOpen] = useState(false);
  const [portalContainer, setPortalContainer] = useState(null);

  const handleAddNew = () => {
    if (auth?.business?.id) {
      navigate('/profile/products/new');
      return;
    }

    setOpen(true);
  };

  useEffect(() => {
    setPortalContainer(document.getElementById('profile-layout-actions'));
  }, []);

  return (
    <>
      <Box position="relative">
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 3,
            bgcolor: '#E3E0E0',
          }}
        />
        <Tabs
          value={tab}
          onChange={onChangeTab}
          sx={{
            mb: 2.5,
            position: 'relative',
            '& .MuiTab-root': {
              p: 1.5,
              minWidth: 90,
            },
            '& .MuiTabs-indicator': {
              height: 3,
            },
          }}
        >
          <Tab
            label={t('Active')}
            value="active-products"
            id="tab-active-products"
          />
          <Tab
            label={t('Draft')}
            value="draft-products"
            id="tab-draft-products"
          />
        </Tabs>
      </Box>
      <Portal container={portalContainer}>
        <Button
          variant="contained"
          startIcon={<GPlusIcon />}
          size="small"
          sx={{
            px: 3,
            display: auth?.role === 'owner' ? 'flex' : 'none',
          }}
          onClick={handleAddNew}
        >
          {t('Add new')}
        </Button>
      </Portal>
      <RequireVerifyModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

PageHeader.propTypes = {
  tab: PropTypes.string,
  onChangeTab: PropTypes.func,
};

export default PageHeader;
