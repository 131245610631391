import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const NotificationSettingInput = ({ title, subtitle, values, onChange }) => {
  const { t } = useTranslation();

  const handleChange = (key, checked) => {
    const newValues = { ...values };
    newValues[key] = checked;
    onChange(newValues);
  };

  return (
    <Stack direction="row" justifyContent="space-between" spacing={3}>
      <Stack maxWidth={800} spacing={1}>
        <Typography fontSize={16} fontWeight={500} colorscheme="heading">
          {title}
        </Typography>
        <Typography fontSize={14} colorscheme="gray.500">
          {subtitle}
        </Typography>
      </Stack>
      <FormGroup>
        <FormControlLabel
          control={<Switch />}
          label={t('Push')}
          checked={values.push}
          onChange={(ev, checked) => handleChange('push', checked)}
        />
        <FormControlLabel
          control={<Switch />}
          label={t('Email')}
          checked={values.email}
          onChange={(ev, checked) => handleChange('email', checked)}
        />
        <FormControlLabel
          control={<Switch />}
          label={t('SMS')}
          checked={values.sms}
          onChange={(ev, checked) => handleChange('sms', checked)}
        />
      </FormGroup>
    </Stack>
  );
};

NotificationSettingInput.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  values: PropTypes.any,
  onChange: PropTypes.func,
};

export default NotificationSettingInput;
