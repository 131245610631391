import React, { useCallback } from 'react';
import { Avatar, Box, ButtonBase, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { GMailIcon } from '../ui/AppIcon';
import colors from '../../configs/colors';
import { apiReadNotification } from 'src/apis/notifications';

const NotificationItem = ({
  id,
  highlight,
  title,
  message,
  avatar,
  createdAt,
  payload,
  action,
  bottomBorder,
  ...boxProps
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);

  const handleClick = useCallback(() => {
    if (action) {
      if (action.includes('trade/')) {
        if (payload?.seller === auth?.business?.id && payload?.trade) {
          navigate(`/trades/sales/${payload.trade}`);
        } else if (payload?.buyer === auth?.business?.id && payload?.trade) {
          navigate(`/trades/purchases/${payload.trade}`);
        }
      }
    }

    if (id) {
      apiReadNotification({ notificationId: id });
    }
  }, [payload, action, id]);

  let date = '';
  const time = moment(createdAt).format('hh:mm A');

  if (moment(createdAt).isAfter(moment().startOf('day'))) {
    date = t('Today');
  } else if (
    moment(createdAt).isAfter(moment().subtract(1, 'd').startOf('d'))
  ) {
    date = t('Yesterday');
  } else {
    date = moment(createdAt).format('MM/DD/YYYY');
  }

  return (
    <ButtonBase
      sx={{
        position: 'relative',
        display: 'block',
        width: '100%',
        pt: 1,
        pb: 2,
        ...(bottomBorder && {
          '&:not(:last-child)': {
            borderBottom: `1px solid ${colors['gray.200']}`,
          },
        }),
        ...boxProps,
      }}
      onClick={handleClick}
    >
      <Stack direction="row" px={2} spacing={2}>
        <Avatar
          src={avatar}
          alt="avatar"
          sx={{
            width: 32,
            height: 32,
          }}
        >
          <GMailIcon
            sx={{
              width: 20,
              height: 20,
            }}
          />
        </Avatar>
        <Box flex={1}>
          <Typography
            fontSize={14}
            fontWeight={500}
            lineHeight="22px"
            colorscheme="gray.900"
            textAlign="start"
            display="block"
            flex={1}
          >
            {title}
          </Typography>
          {message && (
            <Box mt={1}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#303030"
                lineHeight="20px"
                textAlign="start"
                sx={{
                  pl: 1.5,
                  display: 'block',
                  position: 'relative',
                  '&::before': {
                    position: 'absolute',
                    display: 'block',
                    content: '""',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: 4,
                    borderRadius: 2,
                    bgcolor: colors.primary,
                  },
                }}
              >
                {message}
              </Typography>
            </Box>
          )}
          <Box mt={1}>
            <Typography
              fontSize={14}
              fontWeight={500}
              color="#A5ACB8"
              lineHeight="20px"
              textAlign="start"
              display="block"
            >
              {date + ' ' + time}
            </Typography>
          </Box>
        </Box>
      </Stack>
      {highlight && (
        <Box
          sx={{
            display: 'block',
            position: 'absolute',
            left: 7,
            top: 7,
            width: 8,
            height: 8,
            bgcolor: '#40C057',
            borderRadius: 8,
          }}
        />
      )}
    </ButtonBase>
  );
};

NotificationItem.propTypes = {
  highlight: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  createdAt: PropTypes.string,
  avatar: PropTypes.string,
  action: PropTypes.string,
  payload: PropTypes.any,
  id: PropTypes.string,
  bottomBorder: PropTypes.bool,
};

export default NotificationItem;
