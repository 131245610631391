import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authSlice, { setAuth } from './auth/slice';
import firebaseSlice from './firebase/slice';
import messagingSlice, { initMessaging } from './messaging/slice';

const persistConfig = {
  key: 'omp-app-root',
  storage,
};

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  firebase: firebaseSlice.reducer,
  messaging: messagingSlice.reducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export const persistor = persistStore(store, {}, () => {
  try {
    store.dispatch(initMessaging());

    const data = localStorage.getItem('omp-auth-remember');
    if (data && data === 'true') {
      console.log('Backed up stored authentication data...');
      return true;
    }
    store.dispatch(setAuth({}));
    return false;
  } catch {
    return false;
  }
});

export default store;
