import React from 'react';
import {
  Container,
  Stack,
  Typography,
  styled,
  ButtonBase,
} from '@mui/material';
import { NavLink } from 'react-router-dom';

import imgLeading from '../../../../assets/images/oil-pumps.png';
import imgLogo from '../../../../assets/logos/logo-white.png';

const LeadingImage = styled('img')(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: 0,
}));

const LeadingLogo = styled('img')(() => ({
  width: 382,
  height: 'auto',
  zIndex: 2,
}));

const LeadingOverlay = styled('div')(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  background:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.29) 67.36%, rgba(0, 0, 0, 0.00) 94.54%)',
  zIndex: 1,
}));

const PageLeading = () => {
  return (
    <Container
      sx={{
        mb: 2.5,
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        py="110px"
        px={4}
        position="relative"
        overflow="hidden"
        borderRadius="12px"
      >
        <LeadingImage src={imgLeading} alt="Background" />
        <LeadingOverlay />
        <LeadingLogo src={imgLogo} />
        <Typography
          fontSize={16}
          fontWeight={500}
          colorscheme="light"
          lineHeight={1.7}
          mt={1.5}
          zIndex={3}
          maxWidth={495}
          textAlign="center"
        >
          Forge Everlasting Trade Partnerships
        </Typography>
        <ButtonBase
          variant="outlined"
          sx={{
            mt: 3.5,
            width: 174,
            height: 52,
            zIndex: 3,
            borderRadius: 0,
            border: '1px solid white',
          }}
          component={NavLink}
          to="/marketplace/products"
        >
          <Typography fontSize={12} fontWeight={500} colorscheme="light">
            BROWSE
          </Typography>
        </ButtonBase>
      </Stack>
    </Container>
  );
};

export default PageLeading;
