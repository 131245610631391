import axios from 'axios';
import { t } from 'i18next';
import notify from 'src/configs/notify';

export const apiSignIn = async ({ email, password }) => {
  try {
    const response = await axios.post('/api/v1/auth/signin', {
      email,
      password,
    });
    if (response.data) {
      if (response.data.message === 'ERROR_EMAIL_NOT_VERIFIED') {
        notify({
          message: t('ERROR_EMAIL_NOT_VERIFIED'),
          type: 'error',
        });
      }
      return response.data;
    } else {
      throw new Error('Error_Unknown');
    }
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiSignUp = async (params) => {
  try {
    const response = await axios.post('/api/v1/auth/signup', params);
    if (response.data) {
      return response.data;
    } else {
      throw new Error('Error_Unknown');
    }
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiRequestEmailOTP = async (params) => {
  try {
    const response = await axios.post(
      '/api/v1/auth/resend-verification-email',
      params,
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error('Error_Unknown');
    }
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiRequestPhoneOTP = async (params) => {
  try {
    const response = await axios.post(
      '/api/v1/auth/resend-verification-phone',
      params,
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error('Error_Unknown');
    }
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiVerifyEmail = async (params) => {
  try {
    const response = await axios.post('/api/v1/auth/verify-email', params);
    if (response.data) {
      return response.data;
    } else {
      throw new Error('Error_Unknown');
    }
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiVerifyPhone = async (params) => {
  try {
    const response = await axios.post('/api/v1/auth/verify-phone', params);
    if (response.data) {
      return response.data;
    } else {
      throw new Error('Error_Unknown');
    }
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiRequestPasswordOTP = async (params) => {
  try {
    const response = await axios.post(
      '/api/v1/auth/send-reset-password-email',
      params,
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error('Error_Unknown');
    }
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiVerifyMfaCode = async (params) => {
  try {
    const response = await axios.post('/api/v1/auth/verify-mfa', params);
    if (response.data) {
      return response.data;
    } else {
      throw new Error('Error_Unknown');
    }
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiResetPassword = async (params) => {
  try {
    const response = await axios.post('/api/v1/auth/reset-password', params);
    if (response.data) {
      return response.data;
    } else {
      throw new Error('Error_Unknown');
    }
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiCheckPasswordOTP = async (params) => {
  try {
    const response = await axios.post(
      '/api/v1/auth/check-validation-code',
      params,
    );
    if (response.data) {
      return response.data;
    } else {
      throw new Error('Error_Unknown');
    }
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetInvitationDetails = async (id) => {
  try {
    const response = await axios.get(`/api/v1/auth/invitations/${id}`);
    return response.data;
  } catch (e) {
    notify({
      message: 'Sorry but your invitation link is invalid',
      type: 'error',
    });
    return null;
  }
};

export const apiAcceptInvitation = async (id, data) => {
  try {
    const response = await axios.post(
      `/api/v1/auth/invitations/${id}/accept`,
      data,
    );
    notify({
      message: 'You have successfully accepted the invitation',
      type: 'success',
    });
    return response.data;
  } catch (e) {
    notify({
      message: 'Sorry but your invitation link is invalid',
      type: 'error',
    });
    return null;
  }
};

export const apiDeclineInvitation = async (id) => {
  try {
    const response = await axios.post(`/api/v1/auth/invitations/${id}/decline`);
    return response.data;
  } catch (e) {
    notify({
      message: 'Sorry but your invitation link is invalid',
      type: 'error',
    });
    return null;
  }
};
