import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
  Stack,
  styled,
  Box,
  Divider,
} from '@mui/material';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import CollapseButton from 'src/components/ui/CollapseButton';
import constants from 'src/configs/constants';

const ProductImage = styled('img')(() => ({
  width: 52,
  height: 52,
  objectFit: 'cover',
  border: '1px solid #D7D7D7',
  borderRadius: 8,
}));

const DataGrid = styled(MuiDataGrid)(() => ({
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '22px',
    color: '#969696',
  },
  '& .MuiDataGrid-columnHeaders': {
    marginBottom: 9,
    background: 'transparent',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
  '& .MuiDataGrid-cell:first-of-type': {
    paddingLeft: 0,
  },
}));

const FooterLabel = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.5,
  color: '#232323',
}));

const PageTradeItemsCard = ({ data }) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(true);
  const [items, setItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [shipping, setShipping] = useState('INCOTERMS CIF');
  const [total, setTotal] = useState(0);

  const handleToggle = () => {
    setOpened((value) => !value);
  };

  useEffect(() => {
    if (data) {
      if (data.shipments && data.shipments.length > 0) {
        const newItems = [];
        let sum = 0;
        for (const shipment of data.shipments) {
          for (const row of shipment.items) {
            newItems.push({
              id: row.id || row._id,
              name: row.name,
              image: constants.getStoragePath(row.image),
              price: row.price,
              quantity: row.quantity,
              total: row.total,
            });
            sum += +row.total;
          }
        }
        setItems(newItems);
        setSubtotal(sum);
        setTotal(sum);
      } else {
        setItems(
          Array(data.numberOfShipments)
            .fill({
              id: 1,
              name: data.product?.name,
              image: constants.getStoragePath(data.product?.photo),
              price: data.product?.price,
              quantity: data.quantity,
              total: data.product?.price * data.quantity,
            })
            .map((item, index) => ({
              ...item,
              id: index + 1,
            })),
        );
        setSubtotal(
          data.product?.price * data.quantity * data.numberOfShipments,
        );
        setTotal(data.product?.price * data.quantity * data.numberOfShipments);
      }
      setShipping('INCOTERMS');
    }
  }, [data]);

  return (
    <Card sx={{ mb: 2.5 }}>
      <CardHeader
        title={
          <Typography fontSize={18} fontWeight={600} colorscheme="heading">
            {t('Cargo')}
          </Typography>
        }
        emphasis="true"
        action={<CollapseButton opened={opened} onToggle={handleToggle} />}
      />
      <Collapse in={opened}>
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          <DataGrid
            columns={[
              {
                headerName: t('Item'),
                field: 'name',
                renderCell: ({ row }) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing="10px"
                    p="10px"
                  >
                    <ProductImage src={row.image} />
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      colorscheme="secondary.500"
                    >
                      {row.name}
                    </Typography>
                  </Stack>
                ),
                flex: 1,
                minWidth: 300,
                sortable: false,
                filterable: false,
              },
              {
                headerName: t('Value'),
                field: 'price',
                renderCell: ({ value }) => (
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    colorscheme="secondary.500"
                  >
                    ${Number(value).toLocaleString()}
                  </Typography>
                ),
                sortable: false,
                filterable: false,
              },
              {
                headerName: t('Qty'),
                field: 'quantity',
                renderCell: ({ value }) => (
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    colorscheme="secondary.500"
                  >
                    {Number(value).toLocaleString()}
                  </Typography>
                ),
                sortable: false,
                filterable: false,
              },
              {
                headerName: t('Total'),
                field: 'total',
                renderCell: ({ value }) => (
                  <Typography fontSize={12} fontWeight={500} lineHeight={1.5}>
                    ${Number(value).toLocaleString()}
                  </Typography>
                ),
                sortable: false,
                filterable: false,
              },
            ]}
            rows={items}
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            disableDensitySelector
            disableEval
            disableRowSelectionOnClick
            hideFooter
            hideFooterSelectedRowCount
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.id || row._id}
            columnHeaderHeight={22}
          />
          <Box bgcolor="#F9F9F9" borderRadius={2} p={3} mt={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FooterLabel>{t('Subtotal')}</FooterLabel>
              <FooterLabel>${Number(subtotal).toLocaleString()}</FooterLabel>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={1.5}
            >
              <Stack>
                <FooterLabel>{t('Shipping')}</FooterLabel>
                <Typography fontSize={12} colorscheme="gray.400">
                  {shipping}
                </Typography>
              </Stack>
              <FooterLabel>-</FooterLabel>
            </Stack>
            <Divider sx={{ my: 1.5 }} />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FooterLabel>{t('Total')}</FooterLabel>
              <FooterLabel>${Number(total).toLocaleString()}</FooterLabel>
            </Stack>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

PageTradeItemsCard.propTypes = {
  data: PropTypes.object,
};

export default PageTradeItemsCard;
