import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import PageNavigation from './PageNavigation';
import TableMenu from 'src/components/ui/TableMenu';
import {
  GCheckIcon,
  GCloseIcon,
  GDotsVerticalIcon,
  GEditIcon,
  GEyeIcon,
  GFileInvoiceIcon,
  GHelpIcon,
  GLinkIcon,
  GMessage2Icon,
  GUserPlusIcon,
} from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';

const PageHeader = ({
  loading,
  data,
  onReject,
  onIssueInvoice,
  onCompleteManufacturing,
  onUploadShippingDocs,
  onRequestSettlement,
  onFinishAgreement,
  onViewContract,
  onViewMessage,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [status, setStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleOpen = (ev) => {
    setAnchor(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleActionMenuClick = (callback) => {
    return () => {
      handleClose();
      callback();
    };
  };

  useEffect(() => {
    if (data) {
      if (data.status === 'shipment') {
        for (const shipment of data.shipments) {
          if (shipment.status !== 'pending' && shipment.status !== 'settled') {
            setStatus(shipment.status);

            for (const payment of shipment.payments) {
              if (payment.status !== 'confirmed') {
                setPaymentStatus(payment.status);
              }
              break;
            }
            return;
          }
        }
        setStatus('');
      } else if (data.status === 'paying' && data.initialPayments.length > 0) {
        setStatus(data.status);
        setPaymentStatus(data.initialPayments[0].status);
      } else {
        setStatus(data.status);
        return;
      }
    }
  }, [data]);

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Box flex={1}>
          <PageNavigation />
          <Typography
            fontSize={24}
            fontWeight={600}
            colorscheme="heading"
            mt={1}
          >
            {t('Trade #id', {
              id: params.tradeId?.substr(-6).toUpperCase(),
            })}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Collapse orientation="horizontal" in={!isSm}>
            <Stack direction="row" alignItems="center" spacing={2}>
              {status === 'requested' && (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      height: '40px !important',
                    }}
                    onClick={onReject}
                  >
                    {t('Reject')}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      height: '40px !important',
                      width: 140,
                    }}
                    LinkComponent={NavLink}
                    to="trade-builder"
                  >
                    {t('Create Trade')}
                  </Button>
                </>
              )}
              {status === 'checking' && (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      height: '40px !important',
                    }}
                    LinkComponent={NavLink}
                    to="trade-builder"
                  >
                    Open Trade Builder
                  </Button>
                  {data.documents.find(
                    (item) => item.type === 'sales-agreement',
                  )?.metadata?.buyerSignedAt && (
                    <Button
                      variant="contained"
                      sx={{
                        height: '40px !important',
                      }}
                      onClick={onFinishAgreement}
                    >
                      Sign Agreement
                    </Button>
                  )}
                </>
              )}
              {['invoicing'].includes(paymentStatus) && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      height: '40px !important',
                      width: 140,
                    }}
                    onClick={onIssueInvoice}
                  >
                    Issue Invoice
                  </Button>
                </>
              )}
              {status === 'manufacturing' && paymentStatus === 'pending' && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      height: '40px !important',
                      width: 240,
                    }}
                    onClick={onCompleteManufacturing}
                  >
                    {data?.documents?.filter(
                      (item) =>
                        item.type === 'manufacturing-document' &&
                        item.status === 'pending',
                    ).length > 0
                      ? 'Add More Documents'
                      : 'Complete Manufacturing'}
                  </Button>
                </>
              )}
              {status === 'shipping' &&
                (paymentStatus === 'pending' || !paymentStatus) && (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        height: '40px !important',
                        width: 240,
                      }}
                      onClick={onUploadShippingDocs}
                    >
                      {data?.documents?.filter(
                        (item) =>
                          item.type === 'importing-document' &&
                          item.status === 'pending',
                      ).length > 0
                        ? 'Add More Documents'
                        : 'Upload Shipping Docs'}
                    </Button>
                  </>
                )}
              {status === 'finishing' && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      height: '40px !important',
                      width: 200,
                    }}
                    onClick={onRequestSettlement}
                  >
                    Request Payment
                  </Button>
                </>
              )}
            </Stack>
          </Collapse>
          <IconButton
            sx={{
              width: 40,
              height: 40,
              borderRadius: 1,
              border: `1px solid ${colors['gray.300']}`,
            }}
            onClick={handleOpen}
          >
            {loading ? <CircularProgress size={24} /> : <GDotsVerticalIcon />}
          </IconButton>
        </Stack>
        <TableMenu open={open} anchorEl={anchor} onClose={handleClose}>
          <MenuItem>
            <ListItemIcon>
              <GEyeIcon />
            </ListItemIcon>
            <ListItemText>{t('View Buyer')}</ListItemText>
          </MenuItem>
          {status === 'requested' && [
            <MenuItem
              key="key-create-trade"
              component={NavLink}
              to="trade-builder"
            >
              <ListItemIcon>
                <GCheckIcon />
              </ListItemIcon>
              <ListItemText>{t('Create Trade')}</ListItemText>
            </MenuItem>,
            <MenuItem
              key="key-reject"
              onClick={handleActionMenuClick(onReject)}
            >
              <ListItemIcon>
                <GCloseIcon />
              </ListItemIcon>
              <ListItemText>{t('Reject')}</ListItemText>
            </MenuItem>,
          ]}
          {status === 'checking' && [
            <MenuItem
              key="key-open-trade-builder"
              component={NavLink}
              to="trade-builder"
            >
              <ListItemIcon>
                <GEditIcon />
              </ListItemIcon>
              <ListItemText>Open Trade Builder</ListItemText>
            </MenuItem>,
            ...(data.documents.find((item) => item.type === 'sales-agreement')
              ?.metadata?.buyerSignedAt
              ? [
                  <MenuItem
                    key="key-review-comments"
                    onClick={onFinishAgreement}
                  >
                    <ListItemIcon>
                      <GFileInvoiceIcon />
                    </ListItemIcon>
                    <ListItemText>Sign Agreement</ListItemText>
                  </MenuItem>,
                ]
              : []),
          ]}
          {status === 'paying' &&
            ['pending', 'invoicing'].includes(paymentStatus) && [
              <MenuItem
                key="key-issue-invoice"
                onClick={handleActionMenuClick(onIssueInvoice)}
              >
                <ListItemIcon>
                  <GFileInvoiceIcon />
                </ListItemIcon>
                <ListItemText>Issue Invoice</ListItemText>
              </MenuItem>,
            ]}
          {['invoicing'].includes(paymentStatus) && [
            <MenuItem
              key="key-issue-invoice"
              onClick={handleActionMenuClick(onIssueInvoice)}
            >
              <ListItemIcon>
                <GFileInvoiceIcon />
              </ListItemIcon>
              <ListItemText>Issue Invoice</ListItemText>
            </MenuItem>,
          ]}
          {status === 'manufacturing' &&
            paymentStatus === 'pending' && [
              <MenuItem
                key="key-complete-manufacturing"
                onClick={handleActionMenuClick(onCompleteManufacturing)}
              >
                <ListItemIcon>
                  <GCheckIcon />
                </ListItemIcon>
                <ListItemText>Complete Manufacturing</ListItemText>
              </MenuItem>,
            ]}
          {status === 'shipping' &&
            (paymentStatus === 'pending' || !paymentStatus) && [
              <MenuItem
                key="key-upload-shipping-docs"
                onClick={handleActionMenuClick(onUploadShippingDocs)}
              >
                <ListItemIcon>
                  <GCheckIcon />
                </ListItemIcon>
                <ListItemText>Upload Shipping Docs</ListItemText>
              </MenuItem>,
            ]}
          {status === 'finishing' && [
            <MenuItem
              key="key-request-settlement"
              onClick={handleActionMenuClick(onRequestSettlement)}
            >
              <ListItemIcon>
                <GCheckIcon />
              </ListItemIcon>
              <ListItemText>Request Payment</ListItemText>
            </MenuItem>,
          ]}
          {data?.bcContractAddress && [
            <MenuItem
              key="key-view-contract"
              onClick={handleActionMenuClick(onViewContract)}
            >
              <ListItemIcon>
                <GLinkIcon />
              </ListItemIcon>
              <ListItemText>View Blockchain</ListItemText>
            </MenuItem>,
          ]}
          <MenuItem
            key="key-message-buyer"
            onClick={handleActionMenuClick(onViewMessage)}
          >
            <ListItemIcon>
              <GMessage2Icon />
            </ListItemIcon>
            <ListItemText>{t('Message Buyer')}</ListItemText>
          </MenuItem>
          <MenuItem key="key-assign">
            <ListItemIcon>
              <GUserPlusIcon />
            </ListItemIcon>
            <ListItemText>{t('Assgin to Trade')}</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <GHelpIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t('Get Help')}</Typography>
            </ListItemText>
          </MenuItem>
        </TableMenu>
      </Stack>
    </>
  );
};

PageHeader.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.any,
  onReject: PropTypes.func,
  onFinishAgreement: PropTypes.func,
  onIssueInvoice: PropTypes.func,
  onCompleteManufacturing: PropTypes.func,
  onUploadShippingDocs: PropTypes.func,
  onRequestSettlement: PropTypes.func,
  onViewContract: PropTypes.func,
  onViewMessage: PropTypes.func,
};

export default PageHeader;
