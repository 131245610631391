import React from 'react';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import TableFilter from '../../../../components/ui/TableFilter';
import TicketsTableActionMenu from './ActionMenu';
import TicketStatusChip from '../../../../components/chips/TicketStatusChip';
import _tickets from '../../../../_dev/sample-data/tickets.json';

const PageTicketsTable = () => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        mt: 3,
      }}
    >
      <CardHeader
        title={
          <Typography
            fontSize={20}
            fontWeight={500}
            lineHeight={1.5}
            colorscheme="fonts.heading"
          >
            {t('Ticket List')}
          </Typography>
        }
        emphasis="true"
        action={<TableFilter />}
      />
      <CardContent
        sx={{
          pb: '16px !important',
        }}
      >
        <DataGrid
          rows={_tickets}
          columns={[
            {
              field: 'id',
              headerName: t('ID'),
              width: 90,
              valueFormatter: ({ value }) => `#${value}`,
            },
            {
              field: 'subject',
              headerName: t('Subject'),
              minWidth: 150,
              flex: 1,
            },
            {
              field: 'type',
              headerName: t('Type'),
              width: 105,
            },
            {
              field: 'createdAt',
              headerName: t('Created'),
              width: 130,
              valueFormatter: ({ value }) =>
                moment(value).format('MMM DD, YYYY'),
            },
            {
              field: 'status',
              headerName: t('Status'),
              renderCell: ({ row }) => <TicketStatusChip status={row.status} />,
              width: 120,
            },
            {
              field: '_',
              headerName: t('Action'),
              sortable: false,
              renderCell: () => <TicketsTableActionMenu />,
            },
          ]}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          autoHeight
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnFilter
        />
      </CardContent>
    </Card>
  );
};

export default PageTicketsTable;
