import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
} from '@mui/material';

import SPAInput from './SPAInput';
import CollapseButton from 'src/components/ui/CollapseButton';

const PageSPACard = ({ spa, onChange }) => {
  const [opened, setOpened] = useState(true);

  const handleToggle = () => {
    setOpened((value) => !value);
  };

  return (
    <Card
      sx={{
        mb: 2.5,
      }}
    >
      <CardHeader
        title={
          <Typography fontSize={18} fontWeight={600} colorscheme="heading">
            Sales Purchase Agreement
          </Typography>
        }
        emphasis="true"
        action={<CollapseButton opened={opened} onToggle={handleToggle} />}
      />
      <Collapse in={opened}>
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          <Typography fontSize={14}>
            Please upload the Sales Purchase Agreement for the buyer.
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={600}
            colorscheme="heading"
            mt={2}
          >
            Attach File
          </Typography>
          <SPAInput value={spa} onChange={onChange} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

PageSPACard.propTypes = {
  spa: PropTypes.any,
  onChange: PropTypes.func,
};

export default PageSPACard;
