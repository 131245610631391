import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import AttachmentsInput from './AttachmentsInput';

const schema = yup.object({
  email: yup.string().email().required(),
  subject: yup.string().required(),
  type: yup.string().required(),
  description: yup.string().required(),
  files: yup.array().optional(),
});

const PageFormView = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      subject: '',
      type: '',
      description: '',
      files: [],
    },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const handleOnSubmit = (values) => {
    console.log(values);
    navigate('/support/tickets');
  };

  return (
    <>
      <Card
        sx={{
          mt: 3,
        }}
      >
        <CardHeader
          title={
            <Typography
              fontSize={20}
              fontWeight={600}
              lineHeight={1.5}
              colorscheme="fonts.heading"
            >
              {t('Create new ticket')}
            </Typography>
          }
          emphasis="true"
        />
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          <Divider
            sx={{
              mt: 0,
              mb: 2,
            }}
          />
          <Stack
            direction="column"
            spacing={2}
            maxWidth={720}
            sx={{
              '& .MuiFormLabel-root': {
                fontSize: 16,
                fontWeight: 600,
                lineHeight: 1.5,
              },
            }}
          >
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  error={!!error?.message}
                >
                  <InputLabel shrink>{t('Your Email')}</InputLabel>
                  <OutlinedInput
                    placeholder={t('Enter your email')}
                    {...field}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="subject"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  error={!!error?.message}
                >
                  <InputLabel shrink>{t('Subject')}</InputLabel>
                  <OutlinedInput
                    placeholder={t('Enter subject')}
                    {...field}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="type"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  error={!!error?.message}
                >
                  <InputLabel shrink>{t('Type')}</InputLabel>
                  <Select {...field}>
                    <MenuItem value="any">Any</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  error={!!error?.message}
                >
                  <InputLabel shrink>{t('Description')}</InputLabel>
                  <OutlinedInput
                    placeholder={t('Short description of your issue')}
                    multiline
                    sx={{
                      '& textarea': {
                        p: 0,
                      },
                    }}
                    minRows={6}
                    {...field}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="files"
              render={({ fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  error={!!error?.message}
                >
                  <InputLabel shrink>
                    {t('Attach File')}{' '}
                    <span
                      style={{
                        color: '#8C979A',
                        fontWeight: 400,
                      }}
                    >
                      ({t('Optional')})
                    </span>
                  </InputLabel>
                  <AttachmentsInput />
                </FormControl>
              )}
            />
          </Stack>
        </CardContent>
      </Card>
      <Stack
        mt={3}
        direction="row"
        justifyContent="flex-end"
        spacing={2}
      >
        <Button
          variant="outlined"
          sx={{
            width: 104,
            height: 50,
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: 104,
            height: 50,
          }}
          onClick={handleSubmit(handleOnSubmit)}
        >
          {t('Submit')}
        </Button>
      </Stack>
    </>
  );
};

export default PageFormView;
