import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

import PageTradeOverviewCard from '../common/PageTradeOverviewCard';
import PageTradeItemsCard from '../common/PageTradeItemsCard';
import PagePersonInfoCard from '../common/PagePersonInfoCard';
import PageTradeFlowCard from '../common/PageTradeFlowCard';
import PageNotificationCard from './PageNotificationCard';
import PageTransactionsCard from '../common/PageTransactionsCard';
import PageDocumentsCard from '../common/PageDocumentsCard';

export default function PageSummaryTab({ active, data, refetch }) {
  const [status, setStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');

  useEffect(() => {
    if (data) {
      if (data.status === 'paying') {
        setStatus(data.status);
        for (const row of data.initialPayments) {
          if (row.status !== 'pending' && row.status !== 'confirmed') {
            setPaymentStatus(row.status);
            return;
          }
        }
      }

      if (data.status !== 'shipment') {
        return setStatus(data.status);
      }

      for (const shipment of data.shipments) {
        if (shipment.status !== 'pending' && shipment.status !== 'settled') {
          setStatus(shipment.status);
          for (const payment of shipment.payments) {
            if (
              payment.status !== 'pending' &&
              payment.status !== 'confirmed'
            ) {
              setPaymentStatus(payment.status);
              return;
            }
          }
        }
      }
    }
  }, [data]);

  return (
    <Box display={active ? 'block' : 'none'}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={8}>
          <PageNotificationCard data={data} />
          {[
            {
              weight: status === 'shipping' ? 9 : 1,
              children: (
                <PageDocumentsCard
                  data={data}
                  opened
                  showUpdate
                  title="Shipping Documents"
                  type="importing-document"
                  reload={refetch}
                />
              ),
            },
            {
              weight: status === 'manufacturing' ? 9 : 1,
              children: (
                <PageDocumentsCard
                  data={data}
                  opened
                  showUpdate
                  title="Manufacturing Documents"
                  type="manufacturing-document"
                  reload={refetch}
                />
              ),
            },
            {
              weight:
                status === 'paying' ||
                paymentStatus === 'invoicing' ||
                paymentStatus === 'checking' ||
                paymentStatus === 'accepting' ||
                paymentStatus === 'paying' ||
                paymentStatus === 'checked'
                  ? 10
                  : 1,
              children: (
                <>
                  <PageDocumentsCard
                    data={data}
                    opened
                    showUpdate
                    showStatus
                    title="Invoices"
                    type="invoice"
                    reload={refetch}
                  />
                  <PageTransactionsCard data={data} opened />
                </>
              ),
            },
            {
              weight: 1,
              children: (
                <>
                  <PageDocumentsCard
                    data={data}
                    opened
                    showUpdate
                    title="Sales Purchase Agreement"
                    type="sales-agreement"
                    reload={refetch}
                  />
                  <PageDocumentsCard
                    data={data}
                    opened
                    showUpdate
                    title="Proof of Product"
                    type="product-proof"
                    reload={refetch}
                  />
                </>
              ),
            },
            {
              weight: 1,
              children: <PageTradeOverviewCard data={data} />,
            },
            {
              weight: 1,
              children: (
                <PageDocumentsCard
                  data={data}
                  opened
                  showUpdate
                  title="Trade Requirements"
                  type="trade-requirement"
                  reload={refetch}
                />
              ),
            },
            {
              weight: 1,
              children: <PageTradeItemsCard data={data} />,
            },
          ]
            .sort((a, b) => b.weight - a.weight)
            .map((item, index) => (
              <div key={`card-${index}`}>{item.children}</div>
            ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <PagePersonInfoCard
            info={{
              name: data?.buyer?.company?.name,
              photo: data?.buyer?.company?.logo,
            }}
            members={data?.buyer?.users ?? []}
            role="buyer"
          />
          <PageTradeFlowCard data={data} status={status} />
        </Grid>
      </Grid>
    </Box>
  );
}

PageSummaryTab.propTypes = {
  active: PropTypes.bool,
  data: PropTypes.any,
  refetch: PropTypes.func,
};
