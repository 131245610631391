import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Breadcrumbs,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GHomeIcon, GMenu2Icon } from '../../../components/ui/AppIcon';
import colors from 'src/configs/colors';

const PageHeader = ({ drafting, publishing, onSaveDraft, onPublish }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const [anchor, setAnchor] = useState(null);

  const handleOpenMenu = (ev) => {
    setAnchor(ev.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return (
    <Stack direction="row" justifyContent="space-between" mb={2.5}>
      <Stack>
        <Breadcrumbs>
          <Link component={NavLink} to="/">
            <GHomeIcon />
          </Link>
          <Link component={NavLink} to="/profile/products">
            {t('My Products')}
          </Link>
          <Link component={NavLink} to="#">
            {params.id ? 'Edit Product' : 'Add New Product'}
          </Link>
        </Breadcrumbs>
        <Typography
          fontSize={24}
          fontWeight={600}
          lineHeight="34px"
          colorscheme="fonts.heading"
          mt={1}
        >
          {params.id ? 'Edit Product' : 'Add New Product'}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        {isSm ? (
          <>
            <IconButton
              sx={{
                border: '1px solid #D7D7D7',
                borderRadius: '6px',
                color: colors.primary,
              }}
              onClick={handleOpenMenu}
            >
              <GMenu2Icon />
            </IconButton>
            <Menu
              open={Boolean(anchor)}
              onClose={handleCloseMenu}
              anchorEl={anchor}
            >
              <MenuItem disabled={drafting || publishing} onClick={onSaveDraft}>
                <ListItemText>{t('Save to Draft')}</ListItemText>
              </MenuItem>
              <MenuItem disabled={drafting || publishing} onClick={onPublish}>
                <ListItemText>{t('Approve')}</ListItemText>
              </MenuItem>
              <MenuItem component={NavLink} to="/profile/products" replace>
                <ListItemText>Cancel</ListItemText>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              size="small"
              component={NavLink}
              to="/profile/products"
              replace
            >
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              size="small"
              loading={drafting}
              disabled={publishing}
              onClick={onSaveDraft}
            >
              Save to Draft
            </LoadingButton>
            <LoadingButton
              variant="contained"
              size="small"
              loading={publishing}
              disabled={drafting}
              onClick={onPublish}
            >
              Approve
            </LoadingButton>
          </>
        )}
      </Stack>
    </Stack>
  );
};

PageHeader.propTypes = {
  drafting: PropTypes.bool,
  publishing: PropTypes.bool,
  onSaveDraft: PropTypes.func,
  onPublish: PropTypes.func,
};

export default PageHeader;
