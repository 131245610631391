import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const PageHeader = ({ processing, onReadAll }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={3}
    >
      <Typography
        fontSize={30}
        fontWeight={600}
        lineHeight="38px"
        color="#303030"
      >
        Notifications
      </Typography>
      <Box>
        <LoadingButton
          size="small"
          variant="contained"
          loading={processing}
          onClick={onReadAll}
        >
          Read All
        </LoadingButton>
      </Box>
    </Stack>
  );
};

PageHeader.propTypes = {
  processing: PropTypes.bool,
  onReadAll: PropTypes.func,
};

export default PageHeader;
