import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const Center = ({ children, ...boxProps }) => (
  <Box
    {...boxProps}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Box>
);

Center.propTypes = {
  children: PropTypes.any,
};

export default Center;
