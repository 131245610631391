/* eslint-disable no-async-promise-executor */
import { State, City } from 'country-state-city';
import axios from 'axios';

export const apiGetStates = async (countryCode) => {
  return new Promise((resolve) => {
    const states = State.getStatesOfCountry(countryCode);
    const result = states.map((item) => item.name);
    resolve(result);
  });
};

export const apiGetCities = async (countryCode, stateName) => {
  return new Promise((resolve) => {
    const states = State.getStatesOfCountry(countryCode);
    for (const item of states) {
      if (item.name === stateName) {
        const cities = City.getCitiesOfState(countryCode, item.isoCode);
        return resolve(cities.map((city) => city.name));
      }
    }

    return resolve([]);
  });
};

export const apiGetLocationInfo = async () => {
  try {
    const response = await axios.get('https://ipapi.co/json', {
      baseURL: '',
    });
    return {
      country: response.data?.country_code ? response.data.country_code : 'US',
      countryCallingCode: response.data?.country_calling_code
        ? response.data.country_calling_code
        : '+1',
    };
  } catch {
    return {
      country: 'US',
      countryCallingCode: '+1',
    };
  }
};
