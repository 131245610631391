import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';

import DevSideMenu from './DevSideMenu';

const FullScreenContainer = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const DevLayout = () => {
  return (
    <FullScreenContainer>
      <DevSideMenu />
      <Scrollbars
        style={{
          flex: 1,
          height: '100vh',
        }}
      >
        <Box sx={{ w: '100%', minHeight: '100vh', p: 4 }}>
          <Outlet />
        </Box>
      </Scrollbars>
    </FullScreenContainer>
  );
};

export default DevLayout;
