import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Container, Stack, Link, Box } from '@mui/material';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CategoryItem from '../../../../components/items/CategoryItem';
import EmptyResult from 'src/components/ui/EmptyResult';
import { GArrowLeftIcon } from '../../../../components/ui/AppIcon';
import constants from 'src/configs/constants';

const PageCategoriesView = ({ data, loading }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        mt: 2.5,
        pb: 10,
      }}
    >
      <Link
        component={NavLink}
        to="/marketplace"
        sx={{
          display: 'inline-block',
          '&:not(:hover)': {
            textDecoration: 'none',
          },
        }}
        colorscheme="heading"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <GArrowLeftIcon
            sx={{
              width: 32,
              height: 32,
            }}
          />
          <Typography fontSize={30} fontWeight={600}>
            {t('Categories')}
          </Typography>
        </Stack>
      </Link>
      <Box mt={2.5}>
        <Grid container rowSpacing={2.5} columnSpacing={2.5}>
          {loading ? (
            Array(9)
              .fill(1)
              .map((item, index) => (
                <Grid key={`category-placeholder-${index}`} item xs={12} md={4}>
                  <CategoryItem loading subtitle="..." drawShadow />
                </Grid>
              ))
          ) : data.length > 0 ? (
            data.map((category, index) => (
              <Grid item xs={12} md={4} key={`sub-cat-${index}-item`}>
                <CategoryItem
                  image={constants.getStoragePath(category.image)}
                  title={category.name}
                  subtitle={category.description}
                  drawShadow
                  onClick={() =>
                    navigate(
                      `/marketplace/products?parentCategory=${
                        params.categoryName ? params.categoryName : 'All'
                      }&subCategory=${category.name}`,
                    )
                  }
                />
              </Grid>
            ))
          ) : (
            <Grid xs={12} item>
              <EmptyResult />
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

PageCategoriesView.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default PageCategoriesView;
