import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { GUserIcon } from '../../../components/ui/AppIcon';
import TableFilter from '../../../components/ui/TableFilter';
import MemberStatusChip from '../../../components/chips/MemberStatusChip';
import ActionMenu from './ActionMenu';
import constants from 'src/configs/constants';

const PageMembersCard = ({
  members: data,
  loading,
  onViewTrades,
  onCancelInvite,
  onEnableUser,
  onDisableUser,
}) => {
  const { t } = useTranslation();

  const [members, setMembers] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (data) {
      if (search) {
        setMembers(
          data.filter((item) =>
            item.email.toLowerCase().includes(search.toLowerCase()),
          ),
        );
      } else {
        setMembers(data);
      }
    } else {
      setMembers([]);
    }
  }, [data, search]);

  return (
    <Card>
      <CardHeader
        title={
          <Typography
            fontSize={20}
            fontWeight={500}
            lineHeight={1.5}
            colorscheme="fonts.heading"
          >
            {t('Team Members')}
          </Typography>
        }
        emphasis="true"
        action={
          <TableFilter
            hideFilter
            hideSorter
            search={search}
            onSearch={setSearch}
          />
        }
      />
      <CardContent>
        <DataGrid
          rows={members}
          rowCount={members.length}
          columns={[
            {
              field: 'name',
              headerName: t('Name'),
              width: 140,
              renderCell: ({ row }) => (
                <Stack spacing={1} direction="row" alignItems="center">
                  <Avatar
                    src={constants.getStoragePath(row.photo)}
                    sx={{
                      width: 32,
                      height: 32,
                    }}
                  >
                    <GUserIcon />
                  </Avatar>
                  <Box
                    flex={1}
                    sx={{
                      whiteSpace: 'normal',
                    }}
                  >
                    {row.firstName} {row.lastName}
                  </Box>
                </Stack>
              ),
              sortable: false,
            },
            {
              field: 'email',
              headerName: t('Email'),
              minWidth: 120,
              flex: 1,
            },
            {
              field: 'role',
              headerName: t('Role'),
              width: 120,
              valueFormatter: ({ value }) =>
                value === 'owner' ? 'Owner' : value === 'agent' ? 'Agent' : '',
              sortable: false,
            },
            {
              field: 'invitedAt',
              headerName: t('Invited On'),
              minWidth: 120,
              renderCell: ({ row }) =>
                row?.invitation?.invitedAt
                  ? moment(row?.invitation?.invitedAt).format('DD MMM, YYYY')
                  : '',
              sortComparator: (v1, v2) => {
                if (v1?.invitation?.invitedAt) {
                  if (v2?.invitation?.invitedAt) {
                    return moment(v1.invitation.invitedAt).isBefore(
                      moment(v2.invitation.invitedAt),
                    )
                      ? 1
                      : -1;
                  }
                  return 1;
                }
                if (v2?.invitation?.invitedAt) {
                  return -1;
                }
                return 1;
              },
            },
            {
              field: 'status',
              headerName: t('Status'),
              renderCell: ({ row }) => (
                <MemberStatusChip
                  status={row.status}
                  inviteStatus={row.invitation?.status}
                />
              ),
              width: 100,
              sortable: false,
            },
            {
              field: '_',
              headerName: t('Action'),
              sortable: false,
              renderCell: ({ row }) => (
                <ActionMenu
                  row={row}
                  onViewTrades={() => onViewTrades(row._id)}
                  onCancelInvite={() => onCancelInvite(row._id)}
                  onEnableUser={() => onEnableUser(row._id)}
                  onDisableUser={() => onDisableUser(row._id)}
                />
              ),
            },
          ]}
          loading={loading}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          autoHeight
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          getRowId={(row) => row._id}
        />
      </CardContent>
    </Card>
  );
};

PageMembersCard.propTypes = {
  members: PropTypes.array,
  loading: PropTypes.bool,
  onViewTrades: PropTypes.func,
  onCancelInvite: PropTypes.func,
  onEnableUser: PropTypes.func,
  onDisableUser: PropTypes.func,
};

export default PageMembersCard;
