import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import notify from 'src/configs/notify';

export default function PageSignerViewModal({ url, open, onFinish }) {
  const handleBoldSignEvent = (params) => {
    if (
      params.origin !== 'https://app-eu.boldsign.com' &&
      params.origin !== 'https://app.boldsign.com'
    ) {
      return;
    }

    switch (params.data.action) {
      case 'onDocumentSigned':
        notify({
          message: 'You have finished signing on the agreement successfully',
          type: 'success',
        });
        onFinish();
        break;
      case 'onDocumentSigningFailed':
        onFinish();
        break;
      case 'onDocumentReassigned':
        onFinish();
        break;
      case 'onDocumentReassigningFailed':
        onFinish();
        break;
      case 'onDocumentDeclined':
        onFinish();
        break;
      case 'onDocumentDecliningFailed':
        onFinish();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleBoldSignEvent);
    return () => {
      window.removeEventListener('message', handleBoldSignEvent);
    };
  }, []);

  return (
    <Dialog open={open} fullScreen>
      <iframe
        id="boldsign-signer-view"
        src={url}
        width="100%"
        height="100%"
        style={{
          border: 'none',
          boxShadow: 'none',
          outline: 'none',
        }}
      />
    </Dialog>
  );
}

PageSignerViewModal.propTypes = {
  url: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onFinish: PropTypes.func,
};
