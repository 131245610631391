import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import ProductStatusChip from '../../../components/chips/ProductStatusChip';
import TableFilter from 'src/components/ui/TableFilter';
import {
  GDotsVerticalIcon,
  GHelpIcon,
  GEditIcon,
  GTrashIcon,
} from '../../../components/ui/AppIcon';
import { useSelector } from 'react-redux';

const ActionMenu = ({ onView, onHelp, onDelete }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  const [el, setEl] = useState(null);
  const open = Boolean(el);

  const handleClick = (ev) => {
    setEl(ev.currentTarget);
  };

  const handleClose = () => {
    setEl(null);
  };

  const handleDelete = () => {
    handleClose();
    onDelete();
  };

  return (
    <>
      <IconButton
        sx={{
          width: 24,
          height: 24,
          borderRadius: '24px',
          ':disabled': {
            opacity: 0.5,
          },
        }}
        disabled={auth.role !== 'owner'}
        onClick={handleClick}
      >
        <GDotsVerticalIcon
          sx={{
            width: 16,
            height: 16,
            color: '#2D3643',
          }}
        />
      </IconButton>
      <Menu anchorEl={el} open={open} onClose={handleClose}>
        <MenuItem onClick={onView}>
          <ListItemIcon>
            <GEditIcon
              sx={{
                width: 24,
                height: 24,
                color: '#475B63',
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              fontSize={14}
              lineHeight="24px"
              colorscheme="fonts.text"
            >
              {t('Edit')}
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <GTrashIcon
              sx={{
                width: 24,
                height: 24,
                color: '#475B63',
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              fontSize={14}
              lineHeight="24px"
              colorscheme="fonts.text"
            >
              {t('Delete')}
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={onHelp}>
          <ListItemIcon>
            <GHelpIcon
              sx={{
                width: 24,
                height: 24,
                color: '#475B63',
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              fontSize={14}
              lineHeight="24px"
              colorscheme="fonts.text"
            >
              {t('Get Help')}
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

ActionMenu.propTypes = {
  onView: PropTypes.func,
  onHelp: PropTypes.func,
  onDelete: PropTypes.func,
};

const PageProductsTable = ({ products, loading, onView, onHelp, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Typography variant="h5" fontWeight={500}>
            Products List
          </Typography>
        }
        emphasis="true"
        action={<TableFilter hideFilter hideSorter />}
      />
      <CardContent sx={{}}>
        <DataGrid
          rows={products}
          columns={[
            {
              field: '_id',
              headerName: 'ID',
              width: 100,
              valueFormatter: ({ value }) =>
                `#${value.substr(-6).toUpperCase()}`,
            },
            {
              field: 'name',
              headerName: 'Product',
              minWidth: 200,
              flex: 1,
            },
            {
              field: 'category',
              headerName: 'Category',
              width: 140,
              valueFormatter: ({ value }) => value?.name,
            },
            {
              field: 'unit',
              headerName: 'Unit',
              width: 60,
              renderCell: ({ value }) => value,
            },
            {
              field: 'price',
              headerName: 'Price',
              width: 90,
              valueFormatter: ({ value }) =>
                value ? `$${Number(value).toFixed(2)}` : '',
            },
            {
              field: 'status',
              headerName: t('Status'),
              renderCell: ({ row }) => (
                <ProductStatusChip status={row.status} />
              ),
              width: 120,
            },
            {
              field: '_',
              headerName: t('Action'),
              sortable: false,
              renderCell: ({ row }) => (
                <ActionMenu
                  onHelp={() => onHelp(row._id)}
                  onView={() => onView(row._id)}
                  onDelete={() => onDelete(row._id)}
                />
              ),
            },
          ]}
          loading={loading}
          getRowId={(row) => row._id}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          autoHeight
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnFilter
        />
      </CardContent>
    </Card>
  );
};

PageProductsTable.propTypes = {
  loading: PropTypes.bool,
  products: PropTypes.array,
  onHelp: PropTypes.func,
  onView: PropTypes.func,
  onDelete: PropTypes.func,
};

export default PageProductsTable;
