import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Skeleton,
  Typography,
  styled,
} from '@mui/material';
import { Country } from 'country-state-city';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import constants from '../../../configs/constants';
import colors from 'src/configs/colors';

const NameTypography = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '22px',
  color: constants.colors.gray[500],
}));

const ValueTypography = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '24px',
  color: constants.colors.fonts.heading,
}));

const PageStaticPersonalDetails = ({ loading, profile }) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        mt: 2.5,
      }}
    >
      <CardHeader
        title={
          <Typography variant="lg" fontWeight={500} colorscheme="heading">
            {t('Personal Details')}
          </Typography>
        }
        emphasis="true"
        action={
          <Chip
            label={profile?.isVerified ? 'Verfied' : 'In Review'}
            sx={{
              bgcolor: profile?.isVerified
                ? colors['green.highlight']
                : colors['red.highlight'],
              color: profile?.isVerified
                ? colors['status.success']
                : colors['status.error'],
              fontWeight: 500,
            }}
            variant="mixed"
          />
        }
      />
      <CardContent
        sx={{
          pb: `16px !important`,
        }}
      >
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>First Name</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.firstName}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Last Name</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.lastName}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>DOB</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? (
                      <Skeleton />
                    ) : profile?.dob ? (
                      moment(profile?.dob).format('DD MMM, YYYY')
                    ) : (
                      ''
                    )}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Phone Number</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      formatPhoneNumberIntl('+' + profile?.phone)
                    )}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Address</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.address}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>State</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.state}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>City</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.city}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Zip Code</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.zipCode}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Country</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      Country.getCountryByCode(profile?.country)?.name
                    )}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

PageStaticPersonalDetails.propTypes = {
  loading: PropTypes.bool,
  profile: PropTypes.any,
};

export default PageStaticPersonalDetails;
