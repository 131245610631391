import React from 'react';
import PropTypes from 'prop-types';
import { Slide, Grid } from '@mui/material';

import PageNotificationsView from './PageNotificationsView';
import PageRecentActivityView from './PageRecentActivityView';

const PageBuyerWidgets = (props) => {
  if (!props.in) {
    return null;
  }

  return (
    <Slide
      in={props.in}
      direction="left"
      mountOnEnter
      unmountOnExit
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <PageNotificationsView />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <PageRecentActivityView />
        </Grid>
      </Grid>
    </Slide>
  );
};

PageBuyerWidgets.propTypes = {
  in: PropTypes.bool,
};

export default PageBuyerWidgets;
