import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Button,
  Grid,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import HighlightedTypography from '../../../components/ui/HighlightedTypography';
import { GCloseIcon } from '../../../components/ui/AppIcon';

const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  role: yup.string().required(),
});

const InviteMemberModal = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: 'agent',
    },
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = (values) => {
    onClose();
    onSubmit(values);
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 495,
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          color="#303030"
          fontSize={30}
          lineHeight="38px"
          fontWeight={600}
          display="block"
        >
          {t('Invite Member')}
        </HighlightedTypography>
        <Box mt={3}>
          <Grid container spacing={2.5}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error?.message}>
                    <InputLabel shrink>First Name</InputLabel>
                    <OutlinedInput
                      placeholder={t('Enter first name')}
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error?.message}>
                    <InputLabel shrink>Last Name</InputLabel>
                    <OutlinedInput
                      placeholder={t('Enter last name')}
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                error={!!error?.message}
                sx={{
                  mt: 2.5,
                }}
              >
                <InputLabel shrink>{t('Email')}</InputLabel>
                <OutlinedInput placeholder={t('Enter email')} {...field} />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="role"
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                error={!!error?.message}
                sx={{
                  mt: 2.5,
                }}
              >
                <InputLabel shrink>{t('Role')}</InputLabel>
                <Select {...field}>
                  <MenuItem value="agent">Agent</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Box>
        <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
          <Button variant="outlined" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button variant="contained" onClick={handleSubmit(handleOnSubmit)}>
            {t('Invite')}
          </Button>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

InviteMemberModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default InviteMemberModal;
