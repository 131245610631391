/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import CountrySelect from '../../../components/ui/CountrySelect';
import PhoneNumberInput from 'src/components/ui/PhoneNumberInput';
import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import SingleFileInputWithTitle from 'src/components/inputs/SingleFileInputWithTitle';
import { GCalendarIcon } from '../../../components/ui/AppIcon';
import {
  apiGetCities,
  apiGetLocationInfo,
  apiGetStates,
} from 'src/apis/locations';
import colors from 'src/configs/colors';

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    dob: yup.object().required(),
    phone: yup.string().min(6).required(),
    address: yup.string().required(),
    city: yup.string(),
    country: yup.string().required(),
    state: yup.string().required(),
    zipCode: yup.string().required(),

    identification: yup.object().required(),
    proofOfAddress: yup.object().required(),
    bankStatement: yup.object().required(),
  })
  .required();

const PersonalAccountSetup = ({
  onNextPage,
  onBackPage,
  onChangeData,
  defaultData,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      firstName: defaultData?.firstName ?? '',
      lastName: defaultData?.lastName ?? '',
      dob: defaultData?.dob ? moment(defaultData.dob) : null,
      phone: defaultData?.phone ?? '1',
      address: defaultData?.address ?? '',
      city: defaultData?.city ?? '',
      country: defaultData?.country ?? 'US',
      state: defaultData?.state ?? '',
      zipCode: defaultData?.zipCode ?? '',
      identification: defaultData?.identification ?? null,
      proofOfAddress: defaultData?.proofOfAddress ?? null,
      bankStatement: defaultData?.bankStatement ?? null,
    },
    resolver: yupResolver(schema),
  });
  const [country, state] = watch(['country', 'state']);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const handleOnFinish = useCallback(
    (values) => {
      onChangeData(values);
      onNextPage();
    },
    [onChangeData, onNextPage],
  );

  useEffect(() => {
    apiGetStates(country).then((result) => {
      setStates(result);
    });
    setValue('state', '');
    setValue('city', '');
  }, [country]);

  useEffect(() => {
    apiGetCities(country, state).then((result) => {
      setCities(result);
    });
    setValue('city', '');
  }, [country, state]);

  useEffect(() => {
    apiGetLocationInfo().then((result) => {
      if (result?.country && result?.countryCallingCode) {
        setValue('country', result.country);
        setValue('phone', result.countryCallingCode.replace('+', ''));
      }
    });
  }, []);

  return (
    <Box
      sx={{
        borderRadius: 1.5,
        p: 5,
        mb: 5,
        mt: 10,
        bgcolor: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Typography
        fontSize={36}
        fontWeight={600}
        textAlign="center"
        colorscheme="heading"
      >
        {t('Personal Detail')}
      </Typography>
      <Typography fontSize={16} textAlign="center" mt={1}>
        {t(
          'Please enter your personal details, the address here should be your personal address, not business',
        )}
      </Typography>
      <form onSubmit={handleSubmit(handleOnFinish)}>
        <Grid container mt={5} rowSpacing={2} columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('First Name')}</InputLabel>
                  <OutlinedInput
                    placeholder={t('Enter your first name')}
                    {...field}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('Last Name')}</InputLabel>
                  <OutlinedInput
                    placeholder={t('Enter your last name')}
                    {...field}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="dob"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required shrink>
                    {t('DOB')}
                  </InputLabel>
                  <DatePicker
                    placeholder={t('Choose')}
                    slots={{
                      openPickerIcon: GCalendarIcon,
                    }}
                    {...field}
                    sx={{
                      ...(error
                        ? {
                            '& fieldset': {
                              borderColor: `${colors['status.error']} !important`,
                            },
                          }
                        : {}),
                    }}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="phone"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <Box>
                  <InputLabel required shrink error={!!error}>
                    {t('Enter Number')}
                  </InputLabel>
                  <PhoneNumberInput innerRef={ref} {...fieldProps} />
                  {error?.message && (
                    <FormHelperText error={!!error}>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="address"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('Address')}</InputLabel>
                  <OutlinedInput
                    placeholder={t('Enter company address')}
                    {...field}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="country"
              render={({ field: { ref, ...props }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('Country')}</InputLabel>
                  <CountrySelect
                    {...props}
                    innerRef={ref}
                    showCountryName
                    fullWidth
                    placeholder={t('Select Country')}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="state"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('State')}</InputLabel>
                  <Autocomplete
                    options={states}
                    {...field}
                    onChange={(ev, value) => field.onChange(value)}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      option?.toLowerCase() === value?.toLowerCase()
                    }
                    renderInput={(params) => (
                      <OutlinedInput
                        {...params.InputProps}
                        inputProps={params.inputProps}
                        fullWidth
                        placeholder={t('Select state')}
                      />
                    )}
                    sx={{
                      display: 'block',
                    }}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="city"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  disabled={cities.length < 1}
                >
                  <InputLabel required>{t('City')}</InputLabel>
                  <Autocomplete
                    options={cities}
                    {...field}
                    onChange={(ev, value) => field.onChange(value)}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      option.toLowerCase() === value.toLowerCase()
                    }
                    renderInput={(params) => (
                      <OutlinedInput
                        {...params.InputProps}
                        inputProps={params.inputProps}
                        fullWidth
                        placeholder={t('Select city')}
                      />
                    )}
                    disabled={cities.length < 1}
                    sx={{
                      display: 'block',
                    }}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="zipCode"
              render={({ field, fieldState: { error } }) => (
                <>
                  <FormControl fullWidth error={!!error}>
                    <InputLabel required>{t('Zip Code')}</InputLabel>
                    <OutlinedInput
                      placeholder={t('Enter ZIP code')}
                      {...field}
                    />
                    {error && (
                      <FormHelperText>
                        {t('This field is required')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              )}
            />
          </Grid>
        </Grid>
        <Box mt={3}>
          <HighlightedTypography variant="xl" colorscheme="heading">
            Personal Documents
          </HighlightedTypography>
          <Box mt={2}>
            <Controller
              control={control}
              name="identification"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <SingleFileInputWithTitle
                  title="Identification Document"
                  disableTitleEdit
                  disableRemove
                  required
                  folder="user-documents"
                  error={!!error}
                  value={value}
                  onValueChange={onChange}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              control={control}
              name="proofOfAddress"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <SingleFileInputWithTitle
                  title="Proof of Address (issued within 3 months)"
                  disableTitleEdit
                  disableRemove
                  required
                  folder="user-documents"
                  error={!!error}
                  value={value}
                  onValueChange={onChange}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              control={control}
              name="bankStatement"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <SingleFileInputWithTitle
                  title="Bank Statement (issued within 3 months)"
                  disableTitleEdit
                  disableRemove
                  required
                  folder="user-documents"
                  error={!!error}
                  value={value}
                  onValueChange={onChange}
                />
              )}
            />
          </Box>
        </Box>
        <Stack
          mt={5}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1.5}
        >
          <Button
            variant="outlined"
            sx={{ px: 2 }}
            size="small"
            onClick={onBackPage}
          >
            {t('Back')}
          </Button>
          <Button variant="contained" type="submit" size="small" sx={{ px: 4 }}>
            {t('Next')}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

PersonalAccountSetup.propTypes = {
  onNextPage: PropTypes.func,
  onBackPage: PropTypes.func,
  onChangeData: PropTypes.func,
  defaultData: PropTypes.any,
};

export default PersonalAccountSetup;
