import React from 'react';
import { Pagination as MuiPagination, styled } from '@mui/material';
import {
  gridPageSizeSelector,
  GridPagination,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';

import constants from '../../configs/constants';

const CustomPagination = styled(MuiPagination)(() => ({
  '& .MuiPaginationItem-root': {
    padding: '4px 10px',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    minWidth: 'unset !important',
    gap: 10,
    backgroundColor: constants.colors.gray[100],
    color: constants.colors.fonts.heading,
    fontSize: '12px !important',
    lineHeight: 1.5,
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  '& .Mui-selected': {
    backgroundColor: constants.colors.fills.primary,
    color: 'white',
  },
}));

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  return (
    <CustomPagination
      color="primary"
      className={className}
      count={Math.ceil(rowCount / pageSize)}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

Pagination.propTypes = {
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  className: PropTypes.string,
};

export default function AppDataGridPagination(props) {
  return (
    <GridPagination
      sx={{
        flex: 1,

        '& .MuiTablePagination-spacer': {
          flex: 0,
        },
        '& .MuiPagination-root': {
          marginLeft: 'auto !important',
        },
      }}
      ActionsComponent={Pagination}
      {...props}
    />
  );
}
