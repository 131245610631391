import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { Box, ButtonBase, styled } from '@mui/material';

import {
  GChevronLeft,
  GChevronRight,
  GMaximizeIcon,
  GMinimizeIcon,
} from './AppIcon';

const NavButton = styled(ButtonBase)(({ opened, left, right }) => ({
  color: 'white',
  padding: opened ? 16 : 8,
  borderRadius: 4,
  backgroundColor: '#2E2C2F80',
  position: opened ? 'fixed' : 'absolute',
  zIndex: 100,
  transition: 'background-color 0.2s',
  ...(right &&
    (opened
      ? {
          top: 'calc(50vh - 26px)',
          right: 40,
        }
      : {
          right: 8,
          bottom: -88,
        })),
  ...(left &&
    (opened
      ? {
          top: 'calc(50vh - 26px)',
          left: 40,
        }
      : {
          left: 8,
          bottom: -88,
        })),
  '& > svg': {
    width: opened ? 32 : 16,
    height: opened ? 32 : 16,
  },
  '&:hover': {
    backgroundColor: '#2E2C2FAA',
  },
}));

const FullScreenButton = styled(ButtonBase)(
  ({ isFullScreen: isfullscreen }) => ({
    position: isfullscreen ? 'fixed' : 'absolute',
    zIndex: 100,
    color: 'white',
    padding: 8,
    backgroundColor: isfullscreen ? '#00000088' : '#00000033',
    borderRadius: '100%',
    right: isfullscreen ? 25 : 24,
    ...(isfullscreen && {
      top: 23,
    }),
    ...(!isfullscreen && {
      bottom: 24,
    }),
  }),
);

const ThumbBox = styled(Box)(() => ({
  borderRadius: 8,
  overflow: 'hidden',
  '& img': {
    width: 170,
    height: 114,
    display: 'block',
  },
}));

const ProductImageGallery = ({ items }) => {
  const [opened, setOpened] = useState(false);

  const renderFullScreenButton = (onClick, isFullScreen) => {
    setOpened(!!isFullScreen);

    return (
      <FullScreenButton
        isfullscreen={isFullScreen ? 'true' : undefined}
        onClick={onClick}
      >
        {isFullScreen ? <GMinimizeIcon /> : <GMaximizeIcon />}
      </FullScreenButton>
    );
  };

  const renderRightNav = (onClick, disabled) => (
    <NavButton
      right="true"
      opened={opened}
      disabled={disabled}
      onClick={onClick}
    >
      <GChevronRight />
    </NavButton>
  );

  const renderLeftNav = (onClick, disabled) => (
    <NavButton
      left="true"
      opened={opened}
      disabled={disabled}
      onClick={onClick}
    >
      <GChevronLeft />
    </NavButton>
  );

  const renderThumbInner = (item) => (
    <ThumbBox>
      <img
        src={item.thumbnail || item.original}
        alt="Product Image"
        style={{
          objectFit: 'cover',
        }}
      />
    </ThumbBox>
  );

  return (
    <ImageGallery
      items={items}
      infinite
      showThumbnails={!opened}
      showPlayButton={false}
      thumbnailPosition="bottom"
      renderFullscreenButton={renderFullScreenButton}
      renderRightNav={renderRightNav}
      renderLeftNav={renderLeftNav}
      renderThumbInner={renderThumbInner}
      useBrowserFullscreen={false}
    />
  );
};

ProductImageGallery.propTypes = {
  items: PropTypes.array,
};

export default ProductImageGallery;
