import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

import {
  GDotsVerticalIcon,
  GEyeIcon,
  GUserCheckIcon,
  GUserOffIcon,
  GUserXIcon,
} from 'src/components/ui/AppIcon';
import TableMenu from 'src/components/ui/TableMenu';

const ActionMenu = ({
  row,
  onViewTrades,
  onCancelInvite,
  onEnableUser,
  onDisableUser,
}) => {
  const auth = useSelector((state) => state.auth);

  const [el, setEl] = useState(null);
  const open = Boolean(el);

  const handleClick = (ev) => {
    setEl(ev.currentTarget);
  };

  const handleClose = () => {
    setEl(null);
  };

  const handleActionClick = (callback) => {
    return () => {
      handleClose();
      callback();
    };
  };

  return (
    <>
      <IconButton disabled={auth?.role !== 'owner'} onClick={handleClick}>
        <GDotsVerticalIcon
          sx={{
            width: 16,
            height: 16,
            color: auth?.role === 'owner' ? '#2D3643' : '#DDDDDD',
          }}
        />
      </IconButton>
      <TableMenu anchorEl={el} open={open} onClose={handleClose}>
        <MenuItem
          onClick={handleActionClick(onViewTrades)}
          disabled={row?.invitation?.status !== 'accepted'}
        >
          <ListItemIcon>
            <GEyeIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>View Agents Trade</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={handleActionClick(onCancelInvite)}
          disabled={row?.invitation?.status !== 'pending'}
        >
          <ListItemIcon>
            <GUserOffIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>Cancel Invite</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={handleActionClick(onDisableUser)}
          disabled={row?.invitation?.status !== 'accepted'}
        >
          <ListItemIcon>
            <GUserXIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>Disable User</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={handleActionClick(onEnableUser)}
          disabled={row?.invitation?.status !== 'accepted'}
        >
          <ListItemIcon>
            <GUserCheckIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>Enable User</Typography>
          </ListItemText>
        </MenuItem>
      </TableMenu>
    </>
  );
};

ActionMenu.propTypes = {
  row: PropTypes.any,
  onViewTrades: PropTypes.func,
  onCancelInvite: PropTypes.func,
  onDisableUser: PropTypes.func,
  onEnableUser: PropTypes.func,
};

export default ActionMenu;
