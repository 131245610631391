import { styled, IconButton } from '@mui/material';
import colors from 'src/configs/colors';

const TableActionButton = styled(IconButton)(() => ({
  width: 24,
  height: 24,
  borderRadius: 24,

  '& > svg': {
    width: 16,
    height: 16,
    color: colors.text,
  },
}));

export default TableActionButton;
