import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

import colors from 'src/configs/colors';

const configs = {
  active: {
    back: colors['green.highlight'],
    front: colors['status.success'],
    label: 'Active',
  },
  disabled: {
    back: colors['red.highlight'],
    front: colors['status.error'],
    label: 'Disabled',
  },
  pending: {
    back: colors['blue.highlight'],
    front: colors['status.info'],
    label: 'Pending',
  },
  rejected: {
    back: colors['red.highlight'],
    front: colors['status.error'],
    label: 'Rejected',
  },
};

const MemberStatusChip = ({ status, inviteStatus, ...props }) => {
  return (
    <Chip
      label={
        configs[
          inviteStatus && inviteStatus !== 'accepted' ? inviteStatus : status
        ]?.label
      }
      sx={{
        backgroundColor:
          configs[
            inviteStatus && inviteStatus !== 'accepted' ? inviteStatus : status
          ]?.back,
        '& .MuiChip-label': {
          color:
            configs[
              inviteStatus && inviteStatus !== 'accepted'
                ? inviteStatus
                : status
            ]?.front,
          fontWeight: 500,
          fontSize: 12,
          lineHeight: '16px',
        },
      }}
      {...props}
    />
  );
};

MemberStatusChip.propTypes = {
  status: PropTypes.string,
  inviteStatus: PropTypes.string,
};

export default MemberStatusChip;
