import React from 'react';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const colors = {
  resolved: {
    back: '#ECFFEC',
    front: '#48A96E',
  },
  pending: {
    back: '#FFF5E8',
    front: '#F5A540',
  },
  open: {
    back: '#E9EFFF',
    front: '#4383FF',
  },
};

const TicketStatusChip = ({ status, sx, ...props }) => {
  const { t } = useTranslation();

  return (
    <Chip
      label={
        status === 'open'
          ? t('Open')
          : status === 'pending'
          ? t('Pending')
          : status === 'resolved'
          ? t('Resolved')
          : ''
      }
      sx={{
        backgroundColor: colors[status].back,
        '& .MuiChip-label': {
          color: colors[status].front,
          fontWeight: 500,
          fontSize: 12,
          lineHeight: '16px',
        },
        ...sx,
      }}
      {...props}
    />
  );
};

TicketStatusChip.propTypes = {
  status: PropTypes.string,
  sx: PropTypes.any,
};

export default TicketStatusChip;
