import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';

import Center from '../../components/ui/Center';
import notify from '../../configs/notify';
import colors from 'src/configs/colors';

import { apiRequestEmailOTP, apiSignIn } from '../../apis/auth';
import { setAuth } from '../../redux/auth/slice';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const schema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email(t('Your email address is not a valid one'))
          .required(t('Please provide your email address')),
        password: yup.string().required(t('The password is required')),
      }),
    [t],
  );
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(true);

  const handleSignIn = useCallback(
    (values) => {
      setLoading(true);
      apiSignIn({
        email: values.email,
        password: values.password,
      })
        .then((result) => {
          localStorage.setItem(
            'omp-auth-remember',
            remember ? 'true' : 'false',
          );

          if (result?.error === 'ERROR_EMAIL_NOT_VERIFIED') {
            apiRequestEmailOTP({
              email: values.email,
            });
            Swal.fire({
              title: 'Email Verification Required',
              text: `To continue, you need to verify your email address first. We’ve sent a verification email to ${values.email}`,
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Done',
              confirmButtonColor: colors.primary,
              allowEnterKey: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
            }).then(() => {
              dispatch(
                setAuth({
                  ...result.user,
                }),
              );
              navigate(`/auth/verify?action=verify&email=${values.email}`);
            });
            return;
          }
          if (result?.error === 'ERROR_2FA_ENABLED') {
            if (result?.data?.method === 'email') {
              Swal.fire({
                title: 'Two-Factor Authentication Required',
                text: `We have sent an OTP to your registered email address ${values.email}, please click next and enter the code.`,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'Next',
                confirmButtonColor: colors.primary,
              }).then(() => {
                navigate(
                  `/auth/verify?action=2fa-email&email=${result?.data?.email}`,
                );
              });
            }
            if (result?.data?.method === 'sms') {
              Swal.fire({
                title: 'Two-Factor Authentication Required',
                text: `We have sent an OTP to your registered phone number +${result?.data?.phone}, please click next and enter the code.`,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'Next',
                confirmButtonColor: colors.primary,
              }).then(() => {
                navigate(
                  `/auth/verify?action=2fa-sms&phone=${result?.data?.phone}`,
                );
              });
            }
            return;
          }

          if (result) {
            notify({
              message: t('You have signed in successfully'),
              type: 'success',
            });
            dispatch(
              setAuth({
                ...result.user,
              }),
            );

            if (result.user.isSetupCompleted) {
              navigate('/dashboard', {
                replace: true,
              });
            } else {
              navigate('/setup-account', {
                replace: true,
              });
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [remember],
  );

  return (
    <Center minHeight="100vh" py={3} px={6} boxSizing="border-box">
      <Box maxWidth={512}>
        <Typography fontSize={48} fontWeight={600} colorscheme="gray.900">
          {t('Sign In')}
        </Typography>
        <Typography
          fontSize={20}
          lineHeight={1.5}
          colorscheme="gray.500"
          mt={3}
        >
          {t('Sign in to discover your next trading opportunity')}
        </Typography>
        <Box mt={8}>
          <form onSubmit={handleSubmit(handleSignIn)}>
            <Controller
              name="email"
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!errors.email}
                  disabled={loading}
                >
                  <InputLabel>{t('Email')}</InputLabel>
                  <OutlinedInput
                    placeholder={t('Your Email Address')}
                    {...field}
                  />
                  {errors.email?.message && (
                    <FormHelperText>{errors.email?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{ mt: 3 }}
                  error={!!errors.password}
                  disabled={loading}
                >
                  <InputLabel>{t('Password')}</InputLabel>
                  <OutlinedInput
                    placeholder={t('Your Password')}
                    type="password"
                    {...field}
                  />
                  {errors.password?.message && (
                    <FormHelperText>{errors.password?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormControlLabel
                control={<Checkbox />}
                label={t('Remember Me')}
                disabled={loading}
                checked={remember}
                onChange={(ev, checked) => setRemember(checked)}
              />
              <Link
                component={NavLink}
                to="/auth/forgot-password"
                sx={{ fontSize: 14 }}
              >
                {t('Forgot Password?')}
              </Link>
            </Stack>
            <LoadingButton
              fullWidth
              sx={{ mt: 5 }}
              variant="contained"
              loading={loading}
              onClick={handleSubmit(handleSignIn)}
              type="submit"
            >
              {t('Sign In')}
            </LoadingButton>
          </form>
        </Box>
        <Typography
          fontSize={16}
          fontWeight={500}
          colorscheme="heading"
          textAlign="center"
          mt={3}
        >
          {t("Don't have an account?")}{' '}
          <Link
            component={NavLink}
            to="/auth/register"
            display="inline"
            replace
          >
            {t('Sign Up')}
          </Link>
        </Typography>
      </Box>
    </Center>
  );
};

export default LoginPage;
