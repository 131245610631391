import React from 'react';
import PropTypes from 'prop-types';

import image from '../../assets/logos/logo-without-title.png';

const SimpleLogo = ({ small }) => {
  return (
    <img
      src={image}
      alt="Logo"
      style={{
        width: 65,
        height: 73,
        display: 'block',
        objectFit: 'contain',
        transition: 'all 0.15s',
        ...(small && {
          width: 55,
          height: 61,
        }),
      }}
    />
  );
};

SimpleLogo.propTypes = {
  small: PropTypes.bool,
};

export default SimpleLogo;
