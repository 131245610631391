import React, { Component } from 'react';
import { Box, styled } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { t } from 'i18next';

import { apiGetLocationInfo } from 'src/apis/locations';
import colors from 'src/configs/colors';
import 'react-phone-input-2/lib/material.css';

const CustomizedPhoneInput = styled(PhoneInput)(() => ({
  fontFamily: 'inherit',
  height: 51,

  '& .special-label': {
    display: 'none',
  },
  '& input.form-control': {
    fontFamily: 'Montserrat',
    padding: '14px 16px',
    paddingLeft: 60,
    fontSize: 16,
    lineHeight: 1.5,
    transition: 'none',
    width: '100%',
    height: 51,
    borderRadius: 8,

    '&:focus': {
      borderColor: colors.primary,
      boxShadow: `0 0 0 1px ${colors.primary}`,
    },
  },
  '& .flag-dropdown': {
    fontSize: 16,
  },
  '& .selected-flag': {
    paddingLeft: 16,
  },
}));

export default class PhoneNumberInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 'pc' + Date.now(),
      preferredCountries: [],
    };
  }

  componentDidMount() {
    apiGetLocationInfo().then((result) => {
      if (result?.country) {
        this.setState({
          preferredCountries: [result.country.toLowerCase()],
          key: 'pc' + Date.now(),
        });
      } else {
        this.setState({
          preferredCountries: ['us'],
          key: 'pc' + Date.now(),
        });
      }
    });
  }

  render() {
    return (
      <Box mt="3px">
        <CustomizedPhoneInput
          key={this.state.key}
          preferredCountries={this.state.preferredCountries}
          country={
            this.state.preferredCountries.length > 0
              ? this.state.preferredCountries[0]
              : 'us'
          }
          enableSearch
          placeholder={t('Enter number')}
          {...this.props}
        />
      </Box>
    );
  }
}
