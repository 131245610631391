import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import countries from 'i18n-iso-countries';

import { GUserIcon } from 'src/components/ui/AppIcon';
import constants from 'src/configs/constants';

const PageSummary = ({ loading, product, metadata }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card>
        <CardContent
          sx={{
            p: '16px !important',
          }}
        >
          <Typography fontSize={20} fontWeight={600} colorscheme="heading">
            {t('Summary')}
          </Typography>
          <Divider
            sx={{
              mt: 1.5,
              mb: 2,
            }}
          />
          <Stack spacing={1.5} direction="column">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontSize={14}>{t('Quantity')}</Typography>
              <Typography fontSize={14} fontWeight={500} colorscheme="heading">
                {Number(metadata.quantity).toLocaleString()}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontSize={14} lineHeight="24px">
                {t('Number of Shipments')}
              </Typography>
              <Typography fontSize={14} fontWeight={500} colorscheme="heading">
                {metadata.numberOfShipments}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontSize={14} lineHeight="24px">
                {t('Shipment Type')}
              </Typography>
              <Typography fontSize={14} fontWeight={500} colorscheme="heading">
                {metadata.incoterms}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontSize={14}>{t('Total')}</Typography>
              <Typography fontSize={14} fontWeight={500} colorscheme="heading">
                $
                {(
                  metadata.quantity *
                  metadata.numberOfShipments *
                  product?.price
                ).toLocaleString()}
              </Typography>
            </Stack>
          </Stack>
          <Divider
            sx={{
              my: 2,
            }}
          />
          <Typography
            fontSize={16}
            fontWeight={600}
            lineHeight={1.5}
            color="#212121"
          >
            {t('Seller detail')}
          </Typography>
          <Stack direction="row" alignItems="center" spacing="6px" mt={1}>
            <Avatar
              src={constants.getStoragePath(product?.company?.logo)}
              sx={{
                width: 30,
                height: 30,
              }}
            >
              <GUserIcon />
            </Avatar>
            <Typography fontSize={14} lineHeight="22px" colorscheme="gray.700">
              {product?.company?.name}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            my={1.5}
          >
            <Typography fontSize={14}>{t('Location')}</Typography>
            <Typography fontSize={14} fontWeight={500} colorscheme="heading">
              {countries.getName(product?.location, 'en')}
            </Typography>
          </Stack>
          <Typography
            fontSize={14}
            colorscheme="status.info"
            mt={1.5}
            sx={{
              cursor: 'pointer',
              display: 'none',
            }}
          >
            {t('View Profile')}
          </Typography>
          <Box mt={4}>
            <LoadingButton
              variant="contained"
              size="small"
              fullWidth
              loading={loading}
              type="submit"
            >
              {t('Submit Order')}
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

PageSummary.propTypes = {
  loading: PropTypes.bool,
  product: PropTypes.any,
  metadata: PropTypes.any,
};

export default PageSummary;
