import axios from 'axios';
import store from '../redux/store';
import { setAuth } from 'src/redux/auth/slice';

// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
axios.interceptors.request.use((config) => {
  const token = store.getState().auth?.accessToken;
  if (token) {
    config.headers.set('X-ACCESS-TOKEN', token);
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (
      error &&
      (error.response?.status == 401)
    ) {
      store.dispatch(setAuth({}));
    }
    return Promise.reject(error);
  },
);
