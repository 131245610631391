import axios from 'axios';
import mime from 'mime';

export function getFilenameFromUrl(url) {
  if (!url) {
    return '';
  }

  const arr = url.split('/');
  if (arr.length > 0) {
    return arr[arr.length - 1];
  }
  return '';
}

export async function getFilesizeFromUrl(url) {
  try {
    const response = await axios.head(url, {
      baseURL: '',
    });
    console.log(response.headers['Content-Length']);
    return response.headers['Content-Length'];
  } catch {
    return 0;
  }
}

export function getFileTypeFromUrl(url) {
  const result = mime.getType(url);

  if (result.includes('image/')) {
    return 'image';
  }
  if (result.includes('video/')) {
    return 'video';
  }
  if (result.includes('application/pdf')) {
    return 'pdf';
  }
  return '';
}
