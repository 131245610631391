import React from 'react';
import { Box, Grid, Paper, Typography, styled } from '@mui/material';

import DevHeader from '../components/DevHeader';
import constants from '../../configs/constants';

const ColorBox = styled(Box)`
  border-radius: 32px;
  border: 1px solid #d9dbe9;
  box-shadow: 0px 15px 44px 0px #080f340f;
  overflow: hidden;
`;

const DevColorsPage = () => {
  return (
    <Box>
      <DevHeader title="Colors" />
      <Box>
        <Typography
          variant="h3"
          gutterBottom
        >
          Fill Colors
        </Typography>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={3}
          >
            <ColorBox>
              <Paper
                sx={{ height: 180 }}
                bg="primary"
              />
              <Box p={3}>
                <Typography
                  variant="h4"
                  fontWeight={700}
                >
                  Primary
                </Typography>
                <Typography
                  variant="h6"
                  colorscheme="gray.500"
                >
                  {constants.colors.fills.primary}
                </Typography>
              </Box>
            </ColorBox>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <ColorBox>
              <Paper
                sx={{ height: 180 }}
                bg="highlight"
              />
              <Box p={3}>
                <Typography
                  variant="h4"
                  fontWeight={700}
                >
                  Highlight
                </Typography>
                <Typography
                  variant="h6"
                  colorscheme="gray.500"
                >
                  {constants.colors.fills.highlight}
                </Typography>
              </Box>
            </ColorBox>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <ColorBox>
              <Paper
                sx={{ height: 180 }}
                bg="secondary"
              />
              <Box p={3}>
                <Typography
                  variant="h4"
                  fontWeight={700}
                >
                  Secondary
                </Typography>
                <Typography
                  variant="h6"
                  colorscheme="gray.500"
                >
                  {constants.colors.fills.secondary}
                </Typography>
              </Box>
            </ColorBox>
          </Grid>
        </Grid>
        <Typography
          variant="h3"
          gutterBottom
          mt={4}
        >
          Typo Colors
        </Typography>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={3}
          >
            <ColorBox>
              <Paper
                sx={{ height: 180 }}
                bg="fonts.light"
              />
              <Box p={3}>
                <Typography
                  variant="h4"
                  fontWeight={700}
                >
                  White
                </Typography>
                <Typography
                  variant="h6"
                  colorscheme="gray.500"
                >
                  {constants.colors.fonts.white}
                </Typography>
              </Box>
            </ColorBox>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <ColorBox>
              <Paper
                sx={{ height: 180 }}
                bg="fonts.heading"
              />
              <Box p={3}>
                <Typography
                  variant="h4"
                  fontWeight={700}
                >
                  Headings
                </Typography>
                <Typography
                  variant="h6"
                  colorscheme="gray.500"
                >
                  {constants.colors.fonts.heading}
                </Typography>
              </Box>
            </ColorBox>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <ColorBox>
              <Paper
                sx={{ height: 180 }}
                bg="fonts.text"
              />
              <Box p={3}>
                <Typography
                  variant="h4"
                  fontWeight={700}
                >
                  Text
                </Typography>
                <Typography
                  variant="h6"
                  colorscheme="gray.500"
                >
                  {constants.colors.fonts.text}
                </Typography>
              </Box>
            </ColorBox>
          </Grid>
        </Grid>
        <Typography
          variant="h3"
          gutterBottom
          mt={4}
        >
          Gray Shades
        </Typography>
        <Grid
          container
          spacing={2}
        >
          {[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((item, index) => (
            <Grid
              item
              xs={3}
              key={`gray-shade-${index}`}
            >
              <ColorBox>
                <Paper
                  sx={{ height: 180 }}
                  bg={`gray.${item}`}
                />
                <Box p={3}>
                  <Typography
                    variant="h4"
                    fontWeight={700}
                  >
                    Gray/{item}
                  </Typography>
                  <Typography
                    variant="h6"
                    colorscheme="gray.500"
                  >
                    {constants.colors.gray[item]}
                  </Typography>
                </Box>
              </ColorBox>
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="h3"
          gutterBottom
          mt={4}
        >
          Outline Colors
        </Typography>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={3}
          >
            <Box>
              <Box
                sx={{
                  border: `2px solid ${constants.colors.outlines.secondary}`,
                  borderRadius: 2,
                  height: 180,
                }}
              />
              <Typography
                variant="h4"
                fontWeight={700}
                mt={2}
              >
                {constants.colors.outlines.secondary}
              </Typography>
              <Typography
                variant="h6"
                colorscheme="gray.500"
              >
                Secondary
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Box>
              <Box
                sx={{
                  border: `2px solid ${constants.colors.outlines.success}`,
                  borderRadius: 2,
                  height: 180,
                }}
              />
              <Typography
                variant="h4"
                fontWeight={700}
                mt={2}
              >
                {constants.colors.outlines.success}
              </Typography>
              <Typography
                variant="h6"
                colorscheme="gray.500"
              >
                Success
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          mt={2}
        >
          <Grid
            item
            xs={3}
          >
            <Box>
              <Box
                sx={{
                  border: `2px solid ${constants.colors.outlines.black}`,
                  borderRadius: 2,
                  height: 180,
                }}
              />
              <Typography
                variant="h4"
                fontWeight={700}
                mt={2}
              >
                {constants.colors.outlines.secondary}
              </Typography>
              <Typography
                variant="h6"
                colorscheme="gray.500"
              >
                Black
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Box>
              <Box
                sx={{
                  border: `2px solid ${constants.colors.outlines.gray}`,
                  borderRadius: 2,
                  height: 180,
                }}
              />
              <Typography
                variant="h4"
                fontWeight={700}
                mt={2}
              >
                {constants.colors.outlines.gray}
              </Typography>
              <Typography
                variant="h6"
                colorscheme="gray.500"
              >
                Gray
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Box>
              <Box
                sx={{
                  border: `2px solid ${constants.colors.outlines.lightGray}`,
                  borderRadius: 2,
                  height: 180,
                }}
              />
              <Typography
                variant="h4"
                fontWeight={700}
                mt={2}
              >
                {constants.colors.outlines.lightGray}
              </Typography>
              <Typography
                variant="h6"
                colorscheme="gray.500"
              >
                Gray Light
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Box>
              <Box
                sx={{
                  border: `2px solid ${constants.colors.outlines.white}`,
                  borderRadius: 2,
                  height: 180,
                }}
              />
              <Typography
                variant="h4"
                fontWeight={700}
                mt={2}
              >
                {constants.colors.outlines.white}
              </Typography>
              <Typography
                variant="h6"
                colorscheme="gray.500"
              >
                White
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography
          variant="h3"
          gutterBottom
          mt={4}
        >
          Shadow
        </Typography>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={3}
          >
            <Box>
              <Paper
                sx={{
                  border: `2px solid ${constants.colors.outlines.white}`,
                  borderRadius: 2,
                  height: 180,
                  boxShadow: '0px 0px 16px 0px #00000014',
                }}
              />
              <Typography
                variant="h4"
                fontWeight={700}
                mt={2}
              >
                1
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DevColorsPage;
