import axios from 'axios';
import { t } from 'i18next';
import notify from 'src/configs/notify';

export const apiGetMessagingConfig = async () => {
  const response = await axios.get('/api/v1/messaging/config');
  return response.data.data;
};

export const apiGetUnreadMessagesCount = async () => {
  const response = await axios.get('/api/v1/messaging/unreads');
  return response.data.data;
};

export const apiGetAvailableMembers = async () => {
  try {
    const response = await axios.get('/api/v1/messaging/members');
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return [];
  }
};

export const apiGetAvailableChannels = async () => {
  try {
    const response = await axios.get('/api/v1/messaging/channels');
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return [];
  }
};

export const apiLoadDirectChannel = async (target) => {
  try {
    const response = await axios.get(
      `/api/v1/messaging/channels/direct/${target}`,
    );
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};
