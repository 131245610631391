import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const PageHeader = () => {
  const params = useParams();

  return (
    <Container
      sx={{
        pt: '30px',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          fontSize={36}
          fontWeight={600}
          lineHeight="46px"
          colorscheme="heading"
        >
          {params.categoryName}
        </Typography>
      </Stack>
    </Container>
  );
};

export default PageHeader;
