const isProdMode = process.env.REACT_APP_ENV === 'production';
const isDevMode = process.env.REACT_APP_ENV === 'development';
const isStageMode = process.env.REACT_APP_ENV === 'stage';

const constants = {
  isProdMode: isProdMode,
  isDevMode: isDevMode,
  isStageMode: isStageMode,

  storageHost: process.env.REACT_APP_STORAGE_HOST
    ? process.env.REACT_APP_STORAGE_HOST
    : '',

  getStoragePath: function (key) {
    const host = process.env.REACT_APP_STORAGE_HOST
      ? process.env.REACT_APP_STORAGE_HOST
      : '';

    return `${host}/${key}`;
  },

  getBlockchainTransactionPath: function (hash) {
    const host = process.env.REACT_APP_BLOCKCHAIN_SCAN_PREFIX
      ? process.env.REACT_APP_BLOCKCHAIN_SCAN_PREFIX
      : 'https://sepolia.etherscan.io/tx';
    return `${host}/${hash}`;
  },
  getBlockchainContractPath: function (hash) {
    const host = process.env.REACT_APP_BLOCKCHAIN_CONTRACT_PREFIX
      ? process.env.REACT_APP_BLOCKCHAIN_CONTRACT_PREFIX
      : 'https://sepolia.etherscan.io/address';
    return `${host}/${hash}`;
  },

  colors: {
    fills: {
      primary: '#8C794E',
      highlight: '#BACDB0',
      secondary: '#F3E8EE',
      danger: '#F53A2E',
      gray: '#8C979A',
    },
    fonts: {
      white: '#FFFFFF',
      heading: '#2E2C2F',
      text: '#475B63',
    },
    gray: {
      50: '#FAFAFA',
      100: '#F4F4F4',
      200: '#EAEAEA',
      300: '#D7D7D7',
      400: '#969696',
      500: '#797979',
      600: '#5C5C5C',
      700: '#374151',
      800: '#464646',
      900: '#262626',
    },
    outlines: {
      secondary: '#3F598A',
      success: '#36B37E',
      black: '#183B56',
      gray: '#5A7184',
      lightGray: '#B3BAC5',
      white: '#FFFFFF',
    },
    shadows: {
      level1: '0px 0px 16px 0px rgba(0, 0, 0, 0.08)',
    },
  },
};

export default constants;
