import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grow, Typography, Stack, Avatar } from '@mui/material';

const ChatMessageItem = ({ message, side }) => {
  return (
    <Grow
      sx={{
        alignSelf: side === 'right' ? 'flex-end' : 'flex-start',
        maxWidth: '84%',
        mb: 1.5,
      }}
      in
      appear
    >
      <Stack direction="row">
        <Stack direction="row">
          {side === 'left' && (
            <Stack alignItems="center" width={62}>
              <Avatar sx={{ width: 46, height: 46 }} variant="rounded" />
              <Typography
                mt={1}
                textAlign="center"
                fontSize={14}
                colorscheme="gray.600"
              >
                6.21PM
                <br />
                4th July
              </Typography>
            </Stack>
          )}
        </Stack>
        <Box
          sx={{
            borderRadius: '8px',
            bgcolor: side === 'right' ? '#8C794E44' : '#D7D7D7',
            p: '14px',
            ml: side === 'left' ? 2 : 0,
            mr: side === 'right' ? 2 : 0,
          }}
        >
          <Typography fontSize={14} colorscheme="text">
            {message}
          </Typography>
        </Box>
        {side === 'right' && (
          <Stack alignItems="center" width={62}>
            <Avatar sx={{ width: 46, height: 46 }} variant="rounded" />
            <Typography
              mt={1}
              textAlign="center"
              fontSize={14}
              colorscheme="gray.600"
            >
              6.21PM
              <br />
              4th July
            </Typography>
          </Stack>
        )}
      </Stack>
    </Grow>
  );
};

ChatMessageItem.propTypes = {
  message: PropTypes.string,
  createdAt: PropTypes.string,
  side: PropTypes.string,
};

export default ChatMessageItem;
