import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <Box mb={2.5}>
      <Typography fontSize={36} fontWeight={600} colorscheme="heading">
        {t('Sales')}
      </Typography>
    </Box>
  );
};

export default PageHeader;
