import React from 'react';
import { Container, Stack, Typography } from '@mui/material';

const PageHeader = () => {
  return (
    <Container
      sx={{
        pt: '30px',
        mb: 2.5,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={36} fontWeight={600} colorscheme="heading">
          Categories
        </Typography>
      </Stack>
    </Container>
  );
};

export default PageHeader;
