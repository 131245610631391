import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  Grid,
  Link,
  Stack,
  CircularProgress,
} from '@mui/material';
import {
  apiGetBankAccounts,
  apiCreateBankAccount,
  apiUpdateBankAccount,
} from 'src/apis/profile';
import { useQuery } from 'react-query';

import Center from 'src/components/ui/Center';
import BankAccountEditModal from './BankAccountEditModal';
import BankAccountItem from './BankAccountItem';

function PageBankAccountsCard() {
  const {
    data: bankAccounts,
    isLoading,
    refetch,
  } = useQuery(['profile', 'bank-accounts'], async () => {
    return await apiGetBankAccounts();
  });
  const [selection, setSelection] = useState(null);
  const [editing, setEditing] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleCreate = () => {
    setEditing(true);
    setSelection({
      _id: 'new',
    });
  };

  const handleUpdate = (data) => {
    setEditing(true);
    setSelection(data);
  };

  const handleSelect = async (id) => {
    setProcessing(true);
    await apiUpdateBankAccount(id, {
      isDefault: true,
    });
    await refetch();
    setProcessing(false);
  };

  const handleSubmit = async (values) => {
    if (selection) {
      setProcessing(true);
      if (selection._id === 'new') {
        await apiCreateBankAccount(values);
      } else {
        await apiUpdateBankAccount(selection._id, values);
      }
      await refetch();
      setEditing(false);
      setProcessing(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          mb: 2.5,
        }}
      >
        <CardHeader
          title={
            <Typography fontSize={20} fontWeight={500} colorscheme="heading">
              Bank Account Details
            </Typography>
          }
          emphasis="true"
        />
        <CardContent>
          {isLoading ? (
            <Center height={200}>
              <CircularProgress size={20} />
              <Typography mt={2}>Loading bank accounts</Typography>
            </Center>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography>Select default payment method.</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                {bankAccounts?.length > 0 ? (
                  <Stack direction="column" spacing={1.5}>
                    {bankAccounts?.map((account) => (
                      <BankAccountItem
                        key={`bank-account-${account._id}`}
                        bank={account.bankName}
                        iban={account.iban}
                        loading={processing}
                        isSelected={account.isDefault}
                        onSelect={() => handleSelect(account._id)}
                        onEdit={() => handleUpdate(account)}
                      />
                    ))}
                  </Stack>
                ) : null}
                <Link
                  onClick={handleCreate}
                  href="#"
                  mt={bankAccounts?.length > 0 ? 2 : 0}
                >
                  + Add new account
                </Link>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <BankAccountEditModal
        open={editing}
        loading={processing}
        data={selection}
        onSubmit={handleSubmit}
        onClose={() => setEditing(false)}
      />
    </>
  );
}

export default PageBankAccountsCard;
