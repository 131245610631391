import { styled } from '@mui/material';
import { Timeline } from '@mui/lab';
import colors from 'src/configs/colors';

const AuditTrailTimeline = styled(Timeline)(() => ({
  marginBlockStart: 0,
  marginBlockEnd: 0,
  paddingInlineStart: 0,

  '& .MuiTimelineItem-root::before': {
    flex: 0,
    padding: 0,
  },
  '& .MuiTimelineDot-root': {
    width: 48,
    height: 48,
    m: 0,
    p: 0,
    boxShadow: 'none',
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors['orange.highlight'],

    '& > svg': {
      width: 28,
      height: 28,
      color: colors.primary,
    },
  },
  '& .MuiTimelineConnector-root': {
    backgroundColor: 'transparent',
    width: '1px',
    height: '32px',
    borderRight: '1px dashed #CCCCCC',
  },
  '& .MuiTimelineContent-root': {
    paddingTop: 20,
  },
}));

export default AuditTrailTimeline;
