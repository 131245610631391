import React from 'react';
import PropTypes from 'prop-types';
import { Slide, Grid, Box } from '@mui/material';

import PageStatsSummary from './PageStatsSummary';
import PageTopSaleView from './PageTopSaleView';
import PageBalanceHistory from './PageBalanceHistoryView';

const PageSellerWidgets = (props) => {
  if (props.in) {
    return (
      <Slide
        in={props.in}
        direction="right"
        mountOnEnter
        unmountOnExit
      >
        <Box>
          <PageStatsSummary />
          <Box mt={2}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={8}
              >
                <PageBalanceHistory />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
              >
                <PageTopSaleView />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Slide>
    );
  }

  return null;
};

PageSellerWidgets.propTypes = {
  in: PropTypes.bool,
  containerRef: PropTypes.any,
};

export default PageSellerWidgets;
