import React from 'react';
import { Avatar, ButtonBase } from '@mui/material';
import { GUserIcon } from '../../ui/AppIcon';

import { useSelector } from 'react-redux';
import constants from 'src/configs/constants';

const AppHeaderAvatar = ({ ...buttonProps }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <ButtonBase
      sx={{
        borderRadius: '100%',
      }}
      {...buttonProps}
    >
      <Avatar
        alt={auth?.username ? auth.username : 'Guest'}
        src={constants.getStoragePath(auth?.photo)}
        sx={{
          width: 40,
          height: 40,
          borderRadius: 40,
          border: `1px solid #E2E2E2`,
        }}
      >
        <GUserIcon />
      </Avatar>
    </ButtonBase>
  );
};

export default AppHeaderAvatar;
