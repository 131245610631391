import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { GCloseIcon } from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';

const ValueCell = ({ label }) => {
  return (
    <TableCell
      sx={{
        bgcolor:
          label === 'Seller'
            ? colors['orange.highlight']
            : label === 'Buyer'
            ? colors['green.highlight']
            : colors['gray.300'],
      }}
    >
      {label}
    </TableCell>
  );
};

ValueCell.propTypes = {
  label: PropTypes.string.isRequired,
};

const IncotermsModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 1200,
          maxWidth: 'calc(100vw - 40px)',
        },
      }}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography variant="h6">About Incoterms</Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Table
            sx={{
              '& .MuiTableCell-root': {
                textAlign: 'center',
                borderRight: `1px solid ${colors['gray.300']}`,

                '&:first-child': {
                  borderLeft: `1px solid ${colors['gray.300']}`,
                  bgcolor: colors['blue.highlight'],
                  fontWeight: 500,
                },
              },
              '& .MuiTableHead-root': {
                '& .MuiTableCell-root': {
                  borderTop: `1px solid ${colors['gray.300']}`,
                  bgcolor: colors['blue.highlight'],
                  fontWeight: 700,
                },
              },
            }}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>Incoterms</TableCell>
                <TableCell>EXW</TableCell>
                <TableCell>FCA</TableCell>
                <TableCell>FAS</TableCell>
                <TableCell>FOB</TableCell>
                <TableCell>CFR</TableCell>
                <TableCell>CIF</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    bgcolor: colors['gray.100'],
                  },
                }}
              >
                <TableCell></TableCell>
                <TableCell>Ex Works (Place)</TableCell>
                <TableCell>Free Carrier (Place)</TableCell>
                <TableCell>Free Alongside Ship (Port)</TableCell>
                <TableCell>Free On Board (Port)</TableCell>
                <TableCell>Cost & Freight (Port)</TableCell>
                <TableCell>Cost, Insurance & Freight (Port)</TableCell>
              </TableRow>
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    bgcolor: colors['red.highlight'],
                  },
                }}
              >
                <TableCell>Transfer of Risk</TableCell>
                <TableCell>At Buyers Disposal</TableCell>
                <TableCell>On Buyer Transport</TableCell>
                <TableCell>Alongside Ship</TableCell>
                <TableCell>On Board Vessel</TableCell>
                <TableCell>On Board Vessel</TableCell>
                <TableCell>On Board Vessel</TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    borderRight: 'none !important',
                  }}
                ></TableCell>
                <TableCell
                  colSpan={6}
                  align="center"
                  sx={{
                    borderLeft: 'none !important',
                  }}
                >
                  Responsibilities & Charges
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Export Packaging</TableCell>
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
              </TableRow>
              <TableRow>
                <TableCell>Loading Charges</TableCell>
                <ValueCell label="Buyer" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
              </TableRow>
              <TableRow>
                <TableCell>Delivery to Port/Place</TableCell>
                <ValueCell label="Buyer" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
              </TableRow>
              <TableRow>
                <TableCell>Export Duty, Taxes & Customs Clearance</TableCell>
                <ValueCell label="Buyer" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
              </TableRow>
              <TableRow>
                <TableCell>Origin Terminal Handling Charges</TableCell>
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
              </TableRow>
              <TableRow>
                <TableCell>Loading on Carriage</TableCell>
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
              </TableRow>
              <TableRow>
                <TableCell>Freight Charges</TableCell>
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Seller" />
                <ValueCell label="Seller" />
              </TableRow>
              <TableRow>
                <TableCell>Insurance</TableCell>
                <TableCell>Negotiable</TableCell>
                <TableCell>Negotiable</TableCell>
                <TableCell>Negotiable</TableCell>
                <TableCell>Negotiable</TableCell>
                <TableCell>Negotiable</TableCell>
                <ValueCell label="Seller" />
              </TableRow>
              <TableRow>
                <TableCell>Destination Terminal Handling Charges</TableCell>
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
              </TableRow>
              <TableRow>
                <TableCell>Delivery to Destination</TableCell>
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
              </TableRow>
              <TableRow>
                <TableCell>Unloading at Destination</TableCell>
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
              </TableRow>
              <TableRow>
                <TableCell>Import Duty, Taxes & Customs Clearance</TableCell>
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
                <ValueCell label="Buyer" />
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <IconButton
        sx={{
          position: 'absolute',
          right: 18,
          top: 18,
          width: 24,
          height: 24,
          bgcolor: '#EEEEEE',
        }}
        onClick={onClose}
      >
        <GCloseIcon
          sx={{
            width: 16,
            height: 16,
          }}
        />
      </IconButton>
    </Dialog>
  );
};

IncotermsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IncotermsModal;
