import React, { useCallback, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import PageTitle from './order-submission-page/PageTitle';
import PageAlert from './order-submission-page/PageAlert';
import PageOrderDetailCard from './order-submission-page/PageOrderDetailCard';
import PageShippingPreferencesCard from './order-submission-page/PageShippingPreferencesCard';
import PageTradeRequirementsCard from './order-submission-page/PageTradeRequirementsCard';
import PageSummary from './order-submission-page/PageSummary';
import ConfirmationModal from './order-submission-page/ConfirmationModal';
import { apiGetProductDetails } from 'src/apis/products';
import { apiSubmitOrder } from 'src/apis/trades';

const schema = yup
  .object({
    proofOfProductRequired: yup.boolean().required(),
    quantity: yup.number().integer().moreThan(0).required(),
    numberOfShipments: yup.number().integer().moreThan(0).required(),
    incoterms: yup.string().required(),
    port: yup.string().required(),
    tradeRequirements: yup.object().required(),
  })
  .required();

const OrderSubmissionPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const productId = params.productId;

  const { data: product, isLoading } = useQuery(
    ['products', productId],
    async () => {
      return await apiGetProductDetails(productId);
    },
  );
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      proofOfProductRequired: false,
      quantity: 0,
      numberOfShipments: 1,
      incoterms: '',
      port: '',
      tradeRequirements: null,
    },
    resolver: yupResolver(schema),
  });
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmitOrder = (values) => {
    setProcessing(true);
    apiSubmitOrder({
      ...values,
      tradeRequirements: values.tradeRequirements.url,
      productId: productId,
    })
      .then((result) => {
        if (result) {
          setOpen(true);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleIndicateError = (data) => {
    const requiredKeys = [
      'proofOfProductRequired',
      'quantity',
      'numberOfShipments',
      'incoterms',
      'port',
      'tradeRequirements',
    ];

    for (const key of requiredKeys) {
      if (data[key]) {
        const el = document.getElementById('field-' + key);
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
        return;
      }
    }
  };

  const handleConfirmSubmission = useCallback(() => {
    navigate('/trades/purchases');
  }, []);

  const incoterms = watch('incoterms');
  const quantity = watch('quantity');
  const numberOfShipments = watch('numberOfShipments');

  return (
    <Container
      sx={{
        pt: 4,
        pb: 12,
      }}
    >
      <PageTitle />
      <form onSubmit={handleSubmit(handleSubmitOrder, handleIndicateError)}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={8}>
            <PageAlert />
            <PageOrderDetailCard
              loading={isLoading}
              product={product}
              control={control}
            />
            <PageShippingPreferencesCard
              control={control}
              incoterms={incoterms}
              availableIncoterms={product?.incoterms ?? []}
            />
            <PageTradeRequirementsCard control={control} />
          </Grid>
          <Grid item xs={12} md={4}>
            <PageSummary
              loading={processing}
              product={product}
              metadata={{
                incoterms,
                quantity,
                numberOfShipments,
              }}
            />
          </Grid>
        </Grid>
      </form>
      <ConfirmationModal
        open={open}
        seller={product?.company}
        onConfirm={handleConfirmSubmission}
      />
    </Container>
  );
};

export default OrderSubmissionPage;
