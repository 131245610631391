import React, { useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Box,
  Autocomplete,
  Avatar,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import CountrySelect from '../../../components/ui/CountrySelect';
import PhoneNumberInput from 'src/components/ui/PhoneNumberInput';
import ImageCropModal from './ImageCropModal';
import { GCloudUploadIcon, GPhotoIcon } from '../../../components/ui/AppIcon';
import constants from 'src/configs/constants';

import { apiGetCities, apiGetStates } from 'src/apis/locations';
import { apiUploadFiles } from 'src/apis/upload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    address: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().required(),
    city: yup.string(),
    zipCode: yup.string().required(),
    logo: yup.string(),
    tradingName: yup.string().required(),
    regNumber: yup.string().required(),
  })
  .required();

const PageEditBusinessDetails = ({ reference, profile }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      address: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      tradingName: '',
      regNumber: '',
      logo: '',
    },
    resolver: yupResolver(schema),
  });

  const [country, state, logo, city] = watch([
    'country',
    'state',
    'logo',
    'city',
  ]);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [rawImage, setRawImage] = useState('');

  const handleSelectAvatar = (ev) => {
    if (ev.target.files.length < 1) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setRawImage(reader.result?.toString() || '');
    });
    reader.readAsDataURL(ev.target.files[0]);
  };

  const handleUploadLogo = (file) => {
    setUploading(true);

    const formData = new FormData();
    formData.append('files', file);
    formData.append('key', 'logos');
    apiUploadFiles(formData)
      .then((result) => {
        if (result) {
          setValue('logo', result);
        }
      })
      .finally(() => {
        setUploading(false);
      });
  };

  useImperativeHandle(reference, () => ({
    submitForm: (onValid, onInvalid) => {
      return handleSubmit(onValid, onInvalid);
    },
  }));

  useEffect(() => {
    if (profile) {
      setValue('name', profile.name);
      setValue('email', profile.email);
      setValue('address', profile.address);
      setValue('phone', profile.phone);
      setValue('country', profile.country);
      setValue('state', profile.state);
      setValue('city', profile.city);
      setValue('zipCode', profile.zipCode);
      setValue('logo', profile.logo ? profile.logo : '');
      setValue('tradingName', profile.tradingName ? profile.tradingName : '');
      setValue('regNumber', profile.regNumber ? profile.regNumber : '');
    }
  }, [profile]);

  useEffect(() => {
    apiGetStates(country).then((result) => {
      setStates(result);
    });
  }, [country]);

  useEffect(() => {
    apiGetCities(country, state).then((result) => {
      setCities(result);
    });
  }, [country, state]);

  useEffect(() => {
    if (typeof city !== 'string') {
      setValue('city', '');
    }
  }, [city]);

  return (
    <Card
      sx={{
        mt: 2.5,
      }}
    >
      <CardHeader
        title={
          <Typography
            fontSize={18}
            fontWeight={500}
            lineHeight="28px"
            colorscheme="fonts.heading"
          >
            {t('Business Details')}
          </Typography>
        }
        emphasis="true"
      />
      <CardContent
        sx={{
          pb: '16px !important',
          '& .MuiFormLabel-root': {
            fontWeight: 500,
            fontSize: 14,
          },
        }}
      >
        <Grid container columnSpacing={5} rowSpacing={2.5}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>Company Name</InputLabel>
                  <OutlinedInput
                    placeholder="Enter your company name"
                    {...field}
                  />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tradingName"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>Trading Name</InputLabel>
                  <OutlinedInput placeholder="Trading name" {...field} />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="regNumber"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>
                    Company Registration Number
                  </InputLabel>
                  <OutlinedInput placeholder="Registration Number" {...field} />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>
                    Company Email
                  </InputLabel>
                  <OutlinedInput
                    placeholder="Enter your company email"
                    {...field}
                  />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="phone"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <Box>
                  <InputLabel required shrink>
                    Enter Number
                  </InputLabel>
                  <PhoneNumberInput innerRef={ref} {...fieldProps} />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="address"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>Address</InputLabel>
                  <OutlinedInput placeholder="Address" {...field} />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="country"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error?.message}>
                  <InputLabel sx={{ fontWeight: 600 }}>Country</InputLabel>
                  <CountrySelect
                    {...field}
                    showCountryName
                    fullWidth
                    sx={{}}
                    placeholder="Select Country"
                  />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="state"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>State</InputLabel>
                  <Autocomplete
                    options={states}
                    {...field}
                    onChange={(ev, value) => field.onChange(value)}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      option?.toLowerCase() === value?.toLowerCase()
                    }
                    renderInput={(params) => (
                      <OutlinedInput
                        {...params.InputProps}
                        inputProps={params.inputProps}
                        fullWidth
                        placeholder="Select state"
                      />
                    )}
                    sx={{
                      display: 'block',
                    }}
                  />
                  {error && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="city"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  disabled={cities.length < 1}
                >
                  <InputLabel>City</InputLabel>
                  <Autocomplete
                    options={cities}
                    {...field}
                    onChange={(ev, value) => field.onChange(value)}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      option.toLowerCase() === value.toLowerCase()
                    }
                    renderInput={(params) => (
                      <OutlinedInput
                        {...params.InputProps}
                        inputProps={params.inputProps}
                        fullWidth
                        placeholder="Select city"
                      />
                    )}
                    sx={{
                      display: 'block',
                    }}
                  />
                  {error && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="zipCode"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>ZIP Code</InputLabel>
                  <OutlinedInput placeholder="ZIP Code" {...field} />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: 600 }}>Logo</InputLabel>
            <Box mt={1.5}>
              <Avatar
                src={constants.getStoragePath(logo)}
                sx={{
                  width: 140,
                  height: 140,
                }}
                variant="rounded"
              >
                <GPhotoIcon sx={{}} />
              </Avatar>
              <Box mt={1.5}>
                <LoadingButton
                  startIcon={<GCloudUploadIcon />}
                  size="small"
                  variant="contained"
                  component="label"
                  loading={uploading}
                >
                  Upload Logo
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleSelectAvatar}
                    hidden={uploading}
                  />
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <ImageCropModal
          image={rawImage}
          open={!!rawImage}
          onCrop={handleUploadLogo}
          onClose={() => setRawImage('')}
        />
      </CardContent>
    </Card>
  );
};

PageEditBusinessDetails.propTypes = {
  reference: PropTypes.any,
  profile: PropTypes.any,
};

export default PageEditBusinessDetails;
