import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { default as palette } from 'src/configs/colors';
import { apiGetTopSales } from 'src/apis/statistics';

ChartJS.register(CategoryScale, ArcElement, Tooltip, Legend);

const colors = [
  palette['primary'],
  palette['royal.blue'],
  palette['light.gold'],
];

const PageTopSaleView = () => {
  const { t } = useTranslation();

  const [total, setTotal] = useState(0);

  const { data, isLoading } = useQuery(
    ['statistics', 'top-sales'],
    async () => {
      return await apiGetTopSales();
    },
  );

  useEffect(() => {
    if (data) {
      let sum = 0;
      for (const item of data) {
        sum += item.total;
      }
      setTotal(sum);
    }
  }, [data]);

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Typography fontSize={20} fontWeight={500} colorscheme="heading">
            {t('Top Selling')}
          </Typography>
        }
        emphasis="true"
        action={<Typography>Last 3 months</Typography>}
      />
      <CardContent
        sx={{
          pb: '16px !important',
          height: 350,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" height={200}>
            <CircularProgress size={20} />
            <Typography fontSize={14}>Loading</Typography>
          </Stack>
        ) : data?.length > 0 ? (
          <>
            <Stack
              height={270}
              direction="row"
              justifyContent="center"
              position="relative"
            >
              <DoughnutChart
                data={{
                  labels: data ? data.map((item) => item.name) : [],
                  datasets: [
                    {
                      label: 'Sales',
                      data: data ? data.map((item) => item.total) : [],
                      backgroundColor: colors,
                      borderColor: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
                      borderWidth: 2,
                      borderRadius: 60,
                      spacing: data?.length >= 2 ? -60 : 0,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  plugins: {
                    legend: false,
                    tooltip: {
                      bodyFont: {
                        family: 'Montserrat',
                      },
                      titleFont: {
                        family: 'Montserrat',
                      },
                    },
                  },
                  cutout: 120,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 'calc(50% - 32px)',
                }}
              >
                <Typography
                  fontSize={24}
                  fontWeight={600}
                  colorscheme="fonts.heading"
                  lineHeight="32px"
                  display="block"
                  textAlign="center"
                >
                  {total}
                </Typography>
                <Typography
                  display="block"
                  mt={0.5}
                  fontSize={20}
                  fontWeight={500}
                  lineHeight={1.4}
                  colorscheme="fonts.heading"
                >
                  {t('Trades')}
                </Typography>
              </Box>
            </Stack>
            <Stack spacing={0.5}>
              {data?.map((item, index) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  key={`legend-${index}`}
                >
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      bgcolor: colors[index],
                      borderRadius: 16,
                    }}
                  />
                  <Typography fontSize={12} mx={1} flex={1}>
                    {item.name}
                  </Typography>
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    colorscheme="heading"
                    textAlign="right"
                  >
                    {total
                      ? Number(Math.ceil((item.total / total) * 100)).toFixed(0)
                      : 'x'}
                    %
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </>
        ) : (
          <Stack justifyContent="center" alignItems="center" height={350}>
            <Typography fontSize={14} colorscheme="gray.400">
              No available data
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default PageTopSaleView;
