import React, { useCallback, useEffect, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AppHeader from './main-layout/AppHeader';
import AppContent from './main-layout/AppContent';
import AppSideBar from './main-layout/AppSideBar';
import { GLogoutIcon, GUserIcon } from '../ui/AppIcon';

const MainLayout = () => {
  const auth = useSelector((state) => state.auth);

  const location = useLocation();
  const ref = useRef();

  const handleScroll = useCallback((values) => {
    document.dispatchEvent(
      new CustomEvent('app-scroll', {
        detail: values,
      }),
    );
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollToTop();
    }
  }, [location.pathname]);

  return (
    <Scrollbars
      style={{
        width: '100%',
        height: '100vh',
      }}
      ref={ref}
      onScrollFrame={handleScroll}
    >
      <AppHeader />
      <AppSideBar />
      <AppContent />

      {auth.isUserVerified === false && (
        <Dialog
          open={true}
          sx={{
            '& .MuiBackdrop-root': {
              backdropFilter: 'blur(10px)',
            },
          }}
        >
          <DialogContent
            sx={{
              p: 4,
            }}
          >
            <Typography variant="h3" colorscheme="heading" fontWeight={500}>
              Account Review
            </Typography>
            <Typography fontSize={18} mt={2}>
              Your account is under review. We thoroughly review all accounts to
              ensure that we uphold our commitment to providing our members with
              a marketplace of verified and trusted sellers. Once the review is
              completed, we will send you an email to confirm and you can log in
              again.
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2} mt={4}>
              <Button
                variant="contained"
                startIcon={<GLogoutIcon />}
                sx={{ mt: 3, px: 4 }}
                component={NavLink}
                to="/auth/logout"
                replace
              >
                Logout
              </Button>
              {!auth.isSetupCompleted && (
                <Button
                  variant="outlined"
                  startIcon={<GUserIcon />}
                  sx={{ mt: 3, px: 4 }}
                  component={NavLink}
                  to="/setup-account"
                  replace
                >
                  Setup Account
                </Button>
              )}
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </Scrollbars>
  );
};

export default MainLayout;
