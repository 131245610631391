import React from 'react';
import { createTheme } from '@mui/material';

import AppDataGridPagination from '../components/ui/AppDataGridPagination';

import constants from './constants';
import fonts from './fonts';
import colors from './colors';

import { ReactComponent as SvgBreadcrumbSeparator } from '../assets/icons/breadcrumb-separator.svg';

const getColorValue = (value) => {
  return colors[value];
};

const appTheme = createTheme({
  typography: {
    fontFamily: fonts.join(','),
    xl: {
      fontSize: 20,
    },
    lg: {
      fontSize: 18,
    },
    md: {
      fontSize: 16,
    },
    sm: {
      fontSize: 14,
    },
    xs: {
      fontSize: 12,
    },
    xxs: {
      fontSize: 10,
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    error: {
      main: constants.colors.fills.danger,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        display: 'block',
        colorscheme: 'text',
        lineHeight: 1.5,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: fonts.join(','),
          ...(ownerState.colorscheme && {
            color: getColorValue(ownerState.colorscheme),
          }),
          ...(ownerState.weight && {
            fontWeight: ownerState.weight,
          }),
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.colorscheme && {
            color: getColorValue(ownerState.colorscheme),
            '& .MuiTypography-root': {
              color: getColorValue(ownerState.colorscheme),
            },
          }),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          boxShadow: constants.colors.shadows.level1,
          ...(ownerState.bg && {
            backgroundColor: getColorValue(ownerState.bg),
          }),
          ...(ownerState.rounded && {
            borderRadius: ownerState.rounded,
          }),
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: constants.colors.gray[300],
          },
          '&:hover fieldset': {
            borderColor: `constants.colors.gray[800] !important`,
          },
          'label + &': {
            marginTop: 26,
          },
        },
        input: {
          fontSize: 16,
          padding: '14px 16px',
          lineHeight: '24px',
        },
        multiline: {
          padding: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          'label + &': {
            marginTop: 26,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          'label + &': {
            marginTop: 26,
          },
        },
        inputRoot: {
          padding: 7,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 500,
          transform: 'none',
          paddingLeft: 0.5,
          color: colors.heading,
        },
        asterisk: {
          color: colors['status.error'],
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#707784',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: constants.colors.fonts.heading,
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: '500',
          fontSize: '16px',
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: '500',
          fontSize: '16px',

          '&.MuiButton-sizeMedium': {
            height: 52,
          },
          '&.MuiButton-sizeSmall': {
            height: 40,
          },
          '&.MuiButton-contained': {
            boxShadow: 'none !important',
          },
          '&.MuiButton-text': {
            backgroundColor: constants.colors.gray[100],
            color: constants.colors.gray[500],
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: '#FAFAFA',
          padding: 6,
          borderRadius: 6,
          boxShadow: '0px 2px 8px 0px rgba(20, 20, 43, 0.10)',

          '& .MuiToggleButton-root': {
            padding: '8px 20px',
            border: 'none !important',
            borderRadius: '6px !important',
            transition: 'all 0.2s',
            textTransform: 'none',

            '& .MuiTypography-root': {
              fontSize: 14,
              color: colors['gray.700'],
              fontWeight: 500,
              transition: 'all 0.2s',
            },
            '&.Mui-selected': {
              backgroundColor: `${colors.primary} !important`,

              '& .MuiTypography-root': {
                color: 'white !important',
              },
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: 'white',
          boxShadow: '0px 2px 8px 0px rgba(20, 20, 43, 0.10)',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: 16,

          '& .MuiCardHeader-action': {
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
          },

          '& .MuiTypography-root': {
            lineHeight: 1.5,
          },

          ...(ownerState.emphasis && {
            '& .MuiCardHeader-content .MuiTypography-root': {
              position: 'relative',
              paddingLeft: 20,
              lineHeight: 1.5,
            },
            '& .MuiCardHeader-content .MuiTypography-root::before': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 2,
              bottom: 2,
              display: 'block',
              width: 8,
              borderRadius: 10,
              background: colors.primary,
            },
          }),
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        slots: {
          pagination: AppDataGridPagination,
        },
      },
      styleOverrides: {
        root: {
          border: 'none',

          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#FAFAFA',
            borderRadius: 8,
            borderBottom: 'none',
            marginBottom: 12,
            minHeight: 52,
            maxHeight: 52,
            lineHeight: '52px',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.5,
            color: '#8C979A',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            marginTop: 12,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <SvgBreadcrumbSeparator />,
      },
      styleOverrides: {
        root: {},
        li: {
          '& a': {
            display: 'flex',
            textDecoration: 'none',
          },
          '& svg': {
            color: constants.colors.gray[500],
            width: 18,
            height: 18,
          },
          '& .MuiTypography-root': {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '22px',
            color: constants.colors.fonts.heading,
          },
          '&:last-child': {
            '& .MuiTypography-root': {
              color: constants.colors.gray[500],
            },
          },
        },
        separator: {
          marginLeft: 12,
          marginRight: 12,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {},
        thumb: {
          backgroundColor: colors.primary,
          border: '2px solid #FFFFFF',
          width: 14,
          height: 14,
          '& .MuiSlider-valueLabel': {
            transform: 'translateY(80%) scale(1)',
            backgroundColor: 'transparent',
            color: colors.black,
            fontSize: 14,
            fontWeight: 500,
          },
          '& .MuiSlider-valueLabel:before': {
            display: 'none',
          },
        },
        rail: {
          height: 8,
          borderRadius: '4px',
          background: '#D9D9D9',
        },
        track: {
          height: 8,
          borderRadius: '4px',
          background: colors.primary,
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPaginationItem-root': {
            padding: '4px 10px',
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 28,
            height: 28,
            minWidth: 28,
            minHeight: 28,
            backgroundColor: constants.colors.gray[100],
            color: colors.heading,
            fontSize: '12px !important',
            lineHeight: 1.5,
          },
          '& .MuiButtonBase-root.Mui-selected': {
            backgroundColor: colors.primary,
            color: 'white',
          },
          '& .MuiButtonBase-root.Mui-selected:hover': {
            backgroundColor: colors.primary,
            color: 'white',
          },
          '& .MuiButtonBase-root.MuiPaginationItem-previousNext': {
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          position: 'relative',

          '& .MuiTab-root': {
            padding: '12px 24px',
            fontSize: 16,
            fontWeight: 600,
            color: colors['dark.gray'],
            textTransform: 'none',
          },
          '& .MuiTab-root.Mui-selected': {
            color: colors.primary,
          },
          '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 1,
            backgroundColor: colors['light.gray'],
            zIndex: 0,
          },
        },
        indicator: {
          zIndex: 10,
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'mixed', color: 'error' },
          style: {
            backgroundColor: '#FFF2F2',
            color: '#DC1414',
            fontSize: 12,
            fontWeight: 500,
            padding: '4px 12px',
            lineHeight: '16px',
          },
        },
        {
          props: { variant: 'mixed', color: 'warning' },
          style: {
            backgroundColor: '#FFF5E8',
            color: '#F5A540',
            fontSize: 12,
            fontWeight: 500,
            padding: '4px 12px',
            lineHeight: '16px',
          },
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        root: () => ({
          width: 36,
          height: 20,
          boxSizing: 'content-box',
        }),
        thumb: () => ({
          fill: '#FFFFFF',
          filter:
            'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.10))',
          boxShadow: 'none',
          width: 16,
          height: 16,
          backgroundColor: '#FFFFFF',
        }),
        track: ({ ownerState }) => ({
          opacity: '1 !important',
          borderRadius: '12px',
          backgroundColor: '#F2F4F7',
          ...(ownerState.checked && {
            backgroundColor: constants.colors.fills.primary,
          }),
        }),
        switchBase: () => ({
          transform: 'translate(5px, 5px)',
          '&.Mui-checked': {
            transform: 'translate(21px, 5px)',
          },
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {},
    },
  },
});

export default appTheme;
