import React from 'react';
import { Container } from '@mui/material';

import PageHeader from './tickets-page/PageHeader';
import PageTicketsTable from './tickets-page/PageTicketsTable';

const TicketsPage = () => {
  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <PageHeader />
      <PageTicketsTable />
    </Container>
  );
};

export default TicketsPage;
