import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
  Stack,
  Button,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from 'src/components/ui/AppIcon';

const ConfirmDeliveryModal = ({ open, onClose, onAccept, loading }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 495,
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          colorscheme="black"
          fontSize={30}
          fontWeight={600}
          emphasis="true"
        >
          Confirm Delivery
        </HighlightedTypography>
        <Typography variant="md" mt={3}>
          We are delighted to inform you that your order has been successfully
          delivered to the destination specified in your Sales Purchase
          Agreement.
          <br />
          <br />
          If you have any questions or concerns, please do not hesitate to
          contact our customer service team.
          <br />
          <br />
          We appreciate your business and trust in OODL Marketplace. We look
          forward to serving you again in the future.
        </Typography>
        <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
          <Button
            variant="outlined"
            size="small"
            onClick={onClose}
            sx={{
              px: 2,
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="small"
            loading={loading}
            onClick={onAccept}
            sx={{
              px: 2,
            }}
          >
            Yes
          </LoadingButton>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

ConfirmDeliveryModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
};

export default ConfirmDeliveryModal;
