import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './configs/i18n';
import './configs/firebase';
import './apis/setupAxios';

import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
