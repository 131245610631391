import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Stack,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Swal from 'sweetalert2';

import colors from 'src/configs/colors';
import { apiRequestPasswordOTP } from 'src/apis/auth';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const schema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email(t('Your email address is not a valid one'))
          .required(t('Please provide your email address')),
      }),
    [t],
  );

  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const handleResetPassword = useCallback(
    (values) => {
      setLoading(true);
      apiRequestPasswordOTP({
        email: values.email,
      })
        .then((result) => {
          if (result) {
            Swal.fire({
              title: t('Email Sent'),
              text: t('We’ve sent a verification email to email', {
                email: values.email,
              }),
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: t('Continue'),
              confirmButtonColor: colors.primary,
            }).then(() => {
              navigate(
                `/auth/verify?action=password-recovery&email=${values.email}`,
                {
                  replace: true,
                },
              );
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading],
  );

  return (
    <Stack
      minHeight="100vh"
      py={3}
      px={6}
      boxSizing="border-box"
      alignItems="center"
      justifyContent="center"
    >
      <Box maxWidth={512}>
        <Typography fontSize={48} fontWeight={600} colorscheme="gray.900">
          {t('Forgot Your Password?')}
        </Typography>
        <Typography
          fontSize={16}
          lineHeight={1.5}
          colorscheme="gray.500"
          mt={3}
        >
          {t(
            'Don’t Worry! Resetting your password is easy. Just type in the email your registered while creating account.',
          )}
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field, formState: { errors } }) => (
            <FormControl
              fullWidth
              variant="standard"
              error={!!errors.email}
              disabled={loading}
              sx={{ mt: 4 }}
            >
              <InputLabel>{t('Email')}</InputLabel>
              <OutlinedInput placeholder={t('Your Email Address')} {...field} />
              {errors.email?.message && (
                <FormHelperText>{errors.email?.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
        <LoadingButton
          fullWidth
          sx={{ mt: 5 }}
          variant="contained"
          loading={loading}
          loadingIndicator={t('Sending verification email...')}
          onClick={handleSubmit(handleResetPassword)}
        >
          {t('Send')}
        </LoadingButton>
        <Typography fontSize={16} mt={3} textAlign="center">
          {t('Do you remember your password?')}{' '}
          <Link component={NavLink} to="/auth/login" display="inline">
            {t('Sign In')}
          </Link>
        </Typography>
      </Box>
    </Stack>
  );
};

export default ForgotPasswordPage;
