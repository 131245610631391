import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import moment from 'moment';

import { GUserIcon } from 'src/components/ui/AppIcon';
import TableFilter from 'src/components/ui/TableFilter';
import TransactionStatusChip from 'src/components/chips/TransactionStatusChip';
import constants from 'src/configs/constants';
import { apiGetTransactions } from 'src/apis/transactions';

export default function PageTransactionsCard() {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [code, setCode] = useState('');
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');

  const [paginationModel, setPaginationModel] = useState({
    page: pageIndex - 1,
    pageSize: pageSize,
  });
  const [sortModel, setSortModel] = useState([]);

  const { data, isLoading } = useQuery(
    [
      'transactions',
      pageIndex,
      pageSize,
      search,
      status,
      code,
      sortBy,
      sortOrder,
    ],
    async () => {
      return await apiGetTransactions({
        pageIndex,
        pageSize,
        ...(code && { id: code }),
        ...(search && { search }),
        ...(status && { status }),
        ...(sortBy && { sortBy }),
        ...(sortOrder && { sortOrder }),
      });
    },
  );

  const handleChangePagination = useCallback((model) => {
    setPageIndex(model.page + 1);
    setPageSize(model.pageSize);
  }, []);

  const handleChangeSort = useCallback((model) => {
    if (model.length > 0) {
      setSortBy(model[0].field);
      setSortOrder(model[0].sort);
    } else {
      setSortBy('');
      setSortOrder('');
    }
  }, []);

  useEffect(() => {
    setPaginationModel({
      page: pageIndex - 1,
      pageSize: pageSize,
    });
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (sortBy && sortOrder) {
      setSortModel([
        {
          field: sortBy,
          sort: sortOrder,
        },
      ]);
    } else {
      setSortModel([]);
    }
  }, [sortBy, sortOrder]);

  return (
    <Card>
      <CardHeader
        title={
          <Typography fontSize={20} fontWeight={500} colorscheme="heading">
            Transaction History
          </Typography>
        }
        emphasis="true"
        action={
          <TableFilter
            hideSorter
            search={search}
            onSearch={setSearch}
            filterDropdown={
              <Box width={300} p={2.5} boxSizing="border-box">
                <FormControl fullWidth size="small">
                  <InputLabel>ID</InputLabel>
                  <OutlinedInput
                    value={code}
                    onChange={(ev) => setCode(ev.currentTarget.value)}
                    placeholder="Search ID"
                  />
                </FormControl>
                <FormControl fullWidth size="small" sx={{ mt: 2.5 }}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(ev) => setStatus(ev.target.value)}
                    displayEmpty
                    renderValue={
                      status
                        ? undefined
                        : () => (
                            <Typography colorscheme="gray.600">
                              Select status
                            </Typography>
                          )
                    }
                  >
                    <MenuItem value={undefined}>All</MenuItem>
                    {[
                      { value: 'pending', label: 'Pending' },
                      { value: 'checking', label: 'In Review' },
                      { value: 'paying', label: 'Making Payment' },
                      { value: 'completed', label: 'Completed' },
                    ].map((item) => (
                      <MenuItem key={`status-${item.value}`} value={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            }
          />
        }
      />
      <CardContent>
        <Box>
          <DataGrid
            rows={data?.data ?? []}
            columns={[
              {
                headerName: 'ID',
                field: 'id',
                renderCell: ({ row }) => (
                  <Link>#{row._id.substr(-6).toUpperCase()}</Link>
                ),
                width: 80,
              },
              {
                headerName: 'Sender',
                field: 'sender',
                renderCell: ({ row }) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1.5}
                    width={1}
                    py={1}
                  >
                    <Avatar
                      src={constants.getStoragePath(
                        row?.trade?.buyer?.company?.logo,
                      )}
                      sx={{
                        width: 32,
                        height: 32,
                      }}
                    >
                      <GUserIcon />
                    </Avatar>
                    <Typography whiteSpace="normal" variant="sm">
                      {row?.trade?.buyer?.company?.name}
                    </Typography>
                  </Stack>
                ),
                flex: 1,
                sortable: false,
                minWidth: 180,
              },
              {
                headerName: 'Receiver',
                field: 'receiver',
                renderCell: ({ row }) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1.5}
                    width={1}
                  >
                    <Avatar
                      src={constants.getStoragePath(
                        row?.trade?.seller?.company?.logo,
                      )}
                      sx={{
                        width: 32,
                        height: 32,
                      }}
                    >
                      <GUserIcon />
                    </Avatar>
                    <Typography whiteSpace="normal" variant="sm">
                      {row?.trade?.seller?.company?.name}
                    </Typography>
                  </Stack>
                ),
                flex: 1,
                sortable: false,
                minWidth: 180,
              },
              {
                headerName: 'Date & Time',
                field: 'createdAt',
                valueFormatter: ({ value }) =>
                  moment(value).format('DD MMM, YYYY'),
              },
              {
                headerName: 'Amount',
                field: 'total',
                valueFormatter: ({ value }) =>
                  `$ ${Number(value).toLocaleString()}`,
                width: 130,
              },
              {
                headerName: 'Status',
                field: 'status',
                renderCell: ({ value }) => (
                  <TransactionStatusChip status={value} />
                ),
                width: 130,
                sortable: false,
              },
            ]}
            pagination
            pageSizeOptions={[10, 25, 50, 100]}
            paginationMode="server"
            paginationModel={paginationModel}
            sortingMode="server"
            sortModel={sortModel}
            rowCount={data?.total ? data.total : 0}
            loading={isLoading}
            getRowId={(row) => row._id}
            getRowHeight={() => 'auto'}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            autoHeight
            onPaginationModelChange={handleChangePagination}
            onSortModelChange={handleChangeSort}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
