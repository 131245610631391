import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PageLeadingView = () => {
  const { t } = useTranslation();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      py={12}
      px={5}
      bgcolor="#D4DAD5"
    >
      <Typography
        fontSize={16}
        fontWeight={600}
        lineHeight={1.5}
        colorscheme="fills.primary"
        textAlign="center"
      >
        {t('FAQs')}
      </Typography>
      <Typography
        fontSize={40}
        fontWeight={600}
        lineHeight={1.3}
        colorscheme="fonts.heading"
        mt={1.5}
        textAlign="center"
      >
        {t('We’re here to help')}
      </Typography>
      <Typography
        fontSize={20}
        lineHeight={1.5}
        mt={3}
        textAlign="center"
      >
        {t('Have questions? We’re here to help.')}
      </Typography>
    </Stack>
  );
};

export default PageLeadingView;
