import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';

import SimpleLogo from '../../logos/SimpleLogo';
import {
  GBoxIcon,
  GBuildingStoreIcon,
  GChevronDown,
  GChevronRight,
  GDashboardIcon,
  GShipIcon,
  GUserIcon,
} from '../../ui/AppIcon';
import colors from '../../../configs/colors';

const NavigationButton = styled('div')((props) => ({
  borderLeftWidth: 3,
  borderLeftStyle: 'solid',
  borderLeftColor: 'transparent',
  height: 40,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 21,
  paddingRight: 12,
  cursor: 'pointer',
  transition: 'all 0.2s',
  marginBottom: 16,

  '& > svg': {
    width: 20,
    height: 20,
    color: 'white',
  },
  '& .MuiTypography-root': {
    fontSize: 16,
    fontWeight: 600,
    color: '#FFFFFF',
    textDecoration: 'none !important',
    paddingLeft: 16,
    flex: 1,
  },
  ...(!props.disableHover && {
    '&:hover, &:active': {
      borderLeftColor: '#FFFFFF66',
      background: 'linear-gradient(89.89deg, #8C794E88 56.2%, #8C794E00 99.9%)',
    },
  }),
  ...(props.smallBottom && {
    marginBottom: 8,
  }),
  'a.active > &': {
    borderLeftColor: '#FFFFFF',
    background: 'linear-gradient(89.89deg, #8C794E 56.2%, #8C794E00 99.9%)',
  },

  '& .MuiIconButton-root': {
    color: '#FFFFFF',
    '& > svg': {
      width: 20,
      height: 20,
    },
  },

  'div.small-sidebar &': {
    '& .MuiTypography-root': {
      display: 'none',
      height: 40,
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 16,
    },
  },
}));

const AppSideBar = ({ fixed }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.down('lg'));
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [tradesOpened, setTradesOpened] = useState(true);
  const [anchor, setAnchor] = useState(null);

  const toggleOpenTrades = () => {
    setTradesOpened((value) => !value);
  };

  const toggleMenu = (ev) => {
    if (ev) {
      setAnchor(ev.currentTarget);
    } else {
      setAnchor(null);
    }
  };

  const result = (
    <Box
      position="fixed"
      left={0}
      top={0}
      width={isMd && !isSm ? 75 : 204}
      height="100vh"
      bgcolor={colors['royal.blue']}
      py={4}
      zIndex={4}
      sx={{
        transition: 'width 0.15s',
      }}
    >
      <Scrollbars>
        <Stack direction="row" justifyContent="center" mb={5}>
          <SimpleLogo small={isMd} />
        </Stack>
        <Stack
          sx={{
            '& a': {
              textDecoration: 'none',
            },
          }}
          className={isMd && !isSm ? 'small-sidebar' : ''}
        >
          <NavLink to="/dashboard">
            <NavigationButton>
              <GDashboardIcon />
              <Typography>{t('Dashboard')}</Typography>
            </NavigationButton>
          </NavLink>
          <NavLink to="/marketplace">
            <NavigationButton>
              <GBuildingStoreIcon />
              <Typography>{t('Marketplace')}</Typography>
            </NavigationButton>
          </NavLink>
          {isMd && !isSm ? (
            <>
              <NavigationButton onClick={toggleMenu}>
                <GShipIcon />
                <Typography>{t('Trades')}</Typography>
              </NavigationButton>
              <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={() => toggleMenu()}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'center',
                }}
                anchorPosition={{
                  top,
                }}
              >
                <MenuItem component={NavLink} to="/trades/purchases">
                  <Typography fontSize={14}>{t('Purchases')}</Typography>
                </MenuItem>
                <MenuItem component={NavLink} to="/trades/sales">
                  <Typography fontSize={14}>{t('Sales')}</Typography>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <NavigationButton
                disableHover
                smallBottom={tradesOpened}
                onClick={toggleOpenTrades}
              >
                <GShipIcon />
                <Typography>{t('Trades')}</Typography>
                <IconButton>
                  <GChevronDown />
                </IconButton>
              </NavigationButton>
              <Collapse in={tradesOpened} unmountOnExit mountOnEnter>
                <NavLink to="/trades/purchases">
                  <NavigationButton smallBottom>
                    <GChevronRight />
                    <Typography>{t('Purchases')}</Typography>
                  </NavigationButton>
                </NavLink>
                <NavLink to="/trades/sales">
                  <NavigationButton smallBottom>
                    <GChevronRight />
                    <Typography>{t('Sales')}</Typography>
                  </NavigationButton>
                </NavLink>
              </Collapse>
            </>
          )}
          <NavLink to="/profile/products">
            <NavigationButton>
              <GBoxIcon />
              <Typography>{t('My Products')}</Typography>
            </NavigationButton>
          </NavLink>
          <NavLink to="/profile/account">
            <NavigationButton>
              <GUserIcon />
              <Typography>{t('My Profile')}</Typography>
            </NavigationButton>
          </NavLink>
        </Stack>
      </Scrollbars>
    </Box>
  );

  if (!isSm || fixed) {
    return result;
  }

  return null;
};

AppSideBar.propTypes = {
  fixed: PropTypes.bool,
};

export default AppSideBar;
