const ports = [
  'Port of Durres',
  'Port of Algiers',
  'Port of Djen Djen',
  'Port of Amboim (Porto Amboim)',
  'Port of Cabinda',
  'Port of Lobito',
  'Port of Luanda',
  'Port of Namibe',
  'Port of Soyo',
  'Port of Buenos Aires',
  'Port of Dock Sud',
  'Port of La Plata',
  'Port of Quequen',
  'Port of Santa Fe',
  'Port Botany (seaport), Sydney',
  'Port of Brisbane',
  'Port of Fremantle',
  'Port of Melbourne',
  'Port of Adelaide',
  'Port of Chittagong',
  'Port of Pangaon',
  'Port of Antwerp',
  'Port of Zeebrugge',
  'Port of Santos',
  'Port of Centro',
  'Port of Paranaguá',
  'Port of Sepetiba',
  'Port of Rio de Janeiro',
  'Port of Salvador',
  'Port of Vitoria',
  'Port of Montreal',
  'Port Metro Vancouver',
  'Port of Prince Rupert',
  'Port of Saint John',
  'Port of Halifax',
  'Port of Nanaimo',
  'Port of Valparaiso',
  'Port of Antofagasta',
  'Port of Iquique',
  'Port of Arica, Arica',
  'Port of Talcahuano',
  'Port of San Antonio',
  'Port of Lirquen',
  'Port of Coronel',
  'Port of Dalian',
  'Port of Foshan',
  'Port of Guangzhou',
  'Port of Hong Kong',
  'Port of Lianyungang',
  'Port of Ningbo',
  'Port of Qingdao',
  'Port of Shanghai',
  'Port of Shenzhen',
  'Port of Suzhou',
  'Port of Tianjin',
  'Port of Xiamen',
  'Port of Yantai',
  'Port of Yingkou',
  'Port of Ploče',
  'Port of Rijeka',
  'Adriatic Gate Container Terminal',
  'Rijeka Gateway',
  'Port of Split',
  'Port of Barranquilla, Cartagena',
  'Port of Buenaventura',
  'Port of Santa Marta',
  'Havana Harbor',
  'Port of Limassol',
  'Aarhus',
  'Copenhagen Malmö Port',
  'Fredericia',
  'Kalundborg',
  'Aalborg',
  'Djibouti',
  'Port of Djibouti',
  'Multimodal Caucedo Port',
  'Port of Rio Haina (Haina Occidental Port)',
  'Port Said',
  'West Port',
  'East Terminal',
  'Sokhna',
  'Alexandria Port',
  'El-Dekhila',
  'Damietta',
  'Port of Muuga',
  'Port of Tallinn',
  'Port of Hamina-Kotka',
  'Port of Helsinki',
  'Port of Pori',
  'Port of Rauma',
  'Port of Le Havre',
  'Marseille-Fos Port',
  'Port of Dunkirk, Dunkirk',
  'Nantes – Saint Nazaire Port',
  'Cayenne (French Guiana)',
  'Port of Bremen/Bremerhaven',
  'Port of Germersheim',
  'Port of Hamburg',
  'Lübeck Hafen-Gesellschaft',
  'Rheinhafengesellschaft Weil am Rhein mbH',
  'Port of Piraeus, Athens',
  'Port of Thessaloniki',
  'Port of Volos',
  'Port of Alexandroupoli',
  'Port of Heraklion',
  'Port of Kavala',
  'Port of Chennai',
  'Port of Kollam',
  'Port of Nhava Sheva (Jawaharlal Nehru Port)',
  'Port of Mundra',
  'Port of Visakhapatnam',
  'Port of Vizhinjam (Vizhinjam International Seaport)',
  'Port of Kolkata (Syama Prasad Mookerjee Port)',
  'Port of Haldia',
  'Port of Kakinada',
  'Port of Hazira',
  'Port of Pipavav',
  'Port of Krishnapatnam',
  'Port of Kochi',
  'Port of Tuticorin (V. O. Chidambaranar Port)',
  'Port of Cirebon, Cirebon',
  'Port of Tanjung Priok, Jakarta',
  'Port of Belawan, Medan',
  'Port of Makassar, Makassar',
  'Dwikora Harbour, Pontianak',
  'Port of Trisakti, Banjarmasin',
  'Port of Tanjung Perak, Surabaya',
  'Port of Tanjung Mas, Semarang',
  'Semayang Harbor, Balikpapan',
  'Tenau Port, Kupang',
  'Port of Bandar-Abbas',
  'Port of Bandar-kohemini',
  'Dublin Port',
  'Port of Cork',
  'Port of Haifa',
  'Port of Ashdod',
  'Port of Eilat',
  'Port of Genoa',
  'Port of Gioia Tauro',
  'Port of La Spezia',
  'Port of Trieste',
  'Port of Marghera',
  'Port of Taranto',
  'Port of Palermo',
  'Port of Naples',
  'Port of Salerno',
  'Port of Livorno/Leghorn',
  'Port of Civitavecchia',
  'Kingston Container Terminal (Port of Kingston), Kingston',
  'Port of Nagoya',
  'Port of Tokyo',
  'Port of Yokohama',
  'Port of Osaka',
  'Port of Kobe',
  'Port of Fukuyama',
  'Port of Hiroshima',
  'Port of Fukuoka',
  'Port of Aqaba',
  'Port of Mombasa',
  'Freeport of Riga',
  'Port of Beirut',
  'Port of Tripoli',
  'Port of Klaipėda',
  'Toamasina Autonomous Port',
  'Penang Port',
  'Port Klang',
  'Port of Tanjung Pelepas',
  'Sapangar Container Port',
  'Malta Freeport, Birzebbuga',
  'Port of Valletta',
  'Port of Veracruz',
  'Port of Lázaro Cárdenas',
  'Port of Manzanillo',
  'Port of Altamira',
  'Port of Ensenada',
  'Port of Mazatlan',
  'Port of Progreso',
  'Port of Morelos',
  'Port of Bar',
  'Agadir Port',
  'Casablanca Port',
  'Tanger-Med',
  'Port of Maputo',
  'Port of Beira',
  'Port of Nacala',
  'Port of Amsterdam',
  'Port of Rotterdam',
  'Ports of Auckland',
  'CentrePort Wellington, Wellington Harbour',
  'Port Nelson',
  'Lyttleton Port, Lyttleton',
  'Port Chalmers, Dunedin',
  'Port of Tauranga',
  'Napier Port',
  'Port of Apapa, Lagos',
  'Port of Tincan, Lagos',
  'Port of Bergen',
  'Port of Oslo',
  'Port of Salalah',
  'Muscat',
  'SOHAR Port',
  'Karachi Port',
  'Port Qasim',
  'Gwader Port',
  'Qasim International container terminal',
  'Karachi International container terminal',
  'Pakistan International Container Terminal',
  'South Asia Pakistan terminals',
  'Qasim Freight Station',
  'PEARL TCDT (First Transit Cargo Container Terminal in Karachi Pakistan)',
  'Port of Balboa',
  'Port of Cristóbal, Colón',
  'Manzanillo International Terminal',
  'Port of Callao, Lima',
  'Port of Ilo',
  'Port of Matarani',
  'Port of Manila',
  'DCT Gdańsk',
  'Port of Gdynia',
  'Port of Setúbal, Setubal',
  'Port of Alcântara, Lisbon',
  'Port of Leixões, Porto',
  'Port of Sines, Sines',
  'Port of Hamad',
  'Port of Constanţa',
  'Vostochny Port, Vrangel',
  'Port of Saint Petersburg',
  'Kaliningrad Sea Commercial Port',
  'Port of Novorossiysk',
  'Port of Vladivostok, Free port of Vladivostok',
  'Jeddah Seaport',
  'King Abdul Aziz Sea Port, Dammam',
  'Port of Jubail (King Fahad Industrial Port)',
  'Riyadh Dry Port',
  'Port of Dakar',
  'Port of Singapore',
  'Port of Koper',
  'Port of Durban',
  'Port of East London',
  'Port of Port Elizabeth',
  'Port of Cape Town',
  'Port of Richards Bay',
  'Port of Ngqura',
  'Port of Busan',
  'Port of Incheon',
  'Port of Algeciras',
  'Port of Barcelona',
  'Port of Bilbao',
  'Port of Las Palmas',
  'Port of Tarragona',
  'Port of Valencia',
  'Port of Vigo',
  'Port of Colombo',
  'Port of Sudan',
  'Port of Paramaribo',
  'Ports of Stockholm',
  'Port of Gothenburg',
  'Port of Latakia',
  'Port of Tartus',
  'Port of Kaohsiung',
  'Port of Taichung',
  'Port of Keelung',
  'Port of Dar Es Salaam',
  'Port of Laem Chabang',
  'Port of Bangkok',
  'Port of Aliağa',
  'Port of Ambarl',
  'Port of Antalya',
  'Port of Gemlik, Gemlik',
  'Port of İskenderun',
  'Port of Istanbul',
  'Port of İzmir',
  'Port of Izmit',
  'Port of Kocaeli',
  'Port of Mersin',
  'Port of Samsun',
  'Port of Tekirdağ',
  'Port of Yalova',
  'Port of Odesa',
  'Port of Dubai',
  'Fujairah Port',
  'Port of Khor Fakkan',
  'Port of Sharjah',
  'Jebel Ali',
  'Abu Dhabi',
  'Port of Felixstowe',
  'Port of Southampton',
  'Port of Tilbury, London',
  'London Gateway',
  'London Thamesport',
  'Seaforth Dock, Liverpool',
  'Port of Belfast',
  'Portsmouth',
  'Grangemouth',
  'Port of Immingham',
  'Royal Portbury Dock, Bristol',
  'Main article: United States container ports',
  'Port of Hueneme, California',
  'Port of Long Beach, California',
  'Port of Los Angeles, California',
  'Port of Oakland, California',
  'Port of Seattle, Washington',
  'Port of Tacoma, Washington',
  'PortMiami, Miami, Florida',
  'Port Everglades, Florida',
  'Port of Tampa, Florida',
  'Port of New Orleans, Louisiana',
  'Port of Boston, Massachusetts',
  'Helen Delich Bentley Port of Baltimore, Maryland',
  'Wilmington Marine Terminal, Delaware',
  'Port of New York and New Jersey',
  'Howland Hook Marine Terminal, Staten Island, New York',
  'Port Jersey Marine Terminal, Jersey City, New Jersey',
  'Port Newark-Elizabeth Marine Terminal, New Jersey',
  'Red Hook Marine Terminal, Brooklyn, New York',
  'Port of Savannah, Georgia',
  'Port of Charleston, South Carolina',
  'Port of Wilmington, North Carolina',
  'Virginia Port Authority, Virginia',
  'APM Terminals, Portsmouth, Virginia',
  'Newport News Marine Terminal, Newport News, Virginia',
  'Norfolk International Terminals, Norfolk, Virginia',
  'Virginia Inland Port, Front Royal, Virginia',
  'Port of Houston, Texas',
  'Bayport Terminal, Houston, Texas',
  'Port of Galveston, Texas',
  'Port of Port Lavaca, Texas',
  'Port of Mobile, Alabama',
  'Port of Anchorage, Alaska',
  'Port of Honolulu, Hawaii',
  'Port of San Juan, Puerto Rico',
  'Louisiana International Gulf Transfer Terminal Regional Center pre-construction phase',
  'Port of Montevideo',
  'Saigon Port (Ho Chi Minh City)',
  'Hai Phong',
  'Da Nang',
];

export default ports;
