import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Stack,
  Button,
  FormHelperText,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from 'react-query';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  GEyeIcon,
  GEyeOffIcon,
  GInfoCircleIcon,
} from 'src/components/ui/AppIcon';
import AccountSetupLayout from 'src/components/layouts/AccountSetupLayout';
import PhoneNumberInput from 'src/components/ui/PhoneNumberInput';
import CountrySelect from 'src/components/ui/CountrySelect';
import PasswordStrengthIndicator from 'src/components/ui/PasswordStrengthIndicator';

import {
  apiAcceptInvitation,
  apiDeclineInvitation,
  apiGetInvitationDetails,
} from 'src/apis/auth';
import { setAuth } from 'src/redux/auth/slice';
import notify from 'src/configs/notify';
import colors from 'src/configs/colors';

export default function AcceptInvitationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    country: 'US',
    password: '',
  });
  const [processing, setProcessing] = useState(false);

  const { data: invitation, isLoading: loading } = useQuery(
    ['invitations', query.get('id')],
    async () => {
      if (query.get('id')) {
        return await apiGetInvitationDetails(query.get('id'));
      }
      return null;
    },
  );

  const handleNext = (values) => {
    setData({
      ...values,
    });
    setStep(2);
  };

  const handleAccept = async () => {
    setProcessing(true);
    apiAcceptInvitation(query.get('id'), data)
      .then((result) => {
        if (result && result.user) {
          dispatch(
            setAuth({
              ...result.user,
            }),
          );
          navigate('/dashboard', {
            replace: true,
          });
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleDecline = () => {
    apiDeclineInvitation(query.get('id'));
    notify({
      message: 'You have declined the invitation successfully',
      type: 'success',
    });
    navigate('/auth/login');
  };

  return (
    <AccountSetupLayout>
      <Container
        sx={{
          mt: 18,
          position: 'relative',
          width: 520,
          maxWidth: '100%',
        }}
      >
        {loading ? (
          <PageLoadingCard />
        ) : invitation ? (
          <Box>
            {step === 1 && (
              <PageAccountDataCard
                defaultValues={data}
                invitation={invitation}
                onSubmit={handleNext}
              />
            )}
            {step === 2 && (
              <PageConfirmationCard
                invitation={invitation}
                loading={processing}
                onDecline={handleDecline}
                onAccept={handleAccept}
              />
            )}
          </Box>
        ) : (
          <PageInvalidLinkCard />
        )}
      </Container>
    </AccountSetupLayout>
  );
}

function PageLoadingCard() {
  return (
    <Box
      sx={{
        borderRadius: 1.5,
        p: 5,
        mb: 5,
        mt: 10,
        bgcolor: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Stack direction="column" alignItems="center">
        <Typography
          fontSize={36}
          fontWeight={600}
          lineHeight={1.2}
          textAlign="center"
          colorscheme="heading"
        >
          Welcome to
          <br />
          OODL Marketplace
        </Typography>
        <Box mt={4}>
          <CircularProgress />
        </Box>
        <Typography mt={1}>Validating your link</Typography>
      </Stack>
    </Box>
  );
}

function PageInvalidLinkCard() {
  return (
    <Box
      sx={{
        borderRadius: 1.5,
        p: 5,
        mb: 5,
        mt: 10,
        bgcolor: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Stack direction="column" alignItems="center">
        <GInfoCircleIcon
          sx={{
            color: colors['status.error'],
            width: 42,
            height: 42,
          }}
        />
        <Typography
          variant="xl"
          textAlign="center"
          colorscheme="status.error"
          fontWeight="600"
          mt={2}
        >
          Sorry, your invitation link is invalid one.
        </Typography>
      </Stack>
      <Box mt={4}>
        <Button
          variant="contained"
          fullWidth
          component={NavLink}
          to="/auth/login"
          replace
        >
          Go to Sign In
        </Button>
      </Box>
    </Box>
  );
}

function PageAccountDataCard({ onSubmit, defaultValues, invitation }) {
  const schema = useMemo(
    () =>
      yup
        .object({
          firstName: yup.string().required(),
          lastName: yup.string().required(),
          phone: yup.string().required(),
          country: yup.string().required(),
          password: yup.string().required(),
        })
        .required(),
    [],
  );

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (invitation) {
      const nameArray = invitation.name.split(' ');
      const firstName = nameArray[0] ? nameArray[0] : '';
      const lastName = nameArray.length > 1 ? nameArray.slice(1).join(' ') : '';

      setValue('firstName', firstName);
      setValue('lastName', lastName);
    }
  }, [invitation]);

  return (
    <Box
      sx={{
        borderRadius: 1.5,
        p: 5,
        mb: 5,
        mt: 10,
        bgcolor: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Typography
        fontSize={36}
        fontWeight={600}
        lineHeight={1.2}
        textAlign="center"
        colorscheme="heading"
      >
        Welcome to
        <br />
        OODL Marketplace
      </Typography>
      <Typography fontSize={16} textAlign="center" mt={1.5}>
        You have been invited to work with {invitation?.company?.name} as a{' '}
        {invitation?.role === 'agent' ? 'Agent' : ''}.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={3}>
          <Grid container rowSpacing={2} columnSpacing={5}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel required>First Name</InputLabel>
                    <OutlinedInput
                      placeholder="Enter your first name"
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel required>Last Name</InputLabel>
                    <OutlinedInput
                      placeholder="Enter your last name"
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={!!errors.password}
                    >
                      <InputLabel required>Password</InputLabel>
                      <OutlinedInput
                        placeholder="Your Password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((value) => !value)}
                            >
                              {showPassword ? <GEyeOffIcon /> : <GEyeIcon />}
                            </IconButton>
                          </InputAdornment>
                        }
                        {...field}
                      />
                    </FormControl>
                    <PasswordStrengthIndicator password={field.value} mt={2} />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="phone"
                render={({
                  field: { ref, ...fieldProps },
                  fieldState: { error },
                }) => (
                  <Box>
                    <InputLabel required shrink error={!!error}>
                      Enter Number
                    </InputLabel>
                    <PhoneNumberInput
                      innerRef={ref}
                      {...fieldProps}
                      error={!!error}
                    />
                    {error?.message && (
                      <FormHelperText error={!!error}>
                        This field is required
                      </FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="country"
                render={({
                  field: { ref, ...props },
                  fieldState: { error },
                }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel required>Country</InputLabel>
                    <CountrySelect
                      {...props}
                      innerRef={ref}
                      showCountryName
                      fullWidth
                      placeholder="Select country"
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack
          mt={5}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1.5}
        >
          <Button variant="contained" type="submit" size="small" sx={{ px: 4 }}>
            Next
          </Button>
        </Stack>
      </form>
    </Box>
  );
}

PageAccountDataCard.propTypes = {
  defaultValues: PropTypes.any,
  invitation: PropTypes.any,
  onSubmit: PropTypes.func,
};

function PageConfirmationCard({ invitation, loading, onAccept, onDecline }) {
  return (
    <Box
      sx={{
        borderRadius: 1.5,
        p: 5,
        mb: 5,
        mt: 10,
        bgcolor: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Typography
        fontSize={36}
        fontWeight={600}
        lineHeight={1.2}
        textAlign="center"
        colorscheme="heading"
      >
        Accept Invitation
      </Typography>
      <Typography fontSize={16} textAlign="center" mt={1.5}>
        To accept the invitation from {invitation?.company?.name} to work with
        them as {invitation?.role === 'agent' ? 'Agent' : ''}, please click the
        accept button below, Alternatively, you can reject the request and we
        will inform them.
      </Typography>
      <Stack
        mt={5}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1.5}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{ px: 3 }}
          disabled={loading}
          onClick={onDecline}
        >
          Decline
        </Button>
        <LoadingButton
          variant="contained"
          size="small"
          sx={{ px: 4 }}
          loading={loading}
          onClick={onAccept}
        >
          Accept
        </LoadingButton>
      </Stack>
    </Box>
  );
}

PageConfirmationCard.propTypes = {
  invitation: PropTypes.any,
  loading: PropTypes.bool,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
};
