import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
  Stack,
  Button,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from 'src/components/ui/AppIcon';

const schema = yup.object({
  member: yup.string().required(),
});

const AssignTradeModal = ({ open, loading, members, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      member: '',
    },
    resolver: yupResolver(schema),
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 495,
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          colorscheme="black"
          fontSize={30}
          fontWeight={600}
          emphasis="true"
        >
          {t('Assign Trade')}
        </HighlightedTypography>
        <Typography variant="md" mt={3} display="block" lineHeight="22px">
          {t(
            'You can assign this trade either to an agent within your team or a OODL Trade Manager if you have one, they will be able to view and manage the trade on your behalf.',
          )}
        </Typography>
        <Box my={2}>
          <Controller
            control={control}
            name="member"
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel required>{t('Assignee')}</InputLabel>
                <Select
                  {...field}
                  displayEmpty
                  renderValue={
                    field.value
                      ? undefined
                      : () => (
                          <Typography colorscheme="text">
                            {t('Select')}
                          </Typography>
                        )
                  }
                >
                  {members.map((item) => (
                    <MenuItem key={`member-${item._id}`} value={item._id}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Box>
        <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
          <Button
            variant="outlined"
            size="small"
            onClick={onClose}
            sx={{
              px: 2,
            }}
          >
            {t('Cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            size="small"
            sx={{
              px: 2,
            }}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {t('Send')}
          </LoadingButton>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

AssignTradeModal.propTypes = {
  open: PropTypes.bool,
  members: PropTypes.array,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AssignTradeModal;
