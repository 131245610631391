import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setAuth } from '../../redux/auth/slice';

const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setAuth({
        isAuthorized: false,
      }),
    );
  }, []);

  return <Navigate to="/auth/login" replace />;
};

export default LogoutPage;
