import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GDotsVerticalIcon, GEyeIcon } from '../../../../components/ui/AppIcon';
import { NavLink } from 'react-router-dom';

const TicketsTableActionMenu = () => {
  const { t } = useTranslation();

  const [el, setEl] = useState(null);
  const open = Boolean(el);

  const handleClick = (ev) => {
    setEl(ev.currentTarget);
  };

  const handleClose = () => {
    setEl(null);
  };

  return (
    <>
      <IconButton
        sx={{
          width: 24,
          height: 24,
          borderRadius: '24px',
        }}
        onClick={handleClick}
      >
        <GDotsVerticalIcon
          sx={{
            width: 16,
            height: 16,
            color: '#2D3643',
          }}
        />
      </IconButton>
      <Menu
        anchorEl={el}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={NavLink}
          to="/support/tickets/23232"
        >
          <ListItemIcon>
            <GEyeIcon
              sx={{
                width: 24,
                height: 24,
                color: '#475B63',
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              fontSize={14}
              lineHeight="24px"
              colorscheme="fonts.text"
            >
              {t('Edit')}
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TicketsTableActionMenu;
