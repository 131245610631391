import React from 'react';
import { SvgIcon } from '@mui/material';

import { ReactComponent as SvgCalendar } from '../../assets/icons/calendar.svg';
import { ReactComponent as SvgCloudUpload } from '../../assets/icons/cloud-upload.svg';
import { ReactComponent as SvgSearch } from '../../assets/icons/search.svg';
import { ReactComponent as SvgBell } from '../../assets/icons/bell.svg';
import { ReactComponent as SvgWallet } from '../../assets/icons/wallet.svg';
import { ReactComponent as SvgMail } from '../../assets/icons/mail.svg';
import { ReactComponent as SvgMenu2 } from '../../assets/icons/menu-2.svg';
import { ReactComponent as SvgLink } from '../../assets/icons/link.svg';
import { ReactComponent as SvgLogin } from '../../assets/icons/login.svg';
import { ReactComponent as SvgLogout } from '../../assets/icons/logout.svg';
import { ReactComponent as SvgUser } from '../../assets/icons/user.svg';
import { ReactComponent as SvgHeadset } from '../../assets/icons/headset.svg';
import { ReactComponent as SvgHeart } from '../../assets/icons/heart.svg';
import { ReactComponent as SvgHistory } from '../../assets/icons/history.svg';
import { ReactComponent as SvgMessage2 } from '../../assets/icons/message-2.svg';
import { ReactComponent as SvgSetting } from '../../assets/icons/setting.svg';
import { ReactComponent as SvgChevronUp } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as SvgChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as SvgChevronDown } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as SvgChevronRight } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as SvgArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as SvgArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as SvgArrowDown } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as SvgArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as SvgArrowNarrowUp } from '../../assets/icons/arrow-narrow-up.svg';
import { ReactComponent as SvgArrowNarrowLeft } from '../../assets/icons/arrow-narrow-left.svg';
import { ReactComponent as SvgArrowNarrowDown } from '../../assets/icons/arrow-narrow-down.svg';
import { ReactComponent as SvgArrowNarrowRight } from '../../assets/icons/arrow-narrow-right.svg';
import { ReactComponent as SvgTruckDelivery } from '../../assets/icons/truck-delivery.svg';
import { ReactComponent as SvgCalendarTime } from '../../assets/icons/calendar-time.svg';
import { ReactComponent as SvgBrandHipchat } from '../../assets/icons/brand-hipchat.svg';
import { ReactComponent as SvgSortAscending } from '../../assets/icons/sort-ascending.svg';
import { ReactComponent as SvgSortDescending } from '../../assets/icons/sort-descending.svg';
import { ReactComponent as SvgFilter } from '../../assets/icons/filter.svg';
import { ReactComponent as SvgBox } from '../../assets/icons/box.svg';
import { ReactComponent as SvgDollarCircle } from '../../assets/icons/dollar-circle.svg';
import { ReactComponent as SvgFileInvoice } from '../../assets/icons/file-invoice.svg';
import { ReactComponent as SvgShoppingCart } from '../../assets/icons/shopping-cart.svg';
import { ReactComponent as SvgHome } from '../../assets/icons/home.svg';
import { ReactComponent as SvgCopy } from '../../assets/icons/copy.svg';
import { ReactComponent as SvgCheck } from '../../assets/icons/check.svg';
import { ReactComponent as SvgMaximize } from '../../assets/icons/maximize.svg';
import { ReactComponent as SvgInfoCircle } from '../../assets/icons/info-circle.svg';
import { ReactComponent as SvgMinus } from '../../assets/icons/minus.svg';
import { ReactComponent as SvgPlus } from '../../assets/icons/plus.svg';
import { ReactComponent as SvgCurrencyBitcoin } from '../../assets/icons/currency-bitcoin.svg';
import { ReactComponent as SvgBuildingBank } from '../../assets/icons/building-bank.svg';
import { ReactComponent as SvgDashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as SvgDots } from '../../assets/icons/dots.svg';
import { ReactComponent as SvgDotsVertical } from '../../assets/icons/dots-vertical.svg';
import { ReactComponent as SvgEye } from '../../assets/icons/eye.svg';
import { ReactComponent as SvgHelp } from '../../assets/icons/help.svg';
import { ReactComponent as SvgLicense } from '../../assets/icons/license.svg';
import { ReactComponent as SvgMinimize } from '../../assets/icons/minimize.svg';
import { ReactComponent as SvgFileOff } from '../../assets/icons/file-off.svg';
import { ReactComponent as SvgClose } from '../../assets/icons/x.svg';
import { ReactComponent as SvgCash } from '../../assets/icons/cash.svg';
import { ReactComponent as SvgFileDownload } from '../../assets/icons/file-download.svg';
import { ReactComponent as SvgChecks } from '../../assets/icons/checks.svg';
import { ReactComponent as SvgQRcode } from '../../assets/icons/qrcode.svg';
import { ReactComponent as SvgCheckbox } from '../../assets/icons/checkbox.svg';
import { ReactComponent as SvgVuesaxSearch } from '../../assets/icons/search-normal.svg';
import { ReactComponent as SvgVuesaxSidebar } from '../../assets/icons/sidebar-left.svg';
import { ReactComponent as SvgVuesaxMore } from '../../assets/icons/more.svg';
import { ReactComponent as SvgVuesaxPaperclip } from '../../assets/icons/paperclip-2.svg';
import { ReactComponent as SvgVuesaxHappy } from '../../assets/icons/emoji-happy.svg';
import { ReactComponent as SvgVuesaxSend } from '../../assets/icons/send.svg';
import { ReactComponent as SvgCreditCard } from '../../assets/icons/credit-card.svg';
import { ReactComponent as SvgMap } from '../../assets/icons/map.svg';
import { ReactComponent as SvgShieldLock } from '../../assets/icons/shield-lock.svg';
import { ReactComponent as SvgUsers } from '../../assets/icons/users.svg';
import { ReactComponent as SvgLayoutSidebar } from '../../assets/icons/layout-sidebar.svg';
import { ReactComponent as SvgEdit } from '../../assets/icons/edit.svg';
import { ReactComponent as SvgScan } from '../../assets/icons/scan.svg';
import { ReactComponent as SvgArrowsDoubleSwNe } from '../../assets/icons/arrows-double-sw-ne.svg';
import { ReactComponent as SvgTrash } from '../../assets/icons/trash.svg';
import { ReactComponent as SvgDollar } from '../../assets/icons/currency-dollar.svg';
import { ReactComponent as SvgUpload } from '../../assets/icons/upload.svg';
import { ReactComponent as SvgPhoto } from '../../assets/icons/photo.svg';
import { ReactComponent as SvgBuildingStore } from '../../assets/icons/building-store.svg';
import { ReactComponent as SvgShip } from '../../assets/icons/ship.svg';
import { ReactComponent as SvgPaperClip } from '../../assets/icons/paper-clip.svg';
import { ReactComponent as SvgMic } from '../../assets/icons/mic.svg';
import { ReactComponent as SvgMapPin } from '../../assets/icons/map-pin.svg';
import { ReactComponent as SvgEyeOff } from '../../assets/icons/eye-off.svg';
import { ReactComponent as SvgBan } from '../../assets/icons/ban.svg';
import { ReactComponent as SvgUserPlus } from '../../assets/icons/user-plus.svg';
import { ReactComponent as SvgFileText } from '../../assets/icons/file-text.svg';
import { ReactComponent as SvgTestPipe } from '../../assets/icons/test-pipe.svg';
import { ReactComponent as SvgFile } from '../../assets/icons/file.svg';
import { ReactComponent as SvgUserOff } from '../../assets/icons/user-off.svg';
import { ReactComponent as SvgUserX } from '../../assets/icons/user-x.svg';
import { ReactComponent as SvgUserCheck } from '../../assets/icons/user-check.svg';
import { ReactComponent as SvgPercentage } from '../../assets/icons/percentage.svg';

export const GCalendarIcon = (props) => (
  <SvgIcon {...props}>
    <SvgCalendar />
  </SvgIcon>
);

export const GCloudUploadIcon = (props) => (
  <SvgIcon {...props}>
    <SvgCloudUpload />
  </SvgIcon>
);

export const GSearchIcon = (props) => (
  <SvgIcon {...props}>
    <SvgSearch />
  </SvgIcon>
);

export const GBellIcon = (props) => (
  <SvgIcon {...props}>
    <SvgBell />
  </SvgIcon>
);

export const GWalletIcon = (props) => (
  <SvgIcon {...props}>
    <SvgWallet />
  </SvgIcon>
);

export const GMailIcon = (props) => (
  <SvgIcon {...props}>
    <SvgMail />
  </SvgIcon>
);

export const GMenu2Icon = (props) => (
  <SvgIcon {...props}>
    <SvgMenu2 />
  </SvgIcon>
);

export const GLinkIcon = (props) => (
  <SvgIcon {...props}>
    <SvgLink />
  </SvgIcon>
);

export const GLoginIcon = (props) => (
  <SvgIcon {...props}>
    <SvgLogin />
  </SvgIcon>
);

export const GLogoutIcon = (props) => (
  <SvgIcon {...props}>
    <SvgLogout />
  </SvgIcon>
);

export const GUserIcon = (props) => (
  <SvgIcon {...props}>
    <SvgUser />
  </SvgIcon>
);

export const GHeadsetIcon = (props) => (
  <SvgIcon {...props}>
    <SvgHeadset />
  </SvgIcon>
);

export const GHeartIcon = (props) => (
  <SvgIcon {...props}>
    <SvgHeart />
  </SvgIcon>
);

export const GHistoryIcon = (props) => (
  <SvgIcon {...props}>
    <SvgHistory />
  </SvgIcon>
);

export const GMessage2Icon = (props) => (
  <SvgIcon {...props}>
    <SvgMessage2 />
  </SvgIcon>
);

export const GSettingIcon = (props) => (
  <SvgIcon {...props}>
    <SvgSetting />
  </SvgIcon>
);

export const GChevronUp = (props) => (
  <SvgIcon {...props}>
    <SvgChevronUp />
  </SvgIcon>
);

export const GChevronLeft = (props) => (
  <SvgIcon {...props}>
    <SvgChevronLeft />
  </SvgIcon>
);

export const GChevronDown = (props) => (
  <SvgIcon {...props}>
    <SvgChevronDown />
  </SvgIcon>
);

export const GChevronRight = (props) => (
  <SvgIcon {...props}>
    <SvgChevronRight />
  </SvgIcon>
);

export const GTruckDeliveryIcon = (props) => (
  <SvgIcon {...props}>
    <SvgTruckDelivery />
  </SvgIcon>
);

export const GCalendarTimeIcon = (props) => (
  <SvgIcon {...props}>
    <SvgCalendarTime />
  </SvgIcon>
);

export const GBrandHipchatIcon = (props) => (
  <SvgIcon {...props}>
    <SvgBrandHipchat />
  </SvgIcon>
);

export const GSortAscendingIcon = (props) => (
  <SvgIcon {...props}>
    <SvgSortAscending />
  </SvgIcon>
);

export const GSortDescendingIcon = (props) => (
  <SvgIcon {...props}>
    <SvgSortDescending />
  </SvgIcon>
);

export const GFilterIcon = (props) => (
  <SvgIcon {...props}>
    <SvgFilter />
  </SvgIcon>
);

export const GBoxIcon = (props) => (
  <SvgIcon {...props}>
    <SvgBox />
  </SvgIcon>
);

export const GDollarCircleIcon = (props) => (
  <SvgIcon {...props}>
    <SvgDollarCircle />
  </SvgIcon>
);
export const GFileInvoiceIcon = (props) => (
  <SvgIcon {...props}>
    <SvgFileInvoice />
  </SvgIcon>
);

export const GShoppingCartIcon = (props) => (
  <SvgIcon {...props}>
    <SvgShoppingCart />
  </SvgIcon>
);

export const GArrowUpIcon = (props) => (
  <SvgIcon {...props}>
    <SvgArrowUp />
  </SvgIcon>
);

export const GArrowLeftIcon = (props) => (
  <SvgIcon {...props}>
    <SvgArrowLeft />
  </SvgIcon>
);

export const GArrowDownIcon = (props) => (
  <SvgIcon {...props}>
    <SvgArrowDown />
  </SvgIcon>
);

export const GArrowRightIcon = (props) => (
  <SvgIcon {...props}>
    <SvgArrowRight />
  </SvgIcon>
);

export const GArrowNarrowUpIcon = (props) => (
  <SvgIcon {...props}>
    <SvgArrowNarrowUp />
  </SvgIcon>
);

export const GArrowNarrowLeftIcon = (props) => (
  <SvgIcon {...props}>
    <SvgArrowNarrowLeft />
  </SvgIcon>
);

export const GArrowNarrowDownIcon = (props) => (
  <SvgIcon {...props}>
    <SvgArrowNarrowDown />
  </SvgIcon>
);

export const GArrowNarrowRightIcon = (props) => (
  <SvgIcon {...props}>
    <SvgArrowNarrowRight />
  </SvgIcon>
);

export const GHomeIcon = (props) => (
  <SvgIcon {...props}>
    <SvgHome />
  </SvgIcon>
);

export const GCopyIcon = (props) => (
  <SvgIcon {...props}>
    <SvgCopy />
  </SvgIcon>
);

export const GCheckIcon = (props) => (
  <SvgIcon {...props}>
    <SvgCheck />
  </SvgIcon>
);

export const GMaximizeIcon = (props) => (
  <SvgIcon {...props}>
    <SvgMaximize />
  </SvgIcon>
);

export const GInfoCircleIcon = (props) => (
  <SvgIcon {...props}>
    <SvgInfoCircle />
  </SvgIcon>
);

export const GMinusIcon = (props) => (
  <SvgIcon {...props}>
    <SvgMinus />
  </SvgIcon>
);

export const GPlusIcon = (props) => (
  <SvgIcon {...props}>
    <SvgPlus />
  </SvgIcon>
);

export const GCurrencyBitcoinIcon = (props) => (
  <SvgIcon {...props}>
    <SvgCurrencyBitcoin />
  </SvgIcon>
);

export const GBuildingBankIcon = (props) => (
  <SvgIcon {...props}>
    <SvgBuildingBank />
  </SvgIcon>
);

export const GDashboardIcon = (props) => (
  <SvgIcon {...props}>
    <SvgDashboard />
  </SvgIcon>
);

export const GDotsIcon = (props) => (
  <SvgIcon {...props}>
    <SvgDots />
  </SvgIcon>
);

export const GDotsVerticalIcon = (props) => (
  <SvgIcon {...props}>
    <SvgDotsVertical />
  </SvgIcon>
);

export const GHelpIcon = (props) => (
  <SvgIcon {...props}>
    <SvgHelp />
  </SvgIcon>
);

export const GEyeIcon = (props) => (
  <SvgIcon {...props}>
    <SvgEye />
  </SvgIcon>
);

export const GLicenseIcon = (props) => (
  <SvgIcon {...props}>
    <SvgLicense />
  </SvgIcon>
);

export const GMinimizeIcon = (props) => (
  <SvgIcon {...props}>
    <SvgMinimize />
  </SvgIcon>
);

export const GFileOffIcon = (props) => (
  <SvgIcon {...props}>
    <SvgFileOff />
  </SvgIcon>
);

export const GCloseIcon = (props) => (
  <SvgIcon {...props}>
    <SvgClose />
  </SvgIcon>
);

export const GCashIcon = (props) => (
  <SvgIcon {...props}>
    <SvgCash />
  </SvgIcon>
);

export const GFileDownloadIcon = (props) => (
  <SvgIcon {...props}>
    <SvgFileDownload />
  </SvgIcon>
);

export const GChecksIcon = (props) => (
  <SvgIcon {...props}>
    <SvgChecks />
  </SvgIcon>
);

export const GQRCodeIcon = (props) => (
  <SvgIcon {...props}>
    <SvgQRcode />
  </SvgIcon>
);

export const GCheckboxIcon = (props) => (
  <SvgIcon {...props}>
    <SvgCheckbox />
  </SvgIcon>
);

export const GVuesaxSearchIcon = (props) => (
  <SvgIcon {...props}>
    <SvgVuesaxSearch />
  </SvgIcon>
);

export const GVuesaxMoreIcon = (props) => (
  <SvgIcon {...props}>
    <SvgVuesaxMore />
  </SvgIcon>
);

export const GVuesaxSidebarIcon = (props) => (
  <SvgIcon {...props}>
    <SvgVuesaxSidebar />
  </SvgIcon>
);

export const GVuesaxPaperclipIcon = (props) => (
  <SvgIcon {...props}>
    <SvgVuesaxPaperclip />
  </SvgIcon>
);

export const GVuesaxEmojiHappyIcon = (props) => (
  <SvgIcon {...props}>
    <SvgVuesaxHappy />
  </SvgIcon>
);

export const GVuesaxSendIcon = (props) => (
  <SvgIcon {...props}>
    <SvgVuesaxSend />
  </SvgIcon>
);

export const GCreditCardIcon = (props) => (
  <SvgIcon {...props}>
    <SvgCreditCard />
  </SvgIcon>
);

export const GMapIcon = (props) => (
  <SvgIcon {...props}>
    <SvgMap />
  </SvgIcon>
);

export const GUsersIcon = (props) => (
  <SvgIcon {...props}>
    <SvgUsers />
  </SvgIcon>
);

export const GShieldLockIcon = (props) => (
  <SvgIcon {...props}>
    <SvgShieldLock />
  </SvgIcon>
);

export const GLayoutSidebar = (props) => (
  <SvgIcon {...props}>
    <SvgLayoutSidebar />
  </SvgIcon>
);

export const GEditIcon = (props) => (
  <SvgIcon {...props}>
    <SvgEdit />
  </SvgIcon>
);

export const GScanIcon = (props) => (
  <SvgIcon {...props}>
    <SvgScan />
  </SvgIcon>
);

export const GArrowsDoubleSwNeIcon = (props) => (
  <SvgIcon {...props}>
    <SvgArrowsDoubleSwNe />
  </SvgIcon>
);

export const GTrashIcon = (props) => (
  <SvgIcon {...props}>
    <SvgTrash />
  </SvgIcon>
);

export const GDollarIcon = (props) => (
  <SvgIcon {...props}>
    <SvgDollar />
  </SvgIcon>
);

export const GUploadIcon = (props) => (
  <SvgIcon {...props}>
    <SvgUpload />
  </SvgIcon>
);

export const GPhotoIcon = (props) => (
  <SvgIcon {...props}>
    <SvgPhoto />
  </SvgIcon>
);

export const GBuildingStoreIcon = (props) => (
  <SvgIcon {...props}>
    <SvgBuildingStore />
  </SvgIcon>
);

export const GShipIcon = (props) => (
  <SvgIcon {...props}>
    <SvgShip />
  </SvgIcon>
);

export const GPaperClipIcon = (props) => (
  <SvgIcon {...props}>
    <SvgPaperClip />
  </SvgIcon>
);

export const GMicIcon = (props) => (
  <SvgIcon {...props}>
    <SvgMic />
  </SvgIcon>
);

export const GMapPinIcon = (props) => (
  <SvgIcon {...props}>
    <SvgMapPin />
  </SvgIcon>
);

export const GEyeOffIcon = (props) => (
  <SvgIcon {...props}>
    <SvgEyeOff />
  </SvgIcon>
);

export const GBanIcon = (props) => (
  <SvgIcon {...props}>
    <SvgBan />
  </SvgIcon>
);

export const GUserPlusIcon = (props) => (
  <SvgIcon {...props}>
    <SvgUserPlus />
  </SvgIcon>
);

export const GFileTextIcon = (props) => (
  <SvgIcon {...props}>
    <SvgFileText />
  </SvgIcon>
);

export const GTestPipeIcon = (props) => (
  <SvgIcon {...props}>
    <SvgTestPipe />
  </SvgIcon>
);

export const GFileIcon = (props) => (
  <SvgIcon {...props}>
    <SvgFile />
  </SvgIcon>
);

export const GUserOffIcon = (props) => (
  <SvgIcon {...props}>
    <SvgUserOff />
  </SvgIcon>
);

export const GUserXIcon = (props) => (
  <SvgIcon {...props}>
    <SvgUserX />
  </SvgIcon>
);

export const GUserCheckIcon = (props) => (
  <SvgIcon {...props}>
    <SvgUserCheck />
  </SvgIcon>
);

export const GPercentageIcon = (props) => (
  <SvgIcon {...props}>
    <SvgPercentage />
  </SvgIcon>
);
