import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
  Stack,
  styled,
  Box,
  Divider,
} from '@mui/material';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import CollapseButton from 'src/components/ui/CollapseButton';
import constants from 'src/configs/constants';

const ProductImage = styled('img')(() => ({
  width: 52,
  height: 52,
  objectFit: 'cover',
  border: '1px solid #D7D7D7',
  borderRadius: 8,
}));

const DataGrid = styled(MuiDataGrid)(() => ({
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '22px',
    color: '#969696',
  },
  '& .MuiDataGrid-columnHeaders': {
    marginBottom: 9,
    background: 'transparent',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
  '& .MuiDataGrid-cell:first-of-type': {
    paddingLeft: 0,
  },
}));

const FooterLabel = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.5,
  color: '#232323',
}));

const PageTradeSummaryCard = ({ shipments }) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(true);
  const [items, setItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);

  const handleToggle = () => {
    setOpened((value) => !value);
  };

  useEffect(() => {
    if (shipments && shipments.length) {
      const newItems = [];
      let index = 1;
      for (const shipment of shipments) {
        for (const product of shipment.items) {
          newItems.push({
            id: index,
            image: product.image,
            name: product.name,
            value: product.price,
            quantity: product.quantity,
            unit: product.unit,
          });
          index++;
        }
      }
      setItems(newItems);
    } else {
      setItems([]);
    }
  }, [shipments]);

  useEffect(() => {
    let value = 0;
    for (const item of items) {
      value += item.value * item.quantity;
    }
    setSubtotal(value);
    setTotal(value);
  }, [items]);

  return (
    <Card sx={{ mb: 2.5 }}>
      <CardHeader
        title={
          <Typography fontSize={18} fontWeight={600} colorscheme="heading">
            Trade Value Summary
          </Typography>
        }
        emphasis="true"
        action={<CollapseButton opened={opened} onToggle={handleToggle} />}
      />
      <Collapse in={opened}>
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          <DataGrid
            columns={[
              {
                headerName: t('Item'),
                field: 'name',
                renderCell: ({ row }) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing="10px"
                    p="10px"
                  >
                    <ProductImage src={constants.getStoragePath(row.image)} />
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      colorscheme="secondary.500"
                    >
                      {row.name}
                    </Typography>
                  </Stack>
                ),
                flex: 1,
                minWidth: 300,
                sortable: false,
                filterable: false,
              },
              {
                headerName: t('Value'),
                field: 'value',
                renderCell: ({ value }) => (
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    colorscheme="secondary.500"
                  >
                    ${Number(value).toLocaleString()}
                  </Typography>
                ),
                sortable: false,
                filterable: false,
              },
              {
                headerName: t('Qty'),
                field: 'quantity',
                renderCell: ({ value, row }) => (
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    colorscheme="secondary.500"
                  >
                    {Number(value).toLocaleString() + ' ' + row.unit}
                  </Typography>
                ),
                sortable: false,
                filterable: false,
              },
              {
                headerName: t('Total'),
                field: 'total',
                renderCell: ({ row }) => (
                  <Typography fontSize={12} fontWeight={500} lineHeight={1.5}>
                    ${Number(row.value * row.quantity).toLocaleString()}
                  </Typography>
                ),
                sortable: false,
                filterable: false,
              },
            ]}
            rows={items}
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            disableDensitySelector
            disableEval
            disableRowSelectionOnClick
            hideFooter
            hideFooterSelectedRowCount
            getRowHeight={() => 'auto'}
            columnHeaderHeight={22}
          />
          <Box bgcolor="#F9F9F9" borderRadius={2} p={3} mt={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FooterLabel>{t('Subtotal')}</FooterLabel>
              <FooterLabel>${Number(subtotal).toLocaleString()}</FooterLabel>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={1.5}
            >
              <Stack>
                <FooterLabel>{t('Shipping')}</FooterLabel>
                <Typography fontSize={12} colorscheme="gray.400">
                  INCOTERMS
                </Typography>
              </Stack>
              <FooterLabel>-</FooterLabel>
            </Stack>
            <Divider sx={{ my: 1.5 }} />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FooterLabel>{t('Total')}</FooterLabel>
              <FooterLabel>${Number(total).toLocaleString()}</FooterLabel>
            </Stack>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

PageTradeSummaryCard.propTypes = {
  shipments: PropTypes.array,
};

export default PageTradeSummaryCard;
