import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  styled,
  StepConnector,
  stepConnectorClasses,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import colors from '../../../configs/colors';

import imgEllipse from '../../../assets/images/ellipse-1.svg';

const CustomizedStepCircle = styled('div')`
  position: relative;
  width: 42px;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 42px;
  border: 3px solid ${(props) => (props.active ? colors.primary : '#EBEBEB')};
  background-color: ${(props) => (props.active ? colors.primary : '#FFFFFF')};
  z-index: 3;
`;

const CustomizedStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: colors.primary,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: colors.primary,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#E7E7E7',
    borderRadius: 1,
  },
}));

const CustomizedStep = ({ index, active }) => {
  return (
    <CustomizedStepCircle active={active}>
      <Typography
        fontSize={20}
        fontWeight={500}
        colorscheme={active ? 'light' : 'gray.500'}
      >
        {index + 1}
      </Typography>
      {active && (
        <img
          src={imgEllipse}
          style={{
            position: 'absolute',
            left: -13,
            top: -6,
            width: 55,
            height: 55,
          }}
        />
      )}
    </CustomizedStepCircle>
  );
};

const AccountStepper = ({ step }) => {
  const { t } = useTranslation();

  return (
    <Stepper
      alternativeLabel
      activeStep={step}
      connector={<CustomizedStepConnector />}
    >
      {[t('Introduction'), t('Personal Details'), t('Business')].map((item, index) => (
        <Step
          key={`step-${index}`}
          active={index <= step}
        >
          <StepLabel
            StepIconComponent={CustomizedStep}
            StepIconProps={{ index, active: index <= step }}
            sx={{
              '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                mt: 1.5,
              },
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={600}
              colorscheme={index <= step ? 'royal.blue' : 'grey'}
            >
              {t(item)}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

AccountStepper.propTypes = {
  step: PropTypes.number,
};

CustomizedStep.propTypes = {
  index: PropTypes.number,
  active: PropTypes.bool,
};

export default AccountStepper;
