import React from 'react';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClient, QueryClientProvider } from 'react-query';

import AppRouterProvider from './routers/router';
import ChatProvider from './components/layouts/ChatLayout';
import appTheme from './configs/theme';
import store, { persistor } from './redux/store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChatProvider>
          <ThemeProvider theme={appTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <QueryClientProvider client={queryClient}>
                <AppRouterProvider />
              </QueryClientProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </ChatProvider>
      </PersistGate>
      <ToastContainer />
    </Provider>
  );
}

export default App;
