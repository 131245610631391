import React from 'react';
import {
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';

import Center from '../ui/Center';
import labels from 'react-phone-number-input/locale/en';

const CountrySelect = ({
  value,
  onChange,
  showCountryName,
  innerRef,
  error,
  placeholder,
  ...rest
}) => (
  <Select
    sx={{
      width: '100%',
      mr: 1,
    }}
    {...rest}
    value={value}
    onChange={(event) => onChange(event.target.value || undefined)}
    renderValue={
      value
        ? (value) => (
            <Center sx={{ width: '100%', height: '100%', pt: 0.1 }}>
              {showCountryName ? (
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: '100%' }}
                >
                  <img src={`/flags/${value}.svg`} width={30} />
                  <Typography>{labels[value]}</Typography>
                </Stack>
              ) : (
                <img src={`/flags/${value}.svg`} width={30} />
              )}
            </Center>
          )
        : () => <Typography colorscheme="gray.400">{placeholder}</Typography>
    }
    ref={innerRef}
    error={!!error}
    {...(placeholder && {
      displayEmpty: true,
    })}
    MenuProps={{
      sx: {
        maxHeight: 400,
      },
    }}
  >
    {getCountries().map((country) => (
      <MenuItem key={country} value={country}>
        <ListItemIcon>
          <img src={`/flags/${country}.svg`} width={24} />
        </ListItemIcon>
        <Typography variant="inherit">
          {showCountryName
            ? labels[country]
            : `${country} +${getCountryCallingCode(country)}`}
        </Typography>
      </MenuItem>
    ))}
  </Select>
);

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  innerRef: PropTypes.any,
  showCountryName: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default CountrySelect;
