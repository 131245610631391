import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, OutlinedInput } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import HiddenInput from './HiddenInput';
import { apiV2UploadFiles } from 'src/apis/upload';

const SingleFileInput = ({ value, error, accept, folder, onChange }) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (ev) => {
    if (ev.target.files.length > 0) {
      const file = ev.target.files[0];
      setLoading(true);
      const formData = new FormData();
      formData.append('files', file);
      formData.append('key', folder ? folder : 'general');

      apiV2UploadFiles(formData)
        .then((result) => {
          onChange(result);
        })
        .catch(() => {
          onChange(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <OutlinedInput
      value={value?.filename ? value.filename : ''}
      readOnly
      fullWidth
      placeholder="Choose file"
      endAdornment={
        <InputAdornment position="end">
          <LoadingButton
            variant="contained"
            size="small"
            component="label"
            loading={loading}
            sx={{
              borderRadius: 1,
            }}
          >
            Upload
            <HiddenInput
              type="file"
              onChange={handleChange}
              {...(accept ? { accept } : {})}
            />
          </LoadingButton>
        </InputAdornment>
      }
      sx={{
        pr: 1,
      }}
      error={!!error}
    />
  );
};

SingleFileInput.propTypes = {
  value: PropTypes.any,
  error: PropTypes.bool,
  accept: PropTypes.string,
  folder: PropTypes.string,
  onChange: PropTypes.func,
};

export default SingleFileInput;
