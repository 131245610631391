import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Typography,
  Box,
  Divider,
  Avatar,
  Card,
  CardContent,
  Skeleton,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import striptags from 'striptags';

import constants from 'src/configs/constants';

countries.registerLocale(en);

const PageProductSummary = ({ data, loading }) => {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <>
      <Card>
        <CardContent
          sx={{
            p: '16px !important',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={3}>
            <Avatar
              src={constants.getStoragePath(data?.company?.logo)}
              sx={{
                width: 100,
                height: 100,
              }}
            />
            <Typography
              fontSize={24}
              fontWeight={700}
              colorscheme="heading"
              flex={1}
            >
              {loading ? <Skeleton width="70%" /> : data?.company?.name}
            </Typography>
          </Stack>
          <Typography
            mt={2}
            fontSize={20}
            fontWeight={600}
            colorscheme="heading"
          >
            {loading ? <Skeleton /> : data?.name}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {loading ? (
              <Skeleton variant="rounded" height={30} width={120} />
            ) : (
              <Box>
                <Typography
                  fontSize={24}
                  fontWeight={600}
                  component="span"
                  display="inline"
                  colorscheme="heading"
                >
                  ${data?.price ? Number(data.price).toFixed(2) : '0.00'}
                </Typography>
                <Typography
                  fontSize={24}
                  component="span"
                  display="inline"
                  colorscheme="heading"
                >
                  ({data?.unit})
                </Typography>
              </Box>
            )}
            {/* <IconButton>
              <GHeartIcon />
            </IconButton> */}
          </Stack>
          <Divider sx={{ my: 1.5 }} />
          {loading ? (
            <Box>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Box>
          ) : (
            <Typography fontSize={16}>
              {striptags(data?.description).substr(0, 150) + ' ...'}
            </Typography>
          )}
          <Typography fontSize={16} mt={1.5}>
            {t('Category')}:{' '}
            <Typography
              fontSize={16}
              colorscheme="primary"
              display="inline"
              component="span"
            >
              {data?.category?.name}
            </Typography>
          </Typography>
          <Typography fontSize={16} mt={1.5}>
            {t('Minimum Order Quantity')}:{' '}
            <Typography
              fontSize={16}
              fontWeight={600}
              display="inline"
              component="span"
            >
              {data?.minimumOrderQuantity?.toLocaleString() + ' '}
              {data?.unit}
            </Typography>
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2.5 }}>
        <CardContent sx={{ p: '16px !important' }}>
          <Typography fontSize={20} fontWeight={600} colorscheme="heading">
            {t('Trade Request Submission')}
          </Typography>
          <Divider sx={{ my: 1.5 }} />
          <Typography fontSize={16}>
            To submit a request to trade, click the below request button and
            fill in the details required.
            <br />
            All purchase requests have to be reviewed by the seller and
            approved.
          </Typography>
          <Divider sx={{ my: 1.5 }} />
          <Typography fontSize={16} fontWeight={600} colorscheme="heading">
            {loading ? <Skeleton /> : data?.category?.name}
          </Typography>
          <Typography fontSize={16} mt={1.5}>
            {t('Seller')}:{' '}
            <Typography
              fontSize={16}
              colorscheme="primary"
              display="inline"
              component="span"
            >
              {loading ? (
                <Skeleton sx={{ display: 'inline-block', width: 120 }} />
              ) : (
                data?.company?.name
              )}
            </Typography>
          </Typography>
          <Typography fontSize={16} mt={1}>
            {t('Location')}:{' '}
            <Typography
              fontSize={16}
              colorscheme="primary"
              display="inline"
              component="span"
            >
              {loading ? (
                <Skeleton sx={{ display: 'inline-block', width: 120 }} />
              ) : (
                countries.getName(data?.location, 'en')
              )}
            </Typography>
          </Typography>
          <Button
            variant="contained"
            fullWidth
            size="small"
            sx={{ mt: 2 }}
            LinkComponent={NavLink}
            to={`/marketplace/products/${params.productId}/submit-order`}
          >
            {t('Submit Request')}
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

PageProductSummary.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
  onSubmitOrder: PropTypes.func,
};

export default PageProductSummary;
