import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';

import PhoneNumberConfirmationModal from './PhoneNumberConfirmationModal';
import PhoneNumberVerificationModal from './PhoneNumberVerificationModal';
import { apiGetProfile, apiUpdateProfile } from 'src/apis/profile';
import notify from 'src/configs/notify';

const PageExtraSecurityView = () => {
  const { data: profile, refetch } = useQuery(['profile'], apiGetProfile);

  const [enabled, setEnabled] = useState(false);
  const [method, setMethod] = useState('none');
  const [loading, setLoading] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [modals, setModals] = useState({
    'phone-number-confirmation': false,
    'phone-number-verification': false,
  });

  const handleOpenModal = (name) => {
    setModals((values) => ({
      ...values,
      [name]: true,
    }));
  };

  const handleCloseModal = (name) => {
    setModals((values) => ({
      ...values,
      [name]: false,
    }));
  };

  const handleConfirmNumber = () => {
    handleCloseModal('phone-number-confirmation');
    handleOpenModal('phone-number-verification');
  };

  const handleUpdate = async (skipPhoneVerification = false) => {
    console.log('phoneVerified', phoneVerified);
    console.log('skipPhoneVerification', skipPhoneVerification);
    if (!skipPhoneVerification) {
      if (method === 'sms' && !phoneVerified) {
        return handleOpenModal('phone-number-confirmation');
      }
    }

    const settings = profile?.user?.settings ? profile.user.settings : {};
    settings.is2FAEnabled = enabled;
    settings.methodOf2FA = method;

    setLoading(true);
    if (
      await apiUpdateProfile({
        settings,
      })
    ) {
      await refetch();
      notify({
        message:
          'You have updated your additional security settings successfully',
        type: 'success',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (profile) {
      setEnabled(profile.user?.settings?.is2FAEnabled ? true : false);
      setMethod(
        profile.user?.settings?.methodOf2FA
          ? profile.user.settings.methodOf2FA
          : 'none',
      );
      setPhoneVerified(profile.user?.verifications?.phone ? true : false);
      setLoading(false);
    } else {
      setLoading(true);
      setEnabled(false);
      setMethod('none');
    }
  }, [profile]);

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <CardHeader
          title={
            <Typography
              fontSize={20}
              fontWeight={500}
              colorscheme="heading"
              sx={{
                boxDecorationBreak: 'clone',
              }}
            >
              Add Extra Security to your account
            </Typography>
          }
          emphasis="true"
          action={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <FormControlLabel
                control={<Switch />}
                label={enabled ? 'Disable' : 'Enable'}
                checked={enabled}
                onChange={(ev, checked) => setEnabled(checked)}
              />
            )
          }
        />
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          <Typography fontSize={18}>
            Two-Factor Authentication protects your account by requiring an
            additional code when you log in on a device we don’t recognize.
          </Typography>
          <Typography
            mt={2.5}
            mb={1.5}
            fontSize={18}
            fontWeight={600}
            colorscheme="heading"
          >
            Choose your security method
          </Typography>
          <RadioGroup
            value={method}
            onChange={(ev) => setMethod(ev.target.value)}
          >
            <Stack direction="row" p={2} spacing={2}>
              <Stack flex={1}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  colorscheme="heading"
                >
                  Via Email
                </Typography>
                <Typography fontSize={18} colorscheme="gray.500">
                  We’ll send a code to registered email
                </Typography>
              </Stack>
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <FormControlLabel
                  value="email"
                  control={<Radio />}
                  disabled={!enabled}
                />
              )}
            </Stack>
            <Stack direction="row" p={2} spacing={2}>
              <Stack flex={1}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  colorscheme="heading"
                >
                  Text message (SMS)
                </Typography>
                <Typography fontSize={18} colorscheme="gray.500">
                  We’ll send a code to your registered number
                </Typography>
              </Stack>
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <FormControlLabel
                  value="sms"
                  control={<Radio />}
                  disabled={!enabled}
                />
              )}
            </Stack>
          </RadioGroup>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              sx={{
                px: 2,
              }}
              disabled={loading}
              onClick={() => handleUpdate(false)}
            >
              Update Settings
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <PhoneNumberConfirmationModal
        open={modals['phone-number-confirmation']}
        phoneNumber={profile?.user?.phone}
        onClose={() => handleCloseModal('phone-number-confirmation')}
        onSubmit={handleConfirmNumber}
      />
      <PhoneNumberVerificationModal
        open={modals['phone-number-verification']}
        phoneNumber={profile?.user?.phone}
        onClose={() => handleCloseModal('phone-number-verification')}
        onSubmit={() => {
          setPhoneVerified(true);
          handleUpdate(true);
        }}
      />
    </>
  );
};

export default PageExtraSecurityView;
