import axios from 'axios';
import { t } from 'i18next';
import notify from 'src/configs/notify';

export const apiGetNotifications = async ({
  pageIndex = 1,
  pageSize = 10,
  onlyUnread = false,
}) => {
  try {
    const response = await axios.get('/api/v1/notifications', {
      params: {
        pageIndex,
        pageSize,
        onlyUnread,
      },
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return [];
  }
};

export const apiReadNotification = async ({ notificationId }) => {
  try {
    await axios.post(`/api/v1/notifications/${notificationId}/read`);
    return true;
  } catch (e) {
    return false;
  }
};

export const apiReadAllNotifications = async () => {
  try {
    await axios.post('/api/v1/notifications/read-all');
    notify({
      message: 'You have marked all notifications as read successfully',
      type: 'success',
    });
    return true;
  } catch (e) {
    return false;
  }
};
