import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
  Box,
  Stack,
  OutlinedInput,
  IconButton,
  Link,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';

import CollapseButton from 'src/components/ui/CollapseButton';
import colors from 'src/configs/colors';
import { GCloseIcon, GDollarIcon } from 'src/components/ui/AppIcon';

const PagePreShippingPaymentsCard = ({
  payments,
  onAdd,
  onChange,
  onRemove,
}) => {
  const [opened, setOpened] = useState(true);

  const handleToggle = () => {
    setOpened((value) => !value);
  };

  return (
    <Card
      sx={{
        mb: 2.5,
      }}
    >
      <CardHeader
        title={
          <Typography fontSize={18} fontWeight={600} colorscheme="heading">
            Initial Payments
          </Typography>
        }
        emphasis="true"
        action={<CollapseButton opened={opened} onToggle={handleToggle} />}
      />
      <Collapse in={opened}>
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          {payments.length > 0 ? (
            <Stack
              spacing={1.5}
              sx={{
                overflowX: 'auto',
              }}
            >
              {payments.map((item, index) => (
                <Stack
                  key={`pre-shipping-payment-${index}`}
                  direction="row"
                  alignItems="center"
                  overflow="auto"
                  spacing={1.5}
                >
                  <Box minWidth={230} flex={1}>
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      <Box
                        borderRadius={1}
                        bgcolor={colors['royal.blue']}
                        width={52}
                        height={52}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <GDollarIcon sx={{ color: 'white' }} />
                      </Box>
                      <Typography colorscheme="heading">{item.name}</Typography>
                      {/* <OutlinedInput
                        sx={{
                          flex: 1,
                        }}
                        readOnly
                        value={item.name}
                        onChange={(ev) =>
                          onChange(index, 'name', ev.currentTarget.value)
                        }
                      /> */}
                    </Stack>
                  </Box>
                  <Box width={150}>
                    <NumericFormat
                      customInput={OutlinedInput}
                      prefix="$"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      fullWidth
                      sx={{
                        flex: 1,
                        '& input': {
                          textAlign: 'right',
                        },
                      }}
                      value={item.total}
                      onValueChange={(values) =>
                        onChange(index, 'total', values.floatValue)
                      }
                    />
                  </Box>
                  <Box>
                    <IconButton
                      sx={{
                        bgcolor: colors['gray.200'],
                      }}
                      onClick={() => onRemove(index)}
                    >
                      <GCloseIcon
                        sx={{
                          color: colors['gray.400'],
                          width: 16,
                          height: 16,
                        }}
                      />
                    </IconButton>
                  </Box>
                </Stack>
              ))}
            </Stack>
          ) : null}
          <Box mt={2} display={payments.length ? 'none' : 'block'}>
            <Link
              sx={{
                display: 'inline-block',
                cursor: 'pointer',
              }}
              onClick={onAdd}
            >
              + Payment Stage
            </Link>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

PagePreShippingPaymentsCard.propTypes = {
  payments: PropTypes.any,
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

export default PagePreShippingPaymentsCard;
