import React, { useEffect, useState } from 'react';
import {
  ButtonBase,
  Collapse,
  Container,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  GBellIcon,
  GBoxIcon,
  GCreditCardIcon,
  GLayoutSidebar,
  GShieldLockIcon,
  GUserIcon,
  GUsersIcon,
} from '../../components/ui/AppIcon';
import constants from '../../configs/constants';

const routes = [
  {
    path: '/profile/account',
    icon: <GUserIcon />,
    label: 'Profile',
  },
  {
    path: '/profile/bank',
    icon: <GCreditCardIcon />,
    label: 'Bank Account',
  },
  {
    path: '/profile/products',
    icon: <GBoxIcon />,
    label: 'My Products',
  },
  {
    path: '/profile/team',
    icon: <GUsersIcon />,
    label: 'My Team',
  },
  {
    path: '/profile/security-settings',
    icon: <GShieldLockIcon />,
    label: 'Security Settings',
  },
  {
    path: '/profile/notification-settings',
    icon: <GBellIcon />,
    label: 'Notification Settings',
  },
];

const ProfileNavButton = styled(ButtonBase)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textAlign: 'left',
  width: '100%',
  height: 44,
  borderLeft: '5px solid transparent',
  paddingLeft: 18,
  transform: 'all 0.2s',

  '& svg': {
    width: 18,
    height: 18,
    color: '#8C979A',
    marginRight: 10,
  },
  '& .MuiTypography-root': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '22px',
    color: '8C979A',
  },
  '&.active svg': {
    color: constants.colors.fills.primary,
  },
  '&.active .MuiTypography-root': {
    color: constants.colors.fills.primary,
  },
  '&.active': {
    borderLeft: `5px solid ${constants.colors.fills.primary}`,
    backgroundColor: '#EAE9E8',
  },
}));

const ProfileLayout = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [key, setKey] = useState();
  const [anchor, setAnchor] = useState(null);

  const handleOpenSidebar = (ev) => {
    setAnchor(ev.currentTarget);
  };

  const handleCloseSidebar = () => {
    setAnchor(null);
  };

  useEffect(() => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.includes(routes[i].path)) {
        setKey(i);
        break;
      }
    }
  }, [location]);

  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <Stack
        mb={3}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Typography
          fontSize={24}
          fontWeight={600}
          lineHeight="34px"
          color="#232323"
          flex={1}
        >
          {t('Settings')}
        </Typography>
        <div id="profile-layout-actions"></div>
        {isSm && (
          <>
            <IconButton
              sx={{
                border: '1px solid #D7D7D7',
                borderRadius: '6px',
                color: constants.colors.fills.primary,
              }}
              onClick={handleOpenSidebar}
            >
              <GLayoutSidebar />
            </IconButton>
            <Menu
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleCloseSidebar}
            >
              {routes.map((route, index) => (
                <MenuItem
                  key={`profile-route-${index}`}
                  component={NavLink}
                  to={route.path}
                >
                  <ListItemIcon
                    sx={{
                      color: constants.colors.fonts.text,
                    }}
                  >
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="md">{route.label}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Stack>
      <Grid container spacing={2.5}>
        {!isSm && (
          <Grid item xs={12} md={3}>
            <Collapse in={!isSm} unmountOnExit mountOnEnter>
              <Stack mr={2.5} alignItems="flex-start" width="100%">
                {routes.map((route, index) => (
                  <ProfileNavButton
                    key={`route-${route.path}`}
                    LinkComponent={NavLink}
                    to={route.path}
                    className={index === key ? 'active' : ''}
                  >
                    {route.icon}
                    <Typography>{t(route.label)}</Typography>
                  </ProfileNavButton>
                ))}
              </Stack>
            </Collapse>
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfileLayout;
