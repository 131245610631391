import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
  Grid,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import countries from 'i18n-iso-countries';

import CollapseButton from 'src/components/ui/CollapseButton';
import colors from 'src/configs/colors';

const Caption = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '22px',
  color: colors['gray.600'],
}));

const Information = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '24px',
  color: colors.heading,
}));

const PageTradeOverviewCard = ({ data }) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(true);

  const handleToggle = () => {
    setOpened((value) => !value);
  };

  return (
    <Card sx={{ mb: 2.5 }}>
      <CardHeader
        title={
          <Typography fontSize={18} fontWeight={600} colorscheme="heading">
            {t('Trade Overview')}
          </Typography>
        }
        emphasis="true"
        action={<CollapseButton opened={opened} onToggle={handleToggle} />}
      />
      <Collapse in={opened}>
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Caption>{t('Requested Date')}</Caption>
              <Information>
                {moment(data?.createdAt).format('DD-MM-YYYY HH:mm')}
              </Information>
            </Grid>
            <Grid item xs={12} md={4}>
              <Caption>{t('Origin')}</Caption>
              <Information>
                {countries.getName(data?.product?.location, 'en')}
              </Information>
            </Grid>
            <Grid item xs={12} md={4}>
              <Caption>{t('Destination')}</Caption>
              <Information>{data?.port}</Information>
            </Grid>
            <Grid item xs={12} md={4}>
              <Caption>{t('Incoterms')}</Caption>
              <Information>{data?.incoterms}</Information>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

PageTradeOverviewCard.propTypes = {
  data: PropTypes.object,
};

export default PageTradeOverviewCard;
