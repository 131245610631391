import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Container } from '@mui/material';

import PageHeader from './page/PageHeader';
import PageBuyerWidgets from './buyer/PageBuyerWidgets';
import PageSellerWidgets from './seller/PageSellerWidgets';
import PageTradesView from './page/PageTradesView';

const DashboardPage = () => {
  const [role, setRole] = useState(
    localStorage.getItem('omp/dashboard:remembered-role') || 'buyer',
  );
  const containerRef = useRef();

  const handleChangeRole = useCallback(
    (ev, value) => {
      if (value !== null) {
        setRole(value);
      }
    },
    [setRole],
  );

  useEffect(() => {
    localStorage.setItem('omp/dashboard:remembered-role', role);
  }, [role]);

  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
      ref={containerRef}
    >
      <PageHeader role={role} onChangeRole={handleChangeRole} />
      <PageBuyerWidgets in={role === 'buyer'} />
      <PageSellerWidgets in={role === 'seller'} />
      <Box mt={3}>
        <PageTradesView role={role} />
      </Box>
    </Container>
  );
};

export default DashboardPage;
