import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, Paper, Stack, Typography, styled } from '@mui/material';
import {
  Timeline as MuiTimeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot as MuiTimelineDot,
  TimelineConnector as MuiTimelineConnector,
  TimelineContent as MuiTimelineContent,
} from '@mui/lab';
import { useTranslation } from 'react-i18next';

import CollapseButton from 'src/components/ui/CollapseButton';
import { GCheckIcon } from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';

const Timeline = styled(MuiTimeline)(() => ({
  padding: 0,
  marginBlockStart: 0,
  marginBlockEnd: 0,
  '& .MuiTimelineItem-root::before': {
    flex: 0,
    padding: 0,
  },
}));

const TimelineDot = styled(MuiTimelineDot)(() => ({
  width: 32,
  height: 32,
  margin: 0,
  padding: '2px',
  boxShadow: 'none',
  boxSizing: 'border-box',
  border: `1px solid ${colors.primary}`,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
}));

const TimelineConnector = styled(MuiTimelineConnector)((props) => ({
  backgroundColor: colors.primary,
  width: '1px',
  height: props.small ? '40px' : '60px',
}));

const InnerTimelineDot = ({ type }) => {
  if (type === 'empty') {
    return <></>;
  }
  if (type === 'done') {
    return (
      <GCheckIcon
        sx={{
          width: 20,
          height: 20,
          color: colors.primary,
        }}
      />
    );
  }
  if (type === 'doing') {
    return (
      <Box width={24} height={24} borderRadius={100} bgcolor={colors.primary} />
    );
  }
  return null;
};

InnerTimelineDot.propTypes = {
  type: PropTypes.string,
};

const TimelineContent = styled(MuiTimelineContent)(() => ({
  padding: 0,
  paddingLeft: 24,
}));

const StepLabel = styled(Typography)(() => ({
  fontSize: 14,
  color: colors['gray.800'],
}));

const TitleLabel = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 500,
  color: colors['black'],
  marginTop: 2,
}));

const PageTradeFlowCard = ({ data }) => {
  const { t } = useTranslation();

  const [opens, setOpens] = useState([]);
  const [steps, setSteps] = useState({
    requested: 'doing',
  });

  const handleToggleCollapse = (index) => {
    const newOpens = [...opens];
    newOpens[index] = newOpens[index] ? false : true;
    setOpens(newOpens);
  };

  useEffect(() => {
    const newSteps = {
      requested: 'empty',
      checking: 'empty',
      paying: 'empty',
      shipment: 'empty',
      completed: 'empty',
    };
    if (data) {
      switch (data.status) {
        case 'completed': {
          newSteps.completed = 'done';
          /* falls through */
        }
        case 'shipment': {
          newSteps.shipment = data.status === 'shipment' ? 'doing' : 'done';
          /* falls through */
        }
        case 'paying': {
          newSteps.paying = data.status === 'paying' ? 'doing' : 'done';
          /* falls through */
        }
        case 'checking': {
          newSteps.checking = data.status === 'checking' ? 'doing' : 'done';
          /* falls through */
        }
        case 'requested': {
          newSteps.requested = data.status === 'requested' ? 'doing' : 'done';
          /* falls through */
        }
      }

      if (data.status === 'rejected' || data.status === 'cancelled') {
        for (const key of Object.keys(newSteps)) {
          newSteps[key] = 'empty';
        }
      }
      setSteps(newSteps);
      setOpens(
        data.shipments.map(
          (shipment) =>
            shipment.status !== 'pending' && shipment.status !== 'settled',
        ),
      );
    }
  }, [data]);

  return (
    <Paper
      sx={{
        p: 3,
        mb: 2.5,
      }}
    >
      <Timeline>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              <InnerTimelineDot type={steps.requested} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <StepLabel>Step 1</StepLabel>
            <TitleLabel>Request</TitleLabel>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              <InnerTimelineDot type={steps.checking} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <StepLabel>Step 2</StepLabel>
            <TitleLabel>Agreements</TitleLabel>
          </TimelineContent>
        </TimelineItem>
        {data?.initialPayments?.length > 0 ? (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <InnerTimelineDot type={steps.paying} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <StepLabel>Step 3</StepLabel>
              <TitleLabel>Initial Payment</TitleLabel>
            </TimelineContent>
          </TimelineItem>
        ) : null}
        {data?.shipments?.length > 0 ? (
          data.shipments.map((shipment, index) => {
            const step = 4 + index - (data?.initialPayments?.length ? 0 : 1);
            let substep = 1;

            return (
              <TimelineItem key={`shipment-${index + 1}`}>
                <TimelineSeparator>
                  <TimelineDot>
                    <InnerTimelineDot
                      type={
                        shipment.status === 'settled'
                          ? 'done'
                          : shipment.status !== 'pending'
                          ? 'doing'
                          : 'empty'
                      }
                    />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <StepLabel>Step {step}</StepLabel>
                      <TitleLabel>Order {index + 1}</TitleLabel>
                    </Box>
                    <Box>
                      <CollapseButton
                        opened={opens[index] ?? false}
                        onToggle={() => handleToggleCollapse(index)}
                      />
                    </Box>
                  </Stack>
                  <Collapse orientation="vertical" in={opens[index] ?? false}>
                    <Box>
                      <Timeline>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineConnector
                              sx={{
                                height: 20,
                              }}
                            />
                            <TimelineDot>
                              <InnerTimelineDot
                                type={
                                  shipment.status === 'pending'
                                    ? 'empty'
                                    : shipment.status === 'manufacturing'
                                    ? 'doing'
                                    : 'done'
                                }
                              />
                            </TimelineDot>
                            <TimelineConnector small="small" />
                          </TimelineSeparator>
                          <TimelineContent
                            sx={{
                              pt: 2.5,
                            }}
                          >
                            <StepLabel>
                              Step {step}.{substep++}
                            </StepLabel>
                            <TitleLabel>Manufacturing</TitleLabel>
                          </TimelineContent>
                        </TimelineItem>
                        {shipment.payments
                          .filter(
                            (item) => item.stage === 'after-manufacturing',
                          )
                          .map((payment) => (
                            <TimelineItem key={`payment-${payment._id}`}>
                              <TimelineSeparator>
                                <TimelineDot>
                                  <InnerTimelineDot
                                    type={
                                      payment.status === 'pending'
                                        ? 'empty'
                                        : payment.status === 'confirmed'
                                        ? 'done'
                                        : 'doing'
                                    }
                                  />
                                </TimelineDot>
                                <TimelineConnector small="small" />
                              </TimelineSeparator>
                              <TimelineContent>
                                <StepLabel>
                                  Step {step}.{substep++}
                                </StepLabel>
                                <TitleLabel>Payment</TitleLabel>
                              </TimelineContent>
                            </TimelineItem>
                          ))}
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot>
                              <InnerTimelineDot
                                type={
                                  ['pending', 'manufacturing'].includes(
                                    shipment.status,
                                  )
                                    ? 'empty'
                                    : shipment.status === 'shipping'
                                    ? 'doing'
                                    : 'done'
                                }
                              />
                            </TimelineDot>
                            <TimelineConnector small="small" />
                          </TimelineSeparator>
                          <TimelineContent>
                            <StepLabel>
                              Step {step}.{substep++}
                            </StepLabel>
                            <TitleLabel>Arrange Shipping</TitleLabel>
                          </TimelineContent>
                        </TimelineItem>
                        {shipment.payments
                          .filter((item) => item.stage === 'after-shipping')
                          .map((payment) => (
                            <TimelineItem key={`payment-${payment._id}`}>
                              <TimelineSeparator>
                                <TimelineDot>
                                  <InnerTimelineDot
                                    type={
                                      payment.status === 'pending'
                                        ? 'empty'
                                        : payment.status === 'confirmed'
                                        ? 'done'
                                        : 'doing'
                                    }
                                  />
                                </TimelineDot>
                                <TimelineConnector small="small" />
                              </TimelineSeparator>
                              <TimelineContent>
                                <StepLabel>
                                  Step {step}.{substep++}
                                </StepLabel>
                                <TitleLabel>Payment</TitleLabel>
                              </TimelineContent>
                            </TimelineItem>
                          ))}
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot>
                              <InnerTimelineDot
                                type={
                                  [
                                    'pending',
                                    'manufacturing',
                                    'shipping',
                                  ].includes(shipment.status)
                                    ? 'empty'
                                    : shipment.status === 'delivering'
                                    ? 'doing'
                                    : 'done'
                                }
                              />
                            </TimelineDot>
                            <TimelineConnector small="small" />
                          </TimelineSeparator>
                          <TimelineContent>
                            <StepLabel>
                              Step {step}.{substep++}
                            </StepLabel>
                            <TitleLabel>Goods Delivered</TitleLabel>
                          </TimelineContent>
                        </TimelineItem>
                        {shipment.payments
                          .filter((item) => item.stage === 'after-delivering')
                          .map((payment) => (
                            <TimelineItem key={`payment-${payment._id}`}>
                              <TimelineSeparator>
                                <TimelineDot>
                                  <InnerTimelineDot
                                    type={
                                      payment.status === 'pending'
                                        ? 'empty'
                                        : payment.status === 'confirmed'
                                        ? 'done'
                                        : 'doing'
                                    }
                                  />
                                </TimelineDot>
                                <TimelineConnector small="small" />
                              </TimelineSeparator>
                              <TimelineContent>
                                <StepLabel>
                                  Step {step}.{substep++}
                                </StepLabel>
                                <TitleLabel>Payment</TitleLabel>
                              </TimelineContent>
                            </TimelineItem>
                          ))}
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot>
                              <InnerTimelineDot
                                type={
                                  [
                                    'pending',
                                    'manufacturing',
                                    'shipping',
                                    'delivering',
                                  ].includes(shipment.status)
                                    ? 'empty'
                                    : shipment.status === 'settled'
                                    ? 'done'
                                    : 'doing'
                                }
                              />
                            </TimelineDot>
                          </TimelineSeparator>
                          <TimelineContent>
                            <StepLabel>
                              Step {step}.{substep++}
                            </StepLabel>
                            <TitleLabel>Order Finalised</TitleLabel>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Box>
                  </Collapse>
                </TimelineContent>
              </TimelineItem>
            );
          })
        ) : (
          <>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot>
                  <InnerTimelineDot />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <StepLabel>{t('Step 4')}</StepLabel>
                <TitleLabel>{t('Manufacturing')}</TitleLabel>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot>
                  <InnerTimelineDot />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <StepLabel>Step 5</StepLabel>
                <TitleLabel>{t('Export & Loading')}</TitleLabel>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot>
                  <InnerTimelineDot />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <StepLabel>Step 6</StepLabel>
                <TitleLabel>{t('Import & Delivery')}</TitleLabel>
              </TimelineContent>
            </TimelineItem>
          </>
        )}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              <InnerTimelineDot type={steps.completed} />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <StepLabel>Final Step</StepLabel>
            <TitleLabel>{t('Trade Completion')}</TitleLabel>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Paper>
  );
};

PageTradeFlowCard.propTypes = {
  data: PropTypes.any,
  status: PropTypes.string,
};

export default PageTradeFlowCard;
