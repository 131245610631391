import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CircularProgress,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import ActivityTimeline from '../../../../components/timelines/ActivityTimeline';
import { apiGetActivityLogs } from 'src/apis/profile';

const PageRecentActivityView = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(['activity-logs', 1, 10], async () => {
    return await apiGetActivityLogs({
      pageIndex: 1,
      pageSize: 10,
    });
  });

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Typography fontSize={18} fontWeight={500} colorscheme="heading">
            {t('Recent Activity')}
          </Typography>
        }
        emphasis="true"
      />
      <CardContent
        sx={{
          pt: '6px',
          px: 2,
          pb: '28px',
          maxHeight: '40vh',
          overflow: 'auto',
          boxSizing: 'border-box',
        }}
      >
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" height={200}>
            <CircularProgress size={20} />
            <Typography mt={1.5}>Loading</Typography>
          </Stack>
        ) : (
          <>
            {data?.length > 0 ? (
              <ActivityTimeline activities={data} />
            ) : (
              <Stack justifyContent="center" alignItems="center">
                <Typography fontSize={14} colorscheme="gray.400" py={5}>
                  No activity logs found
                </Typography>
              </Stack>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PageRecentActivityView;
