import React from 'react';

import PageLeadingView from './help-page/PageLeadingView';
import PageFaqsView from './help-page/PageFaqsView';

const HelpPage = () => {
  return (
    <>
      <PageLeadingView />
      <PageFaqsView />
    </>
  );
};

export default HelpPage;
