import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { apiGetCategories, apiGetSubCategories } from 'src/apis/categories';

const PageFiltersPanel = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useSearchParams();

  const { data: categories } = useQuery(['categories', ''], async () => {
    return await apiGetCategories();
  });
  const { data: subCategories } = useQuery(
    ['categories', query.get('parentCategory')],
    async () => {
      if (query.get('parentCategory')) {
        return await apiGetSubCategories(query.get('parentCategory'));
      }
      return [];
    },
  );

  return (
    <Box p={2} width={300} boxSizing="border-box">
      <Typography fontSize={20} fontWeight={600} colorscheme="black">
        {t('Filters')}
      </Typography>
      <Box mt={1}>
        <FormControl fullWidth size="small">
          <InputLabel>{t('Category')}</InputLabel>
          <Select
            value={
              query.get('parentCategory') ? query.get('parentCategory') : 'All'
            }
            onChange={(ev) => {
              const newQuery = new URLSearchParams(query);
              if (newQuery.get('parentCategory') !== ev.target.value) {
                newQuery.set('subCategory', 'All');
              }
              newQuery.set('parentCategory', ev.target.value);
              setQuery(newQuery);
            }}
          >
            <MenuItem value="All">{t('All')}</MenuItem>
            {categories?.map((item) => (
              <MenuItem key={`category-${item._id}`} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          size="small"
          sx={{ mt: 2 }}
          disabled={
            !query.get('parentCategory') ||
            query.get('parentCategory') === 'All'
          }
        >
          <InputLabel>{t('Child Category')}</InputLabel>
          <Select
            value={query.get('subCategory') ? query.get('subCategory') : 'All'}
            onChange={(ev) => {
              const newQuery = new URLSearchParams(query);
              newQuery.set('subCategory', ev.target.value);
              setQuery(newQuery);
            }}
          >
            <MenuItem value="All">{t('All')}</MenuItem>
            {subCategories?.map((item) => (
              <MenuItem key={`category-${item._id}`} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

PageFiltersPanel.propTypes = {
  isShrink: PropTypes.bool,
  isOpened: PropTypes.bool,
};

export default PageFiltersPanel;
