import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Skeleton, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ProductItem = ({
  id,
  image,
  title,
  price,
  loading,
  sx,
  badgeText,
  badgeColor,
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/marketplace/products/${id}`);
  }, [navigate]);

  return (
    <ButtonBase
      sx={{
        textAlign: 'start',
        borderRadius: '5px',
        backgroundColor: 'white',
        boxShadow: '0px 2px 8px 0px rgba(20, 20, 43, 0.10)',
        display: 'block',
        width: '100%',
        p: 2,
        ...sx,
      }}
      onClick={handleClick}
    >
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height="172px" sx={{}} />
      ) : (
        <Box position="relative">
          <img
            src={image}
            style={{
              width: '100%',
              height: 'auto',
              display: 'block',
              aspectRatio: 3 / 2,
              objectFit: 'cover',
            }}
            alt="Product"
          />
          {badgeText && badgeColor && (
            <Box
              position="absolute"
              left={0}
              top={0}
              bgcolor={badgeColor === 'green' ? '#48A96E' : '#F5A540'}
              p="5px 10px"
              borderRadius="2px"
            >
              <Typography
                fontSize={12}
                fontWeight={600}
                lineHeight="16px"
                color="white"
              >
                {badgeText}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          sx={{
            width: '75%',
            height: 22,
            mt: 2,
          }}
        />
      ) : (
        <Typography
          fontSize={16}
          lineHeight="22px"
          colorscheme="fonts.heading"
          display="block"
          mt={2}
        >
          {title}
        </Typography>
      )}
      {loading ? (
        <Skeleton
          variant="rounded"
          sx={{
            width: 80,
            height: 22,
            mt: 1,
          }}
        />
      ) : (
        <Typography fontSize={16} fontWeight={600} colorscheme="primary" mt={1}>
          $ {price?.toLocaleString()}
        </Typography>
      )}
    </ButtonBase>
  );
};

ProductItem.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  ratingValue: PropTypes.number,
  ratingCount: PropTypes.number,
  title: PropTypes.string,
  price: PropTypes.number,
  loading: PropTypes.bool,
  sx: PropTypes.any,
  badgeText: PropTypes.string,
  badgeColor: PropTypes.string,
};

export default ProductItem;
