import React from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GHomeIcon } from '../../../../components/ui/AppIcon';

const PageNavigation = () => {
  const { t } = useTranslation();

  return (
    <Breadcrumbs>
      <Link
        component={NavLink}
        to="/"
      >
        <GHomeIcon />
      </Link>
      <Link
        component={NavLink}
        to="/support/faq"
      >
        {t('Help & Support')}
      </Link>
      <Link
        component={NavLink}
        to="#"
      >
        {t('Create New Ticket')}
      </Link>
    </Breadcrumbs>
  );
};

export default PageNavigation;
