import React, { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import ChatMessageItem from 'src/components/items/ChatMessageItem';
import ChatMessageInput from 'src/components/ui/ChatMessageInput';
import {
  GCloseIcon,
  GUserIcon,
  GVuesaxSearchIcon,
} from 'src/components/ui/AppIcon';
import _messages from 'src/_dev/sample-data/messages.json';

const PageChatView = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isSearchOpened, setIsSearchOpened] = useState(false);

  const ref = useRef();

  const handleToggleSearch = () => {
    setIsSearchOpened((value) => !value);
  };

  const handleCloseSearch = () => {
    setIsSearchOpened(false);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollToBottom();
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Card
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '78vh',
      }}
    >
      <Stack
        px={3}
        py={2.5}
        direction="row"
        alignItems="center"
        borderBottom="1px solid #DFDFDF"
      >
        <Stack direction="row" flex={1}>
          <Avatar
            sx={{
              width: 32,
              height: 32,
            }}
          >
            <GUserIcon />
          </Avatar>
          <Stack ml={1.5}>
            <Typography
              fontSize={20}
              fontWeight={600}
              lineHeight="30px"
              colorscheme="gray.900"
            >
              Courtney Henry
            </Typography>
            <Typography
              mt={1}
              fontSize={14}
              fontWeight={500}
              lineHeight="22px"
              color="#93989A"
            >
              Online
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1}>
          <IconButton onClick={handleToggleSearch}>
            <GVuesaxSearchIcon sx={{ color: '#93989A' }} />
          </IconButton>
        </Stack>
      </Stack>
      <Box flex={1} position="relative">
        <Scrollbars ref={ref}>
          <Stack flex={1} direction="column" pt={3} pb={2} px={6}>
            {loading ? (
              <Stack
                width="100%"
                alignItems="center"
                justifyContent="center"
                pt={6}
              >
                <CircularProgress size={16} />
                <Typography mt={2}>Loading</Typography>
              </Stack>
            ) : (
              _messages.map((item, index) => (
                <ChatMessageItem
                  key={`chat-${index}`}
                  createdAt={item.createdAt}
                  message={item.message}
                  side={item.side}
                />
              ))
            )}
          </Stack>
        </Scrollbars>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 16,
            height: 40,
            width: '100%',
            zIndex: 3,
          }}
        >
          <Stack alignItems="center" justifyContent="center">
            <Collapse in={isSearchOpened}>
              <Card>
                <CardContent
                  sx={{
                    p: '12px !important',
                  }}
                >
                  <OutlinedInput
                    sx={{
                      minWidth: 320,
                      maxWidth: '80%',
                      height: 40,
                      '& input': {
                        fontSize: 12,
                      },
                    }}
                    endAdornment={
                      <IconButton onClick={handleCloseSearch}>
                        <GCloseIcon
                          sx={{
                            width: 16,
                            height: 16,
                          }}
                        />
                      </IconButton>
                    }
                    placeholder={t('Search')}
                  />
                </CardContent>
              </Card>
            </Collapse>
          </Stack>
        </Box>
      </Box>
      <Box px={3} py={2}>
        <ChatMessageInput onSubmit={() => {}} />
      </Box>
    </Card>
  );
};

export default PageChatView;
