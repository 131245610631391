import { Typography, styled } from '@mui/material';
import colors from 'src/configs/colors';

const HighlightedTypography = styled(Typography)`
  position: relative;
  padding-left: 17px;
  line-height: 1.5;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    bottom: 2px;
    display: block;
    width: 5px;
    border-radius: 10px;
    background: ${colors.primary};
  }
`;

export default HighlightedTypography;
