import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
  Stack,
  Button,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from '../../../components/ui/AppIcon';

const RequireVerifyModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const onAccept = () => {
    if (auth?.business?.id) {
      navigate('/profile/products/new');
    } else {
      navigate('/profile/account');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 495,
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          color="#303030"
          fontSize={30}
          lineHeight="38px"
          fontWeight={600}
        >
          Verification Required
        </HighlightedTypography>
        <Typography variant="md" mt={3} display="block" lineHeight="22px">
          {auth?.business?.id ? (
            <>
              Before you purchase or list items for sale, you are required to
              perform essential identity checks known as KYC “Know Your
              Customer” & KYB “Know Your Business”. Only by doing this can we
              ensure th at we build a safe and trusting community.
              <br />
              <br />
              Please go to your profile and complete both KYC and KYB checks.
            </>
          ) : (
            <>
              Before you purchase or list items for sale, you are required to
              complete your account setup (your personal & business information)
            </>
          )}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
          <Button
            variant="outlined"
            sx={{
              height: 50,
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              height: 50,
            }}
            onClick={onAccept}
          >
            Accept
          </Button>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

RequireVerifyModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default RequireVerifyModal;
