import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
  Stack,
  Button,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from 'src/components/ui/AppIcon';

const AcceptInvoiceModal = ({ open, onClose, onAccept, loading }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 495,
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          colorscheme="black"
          fontSize={30}
          fontWeight={600}
          emphasis="true"
        >
          Accept Invoice
        </HighlightedTypography>
        <Typography variant="md" mt={3}>
          This invoice is a legally recognized and binding document arising from
          the Sales Purchase Agreement you executed. By accepting this invoice
          and making payment of this invoice, you are fulfilling your binding
          obligations under the terms and conditions of your Sales Purchase
          Agreement, whether partially or in full.
        </Typography>
        <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
          <Button
            variant="outlined"
            size="small"
            onClick={onClose}
            sx={{
              px: 2,
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="small"
            loading={loading}
            onClick={onAccept}
            sx={{
              px: 2,
            }}
          >
            Accept
          </LoadingButton>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

AcceptInvoiceModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
};

export default AcceptInvoiceModal;
