import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  styled,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';

import PhotosEditor from './PhotosEditor';
import SpecificationsEditor from './SpecificationsEditor';
import VideoEditor from './VideoEditor';
import AttachmentsEditor from './AttachmentsEditor';
import CountrySelect from 'src/components/ui/CountrySelect';
import IncotermsModal from 'src/pages/main/marketplace/order-submission-page/IncotermsModal';
import { GDollarIcon } from '../../../components/ui/AppIcon';
import colors from 'src/configs/colors';

const FieldName = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1.5,
  color: colors.heading,
  display: 'block',
  marginBottom: 8,
}));

const FieldDescription = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '22px',
  color: colors['gray.400'],
  display: 'block',
}));

const PageContent = ({ control, categories }) => {
  const { t } = useTranslation();

  const [showIncoterms, setShowIncoterms] = React.useState(false);

  const toggleIncotermsModal = () => {
    setShowIncoterms((value) => !value);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography
              fontSize={20}
              fontWeight={600}
              lineHeight={1.5}
              color="#101623"
            >
              {t('Product Information')}
            </Typography>
          }
          emphasis="true"
          sx={{
            p: 3,
            borderBottom: '1px solid #F4F5F6',
          }}
        />
        <CardContent
          sx={{
            padding: '24px !important',
          }}
        >
          <Box id="field-name">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>{t('Product name')}</FieldName>
                <FieldDescription>
                  {t(
                    'Do not exceed 20 characters when entering the product name.',
                  )}
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field, fieldState: { error } }) => (
                    <OutlinedInput
                      fullWidth
                      placeholder={t('Enter product name')}
                      {...field}
                      error={!!error}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} id="field-category">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>{t('Category')}</FieldName>
                <FieldDescription>
                  {t(
                    'Please select your product category from the list provided',
                  )}
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="category"
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      fullWidth
                      displayEmpty
                      renderValue={
                        field.value
                          ? undefined
                          : () => (
                              <Typography colorscheme="gray.400">
                                Select category
                              </Typography>
                            )
                      }
                      {...field}
                      error={!!error}
                      MenuProps={{
                        sx: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {categories
                        .filter((category) => category.parent !== 'Root')
                        .map((category) => (
                          <MenuItem
                            key={`cat-${category._id}`}
                            value={category._id}
                          >
                            {category.name}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} id="field-location">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>Location</FieldName>
                <FieldDescription>
                  Please select the location of the product being sold
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="location"
                  render={({ field, fieldState: { error } }) => (
                    <CountrySelect
                      value={field.value}
                      onChange={field.onChange}
                      showCountryName
                      error={!!error}
                      placeholder="Select location"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} id="field-incoterms">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>Incoterms</FieldName>
                <FieldDescription>
                  Please select the available shipping options that is available
                  for this product
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="incoterms"
                  render={({ field, fieldState: { error } }) => (
                    <Box>
                      <Select
                        multiple
                        fullWidth
                        displayEmpty
                        error={!!error}
                        value={field.value}
                        onChange={(ev) => {
                          if (typeof ev.target.value === 'string') {
                            field.onChange(ev.target.value.split(','));
                          } else {
                            field.onChange(ev.target.value);
                          }
                        }}
                        renderValue={(selected) => {
                          if (selected.length > 0) {
                            return (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            );
                          } else {
                            return (
                              <Typography colorscheme="gray.400">
                                Select incoterms
                              </Typography>
                            );
                          }
                        }}
                      >
                        <MenuItem value="EXW">
                          <ListItemText>EXW</ListItemText>
                        </MenuItem>
                        <MenuItem value="FOB">
                          <ListItemText>FOB</ListItemText>
                        </MenuItem>
                        <MenuItem value="CFR">
                          <ListItemText>CFR</ListItemText>
                        </MenuItem>
                        <MenuItem value="CIF">
                          <ListItemText>CIF</ListItemText>
                        </MenuItem>
                      </Select>
                      <Box mt={2}>
                        <Link
                          href="#"
                          color={colors.text}
                          fontWeight={500}
                          onClick={toggleIncotermsModal}
                        >
                          Learn More about Incoterms
                        </Link>
                      </Box>
                    </Box>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} id="field-description">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>{t('Description')}</FieldName>
                <FieldDescription>
                  {t(
                    'Set a description on product to detail your product and better visibility',
                  )}
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="description"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <ReactQuill
                      theme="snow"
                      value={value}
                      onChange={onChange}
                      formats={[
                        'header',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                      ]}
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline', 'strike'],
                          ['blockquote'],

                          [{ header: 1 }, { header: 2 }],
                          [{ list: 'ordered' }, { list: 'bullet' }],
                          [{ script: 'sub' }, { script: 'super' }],
                          [{ indent: '-1' }, { indent: '+1' }],
                          [{ direction: 'rtl' }],

                          [{ header: [1, 2, 3, 4, 5, 6, false] }],

                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          [{ align: [] }],

                          ['clean'],
                        ],
                      }}
                      placeholder={t('Type your description here')}
                      className={error ? 'error' : ''}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} id="field-specifications">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>{t('Specifications')}</FieldName>
                <FieldDescription>
                  {t('Please specify your shipping details')}
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="specifications"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <SpecificationsEditor
                      value={value}
                      onChange={onChange}
                      error={!!error}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} id="field-photos">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>{t('Photo Product')}</FieldName>
                <FieldDescription>
                  Recommended minimum width 1080px X 1080px, with a max size of
                  5MB, only *.png, *.jpg and *.jpeg image files are accepted
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="photos"
                  render={({ field, fieldState: { error } }) => (
                    <PhotosEditor
                      photos={field.value}
                      onChange={field.onChange}
                      error={!!error}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} id="field-video">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>{t('Video')}</FieldName>
                <FieldDescription>
                  Video with a max size of 200MB, only *.mp4, *.avi video files
                  are accepted
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="video"
                  render={({ field, fieldState: { error } }) => (
                    <VideoEditor
                      video={field.value}
                      onChange={field.onChange}
                      error={!!error}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} id="field-attachments">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>Attachments</FieldName>
                <FieldDescription>
                  Attach any document regarding to your product
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="attachments"
                  render={({ field, fieldState: { error } }) => (
                    <AttachmentsEditor
                      attachments={field.value}
                      onChange={field.onChange}
                      error={!!error}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ mt: 3 }}>
        <CardHeader
          title={
            <Typography
              fontSize={20}
              fontWeight={600}
              lineHeight={1.5}
              color="#101623"
            >
              {t('Price')}
            </Typography>
          }
          emphasis="true"
          sx={{
            p: 3,
            borderBottom: '1px solid #F4F5F6',
          }}
        />
        <CardContent
          sx={{
            padding: '24px !important',
          }}
        >
          <Box id="field-price">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>{t('Item Price')}</FieldName>
                <FieldDescription>
                  {t('Set the price of your item.')}
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="price"
                  render={({ field, fieldState: { error } }) => (
                    <OutlinedInput
                      placeholder="0.00"
                      type="number"
                      fullWidth
                      startAdornment={
                        <Box
                          sx={{
                            borderRadius: '6px',
                            bgcolor: '#F4F5F6',
                            padding: '6px',
                            width: 32,
                            height: 32,
                            boxSizing: 'border-box',
                          }}
                        >
                          <GDollarIcon
                            sx={{
                              width: 20,
                              height: 20,
                              color: colors.primary,
                            }}
                          />
                        </Box>
                      }
                      {...field}
                      error={!!error}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} id="field-unit">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>Unit</FieldName>
                <FieldDescription>Set your product unit</FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="unit"
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      displayEmpty
                      renderValue={
                        field.value
                          ? undefined
                          : () => (
                              <Typography colorscheme="gray.400">
                                Select unit
                              </Typography>
                            )
                      }
                      {...field}
                      error={!!error}
                      fullWidth
                    >
                      <MenuItem value="CBM">CBM (Cubic Meter)</MenuItem>
                      <MenuItem value="MT">MT (Metric Ton)</MenuItem>
                      <MenuItem value="BBL">BBL (Barrel)</MenuItem>
                      <MenuItem value="LB">LB (Pound)</MenuItem>
                      <MenuItem value="KG">KG (Kilogram)</MenuItem>
                      <MenuItem value="TON">TON (Short Ton)</MenuItem>
                      <MenuItem value="PC">PC (Piece)</MenuItem>
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} id="field-minimumOrderQuantity">
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FieldName>Minimum Order Quantity</FieldName>
                <FieldDescription>
                  Set your product minimum order quantity
                </FieldDescription>
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  control={control}
                  name="minimumOrderQuantity"
                  render={({ field, fieldState: { error } }) => (
                    <OutlinedInput
                      placeholder={t('Enter minimum order quantity')}
                      fullWidth
                      {...field}
                      error={!!error}
                      type="number"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <IncotermsModal open={showIncoterms} onClose={toggleIncotermsModal} />
    </>
  );
};

PageContent.propTypes = {
  control: PropTypes.any,
  loading: PropTypes.bool,
  categories: PropTypes.array,
};

export default PageContent;
