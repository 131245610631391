import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const PageHeader = ({ role, onChangeRole }) => {
  const { t } = useTranslation();

  const auth = useSelector((state) => state.auth);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      mb={3}
    >
      <Typography fontSize={24} fontWeight={600} colorscheme="heading">
        {t('Hello, {{username}}', {
          username:
            role === 'buyer'
              ? auth?.username
                ? auth.username
                : ' '
              : auth?.business
              ? auth.business.name
              : ' ',
        })}
      </Typography>
      <ToggleButtonGroup exclusive value={role} onChange={onChangeRole}>
        <ToggleButton value="seller">
          <Typography>{t('Seller')}</Typography>
        </ToggleButton>
        <ToggleButton value="buyer">
          <Typography>{t('Buyer')}</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

PageHeader.propTypes = {
  role: PropTypes.string,
  onChangeRole: PropTypes.func,
};

export default PageHeader;
