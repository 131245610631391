import { toast } from 'react-toastify';

const notify = ({ type, message, position }) => {
  toast[type](message, {
    position: position === 'top-center' ? 'top-center' : 'top-right',
    theme: 'light',
    closeOnClick: true,
    pauseOnHover: true,
    hideProgressBar: false,
  });
};

export default notify;
