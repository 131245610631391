import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import colors from 'src/configs/colors';

const info = {
  requested: {
    back: colors['green.highlight'],
    front: colors['status.success'],
    label: 'New Request',
  },
  rejected: {
    back: colors['red.highlight'],
    front: colors['status.error'],
    label: 'Rejected',
  },
  checking: {
    back: colors['blue.highlight'],
    front: colors['status.info'],
    label: 'In Agreement',
  },
  paying: {
    back: colors['blue.highlight'],
    front: colors['status.info'],
    label: 'Payment',
  },
  shipment: {
    back: colors['blue.highlight'],
    front: colors['status.info'],
    label: 'Shipment',
  },
  cancelled: {
    back: colors['orange.highlight'],
    front: colors['status.warning'],
    label: 'Cancelled',
  },
  completed: {
    back: colors['green.highlight'],
    front: colors['status.success'],
    label: 'Completed',
  },
};

const OrderStatusChip = ({ status, ...props }) => {
  const { t } = useTranslation();

  return (
    <Chip
      label={t(info[status]?.label)}
      sx={{
        backgroundColor: info[status]?.back,
        '& .MuiChip-label': {
          color: info[status]?.front,
          fontWeight: 500,
          fontSize: 12,
          lineHeight: '16px',
        },
      }}
      {...props}
    />
  );
};

OrderStatusChip.propTypes = {
  status: PropTypes.string,
};

export default OrderStatusChip;
