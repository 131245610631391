import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Stack,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from '../../../../components/ui/AppIcon';
import ReceiptInput from './ReceiptInput';

const UploadReceiptModal = ({ open, onClose, onSubmit, loading }) => {
  const [file, setFile] = useState(null);

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 605,
          maxWidth: 'calc(100vw - 40px)',
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          color="#303030"
          fontSize={30}
          lineHeight="38px"
          fontWeight={600}
        >
          Upload Payment Receipt
        </HighlightedTypography>
        <Typography variant="md" mt={3} fontSize={14}>
          Once you have completed the transfer you will be required to upload
          the payment receipt.
        </Typography>
        <Typography fontSize={16} fontWeight={600} colorscheme="heading" mt={3}>
          Attach File
        </Typography>
        <ReceiptInput value={file} onChange={setFile} />
        <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
          <Button
            variant="outlined"
            sx={{
              height: 50,
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            sx={{
              height: 50,
            }}
            disabled={!file?.url}
            loading={loading}
            onClick={() => onSubmit(file?.url)}
          >
            Submit
          </LoadingButton>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={handleCancel}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

UploadReceiptModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default UploadReceiptModal;
