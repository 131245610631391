import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import ProtectedRoute from '../components/ProtectedRoute';

import AuthLayout from '../components/layouts/AuthLayout';
import LoginPage from '../pages/auth/LoginPage';
import LogoutPage from '../pages/auth/LogoutPage';
import RegisterPage from '../pages/auth/RegisterPage';
import ForgotPasswordPage from '../pages/auth/ForgotPasswordPage';
import ResetPasswordPage from '../pages/auth/ResetPasswordPage';
import VerifyOTPPage from '../pages/auth/VerifyOTPPage';
import AcceptInvitationPage from 'src/pages/auth/AcceptInvitationPage';

import AccountSetupPage from '../pages/account/AccountSetupPage';

import MainLayout from '../components/layouts/MainLayout';
import DashboardPage from '../pages/main/dashboard/DashboardPage';
import MarketplacePage from '../pages/main/marketplace/MarketplacePage';
import SubCategoriesPage from '../pages/main/marketplace/SubCategoriesPage';
import ProductsPage from '../pages/main/marketplace/ProductsPage';
import ProductDetailPage from '../pages/main/marketplace/ProductDetailPage';
import OrderSubmissionPage from '../pages/main/marketplace/OrderSubmissionPage';
import PurchaseOrdersPage from '../pages/main/my-orders/PurchaseOrdersPage';
import PurchaseOrderDetailPage from 'src/pages/main/my-orders/PurchaseOrderDetailPage';
import SaleOrdersPage from '../pages/main/my-orders/SaleOrdersPage';
import SaleOrderDetailPage from 'src/pages/main/my-orders/SaleOrderDetailPage';
import TradeBuilderPage from 'src/pages/main/my-orders/TradeBuilderPage';
import InvoiceBuilderPage from 'src/pages/main/my-orders/InvoiceBuilderPage';
import MessagesPage from '../pages/main/messages/MessagesPage';
import NotificationsPage from '../pages/main/notifications/NotificationsPage';

import ProfileLayout from '../pages/profile/ProfileLayout';
import MyAccountPage from '../pages/profile/MyAccountPage';
import MyBankPage from '../pages/profile/MyBankPage';
import MyProductsPage from '../pages/profile/MyProductsPage';
import AddProductPage from '../pages/profile/AddProductPage';
import MyTeamPage from '../pages/profile/MyTeamPage';
import MySecuritySettingsPage from '../pages/profile/MySecuritySettingsPage';
import MyNotificationSettingsPage from '../pages/profile/MyNotificationSettingsPage';
import CreateTicketPage from '../pages/main/support/CreateTicketPage';
import TicketsPage from '../pages/main/support/TicketsPage';
import TicketDetailPage from '../pages/main/support/TicketDetailPage';

import HelpPage from '../pages/main/support/HelpPage';

import DevLayout from '../_dev/components/DevLayout';
import DevColorsPage from '../_dev/pages/DevColorsPage';
import DevTypographyPage from '../_dev/pages/DevTypography';

import constants from '../configs/constants';

const AppRouterProvider = () => {
  return (
    <React.Suspense fallback={<LinearProgress />}>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <Routes>
          <Route path="/auth" element={<AuthLayout />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="logout" element={<LogoutPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="verify" element={<VerifyOTPPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
          </Route>
          <Route
            path="/auth/accept-invitation"
            element={<AcceptInvitationPage />}
          />
          <Route
            path="/setup-account"
            element={
              <ProtectedRoute>
                <AccountSetupPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <MainLayout />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="marketplace">
              <Route path="" element={<MarketplacePage />} />
              <Route path="products" element={<ProductsPage />} />
              <Route path=":categoryName" element={<SubCategoriesPage />} />
              <Route
                path="products/:productId/submit-order"
                element={<OrderSubmissionPage />}
              />
              <Route
                path="products/:productId"
                element={<ProductDetailPage />}
              />
            </Route>
            <Route path="trades">
              <Route path="purchases" element={<PurchaseOrdersPage />} />
              <Route
                path="purchases/:tradeId"
                element={<PurchaseOrderDetailPage />}
              />
              <Route path="sales" element={<SaleOrdersPage />} />
              <Route path="sales/:tradeId" element={<SaleOrderDetailPage />} />
              <Route
                path="sales/:tradeId/trade-builder"
                element={<TradeBuilderPage />}
              />
              <Route
                path="sales/:tradeId/invoice-builder"
                element={<InvoiceBuilderPage />}
              />
            </Route>
            <Route path="messages" element={<MessagesPage />} />
            <Route path="notifications" element={<NotificationsPage />} />
            <Route path="profile">
              <Route path="products/:id" element={<AddProductPage />} />
              <Route path="" element={<ProfileLayout />}>
                <Route path="account" element={<MyAccountPage />} />
                <Route path="bank" element={<MyBankPage />} />
                <Route path="products" element={<MyProductsPage />} />
                <Route path="team" element={<MyTeamPage />} />
                <Route
                  path="security-settings"
                  element={<MySecuritySettingsPage />}
                />
                <Route
                  path="notification-settings"
                  element={<MyNotificationSettingsPage />}
                />
                <Route path="*" element={<></>} />
              </Route>
            </Route>
            <Route path="support">
              <Route path="" index element={<Navigate to="/support/faq" />} />
              <Route path="faq" index element={<HelpPage />} />
              <Route path="tickets/new" index element={<CreateTicketPage />} />
              <Route
                path="tickets/:ticketId"
                index
                element={<TicketDetailPage />}
              />
              <Route path="tickets" index element={<TicketsPage />} />
            </Route>
          </Route>
          {constants.isDevMode && (
            <>
              <Route path="/dev" element={<DevLayout />}>
                <Route path="" element={<Navigate to="/dev/colors" />} />
                <Route path="colors" element={<DevColorsPage />} />
                <Route path="typography" element={<DevTypographyPage />} />
              </Route>
            </>
          )}
          <Route path="*" element={<>404</>} />
        </Routes>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default AppRouterProvider;
