import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack, Box } from '@mui/material';

import { GInfoCircleIcon } from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';

const PageNotificationCard = ({ data }) => {
  const [hidden, setHidden] = useState(true);
  const [text, setText] = useState('');

  useEffect(() => {
    if (data) {
      switch (data.status) {
        case 'rejected': {
          setHidden(false);
          setText(
            'This trade has been rejected.<br/><br/>Reason:<br/>' + data.reason,
          );
          break;
        }
        case 'checking': {
          setHidden(false);
          if (
            data.documents.find((item) => item.type === 'sales-agreement')
              ?.metadata?.buyerSignedAt
          ) {
            setText(
              'The buyer has signed on the provided document, please sign the Sales Purchase Agreement to continue the Trade.',
            );
          } else {
            setText(
              'The buyer is reviewing the provided document, Please wait for the buyer to accept the Sales Purchase Agreement.',
            );
          }
          break;
        }
        case 'paying': {
          const initialPayment = data?.initialPayments[0];
          if (initialPayment) {
            switch (initialPayment.status) {
              case 'invoicing': {
                setHidden(false);
                setText(
                  'The agreement has been accepted by the buyer, please provide the invoice for the buyer.',
                );
                break;
              }
              case 'accepting': {
                setHidden(false);
                setText(
                  "The buyer is reviewing the provided invoice, please wait for the buyer's confirmation.",
                );
                break;
              }
              case 'checking':
              case 'paying': {
                setHidden(false);
                setText(
                  'The buyer has accepted the invoice, please wait for payment to be made before processing with the trade.',
                );
                break;
              }
            }
          }
          break;
        }
        case 'shipment': {
          for (const shipment of data.shipments) {
            if (
              shipment.status === 'pending' ||
              shipment.status === 'settled'
            ) {
              continue;
            }

            const payment = shipment.payments[0];
            if (payment) {
              switch (payment.status) {
                case 'invoicing': {
                  setHidden(false);
                  setText(
                    "Now it's time to make a payment, please provide the invoice for the buyer.",
                  );
                  return;
                }
                case 'accepting': {
                  setHidden(false);
                  setText(
                    "The buyer is reviewing the provided invoice, please wait for the buyer's confirmation.",
                  );
                  return;
                }
                case 'checking':
                case 'paying': {
                  setHidden(false);
                  setText(
                    'The buyer has accepted the invoice, please wait for payment to be made before processing with the trade.',
                  );
                  return;
                }
              }
            }

            if (shipment.status === 'manufacturing ') {
              setHidden(false);
              setText(
                'You can now process the trade in line with the terms of the agreement.',
              );
              return;
            }
          }
          break;
        }
        default: {
          setHidden(true);
          setText('');
          break;
        }
      }
    }
  }, [data]);

  return (
    <Stack
      p={2}
      borderRadius={2}
      bgcolor={colors['blue.highlight']}
      direction="row"
      alignItems="flex-start"
      spacing={2}
      mb={2.5}
      display={hidden ? 'none' : 'flex'}
    >
      <Box mt="3px !important">
        <GInfoCircleIcon
          sx={{
            width: 16,
            height: 16,
            color: colors['status.info'],
          }}
        />
      </Box>
      <Typography fontSize={16} colorscheme="status.info" flex={1}>
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></div>
      </Typography>
    </Stack>
  );
};

PageNotificationCard.propTypes = {
  data: PropTypes.any,
};

export default PageNotificationCard;
