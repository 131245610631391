import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import TableActionButton from 'src/components/ui/TableActionButton';
import TableMenu from 'src/components/ui/TableMenu';
import {
  GDotsVerticalIcon,
  GEyeIcon,
  GHelpIcon,
  GUserPlusIcon,
} from 'src/components/ui/AppIcon';

const ActionMenu = ({ onView, onHelp, onAssign }) => {
  const { t } = useTranslation();

  const [el, setEl] = useState(null);
  const open = Boolean(el);

  const handleClick = (ev) => {
    setEl(ev.currentTarget);
  };

  const handleClose = () => {
    setEl(null);
  };

  return (
    <>
      <TableActionButton onClick={handleClick}>
        <GDotsVerticalIcon />
      </TableActionButton>
      <TableMenu anchorEl={el} open={open} onClose={handleClose}>
        <MenuItem onClick={onView}>
          <ListItemIcon>
            <GEyeIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>{t('View')}</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={onAssign}>
          <ListItemIcon>
            <GUserPlusIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>{t('Assign to Trade')}</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={onHelp}>
          <ListItemIcon>
            <GHelpIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>{t('Get Help')}</Typography>
          </ListItemText>
        </MenuItem>
      </TableMenu>
    </>
  );
};

ActionMenu.propTypes = {
  onView: PropTypes.func,
  onHelp: PropTypes.func,
  onAssign: PropTypes.func,
};

export default ActionMenu;
