import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiChangePassword } from 'src/apis/profile';

const schema = yup
  .object({
    currentPassword: yup.string().required(),
    newPassword: yup
      .string()
      .trim()
      .min(12, 'The password should be more than 12 characters')
      .matches(
        /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/,
        'Please make sure to include at least 1 letter, number and special character.',
      )
      .required('The password is required'),
  })
  .required();

const PageChangePasswordView = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
    },
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);

  const handleOnSubmit = (values) => {
    setLoading(true);
    apiChangePassword(values)
      .then((result) => {
        if (result) {
          reset();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography
            fontSize={20}
            fontWeight={500}
            lineHeight={1.5}
            colorscheme="fonts.heading"
          >
            {t('Change Password')}
          </Typography>
        }
        emphasis="true"
      />
      <CardContent
        sx={{
          pb: '16px !important',
        }}
      >
        <Typography display="block" fontSize={18} lineHeight={1.5}>
          {t('Please enter your current password to change your password.')}
        </Typography>
        <Box mt={3} maxWidth={580}>
          <Controller
            control={control}
            name="currentPassword"
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={!!error?.message}>
                <InputLabel shrink>{t('Current password')}</InputLabel>
                <OutlinedInput
                  type="password"
                  placeholder={t('Enter password')}
                  {...field}
                />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="newPassword"
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                error={!!error?.message}
                sx={{
                  mt: 2,
                }}
              >
                <InputLabel shrink>{t('New password')}</InputLabel>
                <OutlinedInput
                  type="password"
                  placeholder={t('Enter password')}
                  {...field}
                />
                {error?.message && (
                  <FormHelperText>{error?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Stack
            mt={3}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <LoadingButton
              variant="contained"
              size="small"
              loading={loading}
              onClick={handleSubmit(handleOnSubmit)}
            >
              {t('Change Password')}
            </LoadingButton>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PageChangePasswordView;
