import { Menu, styled } from '@mui/material';
import colors from 'src/configs/colors';

const TableMenu = styled(Menu)(() => ({
  '& .MuiMenuItem-root': {
    '& .MuiListItemIcon-root > svg': {
      width: 24,
      height: 24,
      color: colors.text,
    },
    '& .MuiListItemText-root .MuiTypography-root': {
      fontSize: 14,
      color: colors.text,
    },
  },
}));

export default TableMenu;
