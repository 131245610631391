import React, { useEffect, useState } from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import constants from '../../configs/constants';

const checkPasswordStrength = (password) => {
  if (!password) {
    return 0;
  }

  if (
    password.match(
      /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])(?!.*(.)\1).{16,}$/,
    )
  ) {
    return 4;
  }
  if (
    password.match(/^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/)
  ) {
    return 3;
  }
  if (password.match(/^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/)) {
    return 2;
  }
  if (password.match(/^(?=.*?[A-Za-z])(?=.*?[0-9]).{1,}$/)) {
    return 1;
  }

  return 0;
};

const PasswordStrengthIndicator = ({ password, ...boxProps }) => {
  const { t } = useTranslation();

  const [level, setLevel] = useState(0);
  const [levelWord, setLevelWord] = useState('');
  const [levelColor, setLevelColor] = useState();

  useEffect(() => {
    setLevel(checkPasswordStrength(password));
  }, [password]);

  useEffect(() => {
    setLevelWord(
      [
        t('PASSWORD_CHECK_EMPTY'),
        t('PASSWORD_CHECK_LOW'),
        t('PASSWORD_CHECK_MEDIUM'),
        t('PASSWORD_CHECK_HIGH'),
        t('PASSWORD_CHECK_VERY_HIGH'),
      ][level],
    );
    setLevelColor(['error', 'error', 'warning', 'success', 'primary'][level]);
  }, [level]);

  return (
    <Box {...boxProps}>
      <LinearProgress
        variant="determinate"
        sx={{ height: 8, borderRadius: 4, bgcolor: constants.colors.gray[200] }}
        value={level * 25}
        color={levelColor}
      />
      <Typography variant="sm" mt={1}>
        {levelWord}
      </Typography>
    </Box>
  );
};

PasswordStrengthIndicator.propTypes = {
  password: PropTypes.string,
};

export default PasswordStrengthIndicator;
