import React from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableFilter from '../../../../components/ui/TableFilter';
import PageFiltersPanel from './PageFiltersPanel';
import { GArrowLeftIcon } from '../../../../components/ui/AppIcon';

const PageHeader = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useSearchParams();

  const parentCategory = query.get('parentCategory')
    ? query.get('parentCategory')
    : 'All';
  const subCategory = query.get('subCategory')
    ? query.get('subCategory')
    : 'All';

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography
          fontSize={36}
          fontWeight={600}
          letterSpacing="-0.72px"
          colorscheme="heading"
        >
          {subCategory !== 'All'
            ? subCategory
            : parentCategory !== 'All'
            ? parentCategory
            : t('All Products')}
        </Typography>
        <TableFilter
          hideSorter
          search={query.get('search')}
          onSearch={(value) => {
            const newQuery = new URLSearchParams(query);
            if (value) {
              newQuery.set('search', value);
            } else {
              newQuery.delete('search');
            }
            setQuery(newQuery);
          }}
          filterDropdown={<PageFiltersPanel />}
        />
      </Stack>
      <Link
        component={NavLink}
        to="/marketplace"
        sx={{
          display: 'inline-block',
          '&:not(:hover)': {
            textDecoration: 'none',
          },
          mt: 2.5,
        }}
        colorscheme="heading"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <GArrowLeftIcon
            sx={{
              width: 32,
              height: 32,
            }}
          />
          <Typography fontSize={30} fontWeight={600}>
            {t('Categories')}
          </Typography>
        </Stack>
      </Link>
    </>
  );
};

export default PageHeader;
