import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';

import OrderStatusChip from 'src/components/chips/OrderStatusChip';
import TableFilter from 'src/components/ui/TableFilter';
import ActionMenu from './ActionMenu';
import { GUserIcon } from 'src/components/ui/AppIcon';
import constants from 'src/configs/constants';
import { apiGetPurchaseTrades, apiGetSaleTrades } from 'src/apis/trades';

const PageTradesView = ({ role }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});

  const [paginationModel, setPaginationModel] = useState({
    page: pageIndex - 1,
    pageSize: pageSize,
  });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({
    incoterms: '',
    status: '',
  });

  const { data, isLoading } = useQuery(
    [
      role === 'seller' ? 'orders/sales' : 'orders/purchases',
      pageIndex,
      pageSize,
      filters.search ? filters.search : '',
      filters.incoterms ? filters.incoterms : '',
      filters.status ? filters.status : '',
      filters.sortBy ? filters.sortBy : '',
      filters.sortOrder ? filters.sortOrder : '',
    ],
    async () => {
      if (role === 'seller') {
        return await apiGetSaleTrades({
          pageIndex,
          pageSize,
          ...filters,
        });
      } else {
        return await apiGetPurchaseTrades({
          pageIndex,
          pageSize,
          ...filters,
        });
      }
    },
  );

  const onViewAction = useCallback(
    (id) => {
      if (role === 'seller') {
        return navigate(`/trades/sales/${id}`);
      } else {
        return navigate(`/trades/purchases/${id}`);
      }
    },
    [role],
  );

  const onHelpAction = useCallback(() => {
    // todo
  }, []);

  const onChangePagination = useCallback((model) => {
    setPageIndex(model.page + 1);
    setPageSize(model.pageSize);
  }, []);

  const onChangeFilters = useCallback(
    (values) => {
      const newFilters = { ...filters };
      if (values.incoterms) {
        newFilters.incoterms = values.incoterms;
      } else {
        delete newFilters.incoterms;
      }
      if (values.status) {
        newFilters.status = values.status;
      } else {
        delete newFilters.status;
      }

      setFilters(newFilters);
    },
    [filters],
  );

  const onChangeSort = useCallback(
    (model) => {
      if (model.length > 0) {
        setFilters({
          ...filters,
          sortBy: model[0].field,
          sortOrder: model[0].sort,
        });
      } else {
        delete filters.sortBy;
        delete filters.sortOrder;
        setFilters({
          ...filters,
        });
      }
    },
    [filters],
  );

  const onChangeSearch = useCallback(
    (value) => {
      if (value) {
        setFilters({
          ...filters,
          search: value,
        });
      } else {
        delete filters.search;
        setFilters({
          ...filters,
        });
      }
    },
    [filters],
  );

  const columns = useMemo(
    () => [
      {
        field: '_id',
        headerName: t('Trade No.'),
        width: 90,
        valueFormatter: ({ value }) => `#${value.substr(-6).toUpperCase()}`,
      },
      {
        field: role === 'seller' ? 'buyer' : 'seller',
        headerName: role === 'seller' ? 'Buyer' : 'Seller',
        width: 220,
        sortable: false,
        renderCell: ({ value }) => (
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Avatar
              src={constants.getStoragePath(value?.company?.logo)}
              sx={{
                width: 32,
                height: 32,
              }}
            >
              <GUserIcon />
            </Avatar>
            <Box
              className="MuiDataGrid-cellContent"
              sx={{
                textWrap: 'pretty',
                py: 1,
              }}
            >
              {value?.company?.name}
            </Box>
          </Stack>
        ),
      },
      {
        field: 'product',
        headerName: t('Product'),
        flex: 1,
        valueFormatter: ({ value }) => value?.name,
        renderCell: ({ value }) => (
          <Box
            py={1}
            sx={{
              textWrap: 'pretty',
            }}
            className="MuiDataGrid-cellContent"
          >
            {value?.name}
          </Box>
        ),
        sortable: false,
      },
      {
        field: 'incoterms',
        headerName: t('Incoterms'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'total',
        headerName: t('Value'),
        sortable: false,
        renderCell: ({ row }) => {
          return '$' + row.total.toLocaleString();
        },
        width: 150,
      },
      {
        field: 'status',
        headerName: t('Status'),
        renderCell: ({ row }) => <OrderStatusChip status={row.status} />,
        width: 120,
        sortable: false,
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        width: 150,
        valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm'),
      },
      {
        field: '_',
        headerName: t('Action'),
        sortable: false,
        renderCell: ({ row }) => (
          <ActionMenu
            onView={() => onViewAction(row._id)}
            onHelp={() => onHelpAction(row._id)}
          />
        ),
      },
    ],
    [onViewAction, onHelpAction, role],
  );

  useEffect(() => {
    setPaginationModel({
      page: pageIndex - 1,
      pageSize: pageSize,
    });
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (filters.sortBy && filters.sortOrder) {
      setSortModel([
        {
          field: filters.sortBy,
          sort: filters.sortOrder,
        },
      ]);
    } else {
      setSortModel([]);
    }
  }, [filters.sortBy, filters.sortOrder]);

  useEffect(() => {
    setFilterModel({
      incoterms: filters.incoterms ? filters.incoterms : '',
      status: filters.status ? filters.status : '',
    });
  }, [filters.incoterms, filters.status]);

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Typography fontSize={20} fontWeight={500} colorscheme="heading">
            {t('My Trades')}
          </Typography>
        }
        emphasis="true"
        action={
          <TableFilter
            hideSorter
            filterDropdown={
              <Box width={300} p={2.5} boxSizing="border-box">
                <FormControl fullWidth size="small">
                  <InputLabel>{t('Incoterms')}</InputLabel>
                  <Select
                    value={filterModel.incoterms}
                    onChange={(ev) =>
                      setFilterModel((model) => ({
                        ...model,
                        incoterms: ev.target.value,
                      }))
                    }
                    displayEmpty
                    renderValue={
                      filterModel.incoterms
                        ? undefined
                        : () => (
                            <Typography colorscheme="gray.600">
                              {t('Select incoterms')}
                            </Typography>
                          )
                    }
                  >
                    <MenuItem value={undefined}>All</MenuItem>
                    {[
                      'EXW',
                      'FCA',
                      'FAS',
                      'FOB',
                      'CFR',
                      'CIF',
                      'CPT',
                      'CIP',
                      'DAP',
                      'DPU',
                      'DDP',
                    ].map((item) => (
                      <MenuItem key={`incoterm-${item}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small" sx={{ mt: 2.5 }}>
                  <InputLabel>{t('Status')}</InputLabel>
                  <Select
                    value={filterModel.status}
                    onChange={(ev) =>
                      setFilterModel((model) => ({
                        ...model,
                        status: ev.target.value,
                      }))
                    }
                    displayEmpty
                    renderValue={
                      filterModel.status
                        ? undefined
                        : () => (
                            <Typography colorscheme="gray.600">
                              {t('Select status')}
                            </Typography>
                          )
                    }
                  >
                    <MenuItem value={undefined}>All</MenuItem>
                    {['New Request', 'Rejected'].map((item) => (
                      <MenuItem key={`status-${item}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  sx={{ mt: 2.5 }}
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={() => onChangeFilters(filterModel)}
                >
                  {t('Search')}
                </Button>
              </Box>
            }
            search={filters.search ? filters.search : ''}
            onSearch={onChangeSearch}
          />
        }
      />
      <CardContent sx={{}}>
        <DataGrid
          rows={data?.result ? data.result : []}
          columns={columns}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          paginationModel={paginationModel}
          sortingMode="server"
          sortModel={sortModel}
          rowCount={data?.count ? data.count : 0}
          autoHeight
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          loading={isLoading}
          onPaginationModelChange={onChangePagination}
          onSortModelChange={onChangeSort}
          getRowId={(row) => row._id}
          getRowHeight={() => 'auto'}
        />
      </CardContent>
    </Card>
  );
};

PageTradesView.propTypes = {
  role: PropTypes.string,
};

export default PageTradesView;
