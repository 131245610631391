/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import PhoneNumberInput from 'src/components/ui/PhoneNumberInput';
import CountrySelect from '../../../components/ui/CountrySelect';
import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import {
  apiGetCities,
  apiGetLocationInfo,
  apiGetStates,
} from 'src/apis/locations';
import FilesDropzone from 'src/components/inputs/FilesDropzone';

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().min(6).required(),
    address: yup.string().required(),
    state: yup.string().required(),
    city: yup.string(),
    country: yup.string().required(),
    zipCode: yup.string().required(),
    documents: yup.array().min(1).required(),
    regNumber: yup.string().required(),
    tradingName: yup.string().required(),
  })
  .required();

const BusinessAccountSetup = ({
  onNextPage,
  onBackPage,
  defaultData,
  loading,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      name: defaultData?.name ?? '',
      email: defaultData?.email ?? '',
      phone: defaultData?.phone ?? '',
      address: defaultData?.address ?? '',
      country: defaultData?.country ?? 'US',
      state: defaultData?.state ?? '',
      city: defaultData?.city ?? '',
      zipCode: defaultData?.zipCode ?? '',
      documents: defaultData?.documents ?? [],
      tradingName: defaultData?.tradingName ?? '',
      regNumber: defaultData?.regNumber ?? '',
    },
    resolver: yupResolver(schema),
  });
  const [country, state] = watch(['country', 'state']);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const handleOnFinish = useCallback(
    (values) => {
      onNextPage(values);
    },
    [onNextPage],
  );

  useEffect(() => {
    apiGetStates(country).then((result) => {
      setStates(result);
    });
    setValue('state', '');
    setValue('city', '');
  }, [country]);

  useEffect(() => {
    apiGetCities(country, state).then((result) => {
      setCities(result);
    });
    setValue('city', '');
  }, [country, state]);

  useEffect(() => {
    apiGetLocationInfo().then((result) => {
      if (result?.country && result?.countryCallingCode) {
        setValue('country', result.country);
        setValue('phone', result.countryCallingCode.replace('+', ''));
      }
    });
  }, []);

  return (
    <Box
      sx={{
        borderRadius: 1.5,
        p: 5,
        mb: 5,
        mt: 10,
        bgcolor: 'white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Typography
        fontSize={36}
        fontWeight={600}
        textAlign="center"
        colorscheme="heading"
      >
        {t('Business Detail')}
      </Typography>
      <Typography variant="lg" display="block" textAlign="center" mt={1}>
        {t(
          'We will now gather your business information to enable you to sell on OODL Marketplace Platform, Remember this should not be your personal information.',
        )}
      </Typography>
      <form onSubmit={handleSubmit(handleOnFinish, (err) => console.log(err))}>
        <Grid container mt={5} rowSpacing={2} columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('Company Name')}</InputLabel>
                  <OutlinedInput
                    placeholder={t('Enter company name')}
                    {...field}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tradingName"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>Trading Name</InputLabel>
                  <OutlinedInput placeholder={t('Trading name')} {...field} />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="regNumber"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>Company Registration Number</InputLabel>
                  <OutlinedInput placeholder="Registration Number" {...field} />
                  {error && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('Company Email')}</InputLabel>
                  <OutlinedInput
                    placeholder={t('Enter company email')}
                    {...field}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="phone"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <Box>
                  <InputLabel required shrink error={!!error}>
                    {t('Enter Number')}
                  </InputLabel>
                  <PhoneNumberInput innerRef={ref} {...fieldProps} />
                  {error?.message && (
                    <FormHelperText error={!!error}>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="address"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('Address')}</InputLabel>
                  <OutlinedInput placeholder={t('Building')} {...field} />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="country"
              render={({ field: { ref, ...props }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('Country')}</InputLabel>
                  <CountrySelect
                    {...props}
                    innerRef={ref}
                    showCountryName
                    fullWidth
                    placeholder={t('Select Country')}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="state"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('State')}</InputLabel>
                  <Autocomplete
                    options={states}
                    {...field}
                    onChange={(ev, value) => field.onChange(value)}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      option?.toLowerCase() === value?.toLowerCase()
                    }
                    renderInput={(params) => (
                      <OutlinedInput
                        {...params.InputProps}
                        inputProps={params.inputProps}
                        fullWidth
                        placeholder={t('Select state')}
                      />
                    )}
                    sx={{
                      display: 'block',
                    }}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="city"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  disabled={cities.length < 1}
                >
                  <InputLabel required>{t('City')}</InputLabel>
                  <Autocomplete
                    options={cities}
                    {...field}
                    onChange={(ev, value) => field.onChange(value)}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      option.toLowerCase() === value.toLowerCase()
                    }
                    renderInput={(params) => (
                      <OutlinedInput
                        {...params.InputProps}
                        inputProps={params.inputProps}
                        fullWidth
                        placeholder={t('Select city')}
                      />
                    )}
                    sx={{
                      display: 'block',
                    }}
                    disabled={cities.length < 1}
                  />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="zipCode"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>{t('Zip Code')}</InputLabel>
                  <OutlinedInput placeholder={t('Enter ZIP code')} {...field} />
                  {error && (
                    <FormHelperText>
                      {t('This field is required')}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Box mt={3}>
          <HighlightedTypography variant="xl" colorscheme="heading" mb={2}>
            Company Documents
          </HighlightedTypography>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography>
                For legal entities, we require the following documents to meet
                our KYB and compliance requirements: simply drag and drop the
                files into the upload box.
              </Typography>
              <br />
              <Typography>
                • Certificate of Incorporation.
                <br />
                • Articles/Memorandum of Association.
                <br />
                • Any applicable licenses. Example Primary Mining License (PML)
                <br />
                • Proof of company registered address (within the last 3 months)
                <br />
                • Company structure chart (if applicable)
                <br />
                • Company Bank Statement (within the last 3 months)
                <br />
                • Latest set of financial accounts
                <br />
                • Document confirming Shareholders/ UBO’s
                <br />
                • Document confirming Directors
                <br />
                <br />
                For each Shareholder/ UBO/ Director we require the following:
                <br />
                • Identification document
                <br />
                • Proof of address (dated within the last 6 months)
                <br />• Operating licenses (if applicable)
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="documents"
                render={({ field, fieldState: { error } }) => (
                  <FilesDropzone
                    label="Documents"
                    required
                    value={field.value}
                    previewType="list"
                    error={!!error}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack
          mt={5}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1.5}
        >
          <Button
            variant="outlined"
            sx={{ px: 2 }}
            size="small"
            onClick={onBackPage}
            disabled={loading}
          >
            {t('Back')}
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            size="small"
            sx={{ px: 4 }}
            loading={loading}
          >
            {t('Next')}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

BusinessAccountSetup.propTypes = {
  onNextPage: PropTypes.func,
  onBackPage: PropTypes.func,
  defaultData: PropTypes.any,
  loading: PropTypes.bool,
};

export default BusinessAccountSetup;
