import React from 'react';
import { Card, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Center from './Center';
import colors from '../../configs/colors';

const Statistics = ({
  title,
  value,
  icon,
  sx,
  growth,
  growthDescription,
  isLoading,
}) => {
  return (
    <Card
      sx={{
        ...sx,
      }}
    >
      <CardContent
        sx={{
          p: '16px !important',
        }}
      >
        <Stack direction="row">
          <Stack spacing={0.5} flex={1}>
            <Typography fontSize={16} fontWeight={500} colorscheme="gray.700">
              {title}
            </Typography>
            {isLoading ? (
              <Skeleton
                sx={{
                  height: 24,
                  width: '80%',
                }}
              />
            ) : (
              <>
                {growth && growthDescription ? (
                  <Stack mt={2.5} direction="row" alignItems="center">
                    <Typography
                      display="block"
                      color={
                        growth > 0
                          ? colors['status.success']
                          : colors['status.error']
                      }
                      fontSize={16}
                      fontWeight={500}
                    >
                      {growth > 0 ? '+' : ''}
                      {growth}%
                    </Typography>
                    <Typography ml={1} fontSize={14}>
                      {growthDescription}
                    </Typography>
                  </Stack>
                ) : null}
              </>
            )}
          </Stack>
          <Center
            sx={{
              width: 62,
              height: 62,
              borderRadius: '14px',
              backgroundColor: 'rgba(114, 152, 121, 0.10)',

              '& svg': {
                width: 32,
                height: 32,
                color: colors.primary,
              },
            }}
          >
            {icon}
          </Center>
        </Stack>
        {isLoading ? (
          <Skeleton
            sx={{
              height: 38,
              width: '75%',
              mt: 2.5,
            }}
          />
        ) : (
          <Typography
            fontSize={30}
            fontWeight={600}
            lineHeight="38px"
            colorscheme="heading"
            mt={2.5}
          >
            {value}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

Statistics.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  icon: PropTypes.element,
  sx: PropTypes.any,
  growth: PropTypes.any,
  growthDescription: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Statistics;
