import axios from 'axios';
import { t } from 'i18next';
import notify from 'src/configs/notify';

export const apiGetCategories = async () => {
  try {
    const response = await axios.get('/api/v1/categories/parents');
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetSubCategories = async (parent) => {
  try {
    const response = await axios.get('/api/v1/categories/' + parent);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetAllCategories = async () => {
  try {
    const response = await axios.get('/api/v1/categories/all');
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};
