import React from 'react';
import { Stack, Breadcrumbs, Link, Typography, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GHomeIcon, GPlusIcon } from '../../../../components/ui/AppIcon';

const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack spacing={1}>
        <Breadcrumbs>
          <Link
            component={NavLink}
            to="/"
          >
            <GHomeIcon />
          </Link>
          <Link
            component={NavLink}
            to="/profile/account"
          >
            {t('Profile')}
          </Link>
          <Link
            component={NavLink}
            to="/support/tickets"
          >
            {t('My Tickets')}
          </Link>
        </Breadcrumbs>
        <Typography
          fontSize={24}
          fontWeight={600}
          lineHeight="34px"
          colorscheme="fonts.heading"
        >
          {t('My Tickets')}
        </Typography>
      </Stack>
      <Button
        variant="contained"
        startIcon={<GPlusIcon />}
        sx={{
          width: 138,
          height: 50,
        }}
        LinkComponent={NavLink}
        to="/support/tickets/new"
      >
        {t('Add new')}
      </Button>
    </Stack>
  );
};

export default PageHeader;
