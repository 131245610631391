import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { GCloseIcon, GUploadIcon } from '../../../components/ui/AppIcon';
import constants from '../../../configs/constants';
import colors from 'src/configs/colors';
import { apiUploadFiles } from 'src/apis/upload';
import ImageCropModal from './ImageCropModal';

const PhotosEditor = ({ photos, onChange, error }) => {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': [],
    },
    multiple: false,
  });
  const [uploads, setUploads] = useState([]);
  const [rawImage, setRawImage] = useState('');

  const handleRemove = (index) => {
    if (index > photos?.length - 1) {
      const newUploads = [...uploads];
      newUploads.splice(index - photos.length, 1);
      setUploads(newUploads);
    } else {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
      onChange(newPhotos);
    }
  };

  const handleUpload = (file) => {
    setUploads((rows) => {
      const values = [...rows];
      values.push({
        file: file,
        status: 'init',
      });
      return values;
    });
  };

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setRawImage(reader.result?.toString() || '');
      });
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    const promises = [];

    for (let i = 0; i < uploads.length; i++) {
      if (uploads[i].status !== 'init') {
        continue;
      }

      const formData = new FormData();
      formData.append('files', uploads[i].file);
      formData.append('key', 'products/photos');

      setUploads((rows) => {
        const newRows = [...rows];
        newRows[i].status = 'uploading';
        return newRows;
      });
      promises.push(apiUploadFiles(formData));

      Promise.all(promises).then((results) => {
        onChange([...photos, ...results]);
        setUploads([]);
      });
    }
  }, [uploads]);

  return (
    <Box>
      <Box
        {...(uploads.length < 1 ? getRootProps({ className: 'dropzone' }) : {})}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '12px',
          border: '2px dashed #E5E7EB',
          cursor: 'pointer',
          p: 3,
          bgcolor: '#FAFAFA',
          ...(error && {
            border: `2px dashed ${colors['status.error']}`,
          }),
        }}
      >
        {uploads.length < 1 && <input {...getInputProps()} />}
        <GUploadIcon
          sx={{
            width: 32,
            height: 32,
            color: '#D3D6DA',
          }}
        />
        <Typography mt={1} fontSize={12} lineHeight="18px" color="#A0A8B0">
          Drag and drop an image or click to browse and upload
        </Typography>
      </Box>
      <Stack direction="row" mt={2} spacing={1.5} useFlexGap flexWrap="wrap">
        {[...(photos ? photos : []), ...uploads].map((row, index) => (
          <Box
            key={`photo-${index}`}
            sx={{
              borderRadius: '12px',
              border: '2px solid ' + constants.colors.gray[300],
              p: 1,
              position: 'relative',
              boxSizing: 'border-box',
              borderColor: colors.primary,
            }}
          >
            <img
              src={
                typeof row === 'string'
                  ? constants.getStoragePath(row)
                  : URL.createObjectURL(row.file)
              }
              alt="Product Thumbnails"
              style={{
                width: 160,
                borderRadius: 12,
                objectFit: 'cover',
                display: 'block',
                aspectRatio: 3 / 2,
              }}
            />
            <Tooltip title={t('Remove')} placement="top">
              <IconButton
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  background: '#FFFFFF99 !important',
                }}
                onClick={() => handleRemove(index)}
              >
                {typeof row !== 'string' && row.status === 'uploading' ? (
                  <CircularProgress size={20} />
                ) : (
                  <GCloseIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        ))}
      </Stack>
      <ImageCropModal
        image={rawImage}
        open={rawImage ? true : false}
        onClose={() => setRawImage(null)}
        onCrop={handleUpload}
      />
    </Box>
  );
};

PhotosEditor.propTypes = {
  photos: PropTypes.any,
  error: PropTypes.any,
  onChange: PropTypes.func,
};

export default PhotosEditor;
