import axios from 'axios';
import { t } from 'i18next';
import notify from 'src/configs/notify';

export const apiGetTransactions = async (data) => {
  try {
    const response = await axios.get('/api/v1/transactions', {
      params: data,
    });
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return [];
  }
};

export const apiGetPendingPayments = async () => {
  try {
    const response = await axios.get('/api/v1/transactions/pending-amount');
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return [];
  }
};
