import React from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GHomeIcon } from '../../../../components/ui/AppIcon';

const PageNavigation = () => {
  const { t } = useTranslation();

  return (
    <Breadcrumbs>
      <Link component={NavLink} to="/">
        <GHomeIcon />
      </Link>
      <Link component={NavLink} to="/trades/sales">
        {t('My Trades')}
      </Link>
      <Link component={NavLink} to="/trades/sales">
        {t('Sales')}
      </Link>
    </Breadcrumbs>
  );
};

export default PageNavigation;
