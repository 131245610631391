import React from 'react';
import { Container, Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import PageHeader from './product-detail-page/PageHeader';
import PageProductImagesView from './product-detail-page/PageProductImagesView';
import PageProductSummary from './product-detail-page/PageProductSummary';
import PageProductDetail from './product-detail-page/PageProductDetails';
import { apiGetProductDetails } from 'src/apis/products';

const ProductDetailPage = () => {
  const params = useParams();
  const productId = params.productId;

  const { data, isLoading } = useQuery(['products', productId], async () => {
    return await apiGetProductDetails(productId);
  });

  return (
    <Container
      sx={{
        pt: 4,
        pb: 12,
      }}
    >
      <PageHeader />
      <Box mt={2.5}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={8}>
            <PageProductImagesView
              images={data?.photos ? data.photos : []}
              loading={isLoading}
            />
            <PageProductDetail data={data} loading={isLoading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <PageProductSummary data={data} loading={isLoading} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ProductDetailPage;
