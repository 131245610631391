import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getDatabase, ref, onValue } from 'firebase/database';

import PageHeader from './purchase-order-detail-page/PageHeader';
import PageTabs from './purchase-order-detail-page/PageTabs';
import PageSummaryTab from './purchase-order-detail-page/PageSummaryTab';
import PageTradeChattingTab from './common/PageTradeChattingTab';
import AcceptAgreementModal from './common/AcceptAgreementModal';
import AcceptInvoiceModal from './common/AcceptInvoiceModal';
import BankTransferModal from './purchase-order-detail-page/BankTransferModal';
import UploadReceiptModal from './purchase-order-detail-page/UploadReceiptModal';
import AcceptDocumentsModal from './purchase-order-detail-page/AcceptDocumentsModal';
import AcceptImportsModal from './purchase-order-detail-page/AcceptImportsModal';
import ConfirmDeliveryModal from './purchase-order-detail-page/ConfirmDeliveryModal';
import PageDocumentsTab from './common/PageDocumentsTab';
import PageAuditTrailTab from './common/PageAuditTrailTab';
import PageSignerViewModal from './purchase-order-detail-page/PageSignerViewModal';
import {
  apiAcceptImportingDocuments,
  apiAcceptManufacturingDocuments,
  apiAcceptTradeAgreements,
  apiAcceptTradeInvoice,
  apiConfirmDelivery,
  apiGetTradeDetails,
  apiUploadTradeReceipt,
} from 'src/apis/trades';
import constants from 'src/configs/constants';

export default function PurchaseOrderDetailPage() {
  const { t } = useTranslation();
  const params = useParams();
  const query = useSearchParams();
  const tradeId = params.tradeId;

  const { data, isLoading, refetch } = useQuery(
    ['orders', tradeId],
    async () => {
      return await apiGetTradeDetails(tradeId);
    },
  );

  const [tab, setTab] = useState('summary');
  const [modals, setModals] = useState({});
  const [processing, setProcessing] = useState(false);

  const [signerView, setSignerView] = useState('');

  const handleOpenModal = (modalName) => {
    setModals((values) => ({
      ...values,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName) => {
    setModals((values) => ({
      ...values,
      [modalName]: false,
    }));
  };

  const handleAcceptAgreements = () => {
    setProcessing(true);
    apiAcceptTradeAgreements({
      id: tradeId,
    })
      .then((result) => {
        if (result) {
          handleCloseModal('accept-agreements');
          refetch();

          if (result.signerView) {
            setSignerView(result.signerView);
          }
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleAcceptInvoice = () => {
    setProcessing(true);
    apiAcceptTradeInvoice({
      id: tradeId,
      status: 'Invoice Accepted',
    })
      .then((result) => {
        if (result) {
          handleCloseModal('accept-invoice');
          refetch();
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleUploadReceipt = (url) => {
    setProcessing(true);
    apiUploadTradeReceipt({
      id: tradeId,
      paymentReceipt: url,
    })
      .then((result) => {
        if (result) {
          handleCloseModal('upload-receipt');
          refetch();
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleAcceptDocuments = () => {
    setProcessing(true);
    apiAcceptManufacturingDocuments({
      id: tradeId,
    })
      .then((result) => {
        if (result) {
          handleCloseModal('accept-documents');
          refetch();
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleAcceptImports = () => {
    setProcessing(true);
    apiAcceptImportingDocuments({
      id: tradeId,
    })
      .then((result) => {
        if (result) {
          handleCloseModal('accept-imports');
          refetch();
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleConfirmDelivery = () => {
    setProcessing(true);
    apiConfirmDelivery({
      id: tradeId,
    })
      .then((result) => {
        if (result) {
          handleCloseModal('confirm-delivery');
          refetch();
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleViewContract = () => {
    if (data?.bcContractAddress) {
      window.open(
        constants.getBlockchainContractPath(data.bcContractAddress),
        'blank',
      );
    }
  };

  const handleViewMessage = () => {
    setTab('messages');
  };

  useEffect(() => {
    const db = getDatabase();
    const tradeRef = ref(db, `trades/${tradeId}/updatedAt`);
    const unsubscribe = onValue(tradeRef, (snapshot) => {
      if (snapshot.exists()) {
        refetch();
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (tab) {
      query[1](
        new URLSearchParams({
          tab: tab,
        }),
      );
    }
  }, [tab]);

  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <PageHeader
        data={data}
        onAcceptAgreements={() => handleOpenModal('accept-agreements')}
        onAcceptInvoice={() => handleOpenModal('accept-invoice')}
        onMakePayment={() => handleOpenModal('bank-transfer')}
        onDownloadInvoice={() =>
          window.open(constants.getStoragePath(data?.invoice), '_blank')
        }
        onDownloadReceipt={() =>
          window.open(constants.getStoragePath(data?.paymentReceipt), '_blank')
        }
        onAcceptDocuments={() => handleOpenModal('accept-documents')}
        onAcceptImports={() => handleOpenModal('accept-imports')}
        onConfirmDelivery={() => handleOpenModal('confirm-delivery')}
        onViewContract={handleViewContract}
        onViewMessage={handleViewMessage}
      />
      <PageTabs tab={tab} onChangeTab={setTab} />
      <Box mt={2.5}>
        {!isLoading ? (
          <>
            <PageSummaryTab active={tab === 'summary'} data={data} />
            <PageDocumentsTab active={tab === 'documentations'} data={data} />
            <PageTradeChattingTab active={tab === 'messages'} data={data} />
            <PageAuditTrailTab active={tab === 'audit-trail'} data={data} />
          </>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={1.5}
            height={300}
          >
            <CircularProgress size={24} />
            <Typography>{t('Loading')}</Typography>
          </Stack>
        )}
      </Box>

      <AcceptAgreementModal
        open={modals['accept-agreements'] ?? false}
        onClose={() => handleCloseModal('accept-agreements')}
        loading={processing}
        onAccept={handleAcceptAgreements}
      />
      <AcceptInvoiceModal
        open={modals['accept-invoice'] ?? false}
        onClose={() => handleCloseModal('accept-invoice')}
        loading={processing}
        onAccept={handleAcceptInvoice}
      />
      <BankTransferModal
        open={modals['bank-transfer'] ?? false}
        data={data}
        onClose={() => handleCloseModal('bank-transfer')}
        onFinish={() => handleOpenModal('upload-receipt')}
      />
      <UploadReceiptModal
        open={modals['upload-receipt'] ?? false}
        onClose={() => handleCloseModal('upload-receipt')}
        loading={processing}
        onSubmit={handleUploadReceipt}
      />
      <AcceptDocumentsModal
        open={modals['accept-documents'] ?? false}
        onClose={() => handleCloseModal('accept-documents')}
        loading={processing}
        onAccept={handleAcceptDocuments}
      />
      <AcceptImportsModal
        open={modals['accept-imports'] ?? false}
        onClose={() => handleCloseModal('accept-imports')}
        loading={processing}
        onAccept={handleAcceptImports}
      />
      <ConfirmDeliveryModal
        open={modals['confirm-delivery'] ?? false}
        onClose={() => handleCloseModal('confirm-delivery')}
        loading={processing}
        onAccept={handleConfirmDelivery}
      />
      <PageSignerViewModal
        url={signerView}
        open={!!signerView}
        onFinish={() => setSignerView('')}
      />
    </Container>
  );
}
