import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import notify from 'src/configs/notify';

export default function PageSenderViewModal({ url, open, onFinish }) {
  const handleBoldSignEvent = (params) => {
    if (
      params.origin !== 'https://app-eu.boldsign.com' &&
      params.origin !== 'https://app.boldsign.com'
    ) {
      return;
    }

    switch (params.data) {
      case 'onDraftSuccess': {
        onFinish();
        break;
      }
      case 'onDraftFailed': {
        onFinish();
        break;
      }
      case 'onCreateSuccess': {
        onFinish();
        notify({
          message: 'You have finished signing on the agreement successfully',
          type: 'success',
        });
        break;
      }
      case 'onCreateFailed': {
        onFinish();
        break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleBoldSignEvent);
    return () => {
      window.removeEventListener('message', handleBoldSignEvent);
    };
  }, []);

  return (
    <Dialog open={open} fullScreen>
      <iframe
        id="boldsign-sender-view"
        src={url}
        width="100%"
        height="100%"
        style={{
          border: 'none',
          boxShadow: 'none',
          outline: 'none',
        }}
      />
    </Dialog>
  );
}

PageSenderViewModal.propTypes = {
  url: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onFinish: PropTypes.func,
};
