import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, ButtonBase, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { GUserIcon } from '../ui/AppIcon';
import { setSelectedUser } from 'src/redux/messaging/slice';

export default function ChatMemberItem({ id, name, profileUrl, custom }) {
  const dispatch = useDispatch();
  const { selectedUser } = useSelector((state) => state.messaging);

  const handleClick = () => {
    dispatch(
      setSelectedUser({
        id: id,
        name: name,
        profileUrl,
        custom,
      }),
    );
  };

  const isSelected = selectedUser?.id === id;

  return (
    <Box width="100%" px={2} boxSizing="border-box">
      <ButtonBase
        sx={{
          borderRadius: '20px',
          bgcolor: isSelected ? 'rgba(114, 152, 121, 0.15)' : 'none',
          '&:hover': {
            bgcolor: isSelected ? 'rgba(114, 152, 121, 0.15)' : '#eeeeee',
          },
          textAlign: 'left',
          width: '100%',
        }}
        onClick={handleClick}
      >
        <Stack
          py={2}
          px="29px"
          direction="row"
          alignItems="center"
          width="100%"
          height={83}
          boxSizing="border-box"
        >
          <Avatar src={profileUrl}>
            <GUserIcon />
          </Avatar>
          <Stack ml="14px" flex={1}>
            <Typography
              fontSize={18}
              fontWeight={600}
              lineHeight="28px"
              colorscheme="fonts.heading"
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              {name}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={false ? 600 : 400}
              lineHeight="22px"
              color={false ? '#729879' : '#606060'}
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              {getRoleDisplayName(custom?.title)}
            </Typography>
          </Stack>
          <Typography
            fontSize={14}
            lineHeight="22px"
            colorscheme="gray.700"
            ml={2}
          ></Typography>
        </Stack>
      </ButtonBase>
    </Box>
  );
}

ChatMemberItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  profileUrl: PropTypes.string,
  custom: PropTypes.any,
};

function getRoleDisplayName(role) {
  switch (role) {
    case 'owner': {
      return 'Business Owner';
    }
    case 'agent': {
      return 'Agent';
    }
    case 'superadmin': {
      return 'System Admin';
    }
    default: {
      return 'Member';
    }
  }
}
