import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  ButtonBase,
  Collapse,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  FilledInput,
  styled,
  useMediaQuery,
  useTheme,
  IconButton,
  Drawer,
  CircularProgress,
  Link,
} from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getDatabase, ref, onValue } from 'firebase/database';

import {
  GBellIcon,
  // GHeadsetIcon,
  GHistoryIcon,
  GLogoutIcon,
  GMailIcon,
  GMenu2Icon,
  GSearchIcon,
  GSettingIcon,
  GWalletIcon,
} from 'src/components/ui/AppIcon';
import AppHeaderAvatar from './AppHeaderAvatar';
import AppSideBar from './AppSideBar';
import NotificationItem from 'src/components/items/NotificationItem';
import colors from 'src/configs/colors';
import { useQuery } from 'react-query';
import {
  apiGetNotifications,
  apiReadAllNotifications,
} from 'src/apis/notifications';
import HighlightedTypography from 'src/components/ui/HighlightedTypography';

const AppSearchInput = styled(FilledInput)`
  padding-left: 16px;
  border: 1px solid ${colors['gray.200']};
  border-radius: 4px !important;
  background-color: ${colors['gray.100']};
  min-width: 330px;

  &:before {
    content: none;
  }

  &:after {
    content: none;
  }

  & .MuiInputBase-input {
    font-size: 14px;
    line-height: 16px;
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 16px;
  }

  & .Mui-focused {
    border-color: transparent;
  }
`;

const HeaderIconButton = styled(ButtonBase)((props) => ({
  position: 'relative',
  width: 32,
  height: 32,
  borderRadius: 32,
  ...(props.badge && {
    '&::after': {
      content: "''",
      position: 'absolute',
      display: 'block',
      right: 2,
      top: 2,
      width: 6,
      height: 6,
      borderRadius: 6,
      backgroundColor: '#F53A2E',
    },
  }),
}));

const HeaderMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
    backgroundColor: 'white',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    width: 240,
  },
  '& .MuiMenuItem-root': {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,

    '& .MuiListItemIcon-root > svg': {
      color: colors.text,
    },
    '& .MuiTypography-root': {
      fontSize: '14px !important',
    },
  },
}));

const AppHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const auth = useSelector((state) => state.auth);

  const isMd = useMediaQuery(theme.breakpoints.down('lg'));
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const isASm = useMediaQuery('(min-width: 800px)');

  const [profileAnchor, setProfileAnchor] = useState(null);
  const [notificationAnchor, setNotificationAnchor] = useState(null);
  const [search, setSearch] = useState('');
  const [drawerOpened, setDrawerOpened] = useState(false);

  const [newNotifications, setNewNotifications] = useState(0);
  const [newMessages, setNewMessages] = useState(0);
  const [reading, setReading] = useState(false);

  const {
    data: notifications,
    isLoading: isLoadingNotifications,
    refetch: refetchNotifications,
  } = useQuery(['notifications', 'unread', 1, 5], async () => {
    return await apiGetNotifications({
      pageIndex: 1,
      pageSize: 5,
      onlyUnread: true,
    });
  });

  const handleOpenProfileMenu = useCallback(
    (event) => {
      setProfileAnchor(event.target);
    },
    [setProfileAnchor],
  );

  const handleCloseProfileMenu = useCallback(() => {
    setProfileAnchor(null);
  }, [setProfileAnchor]);

  const handleOpenNotificationsMenu = useCallback((ev) => {
    setNotificationAnchor(ev.target);
  }, []);

  const handleCloseNotificationsMenu = useCallback(() => {
    setNotificationAnchor(null);
  }, []);

  const handleNavigate = useCallback(
    (path) => {
      return () => {
        navigate(path);
      };
    },
    [navigate],
  );

  const handleReadAllNotifications = useCallback(async () => {
    setReading(true);
    apiReadAllNotifications();
    setReading(false);
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const unreadNotificationref = ref(db, `unreadNotifications/${auth.id}`);
    const unsubscribe = onValue(unreadNotificationref, (snapshot) => {
      const data = snapshot.val();
      if (data && data > 0) {
        setNewNotifications(data);
      } else {
        setNewNotifications(0);
      }

      refetchNotifications();
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setNewMessages(0);
  }, []);

  return (
    <>
      <Box
        sx={{
          height: '86px',
          bgcolor: '#FFFFFF',
          py: 2,
          boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.08)',
          boxSizing: 'border-box',
          position: 'fixed',
          left: isSm ? 0 : isMd ? 75 : 204,
          right: 0,
          top: 0,
          zIndex: 999,
          transition: 'all 0.15s',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            px: 3,
            boxSizing: 'border-box',
          }}
          direction="row"
          alignItems="center"
        >
          {isSm ? (
            <Collapse in orientation="horizontal" unmountOnExit mountOnEnter>
              <IconButton onClick={() => setDrawerOpened(true)}>
                <GMenu2Icon />
              </IconButton>
            </Collapse>
          ) : (
            <Collapse in orientation="horizontal" unmountOnExit mountOnEnter>
              <AppSearchInput
                startAdornment={
                  <GSearchIcon
                    sx={{
                      width: '16px',
                      height: '16px',
                      color: '#8C979A',
                    }}
                  />
                }
                placeholder={t('What are you looking for...')}
                aria-autocomplete="none"
                autoComplete="off"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (search) {
                      navigate(`/marketplace/products?search=${search}`);
                      setSearch('');
                    }
                  }
                }}
              />
            </Collapse>
          )}
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{
              ml: 'auto',
            }}
          >
            <Collapse in={isASm} orientation="horizontal">
              <Stack direction="row" alignItems="center" spacing={2}>
                <HeaderIconButton
                  {...(newMessages > 0 && { badge: 'true' })}
                  onClick={handleNavigate('/messages')}
                >
                  <GMailIcon sx={{ color: '#70757E' }} />
                </HeaderIconButton>
                <HeaderIconButton
                  {...(newNotifications > 0 && { badge: 'true' })}
                  onClick={handleOpenNotificationsMenu}
                >
                  <GBellIcon sx={{ color: '#70757E' }} />
                </HeaderIconButton>
              </Stack>
            </Collapse>
            <AppHeaderAvatar onClick={handleOpenProfileMenu} />
          </Stack>
        </Stack>
        <HeaderMenu
          anchorEl={profileAnchor}
          open={!!profileAnchor}
          onClose={handleCloseProfileMenu}
        >
          <Stack
            p={2}
            direction="row"
            alignItems="center"
            spacing={1.75}
            width="100%"
            boxSizing="border-box"
          >
            <AppHeaderAvatar />
            <Box
              flex={1}
              sx={{
                overflowX: 'auto',
                overflowWrap: 'break-word',
              }}
            >
              <Typography colorscheme="heading" fontSize={14} fontWeight={600}>
                {auth?.username ? auth.username : t('Your Name')}
              </Typography>
              <Typography fontSize={12} display="block">
                {auth?.email ? auth.email : 'Guest User'}
              </Typography>
            </Box>
          </Stack>
          <MenuItem onClick={handleNavigate('/profile/bank')}>
            <ListItemIcon>
              <GWalletIcon />
            </ListItemIcon>
            <Typography>{t('My Bank Accounts')}</Typography>
          </MenuItem>
          <MenuItem onClick={handleNavigate('/notifications')}>
            <ListItemIcon>
              <GBellIcon />
            </ListItemIcon>
            <Typography>{t('Notifications')}</Typography>
          </MenuItem>
          <MenuItem onClick={handleNavigate('/trades/purchases')}>
            <ListItemIcon>
              <GHistoryIcon />
            </ListItemIcon>
            <Typography>{t('Purchase History')}</Typography>
          </MenuItem>
          {/* <MenuItem component={NavLink} to="/support/faq">
            <ListItemIcon>
              <GHeadsetIcon />
            </ListItemIcon>
            <Typography>{t('Help Center')}</Typography>
          </MenuItem> */}
          <MenuItem component={NavLink} to="/profile/account">
            <ListItemIcon>
              <GSettingIcon />
            </ListItemIcon>
            <Typography>{t('Settings')}</Typography>
          </MenuItem>
          <MenuItem onClick={handleNavigate('/auth/logout')}>
            <ListItemIcon>
              <GLogoutIcon />
            </ListItemIcon>
            <Typography>{t('Logout')}</Typography>
          </MenuItem>
        </HeaderMenu>
        <HeaderMenu
          anchorEl={notificationAnchor}
          open={Boolean(notificationAnchor)}
          onClose={handleCloseNotificationsMenu}
          sx={{
            '& .MuiPaper-root': {
              width: 480,
              maxWidth: 'calc(100vw - 48px)',
            },
          }}
        >
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              px={2}
            >
              <HighlightedTypography>
                Unread notifications ({newNotifications})
              </HighlightedTypography>
              {newNotifications > 0 ? (
                reading ? (
                  <CircularProgress size={14} />
                ) : (
                  <Link href="#" onClick={handleReadAllNotifications}>
                    Mark all as read
                  </Link>
                )
              ) : null}
            </Stack>
            <Box>
              {newNotifications ? (
                <>
                  {isLoadingNotifications ? (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      py={4}
                    >
                      <CircularProgress size={20} />
                      <Typography colorscheme="gray.400" fontSize={14} mt={1.5}>
                        Loading notifications
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      {notifications.map((item) => (
                        <NotificationItem
                          id={item._id}
                          avatar={''}
                          createdAt={item.createdAt}
                          highlight={true}
                          message={item.description}
                          title={item.title}
                          payload={item.payload}
                          action={item.action}
                          key={`notification-${item._id}`}
                          bottomBorder
                        />
                      ))}

                      <Box
                        mt={2}
                        px={2}
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        <Link component={NavLink} to="/notifications">
                          View all
                        </Link>
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography colorscheme="gray.400" fontSize={14} py={4}>
                    No new notifications
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </HeaderMenu>
      </Box>
      <Drawer
        anchor="left"
        open={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        keepMounted
      >
        <AppSideBar fixed />
      </Drawer>
    </>
  );
};

export default AppHeader;
