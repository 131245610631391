import React from 'react';
import { Box, ButtonBase, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const CategoryItem = ({
  title,
  subtitle,
  image,
  loading,
  sx,
  onClick,
  drawShadow,
}) => {
  return (
    <ButtonBase
      sx={{
        borderRadius: '5px',
        width: '100%',
        height: '100%',
        textAlign: 'start',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          bgcolor: 'white',
          p: 2,
          borderRadius: '5px',
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          ...sx,
          ...(drawShadow && {
            boxShadow: '0px 2px 8px 0px rgba(20, 20, 43, 0.10)',
          }),
        }}
      >
        {loading || !image ? (
          <Skeleton
            width="100%"
            height={240}
            variant="rounded"
            sx={{
              borderRadius: '13px',
            }}
          />
        ) : (
          <img
            style={{
              width: '100%',
              height: 'auto',
              display: 'block',
              aspectRatio: 3 / 2,
              objectFit: 'cover',
            }}
            src={image}
          />
        )}
        {loading ? (
          <Skeleton
            variant="text"
            sx={{
              fontSize: 30,
              mt: 3,
              width: '50%',
            }}
          />
        ) : (
          <Typography
            fontSize={18}
            fontWeight={600}
            colorscheme="heading"
            mt={3}
          >
            {title}
          </Typography>
        )}
        {subtitle &&
          (loading ? (
            <Skeleton
              variant="text"
              sx={{
                fontSize: 14,
                width: '75%',
              }}
            />
          ) : (
            <Typography mt={1} fontSize={16} colorscheme="gray">
              {subtitle}
            </Typography>
          ))}
      </Box>
    </ButtonBase>
  );
};

CategoryItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  loading: PropTypes.bool,
  sx: PropTypes.any,
  onClick: PropTypes.func,
  drawShadow: PropTypes.bool,
};

export default CategoryItem;
