import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Container,
  Typography,
  styled,
  Stack,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GChevronDown } from '../../../../components/ui/AppIcon';
import constants from '../../../../configs/constants';
import _faqs from '../../../../_dev/sample-data/faqs.json';
import { NavLink } from 'react-router-dom';

const Accordion = styled(MuiAccordion)(() => ({
  padding: 24,
  borderRadius: 8,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  marginBottom: 12,

  '&:before': {
    content: '""',
    display: 'none',
  },
  '&.Mui-expanded': {
    marginTop: 0,
    marginBottom: 12,
  },
}));

const AccordionSummary = ({ children }) => {
  return (
    <MuiAccordionSummary
      expandIcon={<GChevronDown />}
      sx={{
        p: 0,
        minHeight: 'auto !important',
        '& .MuiAccordionSummary-content': {
          m: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          m: 0,
        },
        '& .MuiTypography-root': {
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 1.5,
          color: constants.colors.fonts.heading,
        },
      }}
    >
      {children}
    </MuiAccordionSummary>
  );
};

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  paddingTop: 16,

  '& .MuiTypography-root': {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    color: constants.colors.fonts.text,
  },
}));

AccordionSummary.propTypes = {
  children: PropTypes.element,
};

const PageFaqsView = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        py: 12,
      }}
    >
      {_faqs.map((item, index) => (
        <Accordion key={`faq-${index}`}>
          <AccordionSummary>
            <Typography>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Stack
        mt={2.5}
        justifyContent="center"
        alignItems="center"
        bgcolor="#FAFAFA"
        py="38px"
        px={5}
        borderRadius={1}
      >
        <Typography
          fontSize={20}
          fontWeight={600}
          lineHeight={1.5}
          colorscheme="fonts.heading"
          textAlign="center"
        >
          {t('Still No Help?')}
        </Typography>
        <Typography
          mt={1.5}
          fontSize={16}
          lineHeight={1.5}
          colorscheme="fonts.text"
          maxWidth={526}
          textAlign="center"
        >
          {t(
            'Submit a ticket to our support dashboard and one of our customer assistants will get back to you as soon as possible.',
          )}
        </Typography>
        <Button
          variant="contained"
          sx={{
            mt: 3,
            width: 155,
            height: 50,
          }}
          LinkComponent={NavLink}
          to="/support/tickets/new"
        >
          {t('Submit Ticket')}
        </Button>
      </Stack>
    </Container>
  );
};

export default PageFaqsView;
