import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';

import { GBuildingBankIcon, GCheckIcon } from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';

export default function BankAccountItem({
  isSelected,
  iban,
  bank,
  loading,
  onEdit,
  onSelect,
}) {
  return (
    <ButtonBase
      sx={{
        display: 'block',
        textAlign: 'initial',
      }}
      onClick={isSelected ? undefined : onSelect}
    >
      <Box
        p={2}
        borderRadius={1.5}
        sx={{
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: isSelected ? colors.primary : colors['gray.300'],
        }}
      >
        <Stack direction="row">
          <Box pl={1} pr={2.5}>
            <GBuildingBankIcon
              sx={{
                color: colors.primary,
              }}
            />
          </Box>
          <Box flex={1}>
            <Typography>{maskIBAN(iban)}</Typography>
            <Typography>{bank}</Typography>
            <Stack direction="row" mt={1}>
              <Button
                sx={{
                  height: '32px !important',
                  fontSize: 14,
                }}
                onMouseDown={(ev) => ev.stopPropagation()}
                onClick={(ev) => {
                  ev.stopPropagation();
                  onEdit();
                }}
              >
                Edit
              </Button>
            </Stack>
          </Box>
          <Box>
            {loading ? (
              <CircularProgress size={20} />
            ) : isSelected ? (
              <GCheckIcon
                sx={{
                  borderRadius: 20,
                  width: 20,
                  height: 20,
                  bgcolor: colors.primary,
                  color: 'white',
                  boxSizing: 'border-box',
                  p: 0.4,
                }}
              />
            ) : (
              <Box
                borderRadius={20}
                width={20}
                height={20}
                border={`2px solid ${colors.primary}`}
                boxSizing="border-box"
              />
            )}
          </Box>
        </Stack>
      </Box>
    </ButtonBase>
  );
}

function maskIBAN(iban) {
  let digits = iban.slice(-4);
  digits = '*'.repeat(iban.length - 4) + digits;
  digits = digits.match(/.{1,4}/g);
  return digits.join(' ');
}

BankAccountItem.propTypes = {
  isSelected: PropTypes.bool,
  iban: PropTypes.string,
  bank: PropTypes.string,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onSelect: PropTypes.func,
};
