import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, OutlinedInput, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clone from 'clone';

import { GPlusIcon } from '../../../components/ui/AppIcon';
import constants from '../../../configs/constants';

const SpecificationsEditor = ({ value, onChange, error }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (value.length < 3) {
      let remaining = 3 - value.length;
      for (let i = 1; i <= remaining; i++) {
        value.push({
          specification: '',
          value: '',
        });
      }
      onChange(value);
    }
  }, [value, onChange]);

  const handleChange = (index, name, change) => {
    const newValue = clone(value);
    newValue[index][name] = change;
    onChange(newValue);
  };

  const handleAdd = () => {
    if (value.length > 0) {
      if (
        !(
          value[value.length - 1].specification && value[value.length - 1].value
        )
      ) {
        return;
      }
    }

    const newValue = clone(value);
    newValue.push({
      specification: '',
      value: '',
    });
    onChange(newValue);
  };

  return (
    <Stack direction="column" spacing={0}>
      <Stack direction="row" spacing={5} mb={1}>
        <Typography
          fontSize={16}
          fontWeight={500}
          lineHeight={1.5}
          colorscheme="fonts.heading"
          flex={1}
        >
          {t('Specification')}
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={500}
          lineHeight={1.5}
          colorscheme="fonts.heading"
          flex={1}
        >
          {t('Value')}
        </Typography>
      </Stack>
      {value.map((item, index) => (
        <Stack
          direction="row"
          spacing={5}
          key={`spec-input-${index}`}
          mb={index != value.length - 1 ? 3 : 1}
        >
          <OutlinedInput
            placeholder={t('Enter spec')}
            fullWidth
            value={item.specification}
            onChange={(ev) =>
              handleChange(index, 'specification', ev.currentTarget.value)
            }
            error={!!error}
          />
          <OutlinedInput
            placeholder={t('Value')}
            fullWidth
            value={item.value}
            onChange={(ev) =>
              handleChange(index, 'value', ev.currentTarget.value)
            }
            error={!!error}
          />
        </Stack>
      ))}
      <ButtonBase
        sx={{
          display: 'inline-block',
          width: 110,
        }}
        onClick={handleAdd}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <GPlusIcon
            sx={{
              width: 16,
              height: 16,
              color: constants.colors.fills.primary,
            }}
          />
          <Typography
            color={constants.colors.fills.primary}
            fontSize={16}
            fontWeight={600}
            lineHeight={1.5}
            sx={{
              textDecoration: 'underline',
            }}
          >
            {t('Add More')}
          </Typography>
        </Stack>
      </ButtonBase>
    </Stack>
  );
};

SpecificationsEditor.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default SpecificationsEditor;
