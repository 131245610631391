import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

import ProductItem from '../../../../components/items/ProductItem';
import EmptyResult from 'src/components/ui/EmptyResult';
import constants from 'src/configs/constants';

const PageProductsPanel = ({ products, loading }) => {
  return (
    <Box>
      <Box>
        <Grid container columnSpacing={2.5} rowSpacing={2.5}>
          {loading ? (
            Array(9)
              .fill(1)
              .map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={`placeholder-${index}`}>
                  <ProductItem loading />
                </Grid>
              ))
          ) : products.length > 0 ? (
            products.map((product) => (
              <Grid item xs={12} sm={6} md={4} key={`product-${product._id}`}>
                <ProductItem
                  id={product._id}
                  image={constants.getStoragePath(product.photos[0])}
                  price={product.price}
                  title={product.name}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <EmptyResult />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

PageProductsPanel.propTypes = {
  products: PropTypes.array,
  loading: PropTypes.bool,
};

export default PageProductsPanel;
