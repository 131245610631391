import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import prettyBytes from 'pretty-bytes';

import { GFileIcon, GUploadIcon } from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';
import { apiUploadFiles } from 'src/apis/upload';

const AttachmentsInput = ({ value, onChange, error }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      onChange({
        file: file,
        url: null,
      });

      const formData = new FormData();
      formData.append('files', file);
      formData.append('key', 'trades/requirements');

      apiUploadFiles(formData)
        .then((result) => {
          onChange({
            file: file,
            url: result,
          });
        })
        .catch(() => {
          onChange(null);
        });
    }
  }, [acceptedFiles]);

  return (
    <Box mt={0.5}>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '12px',
          border: `2px dashed ${error ? colors['status.error'] : '#E5E7EB'}`,
          cursor: 'pointer',
          p: 3,
          bgcolor: '#FAFAFA',
        }}
      >
        <input {...getInputProps()} />
        <GUploadIcon
          sx={{
            width: 32,
            height: 32,
            color: '#D3D6DA',
          }}
        />
        <Typography mt={1} fontSize={12} lineHeight="18px" color="#A0A8B0">
          Drag and drop attachments or click to browse and upload
        </Typography>
      </Box>
      <Stack mt={2} spacing={1}>
        {value?.file ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <GFileIcon
              sx={{
                color: colors.text,
              }}
            />
            <Stack flex={1}>
              <Typography fontSize={14}>{value?.file?.name}</Typography>
              <Typography fontSize={12}>
                {prettyBytes(value?.file?.size)}
              </Typography>
            </Stack>
            {value?.url ? null : <CircularProgress size={20} />}
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};

AttachmentsInput.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default AttachmentsInput;
