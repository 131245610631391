import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  IconButton,
  OutlinedInput,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from 'src/components/ui/AppIcon';

const schema = yup.object().shape({
  bankName: yup.string().required(),
  bankAddress: yup.string().required(),
  swiftCode: yup.string().required(),
  routingNumber: yup.string().optional(),
  accountName: yup.string().required(),
  accountNumber: yup.string().required(),
  iban: yup.string().required(),
});

export default function BankAccountEditModal({
  open,
  onClose,
  loading,
  onSubmit,
  data,
}) {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      bankName: '',
      bankAddress: '',
      swiftCode: '',
      routingNumber: '',
      accountName: '',
      accountNumber: '',
      iban: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data) {
      if (data._id === 'new') {
        reset();
      } else {
        reset({
          bankName: data.bankName,
          bankAddress: data.bankAddress,
          swiftCode: data.swiftCode,
          routingNumber: data.routingNumber,
          accountName: data.accountName,
          accountNumber: data.accountNumber,
          iban: data.iban,
        });
      }
    } else {
      reset();
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 495,
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          color="#303030"
          fontSize={30}
          lineHeight="38px"
          fontWeight={600}
          display="block"
        >
          {data?._id === 'new' ? 'Add' : 'Edit'} Bank Account
        </HighlightedTypography>
        <Stack direction="column" spacing={2} mt={3}>
          <Controller
            name="bankName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl>
                <InputLabel required>Bank Name</InputLabel>
                <OutlinedInput
                  {...field}
                  fullWidth
                  placeholder="Enter bank name"
                  error={!!error}
                />
              </FormControl>
            )}
          />
          <Controller
            name="bankAddress"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl>
                <InputLabel required>Bank Address</InputLabel>
                <OutlinedInput
                  {...field}
                  fullWidth
                  placeholder="Enter bank address"
                  error={!!error}
                />
              </FormControl>
            )}
          />
          <Controller
            name="swiftCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl>
                <InputLabel required>BIC/Swift Code</InputLabel>
                <OutlinedInput
                  {...field}
                  fullWidth
                  placeholder="Enter swift code"
                  error={!!error}
                />
              </FormControl>
            )}
          />
          <Controller
            name="routingNumber"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl>
                <InputLabel>Routing Number (US Only)</InputLabel>
                <OutlinedInput
                  {...field}
                  fullWidth
                  placeholder="Enter routing number (US only)"
                  error={!!error}
                />
              </FormControl>
            )}
          />
          <Controller
            name="accountName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl>
                <InputLabel required>Account Name</InputLabel>
                <OutlinedInput
                  {...field}
                  fullWidth
                  placeholder="Enter account name"
                  error={!!error}
                />
              </FormControl>
            )}
          />
          <Controller
            name="accountNumber"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl>
                <InputLabel required>Account Number</InputLabel>
                <OutlinedInput
                  {...field}
                  fullWidth
                  placeholder="Enter account number"
                  error={!!error}
                />
              </FormControl>
            )}
          />
          <Controller
            name="iban"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControl>
                <InputLabel required>IBAN</InputLabel>
                <OutlinedInput
                  {...field}
                  fullWidth
                  placeholder="Enter IBAN number"
                  error={!!error}
                />
              </FormControl>
            )}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              px: 2,
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="small"
            sx={{
              px: 2,
            }}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </LoadingButton>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
}

BankAccountEditModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.any,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
