import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';

import { GPhotoIcon, GUploadIcon } from '../../../../components/ui/AppIcon';
import constants from '../../../../configs/constants';

const AttachmentsInput = ({ boxProps, maxFileSize }) => {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxSize: maxFileSize * 1024 * 1024,
  });

  return (
    <Box mt={3.5} {...boxProps}>
      <Stack
        sx={{
          py: 3,
          px: 5,
          borderRadius: '6px',
          border: '2px dashed #CDD2E1',
          cursor: 'pointer',
        }}
        justifyContent="center"
        alignItems="center"
        spacing={1}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <GUploadIcon
          sx={{
            width: 32,
            height: 32,
            color: constants.colors.gray[500],
          }}
        />
        <Typography fontSize={16} lineHeight={1.5} colorscheme="gray.500">
          {t('Drop your file here, or browse')}
        </Typography>
        <Typography fontSize={14} lineHeight={1.5} colorscheme="fonts.text">
          {t('PNG, Max size: XMB', {
            fileSize: maxFileSize,
          })}
        </Typography>
      </Stack>
      <Stack mt={2} spacing={1}>
        {acceptedFiles.map((item, index) => (
          <Stack
            direction="row"
            key={`attachment-${index}`}
            alignItems="center"
            spacing={1}
          >
            <GPhotoIcon
              sx={{
                color: constants.colors.fonts.text,
              }}
            />
            <Stack>
              <Typography fontSize={14}>{item.name}</Typography>
              <Typography fontSize={12}>{prettyBytes(item.size)}</Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

AttachmentsInput.propTypes = {
  boxProps: PropTypes.any,
  maxFileSize: PropTypes.number,
};

export default AttachmentsInput;
