import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pubnub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { useDispatch, useSelector } from 'react-redux';

import {
  apiGetMessagingConfig,
  apiGetUnreadMessagesCount,
} from 'src/apis/messaging';
import { setMessagingReadyStatus } from 'src/redux/messaging/slice';
import notify from 'src/configs/notify';
import store from 'src/redux/store';

export default function ChatProvider({ children }) {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const [client, setClient] = useState(null);

  useEffect(() => {
    if (auth?.id && auth?.accessToken) {
      dispatch(setMessagingReadyStatus(false));
      apiGetMessagingConfig()
        .then((result) => {
          const instance = new Pubnub({
            publishKey: result.publishKey,
            subscribeKey: result.subscribeKey,
            userId: result.userId,
          });

          instance.addListener({
            message: function (event) {
              let publisherName = '';
              const user = store
                .getState()
                .messaging.users.find((item) => item.id === event.publisher);
              if (user) {
                publisherName = user.name;
              }

              if (document.hasFocus()) {
                if (
                  window.location.pathname !== '/messages' &&
                  !window.location.search.includes('tab=message')
                ) {
                  notify({
                    type: 'success',
                    message: publisherName
                      ? `${publisherName} has sent you a new chat!`
                      : 'You have received new chat',
                  });
                }
              } else {
                if (!('Notification' in window)) {
                  return;
                } else if (Notification.permission === 'granted') {
                  new Notification(
                    publisherName
                      ? `New message from ${publisherName}`
                      : 'New message',
                    { body: event.message.text },
                  );
                } else if (Notification.permission !== 'denied') {
                  Notification.requestPermission().then(function (permission) {
                    if (permission === 'granted') {
                      new Notification(
                        publisherName
                          ? `New message from ${publisherName}`
                          : 'New message',
                        {
                          body: event.message.text,
                        },
                      );
                    }
                  });
                }
              }
            },
          });

          apiGetUnreadMessagesCount();

          setClient(instance);
        })
        .catch(() => {
          setClient(null);
        });
    } else {
      setClient(null);
    }
  }, [auth]);

  useEffect(() => {
    if (client) {
      dispatch(setMessagingReadyStatus(true));
    } else {
      dispatch(setMessagingReadyStatus(false));
    }
  }, [client]);

  if (client) {
    return <PubNubProvider client={client}>{children}</PubNubProvider>;
  } else {
    return children;
  }
}

ChatProvider.propTypes = {
  children: PropTypes.node,
};
