import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
} from '@mui/material';

import SingleFileInput from './SingleFileInput';
import { GCloseIcon } from '../ui/AppIcon';

export default function SingleFileInputWithTitle({
  title,
  value,
  disableTitleEdit,
  disableRemove,
  error,
  required,
  accept,
  folder,
  onRemove,
  onTitleChange,
  onValueChange,
}) {
  const handleTitleChange = (ev) => {
    if (onTitleChange) {
      onTitleChange(ev.target.value);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {disableTitleEdit ? (
          <InputLabel
            fontSize={16}
            fontWeight={500}
            required={!!required}
            error={!!error}
          >
            {title}
          </InputLabel>
        ) : (
          <OutlinedInput
            fullWidth
            placeholder="Document name"
            error={!!error}
            value={title}
            onChange={handleTitleChange}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack direction="row" alignItems="center">
          <SingleFileInput
            value={value}
            error={!!error}
            accept={accept}
            folder={folder}
            onChange={onValueChange}
          />
          {!disableRemove && (
            <IconButton
              sx={{
                ml: 1,
              }}
              onClick={onRemove}
            >
              <GCloseIcon
                sx={{
                  width: 20,
                  height: 20,
                }}
              />
            </IconButton>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

SingleFileInputWithTitle.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
  disableTitleEdit: PropTypes.bool,
  disableRemove: PropTypes.bool,
  error: PropTypes.bool,
  accept: PropTypes.string,
  folder: PropTypes.string,
  required: PropTypes.bool,
  onRemove: PropTypes.func,
  onTitleChange: PropTypes.func,
  onValueChange: PropTypes.func,
};
