import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import PageHeader from './invoice-builder-page/PageHeader';
import InvoiceInput from './invoice-builder-page/InvoiceInput';

import notify from 'src/configs/notify';
import { apiGetTradeDetails, apiUploadInvoice } from 'src/apis/trades';

const InvoiceBuilderPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const tradeId = params.tradeId;

  const { data: trade } = useQuery(['orders', tradeId], async () => {
    return await apiGetTradeDetails(tradeId);
  });
  const [payment, setPayment] = useState(null);

  const [file, setFile] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSendInvoice = () => {
    if (file?.url && payment) {
      setProcessing(true);
      apiUploadInvoice({
        id: tradeId,
        url: file.url,
        paymentId: payment._id,
        ...(payment.shipment && {
          shipmentId: payment.shipment._id,
        }),
      })
        .then((result) => {
          if (result) {
            navigate(-1);
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      notify({
        message: 'Please upload the invoice document',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (trade) {
      let newPayment = null;
      if (trade.status === 'paying') {
        for (const item of trade.initialPayments) {
          if (item.status === 'invoicing') {
            newPayment = item;
            break;
          }
        }
      } else if (trade.status === 'shipment') {
        for (const shipment of trade.shipments) {
          for (const item of shipment.payments) {
            if (item.status === 'invoicing') {
              newPayment = item;
              newPayment.shipment = shipment;
              break;
            }
          }
        }
      } else {
        newPayment = null;
      }
      setPayment(newPayment);
    } else {
      setPayment(null);
    }
  }, [trade]);

  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <PageHeader
        loading={processing}
        disabled={!payment}
        onSubmit={handleSendInvoice}
      />
      <Card>
        <CardHeader
          title={
            <Typography fontSize={18} fontWeight={600} colorscheme="heading">
              Upload Invoice
            </Typography>
          }
          emphasis="true"
        />
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          <Typography fontSize={14}>
            If you have an invoice you can upload it here for payment, otherwise
            use the below form to submit an invoice generated by OODL
            Marketplace.
          </Typography>
          <Typography colorscheme="primary" mt={2}>
            Required Invoice Amount
          </Typography>
          <Typography colorscheme="black" fontWeight={600} mt={1}>
            $ {payment ? Number(payment.total).toLocaleString() : 'Loading...'}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={600}
            mt={2}
            colorscheme="heading"
          >
            Attach File
          </Typography>
          <InvoiceInput value={file} onChange={setFile} />
        </CardContent>
      </Card>
    </Container>
  );
};

export default InvoiceBuilderPage;
