import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

export default function ImageCropModal({ image, open, onClose, onCrop }) {
  const [crop, setCrop] = useState();

  const imgRef = useRef(null);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 100,
          },
          1 / 1,
          width,
          height,
        ),
        width,
        height,
      ),
    );
  };

  const onComplete = async () => {
    const image = imgRef.current;
    if (!image || !crop) {
      return;
    }

    const offscreen = new OffscreenCanvas(
      (crop.width * image.naturalWidth) / 100,
      (crop.height * image.naturalHeight) / 100,
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.drawImage(
      imgRef.current,
      (crop.x * image.naturalWidth) / 100,
      (crop.y * image.naturalHeight) / 100,
      (crop.width * image.naturalWidth) / 100,
      (crop.height * image.naturalHeight) / 100,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const blob = await offscreen.convertToBlob({
      type: 'image/jpeg',
      quality: 1,
    });
    onCrop(blob);
    return onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent>
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          aspect={1 / 1}
          minWidth={40}
        >
          <img
            src={image}
            alt="Cropping now"
            onLoad={onImageLoad}
            ref={imgRef}
          />
        </ReactCrop>
        <Stack direction="row" justifyContent="flex-end" mt={2} spacing={2}>
          <Button size="small" sx={{ px: 3 }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            sx={{ px: 3 }}
            onClick={onComplete}
          >
            Confirm
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

ImageCropModal.propTypes = {
  image: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCrop: PropTypes.func,
};
