import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Divider,
  Link,
  ListItemIcon,
  CircularProgress,
  InputLabel,
} from '@mui/material';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useDropzone } from 'react-dropzone';
import prettyBytes from 'pretty-bytes';
import mime from 'mime';

import { GCloseIcon, GUploadIcon } from '../ui/AppIcon';
import { apiV2UploadFiles } from 'src/apis/upload';
import colors from 'src/configs/colors';

export default function FilesDropzone({
  value,
  folder,
  accept,
  error,
  placeholder,
  previewType,
  label,
  required,
  onChange,
}) {
  const [uploadingFiles, setUploadingFiles] = useState([]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const filesToUpload = acceptedFiles.filter(
        (file) =>
          !uploadingFiles.some(
            (uploadingFile) => uploadingFile.name === file.name,
          ),
      );

      if (filesToUpload.length === 0) {
        return;
      }

      try {
        const formData = new FormData();
        formData.append('key', folder ? folder : 'general');
        for (const row of filesToUpload) {
          formData.append('files', row);
        }

        setUploadingFiles((prevUploadingFiles) => [
          ...prevUploadingFiles,
          ...filesToUpload.map((file) => ({
            filename: file.name,
            size: file.size,
            ext: mime.getExtension(file.mimetype),
          })),
        ]);

        const uploadedFiles = await apiV2UploadFiles(formData);

        if (onChange) {
          onChange([
            ...(value || []),
            ...(uploadedFiles.length > 0 ? uploadedFiles : [uploadedFiles]),
          ]);
        }
      } catch (error) {
        console.error('Error uploading files:', error);
      } finally {
        setUploadingFiles([]);
      }
    },
    [value, onChange, uploadingFiles],
  );

  const removeFile = (indexToRemove) => {
    const updatedFiles = value.filter((_, index) => index !== indexToRemove);
    if (onChange) {
      onChange(updatedFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept,
  });

  const renderFileList = () => {
    return (
      <List>
        {value.map((file, index) => (
          <React.Fragment key={index}>
            <ListItem
              onClick={() => {}}
              sx={{
                py: 0,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '0',
                  mr: 2,
                  '& svg': {
                    width: 30,
                  },
                }}
              >
                <FileIcon extension={file.ext} {...defaultStyles[file.ext]} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    href={file.downloadUrl}
                    target="_blank"
                    sx={{
                      wordBreak: 'break-all',
                    }}
                  >
                    {file.filename}
                  </Link>
                }
                secondary={'Size: ' + prettyBytes(file.size)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeFile(index)}
                >
                  <GCloseIcon
                    sx={{
                      color: colors['text'],
                      width: 20,
                      height: 20,
                    }}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {index < value.length - 1 ? <Divider /> : null}
          </React.Fragment>
        ))}
      </List>
    );
  };

  const renderFilePreviews = () => {
    return (
      <List>
        {value.map((file, index) => (
          <ListItem key={index}>
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              style={{ width: '50px', height: 'auto' }}
            />
            <ListItemText
              primary={file.name}
              secondary={`Size: ${file.size} bytes`}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => removeFile(index)}>
                <GCloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box>
      {label ? (
        <InputLabel
          required={!!required}
          sx={{
            pb: 0.5,
          }}
          error={!!error}
        >
          {label}
        </InputLabel>
      ) : null}
      <Box
        {...getRootProps()}
        sx={{
          border: `2px dashed ${
            error ? colors['status.error'] : colors['gray.300']
          }`,
          borderRadius: 1.5,
          p: 3,
          textAlign: 'center',
          bgcolor: colors['gray.100'],
        }}
      >
        <input {...getInputProps()} />
        <GUploadIcon
          sx={{
            color: colors['gray.400'],
            width: 30,
            height: 30,
          }}
        />
        <Typography variant="md" colorscheme="gray.400" mt={2}>
          {placeholder
            ? placeholder
            : 'Drag & drop some files here, or click to select files'}
        </Typography>
        {uploadingFiles.length > 0 && (
          <Box mt={3}>
            <Typography variant="xs" colorscheme="gray.400">
              Uploading Files
            </Typography>
            <List>
              {uploadingFiles.map((file, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemIcon
                    sx={{
                      minWidth: '0',
                      mr: 1,
                    }}
                  >
                    <GUploadIcon
                      sx={{
                        color: colors['gray.400'],
                        width: 18,
                        height: 18,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="xs" colorscheme="gray.400">
                        {file.filename}
                      </Typography>
                    }
                    sx={{
                      wordBreak: 'break-all',
                    }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end">
                      <CircularProgress size={14} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
      <Box>
        {previewType === 'image' ? renderFilePreviews() : renderFileList()}
      </Box>
    </Box>
  );
}

FilesDropzone.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.array,
  folder: PropTypes.string,
  placeholder: PropTypes.string,
  accept: PropTypes.any,
  error: PropTypes.bool,
  previewType: PropTypes.oneOf(['list', 'image']),
  onChange: PropTypes.func,
};
