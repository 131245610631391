import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  OutlinedInput,
  Stack,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from 'src/components/ui/AppIcon';
import { LoadingButton } from '@mui/lab';

const schema = yup
  .object({
    reason: yup.string().required(),
  })
  .required();

const RejectTradeModal = ({ open, processing, onClose, onFinish }) => {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      reason: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (open) {
      setValue('reason', '');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 600,
          maxWidth: '100vw',
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          color="#303030"
          fontSize={30}
          lineHeight="38px"
          fontWeight={600}
        >
          Enter Reason for Rejection
        </HighlightedTypography>
        <Controller
          control={control}
          name="reason"
          render={({ field, fieldState: { error } }) => (
            <Box mt={3}>
              <OutlinedInput
                {...field}
                error={!!error}
                placeholder="Describe your reason briefly"
                fullWidth
                multiline
                minRows={4}
              />
            </Box>
          )}
        />
        <Stack direction="row" alignItems="center" mt={3} spacing={2}>
          <Button
            variant="outlined"
            onClick={onClose}
            size="small"
            sx={{
              px: 3,
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(onFinish)}
            size="small"
            loading={processing}
            sx={{
              px: 3,
            }}
          >
            Submit
          </LoadingButton>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

RejectTradeModal.propTypes = {
  open: PropTypes.bool,
  processing: PropTypes.bool,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
};

export default RejectTradeModal;
