import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

import colors from 'src/configs/colors';

const info = {
  pending: {
    back: colors['blue.highlight'],
    front: colors['status.info'],
    label: 'Pending',
  },
  paying: {
    back: colors['orange.highlight'],
    front: colors['status.warning'],
    label: 'Making Payment',
  },
  checking: {
    back: colors['orange.highlight'],
    front: colors['status.warning'],
    label: 'In Review',
  },
  completed: {
    back: colors['green.highlight'],
    front: colors['status.success'],
    label: 'Completed',
  },
};

const TransactionStatusChip = ({ status, ...props }) => {
  return (
    <Chip
      label={info[status]?.label}
      sx={{
        backgroundColor: info[status]?.back,
        '& .MuiChip-label': {
          color: info[status]?.front,
          fontWeight: 500,
          fontSize: 12,
          lineHeight: '16px',
        },
      }}
      {...props}
    />
  );
};

TransactionStatusChip.propTypes = {
  status: PropTypes.string,
};

export default TransactionStatusChip;
