import React from 'react';
import { Stack, Breadcrumbs, Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GHomeIcon } from '../../../../components/ui/AppIcon';

const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={1}
      mb={3}
    >
      <Breadcrumbs>
        <Link
          component={NavLink}
          to="/"
        >
          <GHomeIcon />
        </Link>
        <Link
          component={NavLink}
          to="/profile/account"
        >
          {t('Profile')}
        </Link>
        <Link
          component={NavLink}
          to="/support/tickets"
        >
          {t('My Tickets')}
        </Link>
      </Breadcrumbs>
      <Typography
        fontSize={24}
        fontWeight={600}
        lineHeight="34px"
        colorscheme="fonts.heading"
      >
        {t('My Tickets')}
      </Typography>
    </Stack>
  );
};

export default PageHeader;
