import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import mime from 'mime';

import { GEditIcon, GEyeIcon } from 'src/components/ui/AppIcon';
import CollapseButton from 'src/components/ui/CollapseButton';
import UpdateDocumentModal from './UpdateDocumentModal';
import PageSenderViewModal from '../trade-builder-page/PageSenderViewModal';
import constants from 'src/configs/constants';
import colors from 'src/configs/colors';
import { getFilenameFromUrl } from 'src/utils/functions';
import { apiUpdateDocument } from 'src/apis/trades';

import svgPdfFile from 'src/assets/extra/colorful-pdf-icon.svg';
import svgImageFile from 'src/assets/extra/colorful-jpg-icon.svg';
import svgVideoFile from 'src/assets/extra/colorful-media-icon.svg';

const DocumentItem = ({ title, subtitle, status, showStatus, path }) => {
  const [type, setType] = useState();
  const config = useMemo(() => {
    return {
      pending: {
        background: colors['orange.highlight'],
        foreground: colors['status.warning'],
        label: 'Outstanding',
      },
      agreed: {
        background: colors['green.highlight'],
        foreground: colors['status.success'],
        label: 'Agreed',
      },
      completed: {
        background: colors['green.highlight'],
        foreground: colors['status.success'],
        label: 'Completed',
      },
    };
  }, []);

  useEffect(() => {
    const result = mime.getType(path);
    if (result.includes('image/')) {
      setType('image');
    } else if (result.includes('application/pdf')) {
      setType('pdf');
    } else {
      setType('video');
    }
  }, [path]);

  return (
    <Stack direction="row" alignItems="center" flex={1}>
      {type && (
        <img
          src={
            type === 'image'
              ? svgImageFile
              : type === 'video'
                ? svgVideoFile
                : svgPdfFile
          }
          width={43}
          height={55}
        />
      )}
      <Stack ml={1.5}>
        <Typography
          fontSize={16}
          fontWeight={500}
          colorscheme="black"
          sx={{
            wordBreak: 'break-all',
          }}
        >
          {title}
          {showStatus && (
            <Chip
              component="span"
              label={config[status]?.label}
              size="small"
              sx={{
                ml: 1,
                bgcolor: config[status]?.background,
                color: config[status]?.foreground,
              }}
            />
          )}
        </Typography>
        <Typography fontSize={12} fontWeight={500} colorscheme="gray.500">
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

DocumentItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  status: PropTypes.string,
  path: PropTypes.string,
  showStatus: PropTypes.bool,
};

export default function PageDocumentsCard({
  opened: defaultOpen,
  data,
  type,
  title,
  showUpdate = false,
  showStatus = false,
  reload,
}) {
  const [opened, setOpened] = useState(defaultOpen);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [senderView, setSenderView] = useState('');

  const handleToggle = () => {
    setOpened((value) => !value);
  };

  const handleUpdateDocument = (file) => {
    setProcessing(true);
    apiUpdateDocument({
      tradeId: data?._id,
      documentId: selectedDocument,
      file: file,
    }).then((result) => {
      setProcessing(false);
      setSelectedDocument(null);

      if (result?.senderView) {
        setSenderView(result.senderView);
      }

      if (reload) {
        reload();
      }
    });
  };

  const handleOpenUpdateModal = (id) => {
    setSelectedDocument(id);
  };

  const handleCloseUpdateModal = () => {
    if (processing) {
      return;
    }
    setSelectedDocument(null);
  };

  const handleCloseSenderView = () => {
    setSenderView('');
  };

  if (!data) {
    return null;
  }
  if (data.documents.filter((item) => item.type === type).length < 1) {
    return null;
  }

  return (
    <>
      <Card sx={{ mb: 2.5 }}>
        <CardHeader
          title={
            <Typography fontSize={18} fontWeight={600} colorscheme="heading">
              {title}
            </Typography>
          }
          emphasis="true"
          action={<CollapseButton opened={opened} onToggle={handleToggle} />}
        />
        <Collapse in={opened}>
          <CardContent
            sx={{
              pb: '16px !important',
            }}
          >
            <Stack spacing={1.5}>
              {data.documents
                .filter((item) => item.type === type)
                .map((item) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    key={item._id}
                  >
                    <DocumentItem
                      title={getFilenameFromUrl(item.url)}
                      subtitle={
                        moment(item.uploadedAt).format('DD MMM, YYYY') +
                        ' (' +
                        item.name +
                        ')'
                      }
                      status={item.status}
                      showStatus={showStatus}
                      path={item.url}
                    />
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      ml={2}
                    >
                      <IconButton
                        sx={{
                          bgcolor: `${colors.primary} !important`,
                          color: 'white',
                        }}
                        onClick={() =>
                          window.open(
                            constants.getStoragePath(item.url, '_blank'),
                          )
                        }
                      >
                        <GEyeIcon />
                      </IconButton>
                      {showUpdate && item.status === 'pending' && (
                        <IconButton
                          sx={{
                            bgcolor: `${colors.primary} !important`,
                            color: 'white',
                          }}
                          onClick={() => handleOpenUpdateModal(item._id)}
                        >
                          <GEditIcon />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                ))}
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
      <UpdateDocumentModal
        open={Boolean(selectedDocument)}
        loading={processing}
        type={type}
        onSubmit={handleUpdateDocument}
        onClose={handleCloseUpdateModal}
      />
      <PageSenderViewModal
        url={senderView}
        open={Boolean(senderView)}
        onFinish={handleCloseSenderView}
      />
    </>
  );
}

PageDocumentsCard.propTypes = {
  opened: PropTypes.bool,
  data: PropTypes.any,
  type: PropTypes.string,
  title: PropTypes.string,
  showUpdate: PropTypes.bool,
  showStatus: PropTypes.bool,
  reload: PropTypes.func,
};
