import React from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';

import DevHeader from '../components/DevHeader';

const DevTypographyPage = () => {
  return (
    <Box>
      <DevHeader title="Typography" />
      <Box pl={4}>
        <Stack
          direction="row"
          alignItems="center"
        >
          <Typography
            variant="h1"
            fontWeight="600"
          >
            Aa
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ bgcolor: 'black', mx: 4 }}
            flexItem
          />
          <Box>
            <Typography variant="h6">Primary Font</Typography>
            <Typography
              variant="h1"
              fontWeight="bold"
              colorscheme="primary"
            >
              Montserrat
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Grid
        container
        spacing={2}
        mt={4}
      >
        <Grid
          item
          xs={6}
        >
          <Box>
            <Typography variant="h6">- Heading Text Styles (Semibold)</Typography>
            <Typography
              variant="h1"
              fontWeight={600}
              mt={2}
            >
              H1 Semibold / 48px
            </Typography>
            <Typography
              variant="h2"
              fontWeight={600}
              mt={2}
            >
              H2 Semibold / 40px
            </Typography>
            <Typography
              variant="h3"
              fontWeight={600}
              mt={2}
            >
              H3 Semibold / 32px
            </Typography>
            <Typography
              variant="h4"
              fontWeight={600}
              mt={2}
            >
              H4 Semibold / 24px
            </Typography>
            <Typography
              variant="h5"
              fontWeight={600}
              mt={2}
            >
              H5 Semibold / 20px
            </Typography>
            <Typography
              variant="h6"
              fontWeight={600}
              mt={2}
            >
              H6 Semibold / 18px
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h6">- Heading Text Styles (Medium)</Typography>
            <Typography
              variant="h1"
              fontWeight={500}
              mt={2}
            >
              H1 Medium / 48px
            </Typography>
            <Typography
              variant="h2"
              fontWeight={500}
              mt={2}
            >
              H2 Medium / 40px
            </Typography>
            <Typography
              variant="h3"
              fontWeight={500}
              mt={2}
            >
              H3 Medium / 32px
            </Typography>
            <Typography
              variant="h4"
              fontWeight={500}
              mt={2}
            >
              H4 Medium / 24px
            </Typography>
            <Typography
              variant="h5"
              fontWeight={500}
              mt={2}
            >
              H5 Medium / 20px
            </Typography>
            <Typography
              variant="h6"
              fontWeight={500}
              mt={2}
            >
              H6 Medium / 18px
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h6">- Heading Text Styles (Regular)</Typography>
            <Typography
              variant="h1"
              mt={2}
            >
              H1 Regular / 48px
            </Typography>
            <Typography
              variant="h2"
              mt={2}
            >
              H2 Regular / 40px
            </Typography>
            <Typography
              variant="h3"
              mt={2}
            >
              H3 Regular / 32px
            </Typography>
            <Typography
              variant="h4"
              mt={2}
            >
              H4 Regular / 24px
            </Typography>
            <Typography
              variant="h5"
              mt={2}
            >
              H5 Regular / 20px
            </Typography>
            <Typography
              variant="h6"
              mt={2}
            >
              H6 Regular / 18px
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Stack spacing={2}>
            <Typography variant="h6">- Body Text Styles (Semibold)</Typography>
            <Typography
              variant="xl"
              fontWeight={600}
              mt={2}
            >
              Extra-Large Semibold / 18px
            </Typography>
            <Typography
              variant="lg"
              fontWeight={600}
              mt={2}
            >
              Large Semibold / 16px
            </Typography>
            <Typography
              variant="md"
              fontWeight={600}
              mt={2}
            >
              Medium Semibold / 14px
            </Typography>
            <Typography
              variant="sm"
              fontWeight={600}
              mt={2}
            >
              Small Semibold / 12px
            </Typography>
            <Typography
              variant="xs"
              fontWeight={600}
              mt={2}
            >
              Extra-Small Semibold / 10px
            </Typography>
          </Stack>
          <Stack
            spacing={2}
            mt={4}
          >
            <Typography variant="h6">- Body Text Styles (Medium)</Typography>
            <Typography
              variant="xl"
              fontWeight={500}
              mt={2}
            >
              Extra-Large Medium / 18px
            </Typography>
            <Typography
              variant="lg"
              fontWeight={500}
              mt={2}
            >
              Large Medium / 16px
            </Typography>
            <Typography
              variant="md"
              fontWeight={500}
              mt={2}
            >
              Medium Medium / 14px
            </Typography>
            <Typography
              variant="sm"
              fontWeight={500}
              mt={2}
            >
              Small Medium / 12px
            </Typography>
            <Typography
              variant="xs"
              fontWeight={500}
              mt={2}
            >
              Extra-Small Medium / 10px
            </Typography>
          </Stack>
          <Stack
            spacing={2}
            mt={4}
          >
            <Typography variant="h6">- Body Text Styles (Regular)</Typography>
            <Typography
              variant="xl"
              mt={2}
            >
              Extra-Large Regular / 18px
            </Typography>
            <Typography
              variant="lg"
              mt={2}
            >
              Large Regular / 16px
            </Typography>
            <Typography
              variant="md"
              mt={2}
            >
              Medium Regular / 14px
            </Typography>
            <Typography
              variant="sm"
              mt={2}
            >
              Small Regular / 12px
            </Typography>
            <Typography
              variant="xs"
              mt={2}
            >
              Extra-Small Regular / 10px
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DevTypographyPage;
