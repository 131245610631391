import React from 'react';
import { Container } from '@mui/material';

import PageNavigation from './create-ticket-page/PageNavigation';
import PageFormView from './create-ticket-page/PageFormView';

const CreateTicketPage = () => {
  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <PageNavigation />
      <PageFormView />
    </Container>
  );
};

export default CreateTicketPage;
