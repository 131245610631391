import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import colors from 'src/configs/colors';

const AppContent = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.down('lg'));
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        paddingLeft: isSm ? 0 : isMd ? '75px' : '204px',
        marginTop: '86px',
        bgcolor: colors['gray.50'],
        minHeight: '100vh',
        transition: 'all 0.15s',
      }}
    >
      <Outlet />
    </Box>
  );
};

export default AppContent;
