import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Breadcrumbs,
  Typography,
  Link,
  Stack,
  Button,
  useTheme,
  useMediaQuery,
  IconButton,
  CircularProgress,
  MenuItem,
  ListItemText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NavLink, useNavigate } from 'react-router-dom';

import { GDotsVerticalIcon, GHomeIcon } from 'src/components/ui/AppIcon';
import TableMenu from 'src/components/ui/TableMenu';
import colors from 'src/configs/colors';

const PageHeader = ({ loading, onSubmit }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [anchor, setAnchor] = useState(null);

  const handleBack = () => {
    navigate(-1);
  };

  const handleOpen = (ev) => {
    setAnchor(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleMenuAction = (callback) => {
    return () => {
      handleClose();
      callback();
    };
  };

  return (
    <Box mb={2.5}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Breadcrumbs>
            <Link component={NavLink} to="/">
              <GHomeIcon />
            </Link>
            <Link component={NavLink} to="/trades/sales">
              My Trades
            </Link>
            <Link component={NavLink} to="/trades/sales">
              Sales
            </Link>
          </Breadcrumbs>
          <Typography fontSize={36} fontWeight={600} colorscheme="heading">
            Trade Builder
          </Typography>
        </Box>
        {isSm ? (
          <>
            <IconButton
              sx={{
                width: 40,
                height: 40,
                borderRadius: 1,
                border: `1px solid ${colors['gray.300']}`,
              }}
              onClick={handleOpen}
            >
              {loading ? <CircularProgress size={24} /> : <GDotsVerticalIcon />}
            </IconButton>
            <TableMenu
              open={Boolean(anchor)}
              anchorEl={anchor}
              onClose={handleClose}
            >
              <MenuItem onClick={handleMenuAction(handleBack)}>
                <ListItemText>Back</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleMenuAction(onSubmit)}>
                <ListItemText>Submit</ListItemText>
              </MenuItem>
            </TableMenu>
          </>
        ) : (
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleBack}
              sx={{ px: 3 }}
            >
              Back
            </Button>
            <LoadingButton
              variant="contained"
              sx={{ px: 3 }}
              size="small"
              loading={loading}
              onClick={onSubmit}
            >
              Submit
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

PageHeader.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default PageHeader;
