import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Typography, Stack } from '@mui/material';
import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getDatabase, onValue, ref } from 'firebase/database';

import PageHeader from './notifications-page/PageHeader';
import NotificationItem from '../../../components/items/NotificationItem';
import {
  apiGetNotifications,
  apiReadAllNotifications,
} from 'src/apis/notifications';

const NotificationsPage = () => {
  const pageSize = 100;
  const auth = useSelector((state) => state.auth);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch: refetchNotifications,
  } = useInfiniteQuery({
    queryKey: ['notifications', 'all'],
    queryFn: async ({ pageParam = 0 }) => {
      const data = await apiGetNotifications({
        pageIndex: pageParam + 1,
        pageSize,
        onlyUnread: false,
      });
      return {
        data,
        pageParam,
      };
    },
    getNextPageParam: (lastPage) => {
      if (lastPage) {
        if (lastPage.data.length > 0) {
          return lastPage.pageParam + 1;
        } else {
          return undefined;
        }
      }
      return undefined;
    },
  });

  const [processing, setProcessing] = useState(false);

  const handleReadAll = async () => {
    setProcessing(true);
    await apiReadAllNotifications();
    setProcessing(false);
  };

  useEffect(() => {
    const onScroll = (ev) => {
      if (ev.detail.scrollHeight - ev.detail.scrollTop < 1000) {
        if (hasNextPage && !isFetching && !isFetchingNextPage) {
          fetchNextPage();
        }
      }
    };

    document.addEventListener('app-scroll', onScroll);

    return () => document.removeEventListener('app-scroll', onScroll);
  }, [hasNextPage, isFetching, isFetchingNextPage]);

  useEffect(() => {
    const db = getDatabase();
    const unreadNotificationref = ref(db, `unreadNotifications/${auth.id}`);
    const unsubscribe = onValue(unreadNotificationref, () => {
      refetchNotifications();
    });

    return () => unsubscribe();
  }, []);

  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <PageHeader processing={processing} onReadAll={handleReadAll} />
      {status === 'loading' ? (
        <Stack justifyContent="center" alignItems="center" py={5}>
          <CircularProgress size={20} />
          <Typography mt={1.5}>Loading</Typography>
        </Stack>
      ) : (
        <>
          {data.pages.map((page, i) => (
            <React.Fragment key={`page-${i}`}>
              {page.data.map((notification) => (
                <NotificationItem
                  key={notification._id}
                  avatar={''}
                  createdAt={notification.createdAt}
                  highlight={!notification.reads}
                  message={notification.description}
                  title={notification.title}
                  payload={notification.payload}
                  action={notification.action}
                  borderRadius="8px"
                  mb={2}
                  boxShadow="0px 2px 8px 0px rgba(20, 20, 43, 0.10)"
                />
              ))}
            </React.Fragment>
          ))}
          {isFetching && !isFetchingNextPage ? (
            <Stack justifyContent="center" alignItems="center" height={200}>
              <CircularProgress size={20} />
              <Typography mt={1.5}>Loading</Typography>
            </Stack>
          ) : null}
        </>
      )}
    </Container>
  );
};

export default NotificationsPage;
