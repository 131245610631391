import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageHeader from './my-products-page/PageHeader';
import PageProductsTable from './my-products-page/PageProductsTable';

import colors from 'src/configs/colors';
import { apiDeleteMyProduct, apiGetMyProducts } from 'src/apis/profile';

const MyProductsPage = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const [tab, setTab] = useState('active-products');
  const {
    data: products,
    isLoading,
    refetch,
  } = useQuery(['profile', 'products', tab], async () => {
    const result = await apiGetMyProducts();

    if (tab === 'active-products') {
      return result.filter((row) => row.status === 'Published');
    } else {
      return result.filter((row) => row.status !== 'Published');
    }
  });

  const [processing, setProcessing] = useState(false);

  const handleChangeTab = (ev, newValue) => {
    const newParams = new URLSearchParams(params);
    newParams.set('tab', newValue === 'active-products' ? 'active' : 'draft');
    setParams(newParams);
  };

  const handleClickView = (id) => {
    navigate(`/profile/products/${id}`);
  };

  const handleClickHelp = () => {};

  const handleClickDelete = (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Want to delete?',
      text: 'Are you sure want to delete this product? Products which have been deleted can not be recovered.',
      showConfirmButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: colors.primary,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      cancelButtonColor: colors['gray.600'],
    }).then(() => {
      setProcessing(true);
      apiDeleteMyProduct(id)
        .then((result) => {
          if (result) {
            refetch();
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    });
  };

  useEffect(() => {
    if (params.get('tab') === 'active') {
      setTab('active-products');
    }
    if (params.get('tab') === 'draft') {
      setTab('draft-products');
    }
  }, [params.get('tab')]);

  return (
    <>
      <PageHeader tab={tab} onChangeTab={handleChangeTab} />
      <PageProductsTable
        products={products ? products : []}
        loading={isLoading || processing}
        onView={handleClickView}
        onHelp={handleClickHelp}
        onDelete={handleClickDelete}
      />
    </>
  );
};

export default MyProductsPage;
