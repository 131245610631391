import React, { useCallback, useState } from 'react';
import { Container } from '@mui/material';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageNavigation from './purchase-orders-page/PageNavigation';
import PageHeader from './purchase-orders-page/PageHeader';
import PageOrdersView from './purchase-orders-page/PageOrdersView';
import AssignTradeModal from './purchase-orders-page/AssignTradeModal';
import { apiAssignMemberOnTrade, apiGetPurchaseTrades } from 'src/apis/trades';
import { apiGetTeamMembers } from 'src/apis/team';

const PurchaseOrdersPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const pageIndex = searchParams.get('pageIndex')
    ? parseInt(searchParams.get('pageIndex'))
    : 1;
  const pageSize = searchParams.get('pageSize')
    ? parseInt(searchParams.get('pageSize'))
    : 10;
  const filters = {
    ...(searchParams.get('search') && {
      search: searchParams.get('search'),
    }),
    ...(searchParams.get('seller') && {
      seller: searchParams.get('seller'),
    }),
    ...(searchParams.get('incoterms') && {
      incoterms: searchParams.get('incoterms'),
    }),
    ...(searchParams.get('status') && {
      status: searchParams.get('status'),
    }),
    ...(searchParams.get('sortBy') && {
      sortBy: searchParams.get('sortBy'),
    }),
    ...(searchParams.get('sortOrder') && {
      sortOrder: searchParams.get('sortOrder'),
    }),
  };

  const { data, isFetching: isLoading } = useQuery(
    [
      'orders/purchases',
      pageIndex,
      pageSize,
      filters.search ? filters.search : '',
      filters.seller ? filters.seller : '',
      filters.incoterms ? filters.incoterms : '',
      filters.status ? filters.status : '',
      filters.sortBy ? filters.sortBy : '',
      filters.sortOrder ? filters.sortOrder : '',
    ],
    async () => {
      const result = await apiGetPurchaseTrades({
        pageIndex,
        pageSize,
        ...filters,
      });
      if (result) return result;
      throw new Error();
    },
    {
      keepPreviousData: true,
    },
  );
  const { data: members } = useQuery(
    ['profile/team/members'],
    apiGetTeamMembers,
  );

  const [processing, setProcessing] = useState(false);
  const [modals, setModals] = useState({
    assign: {
      id: null,
      open: false,
    },
  });

  const handleChangePagination = useCallback(
    (model) => {
      setSearchParams({
        pageIndex: model.page + 1,
        pageSize: model.pageSize,
        ...filters,
      });
    },
    [setSearchParams],
  );

  const handleChangeSort = useCallback(
    (model) => {
      if (model.length > 0) {
        setSearchParams({
          pageIndex,
          pageSize,
          ...filters,
          sortBy: model[0].field,
          sortOrder: model[0].sort,
        });
      } else {
        delete filters.sortBy;
        delete filters.sortOrder;
        setSearchParams({
          pageIndex,
          pageSize,
          ...filters,
        });
      }
    },
    [setSearchParams],
  );

  const handleChangeSearch = useCallback(
    (value) => {
      if (value) {
        setSearchParams({
          pageIndex,
          pageSize,
          ...filters,
          search: value,
        });
      } else {
        delete filters.search;
        setSearchParams({
          pageIndex,
          pageSize,
          ...filters,
        });
      }
    },
    [setSearchParams],
  );

  const handleChangeFilters = useCallback(
    (values) => {
      const newFilters = { ...filters };
      if (values.incoterms) {
        newFilters.incoterms = values.incoterms;
      } else {
        delete newFilters.incoterms;
      }
      if (values.status) {
        newFilters.status = values.status;
      } else {
        delete newFilters.status;
      }

      setSearchParams({
        pageIndex,
        pageSize,
        ...newFilters,
      });
    },
    [setSearchParams],
  );

  const handleAssignMember = async (values) => {
    setProcessing(true);
    await apiAssignMemberOnTrade(modals.assign.id, values.member);
    handleCloseModal('assign');
    setProcessing(false);
  };

  const onViewAction = useCallback((id) => {
    navigate(`/trades/purchases/${id}`);
  }, []);

  const onAssignAction = useCallback((id) => {
    setModals((values) => ({
      ...values,
      assign: {
        open: true,
        id: id,
      },
    }));
  }, []);

  const handleCloseModal = (name) => {
    setModals((values) => ({
      ...values,
      [name]: {
        ...values[name],
        open: false,
      },
    }));
  };

  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <PageNavigation />
      <PageHeader />
      <PageOrdersView
        pageIndex={pageIndex}
        pageSize={pageSize}
        loading={isLoading}
        data={data?.result ? data.result : []}
        total={data?.count ? data.count : 0}
        filters={filters}
        onChangePagination={handleChangePagination}
        onChangeSort={handleChangeSort}
        onChangeSearch={handleChangeSearch}
        onChangeFilters={handleChangeFilters}
        onViewAction={onViewAction}
        onAssignAction={onAssignAction}
        onHelpAction={() => {}}
      />
      <AssignTradeModal
        open={modals.assign.open}
        members={members ?? []}
        loading={processing}
        onClose={() => handleCloseModal('assign')}
        onSubmit={handleAssignMember}
      />
    </Container>
  );
};

export default PurchaseOrdersPage;
