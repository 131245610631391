import React from 'react';

import PageChangePasswordView from './my-security-settings-page/PageChangePasswordView';
import PageExtraSecurityView from './my-security-settings-page/PageExtraSecurityView';

const MySecuritySettingsPage = () => {
  return (
    <>
      <PageChangePasswordView />
      <PageExtraSecurityView />
    </>
  );
};

export default MySecuritySettingsPage;
