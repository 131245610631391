import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PageTitle = () => {
  const { t } = useTranslation();

  return (
    <Typography fontSize={36} fontWeight={600} colorscheme="heading" mb={2.5}>
      {t('Request Submission')}
    </Typography>
  );
};

export default PageTitle;
