import React from 'react';
import { useSelector } from 'react-redux';

import PageSummary from './my-bank-page/PageSummary';
import PageBankAccountsCard from './my-bank-page/PageBankAccountsCard';
import PageTransactionsCard from './my-bank-page/PageTransactionsCard';

const MyBankPage = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <PageSummary />
      {auth?.role === 'owner' && <PageBankAccountsCard />}
      <PageTransactionsCard />
    </>
  );
};

export default MyBankPage;
