import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GInfoCircleIcon } from '../../../../components/ui/AppIcon';

const PageAlert = () => {
  const { t } = useTranslation();

  return (
    <Box p={2} borderRadius="12px" bgcolor="#E9F1FF">
      <Stack direction="row" spacing={2}>
        <GInfoCircleIcon
          sx={{
            width: 16,
            height: 16,
            color: '#4383FF',
          }}
        />
        <Typography fontSize={16} lineHeight={1.5} color="#4383FF" flex={1}>
          {t(
            'To submit a request to purchase, please fill in the below information and select the quantity that you wish to purchase and click submit.',
          )}
        </Typography>
      </Stack>
    </Box>
  );
};

export default PageAlert;
