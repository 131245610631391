import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useQuery } from 'react-query';
import numeral from 'numeral';

import { apiGetPendingPayments } from 'src/apis/transactions';

const PageSummary = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const { data, isLoading } = useQuery(
    ['transactions', 'pending-amount'],
    async () => {
      return await apiGetPendingPayments();
    },
  );

  return (
    <Box mb={2.5}>
      <Stack direction={isSm ? 'column' : 'row'} spacing={2.5}>
        <Card
          sx={{
            width: isSm ? '100%' : 300,
          }}
        >
          <CardContent
            sx={{
              py: '16px !important',
              px: '24px !important',
            }}
          >
            <Stack justifyContent="center" alignItems="center">
              {isLoading ? (
                <Skeleton height={30} width="100%" variant="rounded" />
              ) : (
                <Typography
                  fontSize={30}
                  fontWeight={600}
                  lineHeight="38px"
                  colorscheme="fonts.text"
                >
                  ${data?.in ? numeral(data.in).format('0.0a') : 0}
                </Typography>
              )}
              <Typography
                fontSize={16}
                fontWeight={500}
                lineHeight="28px"
                colorscheme="gray.700"
                mt={1.5}
              >
                To be paid
              </Typography>
            </Stack>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: isSm ? '100%' : 300,
          }}
        >
          <CardContent
            sx={{
              py: '16px !important',
              px: '24px !important',
            }}
          >
            <Stack justifyContent="center" alignItems="center">
              {isLoading ? (
                <Skeleton height={30} width="100%" variant="rounded" />
              ) : (
                <Typography
                  fontSize={30}
                  fontWeight={600}
                  lineHeight="38px"
                  colorscheme="fonts.text"
                >
                  ${data?.out ? numeral(data.out).format('0.0a') : 0}
                </Typography>
              )}
              <Typography
                fontSize={16}
                fontWeight={500}
                lineHeight="28px"
                colorscheme="gray.700"
                mt={1.5}
              >
                Pending Payout
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
};

export default PageSummary;
