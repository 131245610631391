import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Skeleton,
  Box,
  ButtonBase,
} from '@mui/material';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

import { GCheckIcon } from '../../../../components/ui/AppIcon';
import colors from '../../../../configs/colors';
import constants from 'src/configs/constants';
import { getFileTypeFromUrl, getFilenameFromUrl } from 'src/utils/functions';

import svgImage from 'src/assets/extra/colorful-jpg-icon.svg';
import svgPdf from 'src/assets/extra/colorful-pdf-icon.svg';

const PageProductDetail = ({ data, loading }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {data?.video ? (
        <Box mt={2.5}>
          <ReactPlayer
            url={constants.getStoragePath(data.video)}
            width="100%"
            height={350}
            controls
          />
        </Box>
      ) : null}
      <Card sx={{ mt: 2.5 }}>
        <CardHeader
          title={
            <Typography fontSize={18} fontWeight={600} colorscheme="heading">
              {t('Specifications')}
            </Typography>
          }
          emphasis="true"
        />
        <CardContent sx={{ pb: '16px !important' }}>
          {loading ? (
            <Stack direction="row" alignItems="center" spacing={1.5} py={2}>
              <CircularProgress size={18} />
              <Typography fontSize={16} colorscheme="gray.600">
                {t('Loading')}
              </Typography>
            </Stack>
          ) : data?.specifications?.length > 0 ? (
            data.specifications
              .filter((item) => item.specification && item.value)
              .map((item, index) => (
                <Stack
                  key={`specification-${index}`}
                  direction="row"
                  alignItems="center"
                  mb={index < data.specifications.length - 1 ? 1.2 : 0}
                >
                  <Stack
                    flex={1}
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <GCheckIcon sx={{ color: colors.primary }} />
                    <Typography fontSize={16} colorscheme="heading">
                      {item.specification}
                    </Typography>
                  </Stack>
                  <Typography
                    flex={isSm ? 0 : 1}
                    minWidth={90}
                    fontSize={16}
                    colorscheme="heading"
                    textAlign={isSm ? 'right' : 'left'}
                    pl={2}
                  >
                    {item.value}
                  </Typography>
                </Stack>
              ))
          ) : (
            <Typography py={3} fontSize={14} colorscheme="gray.600">
              {t('No specification')}
            </Typography>
          )}
        </CardContent>
      </Card>
      <Card sx={{ mt: 2.5 }}>
        <CardHeader
          title={
            <Typography fontSize={18} fontWeight={600} colorscheme="heading">
              {t('Details')}
            </Typography>
          }
          emphasis="true"
        />
        <CardContent sx={{ pb: '16px !important' }}>
          {loading ? (
            <Box>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          ) : (
            <Typography
              component="div"
              fontSize={16}
              lineHeight={1.5}
              colorscheme="gray.800"
              dangerouslySetInnerHTML={{
                __html: data?.description,
              }}
            />
          )}
        </CardContent>
      </Card>
      <Card sx={{ mt: 2.5 }}>
        <CardHeader
          title={
            <Typography fontSize={18} fontWeight={600} colorscheme="heading">
              {t('Attachments')}
            </Typography>
          }
          emphasis="true"
        />
        <CardContent sx={{ pb: '16px !important', px: 0 }}>
          {loading ? (
            <Stack direction="row" alignItems="center" spacing={1.5} py={2}>
              <CircularProgress size={18} />
              <Typography fontSize={16} colorscheme="gray.600">
                {t('Loading')}
              </Typography>
            </Stack>
          ) : (
            data?.attachments?.map((item, index) => (
              <ButtonBase
                key={`attach-${index}`}
                sx={{
                  px: 2,
                  py: 0,
                  width: '100%',
                  display: 'block',
                }}
                LinkComponent="a"
                href={constants.getStoragePath(item)}
                target="_blank"
              >
                <Stack direction="row" alignItems="center" spacing={1.2}>
                  <img
                    src={
                      getFileTypeFromUrl(item) === 'image' ? svgImage : svgPdf
                    }
                    alt="file icon"
                    style={{
                      width: 43,
                      height: 55,
                    }}
                  />
                  <Box>
                    <Typography fontSize={16} colorscheme="black">
                      {getFilenameFromUrl(item)}
                    </Typography>
                    <Typography fontSize={12} colorscheme="gray.500">
                      Submitted
                    </Typography>
                  </Box>
                </Stack>
              </ButtonBase>
            ))
          )}
        </CardContent>
      </Card>
    </>
  );
};

PageProductDetail.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
};

export default PageProductDetail;
