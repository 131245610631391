import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  OutlinedInput,
  Button,
  useTheme,
  useMediaQuery,
  styled,
  Menu,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GSearchIcon, GSortDescendingIcon, GFilterIcon } from './AppIcon';
import colors from '../../configs/colors';

const FilterButton = styled(Button)(() => ({
  borderRadius: '6px',
  height: '40px !important',
  width: 94,
  minWidth: 35,
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 500,
  color: '#797979',
  padding: 0,
}));

const SearchInput = styled(OutlinedInput)(() => ({
  paddingRight: 16,
  height: 40,
  width: 250,
  '& .MuiInputBase-input': {
    padding: '8px 16px',
  },
  borderRadius: '6px !important',
  '& > svg': {
    width: 20,
    height: 20,
    color: colors['gray.400'],
  },
}));

const TableFilter = ({
  hideSorter,
  hideFilter,
  hideSearch,
  search: searchFromParent,
  filterDropdown,
  onSearch,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const isShrink = useMediaQuery('(max-width: 1080px)');

  const [anchor, setAnchor] = useState(null);
  const [filterAnchor, setFilterAnchor] = useState(null);

  const [search, setSearch] = useState(
    searchFromParent ? searchFromParent : '',
  );

  const handleOnSearch = useCallback(
    (ev) => {
      if (ev.key === 'Enter') {
        onSearch(search);
      }
    },
    [onSearch, search],
  );

  useEffect(() => {
    setSearch(searchFromParent ? searchFromParent : '');
  }, [searchFromParent]);

  return (
    <Stack direction="row" spacing={1.25}>
      {hideSearch ? null : isShrink ? (
        <>
          <FilterButton
            sx={{
              width: 35,
            }}
            onClick={(ev) => setAnchor(ev.currentTarget)}
          >
            <GSearchIcon />
          </FilterButton>
          <Menu
            open={Boolean(anchor)}
            onClose={() => setAnchor(null)}
            anchorEl={anchor}
          >
            <SearchInput
              placeholder={t('Search')}
              endAdornment={<GSearchIcon />}
            />
          </Menu>
        </>
      ) : (
        <SearchInput
          placeholder={t('Search')}
          endAdornment={<GSearchIcon />}
          value={search}
          onChange={(ev) => setSearch(ev.currentTarget.value)}
          onKeyDown={handleOnSearch}
        />
      )}
      {!hideSorter && (
        <FilterButton
          sx={{
            width: isSm ? 35 : 94,
          }}
        >
          <Stack direction="row" alignItems="center" spacing="6px">
            {!isSm && <span>{t('Sort by')}</span>}
            <GSortDescendingIcon
              sx={{
                width: 20,
                height: 20,
              }}
            />
          </Stack>
        </FilterButton>
      )}
      {!hideFilter && (
        <>
          <FilterButton
            sx={{
              width: isSm ? 35 : 94,
            }}
            onClick={(ev) => setFilterAnchor(ev.currentTarget)}
          >
            <Stack direction="row" alignItems="center" spacing="6px">
              {!isSm && <span>{t('Filters')}</span>}
              <GFilterIcon
                sx={{
                  width: 20,
                  height: 20,
                }}
              />
            </Stack>
          </FilterButton>
          {filterDropdown && (
            <Menu
              anchorEl={filterAnchor}
              open={Boolean(filterAnchor)}
              onClose={() => setFilterAnchor(null)}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              sx={{
                '& .MuiPaper-root': {
                  maxWidth: 312,
                },
              }}
            >
              {filterDropdown}
            </Menu>
          )}
        </>
      )}
    </Stack>
  );
};

TableFilter.propTypes = {
  hideSorter: PropTypes.bool,
  hideFilter: PropTypes.bool,
  hideSearch: PropTypes.bool,
  filterDropdown: PropTypes.element,
  search: PropTypes.string,
  onSearch: PropTypes.func,
};

export default TableFilter;
