import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import CategoryItem from 'src/components/items/CategoryItem';
import constants from 'src/configs/constants';

const PageCategoriesView = (props) => {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        pb: 9,
      }}
    >
      <Box>
        <Grid container rowSpacing={2.5} columnSpacing={2.5}>
          {props.loading
            ? [1, 2, 3, 4, 5, 6].map((item, index) => (
                <Grid item xs={12} md={4} key={`cat-${index}-item`}>
                  <CategoryItem loading subtitle={'...'} drawShadow />
                </Grid>
              ))
            : props.categories.map((category, index) => (
                <Grid item xs={12} md={4} key={`cat-${index}-item`}>
                  <CategoryItem
                    image={constants.getStoragePath(category.image)}
                    title={category.name}
                    subtitle={category.description}
                    drawShadow
                    onClick={() => navigate('/marketplace/' + category.name)}
                  />
                </Grid>
              ))}
        </Grid>
      </Box>
    </Container>
  );
};

PageCategoriesView.propTypes = {
  loading: PropTypes.bool,
  categories: PropTypes.array,
};

export default PageCategoriesView;
