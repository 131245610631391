import React from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import PageHeader from './marketplace-page/PageHeader';
import PageLeading from './marketplace-page/PageLeading';
import PageCategoriesView from './marketplace-page/PageCategoriesView';

import { apiGetCategories } from 'src/apis/categories';

const MarketplacePage = () => {
  const [params] = useSearchParams();
  const search = params.get('search') ? params.get('search') : '';

  const { data: categories, isLoading } = useQuery(
    ['categories', search],
    async () => {
      const result = await apiGetCategories();
      if (result) {
        if (search) {
          return result.filter((item) =>
            item.name.toString().toLowerCase().includes(search.toLowerCase()),
          );
        }
        return result;
      }
      return [];
    },
  );

  return (
    <>
      <PageHeader />
      {search ? null : <PageLeading />}
      <PageCategoriesView
        categories={categories ? categories : []}
        loading={isLoading}
      />
    </>
  );
};

export default MarketplacePage;
