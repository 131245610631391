import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';

import {
  GArrowRightIcon,
  GFileIcon,
  GTrashIcon,
  GUploadIcon,
} from '../../../components/ui/AppIcon';
import constants from '../../../configs/constants';
import colors from 'src/configs/colors';

import { apiUploadFiles } from 'src/apis/upload';

const VideoEditor = ({ video, onChange, error }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'video/*': [],
    },
  });
  const [upload, setUpload] = useState(null);

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setUpload(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (upload) {
      const formData = new FormData();
      formData.append('files', upload);
      formData.append('key', 'products/videos');

      apiUploadFiles(formData).then((result) => {
        if (result) {
          onChange(result);
        }
        setUpload(null);
      });
    }
  }, [upload]);

  return (
    <Box>
      <Box
        {...(upload ? {} : getRootProps({ className: 'dropzone' }))}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '12px',
          border: '2px dashed #E5E7EB',
          cursor: 'pointer',
          p: 3,
          bgcolor: '#FAFAFA',
          ...(error && {
            border: `2px dashed ${colors['status.error']}`,
          }),
        }}
      >
        {upload ? null : <input {...getInputProps()} />}
        <GUploadIcon
          sx={{
            width: 32,
            height: 32,
            color: '#D3D6DA',
          }}
        />
        <Typography
          mt={1}
          fontSize={12}
          lineHeight="18px"
          color="#A0A8B0"
          display="inline"
        >
          Drag and drop video or click to browse and upload
        </Typography>
      </Box>
      <Stack direction="row" mt={2} spacing={2} useFlexGap flexWrap="wrap">
        {video || upload ? (
          <Stack
            sx={{
              borderRadius: '12px',
              border: '2px solid ' + constants.colors.gray[300],
              p: 1,
              position: 'relative',
              boxSizing: 'border-box',
              borderColor: colors.primary,
            }}
            direction="row"
            alignItems="center"
          >
            <GFileIcon />
            <Typography ml={1} mr={1}>
              Product Video
            </Typography>
            <Stack direction="row" alignItems="center" spacing={0}>
              <IconButton
                disabled={!!upload}
                onClick={() =>
                  window.open(constants.getStoragePath(video), '_blank')
                }
              >
                {upload ? <CircularProgress size={20} /> : <GArrowRightIcon />}
              </IconButton>
              <IconButton disabled={!!upload} onClick={() => onChange('')}>
                <GTrashIcon />
              </IconButton>
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};

VideoEditor.propTypes = {
  video: PropTypes.any,
  error: PropTypes.any,
  onChange: PropTypes.func,
};

export default VideoEditor;
