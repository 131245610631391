import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { MemberList } from '@pubnub/react-chat-components';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import {
  GSearchIcon,
  GVuesaxSearchIcon,
} from '../../../../components/ui/AppIcon';
import ChatMemberItem from 'src/components/items/ChatMemberItem';
import constants from '../../../../configs/constants';
import colors from 'src/configs/colors';
import { apiGetAvailableMembers } from 'src/apis/messaging';
import { setUsers } from 'src/redux/messaging/slice';

const PageUsersView = ({ fullWidth }) => {
  const dispatch = useDispatch();

  const { data: members, isLoading } = useQuery(
    ['messaging', 'members'],
    async () => {
      const members = await apiGetAvailableMembers();
      return members.map((item) => ({
        id: item.id,
        name: item.name ? item.name : 'Unknown',
        profileUrl: item.photo
          ? constants.getStoragePath(item.photo)
          : undefined,
        custom: {
          title: item.role,
          companyId: item.companyId,
        },
      }));
    },
  );

  const [search, setSearch] = useState('');

  const handleChangeSearch = (ev) => {
    setSearch(ev.target.value);
  };

  useEffect(() => {
    if (members) {
      dispatch(setUsers(members));
    }
  }, [members]);

  return (
    <Card
      sx={{
        width: '386px',
        height: '78vh',
        mr: 2.5,
        ...(fullWidth && {
          boxShadow: 'none',
          width: 386,
          height: '100%',
        }),
      }}
    >
      <CardContent
        sx={{
          p: '0 !important',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Box p={2}>
          <OutlinedInput
            value={search}
            onChange={handleChangeSearch}
            fullWidth
            startAdornment={
              <GSearchIcon
                sx={{
                  width: 16,
                  height: 16,
                  color: '#8C979A',
                }}
              />
            }
            placeholder="Search"
            sx={{
              border: `1px solid ${constants.colors.gray[200]}`,
              bgcolor: constants.colors.gray[100],
              height: 40,
            }}
          />
        </Box>
        <Scrollbars flex={1} mt={0.5}>
          {members?.length > 0 ? (
            <MemberList
              members={
                search
                  ? members.filter((item) =>
                      item.name.toLowerCase().includes(search.toLowerCase()),
                    )
                  : members
              }
              memberRenderer={(member) => (
                <ChatMemberItem
                  key={`member-${member.id}`}
                  id={member.id}
                  name={member.name}
                  profileUrl={member.profileUrl}
                  custom={member.custom}
                />
              )}
            />
          ) : (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isLoading ? (
                <>
                  <CircularProgress size={24} />
                  <Typography mt={2}>Loading</Typography>
                </>
              ) : (
                <>
                  <GVuesaxSearchIcon
                    sx={{
                      color: colors['gray.400'],
                    }}
                  />
                  <Typography colorscheme="gray.400" mt={2}>
                    No available member found
                  </Typography>
                </>
              )}
            </Box>
          )}
        </Scrollbars>
      </CardContent>
    </Card>
  );
};

PageUsersView.propTypes = {
  users: PropTypes.array,
  fullWidth: PropTypes.bool,
  selectedUser: PropTypes.number,
  onSelectUser: PropTypes.func,
};

export default PageUsersView;
