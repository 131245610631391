import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useQuery } from 'react-query';

import PageHeader from './my-account-page/PageHeader';
import PageStaticPersonalDetails from './my-account-page/PageStaticPersonalDetails';
import PageStaticBusinessDetails from './my-account-page/PageStaticBusinessDetails';
import PageEditPersonalDetails from './my-account-page/PageEditPersonalDetails';
import PageEditBusinessDetails from './my-account-page/PageEditBusinessDetails';

import { apiGetProfile, apiPutProfile } from 'src/apis/profile';
import notify from 'src/configs/notify';

const MyAccountPage = () => {
  const {
    data: profile,
    isLoading,
    refetch,
  } = useQuery(['profile'], apiGetProfile);

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const personalDetailsRef = useRef();
  const businessDetailsRef = useRef();

  useEffect(() => {
    setIsEditing(false);
  }, []);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleUpdate = async () => {
    try {
      const data = {
        personal: {},
        business: {},
      };

      if (personalDetailsRef.current) {
        await personalDetailsRef.current.submitForm((values) => {
          data.personal = values;
        })();
      }
      if (businessDetailsRef.current) {
        await businessDetailsRef.current.submitForm((values) => {
          data.business = values;
        })();
      }

      if (data.personal && data.business) {
        const params = {
          ...data.personal,
          company: {
            ...data.business,
          },
        };

        setIsSaving(true);
        apiPutProfile(params)
          .then((result) => {
            if (result) {
              notify({
                message: 'You have updated your profile data successfully',
                type: 'success',
              });
              refetch();
              return;
            }
          })
          .finally(() => {
            setIsSaving(false);
            setIsEditing(false);
          });
      }
    } catch {
      return;
    }
  };

  return (
    <Box>
      <PageHeader
        isEditing={isEditing}
        isSaving={isSaving}
        isLoading={isLoading}
        profile={profile?.user}
        onEdit={handleEdit}
        onCancel={handleCancel}
        onUpdate={handleUpdate}
      />
      {isEditing ? (
        <>
          <PageEditPersonalDetails
            reference={personalDetailsRef}
            profile={profile?.user}
          />
          {profile?.user?.role === 'owner' && (
            <PageEditBusinessDetails
              reference={businessDetailsRef}
              profile={profile?.company}
            />
          )}
        </>
      ) : (
        <>
          <PageStaticPersonalDetails
            loading={isLoading}
            profile={profile?.user}
          />
          <PageStaticBusinessDetails
            loading={isLoading}
            profile={profile?.company}
          />
        </>
      )}
    </Box>
  );
};

export default MyAccountPage;
