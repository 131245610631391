import axios from 'axios';
import { t } from 'i18next';
import notify from 'src/configs/notify';

export const apiUploadFiles = async (data) => {
  try {
    const response = await axios.post('/api/v1/upload', data);
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    throw e;
  }
};

export const apiV2UploadFiles = async (data) => {
  try {
    const response = await axios.post('/api/v2/upload', data);
    return response.data.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    throw e;
  }
};
