import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PageTabs = ({ tab, onChangeTab }) => {
  const { t } = useTranslation();

  const handleChangeTab = useCallback(
    (ev, newValue) => {
      onChangeTab(newValue);
    },
    [onChangeTab],
  );

  return (
    <Box mt={2}>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab
          label={t('Trade Information')}
          id="tab-trade-information"
          value="summary"
        />
        <Tab
          label={t('Documentation')}
          id="tab-documentation"
          value="documentations"
        />
        <Tab label={t('Messages')} id="tab-messages" value="messages" />
        <Tab
          label={t('Audit Trail')}
          id="tab-audit-train"
          value="audit-trail"
        />
      </Tabs>
    </Box>
  );
};

PageTabs.propTypes = {
  tab: PropTypes.string,
  onChangeTab: PropTypes.func,
};

export default PageTabs;
