import React, { useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Box,
  Autocomplete,
  Avatar,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import CountrySelect from '../../../components/ui/CountrySelect';
import PhoneNumberInput from 'src/components/ui/PhoneNumberInput';
import ImageCropModal from './ImageCropModal';
import {
  GCalendarIcon,
  GCloudUploadIcon,
  GPhotoIcon,
} from '../../../components/ui/AppIcon';
import constants from 'src/configs/constants';

import { apiGetCities, apiGetStates } from 'src/apis/locations';
import { apiUploadFiles } from 'src/apis/upload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    dob: yup.object().required(),
    phone: yup.string().required(),
    address: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().required(),
    city: yup.string(),
    zipCode: yup.string().required(),
    photo: yup.string(),
  })
  .required();

const PageEditPersonalDetails = ({ reference, profile }) => {
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      dob: moment('1990-11-25'),
      phone: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      photo: '',
    },
    resolver: yupResolver(schema),
  });

  const [country, state, photo, city] = watch([
    'country',
    'state',
    'photo',
    'city',
  ]);

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [rawImage, setRawImage] = useState('');

  const handleSelectAvatar = (ev) => {
    if (ev.target.files.length < 1) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setRawImage(reader.result?.toString() || '');
    });
    reader.readAsDataURL(ev.target.files[0]);
  };

  const handleUploadAvatar = (file) => {
    setUploading(true);

    const formData = new FormData();
    formData.append('files', file);
    formData.append('key', 'avatars');
    apiUploadFiles(formData)
      .then((result) => {
        if (result) {
          setValue('photo', result);
        }
      })
      .finally(() => {
        setUploading(false);
      });
  };

  useImperativeHandle(reference, () => ({
    submitForm: (onValid, onInvalid) => {
      return handleSubmit(onValid, onInvalid);
    },
  }));

  useEffect(() => {
    if (profile) {
      setValue('firstName', profile.firstName);
      setValue('lastName', profile.lastName);
      setValue('dob', moment(profile.dob ? profile.dob : '1960-01-01'));
      setValue('address', profile.address);
      setValue('phone', profile.phone);
      setValue('country', profile.country);
      setValue('state', profile.state);
      setValue('city', profile.city);
      setValue('address', profile.address);
      setValue('zipCode', profile.zipCode);
      setValue('photo', profile.photo ? profile.photo : '');
    }
  }, [profile]);

  useEffect(() => {
    apiGetStates(country).then((result) => {
      setStates(result);
    });
  }, [country]);

  useEffect(() => {
    apiGetCities(country, state).then((result) => {
      setCities(result);
    });
  }, [country, state]);

  useEffect(() => {
    if (typeof city !== 'string') {
      setValue('city', '');
    }
  }, [city]);

  return (
    <Card
      sx={{
        mt: 2.5,
      }}
    >
      <CardHeader
        title={
          <Typography
            fontSize={18}
            fontWeight={500}
            lineHeight="28px"
            colorscheme="fonts.heading"
          >
            Personal Details
          </Typography>
        }
        emphasis="true"
      />
      <CardContent
        sx={{
          pb: '16px !important',
          '& .MuiFormLabel-root': {
            fontWeight: 500,
            fontSize: 14,
          },
        }}
      >
        <Grid container columnSpacing={5} rowSpacing={2.5}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>First Name</InputLabel>
                  <OutlinedInput
                    placeholder="Enter your first name"
                    {...field}
                  />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>Last Name</InputLabel>
                  <OutlinedInput
                    placeholder="Enter your last name"
                    {...field}
                  />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="dob"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>DOB</InputLabel>
                  <DatePicker
                    placeholder="Choose"
                    slots={{
                      openPickerIcon: GCalendarIcon,
                    }}
                    {...field}
                  />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="phone"
              render={({
                field: { ref, ...fieldProps },
                fieldState: { error },
              }) => (
                <Box>
                  <InputLabel required shrink>
                    Enter Number
                  </InputLabel>
                  <PhoneNumberInput innerRef={ref} {...fieldProps} />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="address"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>Address</InputLabel>
                  <OutlinedInput placeholder="Address" {...field} />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="country"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error?.message}>
                  <InputLabel sx={{ fontWeight: 600 }}>Country</InputLabel>
                  <CountrySelect
                    {...field}
                    showCountryName
                    fullWidth
                    sx={{}}
                    placeholder="Select Country"
                  />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="state"
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                  <InputLabel required>State</InputLabel>
                  <Autocomplete
                    options={states}
                    {...field}
                    onChange={(ev, value) => field.onChange(value)}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      option?.toLowerCase() === value?.toLowerCase()
                    }
                    renderInput={(params) => (
                      <OutlinedInput
                        {...params.InputProps}
                        inputProps={params.inputProps}
                        fullWidth
                        placeholder="Select state"
                      />
                    )}
                    sx={{
                      display: 'block',
                    }}
                  />
                  {error && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="city"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  error={!!error}
                  disabled={cities.length < 1}
                >
                  <InputLabel>City</InputLabel>
                  <Autocomplete
                    options={cities}
                    {...field}
                    onChange={(ev, value) => field.onChange(value)}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      option.toLowerCase() === value.toLowerCase()
                    }
                    renderInput={(params) => (
                      <OutlinedInput
                        {...params.InputProps}
                        inputProps={params.inputProps}
                        fullWidth
                        placeholder="Select city"
                      />
                    )}
                    sx={{
                      display: 'block',
                    }}
                  />
                  {error && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="zipCode"
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!error?.message}
                >
                  <InputLabel sx={{ fontWeight: 600 }}>ZIP Code</InputLabel>
                  <OutlinedInput placeholder="ZIP Code" {...field} />
                  {error?.message && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel sx={{ fontWeight: 600 }}>
                Email Address (cannot be changed)
              </InputLabel>
              <OutlinedInput value={profile?.email} readOnly />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: 600 }}>Logo</InputLabel>
            <Box mt={1.5}>
              <Avatar
                src={constants.getStoragePath(photo)}
                sx={{
                  width: 140,
                  height: 140,
                }}
                variant="rounded"
              >
                <GPhotoIcon sx={{}} />
              </Avatar>
              <Box mt={1.5}>
                <LoadingButton
                  startIcon={<GCloudUploadIcon />}
                  size="small"
                  variant="contained"
                  component="label"
                  loading={uploading}
                >
                  Upload Avatar
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleSelectAvatar}
                    hidden={uploading}
                  />
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <ImageCropModal
          image={rawImage}
          open={!!rawImage}
          onCrop={handleUploadAvatar}
          onClose={() => setRawImage('')}
        />
      </CardContent>
    </Card>
  );
};

PageEditPersonalDetails.propTypes = {
  reference: PropTypes.any,
  profile: PropTypes.any,
};

export default PageEditPersonalDetails;
