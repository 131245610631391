import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';

import {
  GArrowRightIcon,
  GCloseIcon,
  GFileIcon,
  GUploadIcon,
} from '../../../components/ui/AppIcon';
import constants from '../../../configs/constants';
import colors from 'src/configs/colors';
import { apiUploadFiles } from 'src/apis/upload';

const AttachmentsEditor = ({ attachments, onChange, error }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    multiple: true,
    accept: {
      'image/*': [],
      'application/pdf': ['.pdf'],
    },
  });
  const [uploads, setUploads] = useState([]);

  const handleRemove = (index) => {
    if (index > attachments?.length - 1) {
      const newUploads = [...uploads];
      newUploads.splice(index - attachments.length, 1);
      setUploads(newUploads);
    } else {
      const newPhotos = [...attachments];
      newPhotos.splice(index, 1);
      onChange(newPhotos);
    }
  };

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setUploads((rows) => {
        const values = [...rows];
        for (const file of acceptedFiles) {
          values.push({
            file: file,
            status: 'init',
          });
        }
        return values;
      });
    }
  }, [acceptedFiles]);

  useEffect(() => {
    const promises = [];

    for (let i = 0; i < uploads.length; i++) {
      if (uploads[i].status !== 'init') {
        continue;
      }

      const formData = new FormData();
      formData.append('files', uploads[i].file);
      formData.append('key', 'products/attachments');

      setUploads((rows) => {
        const newRows = [...rows];
        newRows[i].status = 'uploading';
        return newRows;
      });
      promises.push(apiUploadFiles(formData));

      Promise.all(promises).then((results) => {
        onChange([...attachments, ...results]);
        setUploads([]);
      });
    }
  }, [uploads]);

  return (
    <Box>
      <Box
        {...(uploads.length < 1 ? getRootProps({ className: 'dropzone' }) : {})}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '12px',
          border: '2px dashed #E5E7EB',
          cursor: 'pointer',
          p: 3,
          bgcolor: '#FAFAFA',
          ...(error && {
            border: `2px dashed ${colors['status.error']}`,
          }),
        }}
      >
        {uploads.length < 1 && <input {...getInputProps()} />}
        <GUploadIcon
          sx={{
            width: 32,
            height: 32,
            color: '#D3D6DA',
          }}
        />
        <Typography mt={1} fontSize={12} lineHeight="18px" color="#A0A8B0">
          Drag and drop attachments or click to browse and upload
        </Typography>
      </Box>
      <Stack direction="row" mt={2} spacing={2} useFlexGap flexWrap="wrap">
        {[...(attachments ? attachments : []), ...uploads].map((row, index) => (
          <Stack
            key={`attachment-${index}`}
            sx={{
              borderRadius: '12px',
              border: '2px solid ' + constants.colors.gray[300],
              p: 1,
              position: 'relative',
              boxSizing: 'border-box',
              borderColor: colors.primary,
            }}
            direction="row"
            alignItems="center"
          >
            <GFileIcon />
            <Typography ml={1} mr={1}>
              Attachment File {index + 1}
            </Typography>
            <IconButton
              disabled={typeof row !== 'string'}
              onClick={() =>
                window.open(constants.getStoragePath(row), '_blank')
              }
            >
              {typeof row !== 'string' ? (
                <CircularProgress size={20} />
              ) : (
                <GArrowRightIcon />
              )}
            </IconButton>
            <IconButton
              disabled={typeof row !== 'string'}
              onClick={() => handleRemove(index)}
            >
              <GCloseIcon />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

AttachmentsEditor.propTypes = {
  attachments: PropTypes.any,
  error: PropTypes.any,
  onChange: PropTypes.func,
};

export default AttachmentsEditor;
