import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import TicketStatusChip from '../../../../components/chips/TicketStatusChip';
import { GCheckIcon, GUserIcon } from '../../../../components/ui/AppIcon';

const PageTicketSummary = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent
        sx={{
          p: '16px !important',
        }}
      >
        <Button
          variant="contained"
          fullWidth
          startIcon={<GCheckIcon />}
          sx={{
            height: 50,
          }}
        >
          {t('Mark as Resolved')}
        </Button>
        <Divider
          sx={{
            my: 2,
          }}
        />
        <Typography
          display="block"
          fontSize={16}
          fontWeight={600}
          lineHeight={1.5}
          colorscheme="#212121"
        >
          Want to create an account in your system, please help!
        </Typography>
        <Typography display="block" fontSize={14} lineHeight={1.5} mt={1.5}>
          Diam, in vitae ante velit suscipit scelerisque commodo urna at. Arcu
          quam erat ac volutpat sed nunc faucibus id. Tempor adipiscing
          ultricies odio ipsum sed quis amet, mauris. Diam, in vitae ante.
        </Typography>
        <Divider
          sx={{
            my: 2,
          }}
        />
        <Typography
          display="block"
          fontSize={16}
          fontWeight={600}
          lineHeight={1.5}
          colorscheme="#212121"
        >
          {t('Ticket details')}
        </Typography>
        <Stack mt={1} spacing={1.5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={14} lineHeight={1.7}>
              {t('Ticket ID')}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              lineHeight={1.7}
              colorscheme="fonts.heading"
            >
              #232323
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={14} lineHeight={1.7}>
              {t('Status')}
            </Typography>
            <TicketStatusChip
              status="open"
              sx={{
                height: 24,
              }}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={14} lineHeight={1.7}>
              {t('Req Date')}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              lineHeight={1.7}
              colorscheme="fonts.heading"
            >
              12/06/2023
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={14} lineHeight={1.7}>
              {t('Req Date')}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              lineHeight={1.7}
              colorscheme="fonts.heading"
            >
              General
            </Typography>
          </Stack>
        </Stack>
        <Divider
          sx={{
            my: 2,
          }}
        />
        <Typography
          display="block"
          fontSize={16}
          fontWeight={600}
          lineHeight={1.5}
          colorscheme="#212121"
        >
          {t('Agent Detail')}
        </Typography>
        <Stack mt={1.5} direction="row" alignItems="center" spacing="6px">
          <Avatar
            sx={{
              width: 30,
              height: 30,
            }}
          >
            <GUserIcon />
          </Avatar>
          <Typography fontSize={14}>Courtney Henry</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PageTicketSummary;
