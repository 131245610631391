import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Stack, Typography } from '@mui/material';
import { Chat, MessageInput, MessageList } from '@pubnub/react-chat-components';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import moment from 'moment';

import constants from 'src/configs/constants';
import { apiGetAvailableMembers } from 'src/apis/messaging';
import { setUsers } from 'src/redux/messaging/slice';

export default function PageTradeChattingTab({ active, data }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.messaging.users);

  const { data: members } = useQuery(['messaging', 'members'], async () => {
    const members = await apiGetAvailableMembers();
    return members.map((item) => ({
      id: item.id,
      name: item.name ? item.name : 'Unknown',
      profileUrl: item.photo ? constants.getStoragePath(item.photo) : undefined,
      custom: {
        title: item.role,
        companyId: item.companyId,
      },
    }));
  });

  useEffect(() => {
    if (members) {
      dispatch(setUsers(members));
    }
  }, [members]);

  return (
    <Box display={active ? 'block' : 'none'}>
      <Card>
        {data ? (
          <Chat currentChannel={`trade-chat-${data._id}`} users={users}>
            <Card
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '78vh',
              }}
            >
              <>
                <Stack
                  px={3}
                  py={2.5}
                  direction="row"
                  alignItems="center"
                  borderBottom="1px solid #DFDFDF"
                >
                  <Stack direction="row" flex={1}>
                    <Stack>
                      <Typography
                        fontSize={20}
                        fontWeight={600}
                        lineHeight="30px"
                        colorscheme="gray.900"
                      >
                        Trade Discussion
                      </Typography>
                      <Typography
                        mt={1}
                        fontSize={14}
                        fontWeight={500}
                        lineHeight="22px"
                        color="#93989A"
                      >
                        Created: {moment(data.createdAt).format('YYYY-MM-DD')}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <MessageList fetchMessages={25} enableReactions />
                <Box px={0} py={2} borderTop="1px solid #DFDFDF">
                  <MessageInput typingIndicator placeholder="Write Message" />
                </Box>
              </>
            </Card>
          </Chat>
        ) : null}
      </Card>
    </Box>
  );
}

PageTradeChattingTab.propTypes = {
  active: PropTypes.bool,
  data: PropTypes.any,
};
