import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Typography,
  CardContent,
  styled,
  Stack,
  Grid,
} from '@mui/material';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import mime from 'mime';
import moment from 'moment';

import AuditTrailTimeline from 'src/components/timelines/AuditTrailTimeline';
import Center from 'src/components/ui/Center';
import {
  GDollarIcon,
  GDotsIcon,
  GFileTextIcon,
  GLinkIcon,
} from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';
import { getFilenameFromUrl } from 'src/utils/functions';

import svgPdfFile from 'src/assets/extra/colorful-pdf-icon.svg';
import svgImageFile from 'src/assets/extra/colorful-jpg-icon.svg';
import svgVideoFile from 'src/assets/extra/colorful-media-icon.svg';

const TimelineTitle = styled(Typography)(() => ({
  color: colors.heading,
  fontWeight: 500,
}));

const DatetimeLabel = styled(Typography)(() => ({
  fontSize: 14,
  color: colors['gray.400'],
}));

const CaptionText = styled(Typography)(() => ({
  fontSize: 14,
  color: colors['gray.400'],
}));

const ValueText = styled(Typography)(() => ({
  fontSize: 14,
  color: colors.heading,
}));

const FileIcon = ({ path }) => {
  const [type, setType] = useState();

  useEffect(() => {
    if (path) {
      const result = mime.getType(path);
      if (result.includes('image/')) {
        setType('image');
      } else if (result.includes('application/pdf')) {
        setType('pdf');
      } else {
        setType('video');
      }
    }
  }, [path]);

  if (!type) {
    return null;
  }

  return (
    <img
      src={
        type === 'image'
          ? svgImageFile
          : type === 'video'
            ? svgVideoFile
            : svgPdfFile
      }
      width={43}
      height={55}
    />
  );
};

FileIcon.propTypes = {
  path: PropTypes.string,
};

export default function PageAuditTrailTab({ active, data }) {
  return (
    <Box display={active ? 'block' : 'none'}>
      <Card sx={{ mb: 2.5 }}>
        <CardHeader
          title={
            <Typography fontSize={18} fontWeight={600} colorscheme="heading">
              Audit Trail Logs
            </Typography>
          }
          emphasis="true"
        />
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          {data?.auditTrails?.length > 0 ? (
            <AuditTrailTimeline position="right">
              {data?.auditTrails?.map((row, index) => (
                <TimelineItem key={`trail-${index}`}>
                  <TimelineSeparator>
                    <TimelineDot>
                      {row.eventName === 'Smart Contract Deployed' ? (
                        <GLinkIcon />
                      ) : row.eventName === 'Document Submitted' ||
                        row.eventName === 'Document Updated' ? (
                        <GFileTextIcon />
                      ) : row.eventName === 'Invoice Submitted' ? (
                        <GFileTextIcon />
                      ) : row.eventName === 'Payment Made' ? (
                        <GDollarIcon />
                      ) : (
                        <GDotsIcon />
                      )}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <TimelineTitle>{row.eventName}</TimelineTitle>
                    <DatetimeLabel>
                      {moment(row.createdAt).format('DD MMM YYYY, hh:mm A')}
                    </DatetimeLabel>
                    <Stack mt={2} spacing={1.5}>
                      {row.buyerHash && (
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={24} md={3}>
                              <CaptionText>Buyer Hash</CaptionText>
                            </Grid>
                            <Grid item xs={24} md={9}>
                              <ValueText>0x{row.buyerHash}</ValueText>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {row.sellerHash && (
                        <>
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={24} md={3}>
                                <CaptionText>Seller Hash</CaptionText>
                              </Grid>
                              <Grid item xs={24} md={9}>
                                <ValueText>0x{row.sellerHash}</ValueText>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item xs={24} md={3}>
                                <CaptionText>Trade Number</CaptionText>
                              </Grid>
                              <Grid item xs={24} md={9}>
                                <ValueText>
                                  {data?._id?.toUpperCase()}
                                </ValueText>
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      )}
                      {row.contractHash && (
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={24} md={3}>
                              <CaptionText>Smart Contract Address</CaptionText>
                            </Grid>
                            <Grid item xs={24} md={9}>
                              <ValueText>{row.contractHash}</ValueText>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {row.documentType && (
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={24} md={3}>
                              <CaptionText>Type</CaptionText>
                            </Grid>
                            <Grid item xs={24} md={9}>
                              <ValueText>{row.documentType}</ValueText>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {row.documentName && (
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={24} md={3}>
                              <CaptionText>Name</CaptionText>
                            </Grid>
                            <Grid item xs={24} md={9}>
                              <ValueText>{row.documentName}</ValueText>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {row.documentVersion && (
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={24} md={3}>
                              <CaptionText>Version</CaptionText>
                            </Grid>
                            <Grid item xs={24} md={9}>
                              <ValueText>{row.documentVersion}</ValueText>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {row.eventDetails && (
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={24} md={3}>
                              <CaptionText>Details</CaptionText>
                            </Grid>
                            <Grid item xs={24} md={9}>
                              <ValueText>{row.eventDetails}</ValueText>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {row.documentHash && (
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={24} md={3}>
                              <CaptionText>Document Hash</CaptionText>
                            </Grid>
                            <Grid item xs={24} md={9}>
                              <ValueText>0x{row.documentHash}</ValueText>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {row.transactionHash && (
                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xs={24} md={3}>
                              <CaptionText>Blockchain Transaction</CaptionText>
                            </Grid>
                            <Grid item xs={24} md={9}>
                              <ValueText>{row.transactionHash}</ValueText>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {row.documentUrl && row.eventName !== 'Payment Made' && (
                        <Box>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <FileIcon path={row.documentUrl} />
                            <ValueText>
                              {getFilenameFromUrl(row.documentUrl)}
                            </ValueText>
                          </Stack>
                        </Box>
                      )}
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </AuditTrailTimeline>
          ) : (
            <Center>
              <Typography py={5} fontSize={14} colorscheme="gray.400">
                No data found
              </Typography>
            </Center>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

PageAuditTrailTab.propTypes = {
  active: PropTypes.bool,
  data: PropTypes.any,
};
