import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import clone from 'clone';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from 'src/components/ui/AppIcon';
import { apiUploadFiles } from 'src/apis/upload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileInput = ({ onChange }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const handleChange = (ev) => {
    if (ev.target.files.length > 0) {
      const file = ev.target.files[0];
      setLoading(true);
      const formData = new FormData();
      formData.append('files', file);
      formData.append('key', 'trades/manufacturing-documents');

      apiUploadFiles(formData)
        .then((result) => {
          setValue(file.name);
          onChange(result);
        })
        .catch(() => {
          setValue('');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <OutlinedInput
      value={value}
      readOnly
      endAdornment={
        <InputAdornment position="end">
          <LoadingButton
            variant="contained"
            size="small"
            component="label"
            loading={loading}
          >
            Upload
            <VisuallyHiddenInput type="file" onChange={handleChange} />
          </LoadingButton>
        </InputAdornment>
      }
    />
  );
};

FileInput.propTypes = {
  path: PropTypes.any,
  onChange: PropTypes.func,
};

const CompleteManufacturingModal = ({
  open,
  processing,
  onClose,
  onFinish,
}) => {
  const [files, setFiles] = useState([
    { name: 'Proof of Product', path: null },
    { name: 'Quality Report', path: null },
    { name: 'Certificate of Origin', path: null },
    { name: 'Export Permit', path: null },
  ]);
  const [disabled, setDisabled] = useState(false);

  const handleChangeFiles = (index, field, value) => {
    setFiles((files) => {
      const newFiles = clone(files);
      newFiles[index][field] = value;
      return newFiles;
    });
  };

  const handleAdd = () => {
    for (const file of files) {
      if (!file.name) {
        return;
      }
    }

    const newFiles = clone(files);
    newFiles.push({
      name: '',
      path: null,
    });
    setFiles(newFiles);
  };

  const handleRemove = (index) => {
    const newFiles = clone(files);
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleSubmit = () => {
    onFinish(
      files
        .filter((item) => item.name && item.path)
        .map((item) => ({
          name: item.name,
          document: item.path,
        })),
    );
  };

  useEffect(() => {
    let filesCount = 0;
    for (const row of files) {
      if (!row.name) {
        return setDisabled(true);
      }
      if (row.path) {
        filesCount++;
      }
    }
    if (filesCount < 1) {
      return setDisabled(true);
    }
    setDisabled(false);
  }, [files]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 781,
          maxWidth: '100vw',
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          color="#303030"
          fontSize={30}
          lineHeight="38px"
          fontWeight={600}
        >
          Provide Manufacturing Documents
        </HighlightedTypography>
        {files.map((row, index) => (
          <Box key={`doc-${index}`} mt={index === 0 ? 3 : 0} mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ height: '100%' }}>
                {index <= 3 ? (
                  <Typography
                    lineHeight="51px"
                    fontSize={16}
                    fontWeight={500}
                    colorscheme="secondary.500"
                  >
                    {row.name}
                  </Typography>
                ) : (
                  <OutlinedInput
                    fullWidth
                    placeholder="Document name"
                    value={row.name}
                    onChange={(ev) =>
                      handleChangeFiles(index, 'name', ev.target.value)
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row" alignItems="center">
                  <FileInput
                    onChange={(value) =>
                      handleChangeFiles(index, 'path', value)
                    }
                  />
                  {index >= 4 && (
                    <IconButton
                      sx={{
                        ml: 1,
                      }}
                      onClick={() => handleRemove(index)}
                    >
                      <GCloseIcon
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                      />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        ))}
        <Box>
          <Link
            sx={{
              display: 'inline-block',
              cursor: 'pointer',
            }}
            onClick={handleAdd}
          >
            + Document
          </Link>
        </Box>
        <Stack direction="row" alignItems="center" mt={3} spacing={2}>
          <Button
            variant="outlined"
            onClick={onClose}
            size="small"
            sx={{
              px: 3,
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            size="small"
            disabled={disabled}
            loading={processing}
            sx={{
              px: 3,
            }}
          >
            Submit
          </LoadingButton>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

CompleteManufacturingModal.propTypes = {
  open: PropTypes.bool,
  processing: PropTypes.bool,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
};

export default CompleteManufacturingModal;
