import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  OutlinedInput,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { GMinusIcon, GPlusIcon } from '../../../../components/ui/AppIcon';
import constants from '../../../../configs/constants';
import { Controller } from 'react-hook-form';
import colors from 'src/configs/colors';

const PageOrderDetailCard = ({ loading, product, control }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card
        sx={{
          mt: 2.5,
        }}
      >
        <CardHeader
          title={
            <Typography fontSize={20} fontWeight={500} colorscheme="heading">
              {t('Commodity Info')}
            </Typography>
          }
          emphasis="true"
        />
        <CardContent
          sx={{
            pt: 0,
            pb: '16px !important',
          }}
        >
          <Typography fontSize={16} mb={2}>
            {t(
              'enter the quantity that you require and number of shipments, also select if you require proof of product.',
            )}
          </Typography>
          {loading ? (
            <Skeleton variant="rounded" width="100%" height={200} />
          ) : (
            <DataGrid
              columns={[
                {
                  field: 'name',
                  headerName: t('Products'),
                  flex: true,
                  renderCell: ({ row }) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1.5}
                      py={1.5}
                    >
                      <img
                        src={constants.getStoragePath(row.photos[0])}
                        style={{
                          borderRadius: 2,
                          width: 72,
                          height: 72,
                          objectFit: 'cover',
                        }}
                      />
                      <Typography
                        fontSize={16}
                        lineHeight={1.5}
                        colorscheme="gray.700"
                      >
                        {row.name}
                      </Typography>
                    </Stack>
                  ),
                  minWidth: 250,
                },
                {
                  field: 'proof',
                  headerName: t('Proof Req'),
                  renderCell: () => (
                    <Controller
                      control={control}
                      name="proofOfProductRequired"
                      render={({
                        field: { value, onChange, ...fieldProps },
                      }) => (
                        <Checkbox
                          id="field-proofOfProductRequired"
                          {...fieldProps}
                          checked={value}
                          onChange={(ev, checked) => onChange(checked)}
                        />
                      )}
                    />
                  ),
                  minWidth: 120,
                },
                {
                  field: 'qty',
                  headerName: 'Qty/' + product?.unit,
                  renderCell: () => (
                    <Controller
                      control={control}
                      name="quantity"
                      render={({ field, fieldState: { error } }) => (
                        <OutlinedInput
                          id="field-quantity"
                          sx={{
                            height: 42,
                            borderRadius: '3px !important',
                          }}
                          {...field}
                          error={!!error}
                        />
                      )}
                    />
                  ),
                  minWidth: 160,
                },
                {
                  field: '_',
                  headerName: t('# Shipments'),
                  renderCell: () => (
                    <Controller
                      control={control}
                      name="numberOfShipments"
                      render={({ field, fieldState: { error } }) => (
                        <Stack
                          id="field-numberOfShipments"
                          direction="row"
                          alignItems="center"
                          borderRadius="3px"
                          border={`1px solid ${
                            error ? colors['status.error'] : colors['gray.200']
                          }`}
                          py={0.5}
                          px={1.5}
                        >
                          <ButtonBase
                            sx={{ py: 1, px: 1, borderRadius: 100 }}
                            disabled={field.value <= 1}
                            onClick={() => {
                              if (field.value <= 1) {
                                return;
                              }
                              field.onChange(field.value - 1);
                            }}
                          >
                            <GMinusIcon
                              sx={{
                                width: 16,
                                height: 16,
                                color: colors.heading,
                              }}
                            />
                          </ButtonBase>
                          <Typography
                            mx={2}
                            fontSize={16}
                            colorscheme="fonts.heading"
                            lineHeight={1.5}
                            fontWeight={500}
                          >
                            {field.value}
                          </Typography>
                          <ButtonBase
                            sx={{ py: 1, px: 1, borderRadius: 100 }}
                            onClick={() => {
                              field.onChange(field.value + 1);
                            }}
                          >
                            <GPlusIcon
                              sx={{
                                width: 16,
                                height: 16,
                                color: colors.heading,
                              }}
                            />
                          </ButtonBase>
                        </Stack>
                      )}
                    />
                  ),
                  width: 160,
                },
              ]}
              rows={product ? [product] : []}
              hideFooter
              autoHeight
              disableRowSelectionOnClick
              disableColumnMenu
              disableColumnFilter
              getRowHeight={() => 'auto'}
              getRowId={(row) => row._id}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

PageOrderDetailCard.propTypes = {
  control: PropTypes.any,
  product: PropTypes.any,
  loading: PropTypes.bool,
};

export default PageOrderDetailCard;
