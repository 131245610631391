import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Stack,
  Button,
  Tabs,
  Tab,
  styled,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from '../../../../components/ui/AppIcon';
import colors from 'src/configs/colors';

const InfoLine = styled(Stack)(() => ({
  flexDirection: 'row',
  marginBottom: 24,
}));

const InfoLabel = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: 1.5,
  color: colors.gray,
  width: 150,
  marginRight: 20,
}));

const InfoValue = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.5,
  color: colors['gray.700'],
  flex: 1,
}));

const BankTransferModal = ({ open, onClose, onFinish, data }) => {
  const params = useParams();

  const [tab, setTab] = useState('swift');

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 605,
          maxWidth: 'calc(100vw - 40px)',
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          color="#303030"
          fontSize={30}
          lineHeight="38px"
          fontWeight={600}
        >
          Bank Transfer
        </HighlightedTypography>
        <Typography variant="md" mt={3} fontSize={14}>
          To continue with the trade you will need to make a transfer to the
          below banking coordinates. Please ensure to check all details before
          making the transfer and ensure to include the transfer reference
          number.
        </Typography>
        <Tabs
          sx={{ mt: 3 }}
          value={tab}
          onChange={(ev, value) => setTab(value)}
        >
          <Tab label="Swift" id="tab-swift" value="swift" />
          <Tab label="ACH" id="tab-ach" value="ach" />
        </Tabs>
        <Box display={tab === 'swift' ? 'block' : 'none'} mt={3}>
          <InfoLine>
            <InfoLabel>Account Name</InfoLabel>
            <InfoValue>OODL Marketplace LLC</InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Account Address</InfoLabel>
            <InfoValue>
              112 Capitol Drive Suite A Newark Delaware 19711 USA
            </InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Account Number</InfoLabel>
            <InfoValue>859650059</InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>BIC/SWIFT</InfoLabel>
            <InfoValue>CHAFUS33</InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Bank Name</InfoLabel>
            <InfoValue>Chase Bank</InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Bank Address</InfoLabel>
            <InfoValue>
              1602 East Silver Springs Blvd., Ocala Florida 34470
            </InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Reference</InfoLabel>
            <InfoValue>{params.tradeId}</InfoValue>
          </InfoLine>
        </Box>
        <Box display={tab === 'ach' ? 'block' : 'none'} mt={3}>
          <InfoLine>
            <InfoLabel>Account Name</InfoLabel>
            <InfoValue>OODL Marketplace LLC</InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Account Address</InfoLabel>
            <InfoValue>
              112 Capitol Drive Suite A Newark Delaware 19711 USA
            </InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Account Number</InfoLabel>
            <InfoValue>859650059</InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Routing Number</InfoLabel>
            <InfoValue>267084131</InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Bank Name</InfoLabel>
            <InfoValue>Chase Bank</InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Bank Address</InfoLabel>
            <InfoValue>
              1602 East Silver Springs Blvd., Ocala Florida 34470
            </InfoValue>
          </InfoLine>
          <InfoLine>
            <InfoLabel>Reference</InfoLabel>
            <InfoValue>{params.tradeId}</InfoValue>
          </InfoLine>
        </Box>
        <Stack direction="row" justifyContent="flex-end">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={4}
            p={2}
            bgcolor={colors['gray.100']}
            width={444}
            maxWidth="100%"
          >
            <Typography
              fontSize={16}
              fontWeight={600}
              lineHeight={1.5}
              colorscheme="fonts.heading"
            >
              Payment Due
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={600}
              lineHeight={1.5}
              colorscheme="fonts.heading"
            >
              {Number(getPaymentAmount(data)).toLocaleString()} USD
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
          <Button
            variant="outlined"
            sx={{
              height: 50,
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            sx={{
              height: 50,
            }}
            onClick={() => {
              onClose();
              onFinish();
            }}
          >
            Confirm Transfer
          </LoadingButton>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={handleCancel}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

BankTransferModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.any,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
};

function getPaymentAmount(data) {
  if (data) {
    if (
      data.status === 'paying' &&
      data.initialPayments &&
      data.initialPayments.length > 0
    ) {
      let total = 0;
      for (const item of data.initialPayments) {
        total += item.total;
      }
      if (total) {
        return total;
      }
    }

    if (data.status === 'shipment') {
      for (const shipment of data.shipments) {
        if (shipment.status !== 'pending' && shipment.status !== 'settled') {
          for (const item of shipment.payments) {
            if (item.status === 'paying') {
              return item.total;
            }
          }
        }
      }
    }

    return 0;
  } else {
    return 0;
  }
}

export default BankTransferModal;
