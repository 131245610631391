import React, { useEffect, useState } from 'react';
import { Box, ListItem, ListItemText, MenuItem, MenuList, Typography, styled } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

import constants from '../../configs/constants';

const SideMenuItem = styled(MenuItem)`
  & .MuiListItem-root {
    padding: 0;
  }
  & .MuiTypography-root {
    color: ${constants.colors.fills.primary};
  }
  &.Mui-selected {
    background-color: none;
    background: linear-gradient(89.89deg, #89b691 57.58%, rgba(137, 182, 145, 0) 99.9%) !important;

    & .MuiTypography-root {
      color: ${constants.colors.fonts.white};
    }
  }
`;

const routes = [
  {
    path: '/dev/colors',
    label: 'Colors',
  },
  {
    path: '/dev/typography',
    label: 'Typography',
  },
];

const DevSideMenu = () => {
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(null);

  useEffect(() => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].path === location.pathname) {
        setSelectedKey(i);
        return;
      }
    }
    setSelectedKey(null);
  }, [location]);

  return (
    <Box
      sx={{
        width: 250,
        height: '100vh',
        background: constants.colors.gray[900],
        p: 0,
      }}
    >
      <Typography
        variant="h6"
        color={constants.colors.fills.primary}
        fontWeight={700}
        textAlign="center"
        my={2}
      >
        Dev Components
      </Typography>
      <MenuList>
        {routes.map((item, index) => (
          <SideMenuItem
            key={`dev-menu-${index}`}
            selected={index === selectedKey}
          >
            <ListItem
              component={NavLink}
              to={item.path}
            >
              <ListItemText>{item.label}</ListItemText>
            </ListItem>
          </SideMenuItem>
        ))}
      </MenuList>
    </Box>
  );
};

export default DevSideMenu;
