import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messagingDeviceToken: null,
};

const firebaseSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setMessagingDeviceToken: (state, action) => {
      state.messagingDeviceToken = action.payload;
      return state;
    },
  },
});

export const { setMessagingDeviceToken } = firebaseSlice.actions;

export default firebaseSlice;
