import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const AccountSetupIntroduction = ({ onSkip, onNextPage }) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const handleSkip = useCallback(() => {
    setOpened(true);
  }, [setOpened]);

  return (
    <>
      <Box
        sx={{
          borderRadius: 1.5,
          p: 5,
          mb: 5,
          mt: 10,
          bgcolor: 'white',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Typography
          fontSize={36}
          fontWeight={600}
          textAlign="center"
          colorscheme="heading"
          lineHeight={1.2}
        >
          {t('Account Setup')}
        </Typography>
        <Typography fontSize={16} display="block" textAlign="center" mt={1}>
          {t('Don’t Worry, it wont take long.')}
        </Typography>
        <Typography fontSize={16} mt={5}>
          To ensure the best possible experience we will run through 2 easy
          steps to setup your account so that you can access all features on
          OODL Marketplace Platform, these include:
        </Typography>
        <br />
        <Typography fontSize={16} component="div">
          <ul>
            <li>{t('Personal Details for KYC')}</li>
            <li>{t('Business Details for KYB')}</li>
          </ul>
        </Typography>
        <br />
        <Typography fontSize={16}>
          {t(
            'You can skip these steps for now, however, you will not be able to buy or sell on OODL Marketplace Platform until you have provided the above information.',
          )}
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          mt={5}
          spacing={1.5}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{ px: 2 }}
            onClick={handleSkip}
          >
            {t('Skip')}
          </Button>
          <Button
            variant="contained"
            sx={{ px: 4 }}
            size="small "
            onClick={onNextPage}
          >
            {t('Next')}
          </Button>
        </Stack>
      </Box>
      <Dialog open={opened} onClose={() => setOpened(false)} maxWidth="md">
        <DialogContent
          sx={{
            p: 7,
          }}
        >
          <Typography fontSize={48} fontWeight={700} colorscheme="heading">
            {t('Skip Setup?')}
          </Typography>
          <Typography
            fontSize={24}
            fontWeight={500}
            colorscheme="heading"
            mt={3}
          >
            {t(
              'You can skip this step, but remember you will need perform the setup to access most features on OODL Marketplace Platform.',
            )}
          </Typography>
          <Typography fontSize={18} mt={3}>
            {t(
              'Performing account setup will allow you to access wallet services, setup as a business and list and sell products on OODL Marketplace Platform, Negotiate with sellers and purchase products and ..........',
            )}
          </Typography>
          <Stack direction="row" mt={3} spacing={1.5}>
            <Button
              sx={{ flex: 1 }}
              variant="contained"
              size="small"
              onClick={() => setOpened(false)}
            >
              {t('Back')}
            </Button>
            <Button
              sx={{ flex: 1 }}
              variant="outlined"
              size="small"
              onClick={onSkip}
            >
              {t('Ok, go ahead')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

AccountSetupIntroduction.propTypes = {
  onSkip: PropTypes.func,
  onNextPage: PropTypes.func,
};

export default AccountSetupIntroduction;
