import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import {
  GCheckIcon,
  GCloseIcon,
  GEditIcon,
  GUserIcon,
} from '../../../components/ui/AppIcon';
import constants from 'src/configs/constants';

const PageHeader = ({
  isEditing,
  isSaving,
  isLoading,
  profile,
  onEdit,
  onCancel,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card>
      <Stack direction="row" alignItems="center" position="relative">
        <Box
          sx={{
            width: 84,
            height: 124,
            bgcolor: '#EDD29B',
            position: 'relative',
            mr: '60px',
          }}
        >
          <Avatar
            sx={{
              width: 86,
              height: 86,
              border: '2px solid white',
              position: 'absolute',
              top: 'calc(50% - 42px)',
              right: -42,
            }}
            src={constants.getStoragePath(profile?.photo)}
          >
            <GUserIcon
              sx={{
                width: 48,
                height: 48,
              }}
            />
          </Avatar>
        </Box>
        <Stack>
          <Typography
            fontSize={20}
            fontWeight={500}
            lineHeight={1.5}
            colorscheme="fonts.heading"
          >
            {isLoading ? (
              <Skeleton width={120} />
            ) : (
              `${profile?.firstName ?? ' '} ${profile?.lastName ?? ' '}`
            )}
          </Typography>
          <Typography fontSize={14} lineHeight={1.5} color="#6F7C80" mt={1.5}>
            {isLoading ? <Skeleton width={160} /> : profile?.email}
          </Typography>
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
          }}
        >
          {isEditing ? (
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  px: 2,
                }}
                onClick={onCancel}
              >
                {isSm ? <GCloseIcon /> : 'Cancel'}
              </Button>
              <LoadingButton
                variant="contained"
                size="small"
                loading={isSaving}
                onClick={onUpdate}
                sx={{
                  px: 2,
                }}
              >
                {isSm ? <GCheckIcon /> : t('Update')}
              </LoadingButton>
            </Stack>
          ) : isSm ? (
            <IconButton onClick={onEdit}>
              <GEditIcon />
            </IconButton>
          ) : (
            <Button
              startIcon={<GEditIcon />}
              size="small"
              sx={{
                px: 2,
              }}
              onClick={onEdit}
            >
              {t('Edit Profile')}
            </Button>
          )}
        </Box>
      </Stack>
    </Card>
  );
};

PageHeader.propTypes = {
  isEditing: PropTypes.bool,
  isSaving: PropTypes.bool,
  isLoading: PropTypes.bool,
  profile: PropTypes.any,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default PageHeader;
