import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isReady: false,

  selectedUser: null,
  selectedChannel: null,

  channels: {},
  users: [],
};

const messagingSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    initMessaging: (state) => {
      state.isReady = false;
      state.selectedUser = null;
      state.selectedChannel = null;
      state.channels = {};
    },
    setMessagingReadyStatus: (state, action) => {
      state.isReady = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    saveChannel: (state, action) => {
      if (!state.channels[action.payload.target]) {
        state.channels[action.payload.target] = {
          channelId: action.payload.channelId,
        };
      }
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const {
  initMessaging,
  setSelectedUser,
  setSelectedChannel,
  saveChannel,
  setMessagingReadyStatus,
  setUsers,
} = messagingSlice.actions;

export default messagingSlice;
