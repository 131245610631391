import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Breadcrumbs,
  Typography,
  Link,
  Stack,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { GHomeIcon } from 'src/components/ui/AppIcon';

const PageHeader = ({ loading, disabled, onSubmit }) => {
  const navigate = useNavigate();
  const params = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box mb={2.5}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Breadcrumbs>
            <Link component={NavLink} to="/">
              <GHomeIcon />
            </Link>
            <Link component={NavLink} to="/trades/sales">
              My Trades
            </Link>
            <Link component={NavLink} to="/trades/sales">
              Sales
            </Link>
          </Breadcrumbs>
          <Typography fontSize={36} fontWeight={600} colorscheme="heading">
            Trade #{params.tradeId?.substr(-6).toUpperCase()}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleBack}
            sx={{ px: 3 }}
          >
            Back
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ px: 3 }}
            size="small"
            loading={loading}
            disabled={disabled}
            onClick={onSubmit}
          >
            Send Invoice
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
};

PageHeader.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default PageHeader;
