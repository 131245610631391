import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import clone from 'clone';

import PageHeader from './trade-builder-page/PageHeader';
import PageTradeSummaryCard from './trade-builder-page/PageTradeSummaryCard';
import PageSPACard from './trade-builder-page/PageSPACard';
import PageShipmentCard from './trade-builder-page/PageShipmentCard';
import PagePersonInfoCard from './common/PagePersonInfoCard';
import PageProductProofCard from './trade-builder-page/PageProductProofCard';
import PagePreShippingPaymentsCard from './trade-builder-page/PagePreShippingPaymentsCard';
import PageTradeFlowCard from './common/PageTradeFlowCard';
import PageTradeOverviewCard from './common/PageTradeOverviewCard';
import PageSenderViewModal from './trade-builder-page/PageSenderViewModal';

import { apiCreateTradeData, apiGetTradeDetails } from 'src/apis/trades';
import notify from 'src/configs/notify';
import { getFilenameFromUrl } from 'src/utils/functions';

const TradeBuilderPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const tradeId = params.tradeId;

  const [shipments, setShipments] = useState([]);
  const [spa, setSpa] = useState(null);
  const [preShippingPayments, setPreShippingPayments] = useState([]);
  const [proof, setProof] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [senderView, setSenderView] = useState('');

  const { data: trade, isLoading } = useQuery(['orders', tradeId], async () => {
    return await apiGetTradeDetails(tradeId);
  });

  const handleChangeShipment = (index, value) => {
    const newShipments = clone(shipments);
    newShipments[index] = value;
    setShipments(newShipments);
  };

  const handleAddShipment = () => {
    const newShipments = clone(shipments);
    newShipments.push({
      items: [
        {
          id: Date.now(),
          image: trade.product.photo,
          name: trade.product.name,
          price: trade.product.price,
          quantity: trade.quantity,
          unit: trade.product.unit,
          product: trade.product.id,
        },
      ],
      testings: [],
      payments: [
        {
          name: `Order ${shipments.length + 1} Payment`,
          stage: 'After Export & Loading',
          total: trade.product.price * trade.quantity,
        },
      ],
    });
    setShipments(newShipments);
  };

  const handleRemoveShipment = (index) => {
    if (shipments.length <= 1) {
      return;
    }
    const newShipments = clone(shipments);
    newShipments.splice(index, 1);
    setShipments(newShipments);
  };

  const handleAddPreShippingPayment = () => {
    const newPayments = clone(preShippingPayments);
    newPayments.push({
      name: 'Initial Payment',
      total: 0,
    });
    setPreShippingPayments(newPayments);
  };

  const handleChangePreShippingPayments = (index, field, value) => {
    const newPayments = clone(preShippingPayments);
    newPayments[index][field] = value;
    setPreShippingPayments(newPayments);

    if (field === 'total') {
      let total = 0;
      for (const row of newPayments) {
        if (row.total) {
          total += row.total;
        }
      }
      if (total) {
        if (shipments.length > 0) {
          if (shipments[0].payments.length > 0) {
            let cost = 0;
            for (const item of shipments[0].items) {
              cost += item.price * item.quantity;
            }

            let diff = cost - total;
            if (diff <= 0) {
              diff = 0;
            }

            setShipments((values) => {
              const newValues = clone(values);
              newValues[0].payments[0].total = diff;
              return newValues;
            });
          }
        }
      }
    }
  };

  const handleRemovePreShippingPayment = (index) => {
    const newPayments = clone(preShippingPayments);
    newPayments.splice(index, 1);
    setPreShippingPayments(newPayments);
  };

  const handleSubmit = () => {
    if (!spa || !spa?.url) {
      return notify({
        message: 'Please upload the sales purchase agreement docuemnt',
        type: 'error',
      });
    }
    if (trade?.proofOfProductRequired && !proof) {
      return notify({
        message: 'Please upload the proof of product video or photo',
        type: 'error',
      });
    }

    const data = {
      id: tradeId,
      agreement: spa.url,
      initialPayments: preShippingPayments.map((item) => ({
        name: item.name,
        total: item.total,
      })),
      shipments: shipments.map((item) => ({
        items: item.items.map((row) => ({
          name: row.name,
          image: row.image,
          quantity: +row.quantity,
          price: +row.price,
          unit: row.unit,
          total: row.quantity * row.price,
          product: row.product,
        })),
        testings: item.testings,
        payments: item.payments.map((row) => ({
          name: row.name,
          stage: row.stage,
          total: row.total,
        })),
      })),
    };
    if (trade?.proofOfProductRequired) {
      data.proof = proof;
    }

    setProcessing(true);
    apiCreateTradeData(data)
      .then((result) => {
        if (result) {
          if (result.senderView) {
            setSenderView(result.senderView);
          } else {
            navigate(-1);
          }
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleFinishBoldsign = () => {
    setSenderView('');
    navigate(-1);
  };

  useEffect(() => {
    if (trade && trade.shipments && trade.shipments.length > 0) {
      setShipments(
        trade.shipments.map((shipment) => ({
          items: shipment.items.map((item, index) => ({
            id: index + 1,
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            image: item.image,
            unit: item.unit,
            product: item.product,
          })),
          testings: shipment.testings.map((item) => ({
            name: item.name,
            stage: item.stage,
          })),
          payments: shipment.payments.map((item) => ({
            name: item.name,
            stage: item.stage,
            total: item.total,
          })),
        })),
      );
    }

    if (trade && trade.initialPayments && trade.initialPayments.length > 0) {
      setPreShippingPayments(
        trade.initialPayments.map((row) => ({
          name: row.name,
          total: row.total,
        })),
      );
    }

    if (trade && trade.documents) {
      for (const item of trade.documents) {
        if (item.type === 'sales-agreement') {
          setSpa({
            url: item.url,
            file: {
              name: getFilenameFromUrl(item.url),
              uploaded: 'Uploaded',
            },
          });
          continue;
        }
        if (item.type === 'product-proof') {
          setProof(item.url);
        }
      }
    }
  }, [trade]);

  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <PageHeader loading={processing} onSubmit={handleSubmit} />
      {isLoading || !trade ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={1.5}
          height={300}
        >
          <CircularProgress size={24} />
          <Typography>Loading</Typography>
        </Stack>
      ) : (
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={8}>
            <PageTradeSummaryCard shipments={shipments} />
            <PageSPACard spa={spa} onChange={setSpa} />
            <PageTradeOverviewCard data={trade} />
            <PagePreShippingPaymentsCard
              payments={preShippingPayments}
              onAdd={handleAddPreShippingPayment}
              onChange={handleChangePreShippingPayments}
              onRemove={handleRemovePreShippingPayment}
            />
            {shipments.map((shipment, index) => (
              <PageShipmentCard
                key={`shipment-${index}`}
                index={index + 1}
                shipment={shipment}
                defaultProduct={trade.product}
                onChangeShipment={(data) => handleChangeShipment(index, data)}
                onRemoveShipment={() => handleRemoveShipment(index)}
              />
            ))}
            <Stack direction="row" justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                onClick={handleAddShipment}
              >
                Add Order Stage
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <PagePersonInfoCard
              role="buyer"
              info={{
                name: trade?.buyer?.company?.name,
                photo: trade?.buyer?.company?.logo,
              }}
              members={trade?.buyer?.users ?? []}
            />
            {trade.proofOfProductRequired && (
              <PageProductProofCard proof={proof} onChangeProof={setProof} />
            )}
            <PageTradeFlowCard data={trade} />
          </Grid>
        </Grid>
      )}
      <PageSenderViewModal
        url={senderView}
        open={Boolean(senderView)}
        onFinish={handleFinishBoldsign}
      />
    </Container>
  );
};

export default TradeBuilderPage;
