import axios from 'axios';
import { t } from 'i18next';
import notify from 'src/configs/notify';

export const apiGetTeamMembers = async () => {
  try {
    const response = await axios.get('/api/v1/profile/team/members');
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return [];
  }
};

export const apiInviteTeamMember = async (data) => {
  try {
    const response = await axios.post('/api/v1/profile/team/invite', data);
    notify({
      message: 'You have sent the invitation email successfully',
      type: 'success',
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiPatchTeamMember = async (id, data) => {
  try {
    const response = await axios.patch(
      `/api/v1/profile/team/members/${id}`,
      data,
    );
    notify({
      message: 'You have updated the member info successfuly',
      type: 'success',
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiCancelInvite = async (id) => {
  try {
    const response = await axios.delete(
      `/api/v1/profile/team/members/${id}/remove`,
    );
    notify({
      message: 'You have cancelled the invitation for the selected user',
      type: 'success',
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};
