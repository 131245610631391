import axios from 'axios';
import { t } from 'i18next';
import notify from 'src/configs/notify';
import { updateAuth } from 'src/redux/auth/slice';
import store from 'src/redux/store';

export const apiSyncProfile = async () => {
  try {
    const result = await apiGetProfile();
    if (result) {
      store.dispatch(
        updateAuth({
          username:
            (result.user?.firstName ? result.user.firstName : '') +
            ' ' +
            (result.user?.lastName ? result.user.lastName : ''),
          business: {
            id: result.company?._id ?? '',
            name: result.company?.name ?? '',
            email: result.company?.email ?? '',
            logo: result.company?.logo ?? '',
          },
          photo: result.user?.photo ? result.user.photo : '',
          role: result.user?.role,

          isUserVerified: !!result.user?.isVerified,
          isBusinessVerified: !!result.company?.isVerified,
        }),
      );
    }
  } catch (e) {
    return null;
  }
};

export const apiSetupProfile = async (data) => {
  try {
    const response = await axios.post('/api/v1/profile/setup', data);
    await apiSyncProfile();
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiUpdateProfile = async (data) => {
  try {
    const response = await axios.post('/api/v1/profile', data);
    apiSyncProfile();
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiPutProfile = async (data) => {
  try {
    const response = await axios.put('/api/v1/profile', data);
    apiSyncProfile();
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetProfile = async () => {
  try {
    const response = await axios.get('/api/v1/profile');
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetMyProducts = async () => {
  try {
    const response = await axios.get('/api/v1/profile/products');
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetMyProductDetails = async (id) => {
  try {
    const response = await axios.get('/api/v1/profile/products/' + id);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiCreateMyProduct = async (data) => {
  try {
    const response = await axios.post('/api/v1/profile/products', data);
    notify({
      message:
        'You have successfully added your product, it will remain in drafts until the OODL Marketplace team has approved it for listing',
      type: 'success',
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiUpdateMyProduct = async (id, data) => {
  try {
    const response = await axios.put('/api/v1/profile/products/' + id, data);
    notify({
      message:
        'You have successfully updated your product, it will remain in drafts until the OODL Marketplace team has approved it for listing',
      type: 'success',
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiDeleteMyProduct = async (id) => {
  try {
    const response = await axios.delete('/api/v1/profile/products/' + id);
    notify({
      message: 'You have delete the product successfully',
      type: 'success',
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiChangePassword = async (data) => {
  try {
    const response = await axios.post('/api/v1/profile/change-password', data);

    notify({
      message: 'You have changed your password successfully',
      type: 'success',
    });

    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetBankAccounts = async () => {
  try {
    const response = await axios.get('/api/v1/profile/bank-accounts');
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiCreateBankAccount = async (data) => {
  try {
    const response = await axios.post('/api/v1/profile/bank-accounts', data);
    notify({
      type: 'success',
      message: 'You have added a new bank account successfully',
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiUpdateBankAccount = async (id, data) => {
  try {
    const response = await axios.patch(
      '/api/v1/profile/bank-accounts/' + id,
      data,
    );
    notify({
      type: 'success',
      message: 'You have updated the selected bank account successfully',
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiDeleteBankAccount = async (id) => {
  try {
    const response = await axios.delete('/api/v1/profile/bank-accounts/' + id);
    notify({
      type: 'success',
      message: 'You have updated the selected bank account successfully',
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetActivityLogs = async ({ pageIndex, pageSize }) => {
  try {
    const response = await axios.get('/api/v1/profile/activity-logs', {
      params: {
        pageIndex,
        pageSize,
      },
    });
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return [];
  }
};

export const apiSyncDeviceTokens = async (token) => {
  try {
    await axios.post('/api/v1/profile/device-tokens', {
      token: token,
    });
    return true;
  } catch (e) {
    return false;
  }
};
