import React, { useEffect, useState } from 'react';
import { Portal, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GPlusIcon } from '../../components/ui/AppIcon';
import PageMembersCard from './my-team-page/PageMembersCard';
import InviteMemberModal from './my-team-page/InviteMemberModal';

import notify from 'src/configs/notify';
import {
  apiCancelInvite,
  apiGetTeamMembers,
  apiInviteTeamMember,
  apiPatchTeamMember,
} from 'src/apis/team';

const MyTeamPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const {
    data: members,
    isLoading,
    refetch,
  } = useQuery(['profile/team/members'], apiGetTeamMembers);

  const [open, setOpen] = useState(false);
  const [container, setContainer] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleOpenInviteModal = () => {
    if (auth?.business?.id) {
      setOpen(true);
    } else {
      notify({
        type: 'warning',
        message: 'You need to complete your account setup first',
      });
      navigate('/profile/account');
    }
  };

  const handleCloseInviteModal = () => {
    setOpen(false);
  };

  const handleInviteMember = async (values) => {
    setProcessing(true);
    if (await apiInviteTeamMember(values)) {
      await refetch();
    }
    setProcessing(false);
  };

  const handleViewTrades = () => {
    navigate('/trades/sales');
  };

  const handleCancelInvite = async (id) => {
    setProcessing(true);
    if (await apiCancelInvite(id)) {
      await refetch();
    }
    setProcessing(false);
  };

  const handleEnableUser = async (id) => {
    setProcessing(true);
    if (await apiPatchTeamMember(id, { status: 'active' })) {
      await refetch();
    }
    setProcessing(false);
  };

  const handleDisableUser = async (id) => {
    setProcessing(true);
    if (await apiPatchTeamMember(id, { status: 'disabled' })) {
      await refetch();
    }
    setProcessing(false);
  };

  useEffect(() => {
    setContainer(document.getElementById('profile-layout-actions'));
  }, []);

  return (
    <>
      <Portal container={container}>
        {auth?.role === 'owner' && (
          <Button
            variant="contained"
            size="small"
            startIcon={<GPlusIcon />}
            sx={{
              px: 2,
            }}
            onClick={handleOpenInviteModal}
          >
            {t('Invite Member')}
          </Button>
        )}
      </Portal>
      <PageMembersCard
        members={members ?? []}
        loading={isLoading || processing}
        onViewTrades={handleViewTrades}
        onCancelInvite={handleCancelInvite}
        onEnableUser={handleEnableUser}
        onDisableUser={handleDisableUser}
      />
      <InviteMemberModal
        open={open}
        onClose={handleCloseInviteModal}
        onSubmit={handleInviteMember}
      />
    </>
  );
};

export default MyTeamPage;
