import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Chat, MessageInput, MessageList } from '@pubnub/react-chat-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  GUserIcon,
  GVuesaxSidebarIcon,
} from '../../../../components/ui/AppIcon';
import { saveChannel, setSelectedChannel } from 'src/redux/messaging/slice';
import { apiLoadDirectChannel } from 'src/apis/messaging';

const PageChatView = ({ onToggleDrawer, isSm }) => {
  const dispatch = useDispatch();

  const selectedUser = useSelector((state) => state.messaging.selectedUser);
  const selectedChannel = useSelector(
    (state) => state.messaging.selectedChannel,
  );
  const channels = useSelector((state) => state.messaging.channels);
  const users = useSelector((state) => state.messaging.users);

  const [loading, setLoading] = useState(false);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollToBottom();
    }
  }, []);

  useEffect(() => {
    if (selectedUser) {
      if (channels[selectedUser.id]) {
        dispatch(
          setSelectedChannel({
            channelId: channels[selectedUser.id].channelId,
          }),
        );
      } else {
        setLoading(true);
        apiLoadDirectChannel(selectedUser.id)
          .then((result) => {
            dispatch(
              saveChannel({
                target: selectedUser.id,
                channelId: result.channelId,
              }),
            );
            dispatch(
              setSelectedChannel({
                channelId: result.channelId,
              }),
            );
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  }, [selectedUser]);

  return (
    <Card
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '78vh',
      }}
    >
      {selectedUser ? (
        <>
          <Stack
            px={3}
            py={2.5}
            direction="row"
            alignItems="center"
            borderBottom="1px solid #DFDFDF"
          >
            <Stack direction="row" flex={1}>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                }}
                src={selectedUser?.profileUrl}
              >
                <GUserIcon />
              </Avatar>
              <Stack ml={1.5}>
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  lineHeight="30px"
                  colorscheme="gray.900"
                >
                  {selectedUser?.name}
                </Typography>
                <Typography
                  mt={1}
                  fontSize={14}
                  fontWeight={500}
                  lineHeight="22px"
                  color="#93989A"
                >
                  {getRoleDisplayName(selectedUser?.custom?.title)}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
              {isSm && (
                <IconButton onClick={onToggleDrawer}>
                  <GVuesaxSidebarIcon sx={{ color: '#93989A' }} />
                </IconButton>
              )}
            </Stack>
          </Stack>

          {loading || !selectedChannel ? (
            <Stack
              height="100%"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={24} />
              <Typography mt={2}>Loading</Typography>
            </Stack>
          ) : (
            <Chat currentChannel={selectedChannel.channelId} users={users}>
              <MessageList fetchMessages={25} enableReactions />
              <Box px={0} py={2} borderTop="1px solid #DFDFDF">
                <MessageInput typingIndicator placeholder="Write Message" />
              </Box>
            </Chat>
          )}
        </>
      ) : (
        <Stack
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>No user selected</Typography>
        </Stack>
      )}
    </Card>
  );
};

PageChatView.propTypes = {
  isSm: PropTypes.bool,
  onToggleDrawer: PropTypes.func,
};

export default PageChatView;

function getRoleDisplayName(role) {
  switch (role) {
    case 'owner': {
      return 'Business Owner';
    }
    case 'agent': {
      return 'Agent';
    }
    case 'superadmin': {
      return 'System Admin';
    }
    default: {
      return 'Member';
    }
  }
}
