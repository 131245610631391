import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack, Box } from '@mui/material';

import { GInfoCircleIcon } from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';

const PageNotificationCard = ({ data }) => {
  const [hidden, setHidden] = useState(true);
  const [text, setText] = useState('');

  useEffect(() => {
    if (data) {
      switch (data.status) {
        case 'rejected': {
          setHidden(false);
          setText(
            'The seller rejected this trade.<br/><br/>Reason:<br/>' +
              data.reason,
          );
          break;
        }
        case 'requested': {
          setHidden(false);
          setText(
            'Your Trade request has been submitted, please wait for the seller to review.',
          );
          break;
        }
        case 'checking': {
          setHidden(false);
          if (
            data.documents.find((item) => item.type === 'sales-agreement')
              ?.metadata?.buyerSignedAt
          ) {
            setText(
              'Please wait for the seller to sign on the sales purchase agreement to continue the Trade. If you have any questions, you can message the seller to clarify.',
            );
          } else {
            setText(
              'Please review and sign the Sales Purchase Agreement provided by the seller to continue the Trade, if you have any questions you can message the seller to clarify.',
            );
          }
          break;
        }
        case 'paying': {
          const initialPayment = data?.initialPayments[0];
          if (initialPayment) {
            switch (initialPayment.status) {
              case 'invoicing': {
                setHidden(false);
                setText(
                  'The Seller is preparing an invoice for the payment, please wait until this has been submitted for payment.',
                );
                break;
              }
              case 'accepting': {
                setHidden(false);
                setText(
                  'Please review the provided invoice to continue the Trade, if you have any questions, you can message the seller to clarify.',
                );
                break;
              }
              case 'paying': {
                setHidden(false);
                setText(
                  'Please review the provided invoice and upload your payment receipt to proceed with the Trade.',
                );
                break;
              }
            }
          }
          break;
        }
        case 'shipment': {
          for (const shipment of data.shipments) {
            if (
              shipment.status === 'pending' ||
              shipment.status === 'settled'
            ) {
              continue;
            }

            const payment = shipment.payments[0];
            if (payment) {
              switch (payment.status) {
                case 'invoicing': {
                  setHidden(false);
                  setText(
                    "Now it's time to make a payment, please wait for the seller to provide invoice.",
                  );
                  return;
                }
                case 'accepting': {
                  setHidden(false);
                  setText('Please review the provided invoice.');
                  return;
                }
                case 'paying': {
                  setHidden(false);
                  setText(
                    'Please review the provided invoice and upload your payment receipt to proceed with the Trade.',
                  );
                  return;
                }
                case 'checking': {
                  return;
                }
              }
            }

            if (shipment.status === 'manufacturing') {
              setHidden(false);
              setText(
                data?.documents?.filter(
                  (item) =>
                    item.type === 'manufacturing-document' &&
                    item.status === 'pending',
                ).length > 0
                  ? 'The seller has provided the Manufacturing documents, please review and provide signoff.'
                  : 'Your Trade is being processed. Once processed you will be sent manufacturing documents.',
              );
              return;
            }
            if (shipment.status === 'shipping') {
              setHidden(false);
              setText(
                data?.documents?.filter(
                  (item) =>
                    item.type === 'importing-document' &&
                    item.status === 'pending',
                ).length > 0
                  ? 'Please review the Export & Loading documents and confirm that all is as required.'
                  : 'Your Trade is being processed. Once processed you will be sent shipping documents.',
              );
              return;
            }
            if (shipment.status === 'delivering') {
              setHidden(false);
              setText(
                'You are required to confirm once the delivery has been completed successfully for this order.',
              );
              return;
            }
          }
          setHidden(true);
          setText('');
          break;
        }
        default: {
          setHidden(true);
          setText('');
          break;
        }
      }
    }
  }, [data]);

  return (
    <Stack
      p={2}
      borderRadius={2}
      bgcolor={colors['blue.highlight']}
      direction="row"
      alignItems="flex-start"
      spacing={2}
      mb={2.5}
      display={hidden ? 'none' : 'flex'}
    >
      <Box mt="3px !important">
        <GInfoCircleIcon
          sx={{
            width: 16,
            height: 16,
            color: colors['status.info'],
          }}
        />
      </Box>
      <Typography fontSize={16} colorscheme="status.info" flex={1}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
    </Stack>
  );
};

PageNotificationCard.propTypes = {
  data: PropTypes.any,
};

export default PageNotificationCard;
