import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import { GArrowRightIcon, GCloseIcon } from 'src/components/ui/AppIcon';
import CollapseButton from 'src/components/ui/CollapseButton';
import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import ProductProofInput from './ProductProofInput';
import constants from 'src/configs/constants';
import { getFilenameFromUrl } from 'src/utils/functions';

const PageProductProofCard = ({ proof, onChangeProof }) => {
  const [opened, setOpened] = useState(true);
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState(null);

  const handleToggle = () => {
    setOpened((value) => !value);
  };

  const handleSubmit = () => {
    setModal(false);
    onChangeProof(file.url);
  };

  return (
    <>
      <Card
        sx={{
          mb: 2.5,
        }}
      >
        <CardHeader
          title={
            <Typography fontSize={18} fontWeight={600} colorscheme="heading">
              Proof of Product
            </Typography>
          }
          emphasis="true"
          action={<CollapseButton opened={opened} onToggle={handleToggle} />}
        />
        <Collapse in={opened}>
          <CardContent
            sx={{
              pb: '16px !important',
            }}
          >
            <Typography fontSize={14}>
              The buyer has requested proof of product for this trade, click
              below to provide video proof of the product.
            </Typography>
            {proof ? (
              <Stack direction="row" alignItems="center" mt={2}>
                <Box flex={1}>
                  <Typography>{getFilenameFromUrl(proof)}</Typography>
                  <Typography fontSize={14}>1 file uploaded</Typography>
                </Box>
                <IconButton
                  onClick={() =>
                    window.open(constants.getStoragePath(proof), '_blank')
                  }
                >
                  <GArrowRightIcon />
                </IconButton>
              </Stack>
            ) : null}
            <Box mt={2}>
              <Button
                size="small"
                variant="contained"
                sx={{ px: 2 }}
                onClick={() => setModal(true)}
              >
                Supply Proof
              </Button>
            </Box>
          </CardContent>
        </Collapse>
      </Card>
      <Dialog
        open={modal}
        onClose={() => setModal(false)}
        sx={{
          '& .MuiPaper-root': {
            width: 495,
          },
        }}
      >
        <DialogContent
          sx={{
            position: 'relative',
          }}
        >
          <HighlightedTypography
            color="#303030"
            fontSize={30}
            lineHeight="38px"
            fontWeight={600}
            display="block"
          >
            Proof of Product
          </HighlightedTypography>
          <Typography variant="md" mt={3} display="block" lineHeight="22px">
            You can use your mobile camera to take a Proof of Product video,
            this will be used to provide the buyer proof of the product that you
            are selling.
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={600}
            colorscheme="heading"
            mt={3}
          >
            Attach Proof
          </Typography>
          <ProductProofInput value={file} onChange={setFile} />
          <Stack direction="row" alignItems="center" mt={3} spacing={2}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setModal(false)}
              sx={{ px: 2 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={!file?.url}
              onClick={handleSubmit}
              sx={{ px: 2 }}
            >
              Submit
            </Button>
          </Stack>
          <IconButton
            sx={{
              position: 'absolute',
              right: 24,
              top: 24,
              width: 24,
              height: 24,
              bgcolor: '#EEEEEE',
            }}
            onClick={() => setModal(false)}
          >
            <GCloseIcon
              sx={{
                width: 16,
                height: 16,
              }}
            />
          </IconButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

PageProductProofCard.propTypes = {
  proof: PropTypes.any,
  onChangeProof: PropTypes.func,
};

export default PageProductProofCard;
