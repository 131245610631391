import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageNavigation from './PageNavigation';
import TableMenu from 'src/components/ui/TableMenu';
import {
  GCashIcon,
  GCheckIcon,
  GCloseIcon,
  GDotsVerticalIcon,
  GEyeIcon,
  GHelpIcon,
  GLinkIcon,
  GMessage2Icon,
  GUserPlusIcon,
} from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';

const PageHeader = ({
  data,
  onAcceptAgreements,
  onAcceptInvoice,
  onMakePayment,
  onDownloadReceipt,
  onAcceptDocuments,
  onAcceptImports,
  onConfirmDelivery,
  onViewContract,
  onViewMessage,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [status, setStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleOpen = (ev) => {
    setAnchor(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleClickActionMenu = (callback) => {
    return () => {
      handleClose();
      callback();
    };
  };

  useEffect(() => {
    if (data) {
      if (data.status === 'paying') {
        setStatus(data.status);
        for (const row of data.initialPayments) {
          setPaymentStatus(row.status);
          return;
        }
      }

      if (data.status !== 'shipment') {
        return setStatus(data.status);
      }

      for (const shipment of data.shipments) {
        if (shipment.status !== 'pending' && shipment.status !== 'settled') {
          setStatus(shipment.status);
          for (const payment of shipment.payments) {
            if (
              payment.status !== 'pending' &&
              payment.status !== 'confirmed'
            ) {
              setPaymentStatus(payment.status);
            }
            break;
          }
          break;
        }
      }
    }
  }, [data]);

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Box flex={1}>
          <PageNavigation />
          <Typography
            fontSize={24}
            fontWeight={600}
            colorscheme="heading"
            mt={1}
          >
            {t('Trade #id', {
              id: params.tradeId?.substr(-6).toUpperCase(),
            })}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Collapse orientation="horizontal" in={!isSm}>
            <Stack direction="row" alignItems="center" spacing={2}>
              {status === 'checking' &&
                !data.documents.find((item) => item.type === 'sales-agreement')
                  ?.metadata?.buyerSignedAt && (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        height: '40px !important',
                      }}
                      onClick={onAcceptAgreements}
                    >
                      Sign Agreements
                    </Button>
                  </>
                )}
              {['accepting'].includes(paymentStatus) && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      height: '40px !important',
                    }}
                    onClick={onAcceptInvoice}
                  >
                    Accept Invoice
                  </Button>
                </>
              )}
              {['paying'].includes(paymentStatus) && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      height: '40px !important',
                    }}
                    onClick={onMakePayment}
                  >
                    Make Payment
                  </Button>
                </>
              )}
              {status === 'manufacturing' &&
                data?.documents?.filter(
                  (item) =>
                    item.status === 'pending' &&
                    item.type === 'manufacturing-document',
                ).length > 0 && (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        height: '40px !important',
                      }}
                      onClick={onAcceptDocuments}
                    >
                      Accept Documents
                    </Button>
                  </>
                )}
              {status === 'shipping' &&
                data?.documents?.filter(
                  (item) =>
                    item.status === 'pending' &&
                    item.type === 'importing-document',
                ).length > 0 && (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        height: '40px !important',
                      }}
                      onClick={onAcceptImports}
                    >
                      Accept Documents
                    </Button>
                  </>
                )}
              {status === 'delivering' &&
                (!paymentStatus || paymentStatus === 'pending') && (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        height: '40px !important',
                      }}
                      onClick={onConfirmDelivery}
                    >
                      Confirm Delivery
                    </Button>
                  </>
                )}
            </Stack>
          </Collapse>
          <IconButton
            sx={{
              width: 40,
              height: 40,
              borderRadius: 1,
              border: `1px solid ${colors['gray.300']}`,
            }}
            onClick={handleOpen}
          >
            <GDotsVerticalIcon />
          </IconButton>
        </Stack>
        <TableMenu open={open} anchorEl={anchor} onClose={handleClose}>
          <MenuItem>
            <ListItemIcon>
              <GEyeIcon />
            </ListItemIcon>
            <ListItemText>View Seller</ListItemText>
          </MenuItem>
          {status === 'request' && [
            <MenuItem key="key-cancel-trade">
              <ListItemIcon>
                <GCloseIcon />
              </ListItemIcon>
              <ListItemText>Cancel Trade</ListItemText>
            </MenuItem>,
          ]}
          {status === 'checking' && [
            <MenuItem
              key="key-accept-agreements"
              onClick={handleClickActionMenu(onAcceptAgreements)}
            >
              <ListItemIcon>
                <GCheckIcon />
              </ListItemIcon>
              <ListItemText>Sign Agreements</ListItemText>
            </MenuItem>,
          ]}
          {['accepting'].includes(paymentStatus) && [
            <MenuItem
              key="key-accept-invoice"
              onClick={handleClickActionMenu(onAcceptInvoice)}
            >
              <ListItemIcon>
                <GCheckIcon />
              </ListItemIcon>
              <ListItemText>Accept Invoice</ListItemText>
            </MenuItem>,
          ]}
          {['paying'].includes(paymentStatus) && [
            <MenuItem
              key="key-make-payment"
              onClick={handleClickActionMenu(onMakePayment)}
            >
              <ListItemIcon>
                <GCashIcon />
              </ListItemIcon>
              <ListItemText>Make Payment</ListItemText>
            </MenuItem>,
          ]}
          {status === 'manufacturing' &&
            data?.documents?.filter(
              (item) =>
                item.status === 'pending' &&
                item.type === 'manufacturing-document',
            ).length > 0 && [
              <MenuItem
                key="key-accept-documents"
                onClick={handleClickActionMenu(onAcceptDocuments)}
              >
                <ListItemIcon>
                  <GCheckIcon />
                </ListItemIcon>
                <ListItemText>Accept Documents</ListItemText>
              </MenuItem>,
              <MenuItem
                key="key-view-payment"
                onClick={handleClickActionMenu(onDownloadReceipt)}
              >
                <ListItemIcon>
                  <GCashIcon />
                </ListItemIcon>
                <ListItemText>View Payment Details</ListItemText>
              </MenuItem>,
            ]}
          {status === 'shipping' &&
            data?.documents?.filter(
              (item) =>
                item.status === 'pending' && item.type === 'importing-document',
            ).length > 0 && [
              <MenuItem
                key="key-accept-imports"
                onClick={handleClickActionMenu(onAcceptImports)}
              >
                <ListItemIcon>
                  <GCashIcon />
                </ListItemIcon>
                <ListItemText>Confirm Import</ListItemText>
              </MenuItem>,
            ]}
          {status === 'delivering' &&
            paymentStatus === 'pending' && [
              <MenuItem
                key="key-accept-imports"
                onClick={handleClickActionMenu(onConfirmDelivery)}
              >
                <ListItemIcon>
                  <GCheckIcon />
                </ListItemIcon>
                <ListItemText>Confirm Delivery</ListItemText>
              </MenuItem>,
            ]}
          {paymentStatus === 'confirmed' && [
            <MenuItem
              key="key-view-payment"
              onClick={handleClickActionMenu(onDownloadReceipt)}
            >
              <ListItemIcon>
                <GCashIcon />
              </ListItemIcon>
              <ListItemText>View Payment Details</ListItemText>
            </MenuItem>,
          ]}
          {data?.bcContractAddress && [
            <MenuItem
              key="key-view-contract"
              onClick={handleClickActionMenu(onViewContract)}
            >
              <ListItemIcon>
                <GLinkIcon />
              </ListItemIcon>
              <ListItemText>View Blockchain</ListItemText>
            </MenuItem>,
          ]}
          <MenuItem
            key="key-assign"
            onClick={handleClickActionMenu(onViewMessage)}
          >
            <ListItemIcon>
              <GMessage2Icon />
            </ListItemIcon>
            <ListItemText>Message Seller</ListItemText>
          </MenuItem>
          <MenuItem key="key-assign">
            <ListItemIcon>
              <GUserPlusIcon />
            </ListItemIcon>
            <ListItemText>{t('Assgin to Trade')}</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <GHelpIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>{t('Get Help')}</Typography>
            </ListItemText>
          </MenuItem>
        </TableMenu>
      </Stack>
    </>
  );
};

PageHeader.propTypes = {
  data: PropTypes.any,
  onAcceptAgreements: PropTypes.func,
  onAcceptInvoice: PropTypes.func,
  onMakePayment: PropTypes.func,
  onDownloadInvoice: PropTypes.func,
  onDownloadReceipt: PropTypes.func,
  onAcceptDocuments: PropTypes.func,
  onAcceptImports: PropTypes.func,
  onConfirmDelivery: PropTypes.func,
  onViewContract: PropTypes.func,
  onViewMessage: PropTypes.func,
};

export default PageHeader;
