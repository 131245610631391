import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQuery } from 'react-query';

import NotificationSettingInput from './my-notification-settings-page/NotificationSettingInput';
import { apiGetProfile, apiUpdateProfile } from 'src/apis/profile';
import notify from 'src/configs/notify';

const MyNotificationSettingsPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    reminders: { push: false, email: false, sms: false },
    payments: { push: false, email: false, sms: false },
    messages: { push: false, email: false, sms: false },
  });
  const { data: profile, refetch } = useQuery(['profile'], apiGetProfile);

  const handleChange = (key, values) => {
    console.log(key, values);
    setData((newData) => {
      return {
        ...newData,
        [key]: values,
      };
    });
  };

  const handleUpdate = async () => {
    if (!profile) {
      return;
    }

    const settings = profile?.user?.settings ? profile.user.settings : {};
    settings.notifications = data;

    setLoading(true);
    if (await apiUpdateProfile({ settings })) {
      await refetch();
      notify({
        message: 'You have updated your notification settings successfully',
        type: 'success',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (profile?.user?.settings?.notifications) {
      setData(profile.user.settings.notifications);
    }
  }, [profile]);

  return (
    <Card>
      <CardHeader
        title={
          <Typography fontSize={20} fontWeight={500} colorscheme="heading">
            Notification Settings
          </Typography>
        }
        emphasis="true"
      />
      <CardContent
        sx={{
          pb: '16px !important',
        }}
      >
        <Typography
          fontSize={18}
          lineHeight={1.5}
          colorscheme="fonts.text"
          display="block"
        >
          Stay updated with platform alerts and updates
        </Typography>
        <Box mt={3}>
          <NotificationSettingInput
            title="Reminders"
            subtitle="These are notifications to remind you of updates you might have missed."
            values={data.reminders}
            onChange={(values) => handleChange('reminders', values)}
          />
          <Divider
            sx={{
              my: 2.5,
            }}
          />
          <NotificationSettingInput
            title="Payments"
            subtitle="These are notifications to remind you for your all payments."
            values={data.payments}
            onChange={(values) => handleChange('payments', values)}
          />
          <Divider
            sx={{
              my: 2.5,
            }}
          />
          <NotificationSettingInput
            title="Messages"
            subtitle="These are notifications to remind you all messages or communication."
            values={data.messages}
            onChange={(values) => handleChange('messages', values)}
          />
        </Box>
        <Stack direction="row" justifyContent="flex-end" mt={3}>
          <LoadingButton
            variant="contained"
            size="small"
            sx={{
              px: 2,
            }}
            loading={loading}
            onClick={handleUpdate}
          >
            Update Settings
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MyNotificationSettingsPage;
