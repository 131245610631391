import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Link,
  CircularProgress,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import NotificationItem from '../../../../components/items/NotificationItem';
import { apiGetNotifications } from 'src/apis/notifications';
import { Stack } from '@mui/system';

const PageNotificationsView = () => {
  const { t } = useTranslation();
  const { data: notifications, isLoading } = useQuery(
    ['notifications', 'all', 1, 5],
    async () => {
      return await apiGetNotifications({
        pageIndex: 1,
        pageSize: 5,
        onlyUnread: false,
      });
    },
  );

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Typography fontSize={18} fontWeight={500} colorscheme="heading">
            {t('Notifications')}
          </Typography>
        }
        emphasis="true"
        action={
          <Link
            component={NavLink}
            to="/notifications"
            fontSize={14}
            fontWeight={500}
          >
            {t('View all')}
          </Link>
        }
      />
      <CardContent
        sx={{
          p: 0,
          paddingBottom: '0 !important;',
          maxHeight: '40vh',
          overflowY: 'auto',
          boxSizing: 'border-box',
        }}
      >
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" height={200}>
            <CircularProgress size={20} />
            <Typography mt={1.5}>Loading</Typography>
          </Stack>
        ) : notifications?.length > 0 ? (
          notifications.map((notification) => (
            <NotificationItem
              id={notification._id}
              avatar={''}
              createdAt={notification.createdAt}
              highlight={!notification.reads}
              message={notification.description}
              title={notification.title}
              payload={notification.payload}
              action={notification.action}
              key={`notification-${notification._id}`}
              bottomBorder
            />
          ))
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <Typography fontSize={14} colorscheme="gray.400" py={5}>
              No activity logs found
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default PageNotificationsView;
