import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GBanIcon } from './AppIcon';
import colors from 'src/configs/colors';

const EmptyResult = ({ icon }) => {
  const { t } = useTranslation();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height={350}
      spacing={2.5}
    >
      {icon ? (
        icon
      ) : (
        <GBanIcon
          sx={{
            width: 40,
            height: 40,
            color: colors.text,
          }}
        />
      )}
      <Typography>{t('No search result')}</Typography>
    </Stack>
  );
};

EmptyResult.propTypes = {
  icon: PropTypes.element,
};

export default EmptyResult;
