import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

import { default as colors } from 'src/configs/colors';

const info = {
  Published: {
    back: colors['green.highlight'],
    front: colors['status.success'],
  },
  Disabled: {
    back: colors['red.highlight'],
    front: colors['status.error'],
  },
  'Out of Stock': {
    back: colors['orange.highlight'],
    front: colors['status.warning'],
  },
  Pending: {
    back: colors['blue.highlight'],
    front: colors['status.info'],
  },
  Archived: {},
  Draft: {
    back: colors['orange.highlight'],
    front: colors['status.warning'],
  },
};

const ProductStatusChip = ({ status, ...props }) => {
  return (
    <Chip
      label={status}
      sx={{
        backgroundColor: info[status]?.back,
        '& .MuiChip-label': {
          color: info[status]?.front,
          fontWeight: 500,
          fontSize: 12,
          lineHeight: '16px',
        },
      }}
      {...props}
    />
  );
};

ProductStatusChip.propTypes = {
  status: PropTypes.string,
};

export default ProductStatusChip;
