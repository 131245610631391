import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import CollapseButton from 'src/components/ui/CollapseButton';
import { GMessage2Icon, GUserIcon } from '../../../../components/ui/AppIcon';
import constants from 'src/configs/constants';
import colors from 'src/configs/colors';

const PagePersonInfoCard = ({ loading, info, members, showMessage, role }) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(true);

  const handleToggle = () => {
    setOpened((value) => !value);
  };

  return (
    <Card
      sx={{
        mb: 2.5,
      }}
    >
      <CardHeader
        title={
          <Typography fontSize={18} fontWeight={600} colorscheme="heading">
            {role === 'buyer' ? t('Buyer Details') : t('Seller Details')}
          </Typography>
        }
        emphasis="true"
        action={<CollapseButton opened={opened} onToggle={handleToggle} />}
      />
      <Collapse in={opened}>
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          <Stack direction="row" alignItems="center" spacing="10px">
            {loading ? (
              <Skeleton
                variant="rounded"
                sx={{
                  borderRadius: '12px',
                  width: 50,
                  height: 50,
                }}
              />
            ) : (
              <Avatar
                src={constants.getStoragePath(info?.photo)}
                sx={{
                  borderRadius: '12px',
                  width: 50,
                  height: 50,
                }}
              >
                <GUserIcon />
              </Avatar>
            )}
            <Stack flex={1} spacing="1px">
              <Typography
                fontSize={14}
                fontWeight={600}
                colorscheme="secondary.500"
              >
                {loading ? (
                  <Skeleton variant="text" width="100px" />
                ) : (
                  info?.name
                )}
              </Typography>
              <Typography fontSize={12} colorscheme="secondary.300">
                {loading ? (
                  <Skeleton variant="text" width="120px" />
                ) : (
                  info?.from
                )}
              </Typography>
            </Stack>
          </Stack>
          {loading ? null : (
            <>
              <Divider
                textAlign="left"
                sx={{
                  my: 2,
                }}
              >
                <Typography fontSize={12}>Members</Typography>
              </Divider>
              <Stack direction="column" spacing={1.5}>
                {members?.map((item, index) => (
                  <Stack
                    key={`member-${role}-${index}`}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing="10px"
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing="10px"
                    >
                      <Avatar
                        src={constants.getStoragePath(item.photo)}
                        sx={{
                          borderRadius: '12px',
                          width: 50,
                          height: 50,
                        }}
                      >
                        <GUserIcon />
                      </Avatar>
                      <Box>
                        <Typography
                          fontSize={14}
                          fontWeight={600}
                          colorscheme="secondary.500"
                        >
                          {item.firstName ?? ''} {item.lastName ?? ''}
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          colorscheme="secondary.300"
                        >
                          {item.role === 'owner'
                            ? 'Owner'
                            : item.role === 'agent'
                            ? 'Agent'
                            : ''}
                        </Typography>
                      </Box>
                    </Stack>
                    {showMessage && (
                      <Tooltip title="Send Message">
                        <IconButton
                          sx={{
                            bgcolor: `${colors.primary} !important`,
                            color: 'white',
                          }}
                        >
                          <GMessage2Icon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                ))}
              </Stack>
            </>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

PagePersonInfoCard.propTypes = {
  loading: PropTypes.bool,
  info: PropTypes.any,
  members: PropTypes.array,
  showMessage: PropTypes.bool,
  role: PropTypes.string,
};

export default PagePersonInfoCard;
