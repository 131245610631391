import React from 'react';
import { Box, Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import PageHeader from './products-page/PageHeader';
import PageProductsPanel from './products-page/PageProductsPanel';
import { apiGetProducts } from 'src/apis/products';

const ProductsPage = () => {
  const [query] = useSearchParams();
  const parentCategory = query.get('parentCategory')
    ? query.get('parentCategory')
    : 'All';
  const subCategory = query.get('subCategory')
    ? query.get('subCategory')
    : 'All';
  const search = query.get('search');

  const { data, isLoading } = useQuery(
    ['products', parentCategory, subCategory, search],
    async () => {
      let result = await apiGetProducts();
      if (result) {
        if (subCategory !== 'All') {
          result = result.filter(
            (item) =>
              item.category?.name?.toLowerCase() === subCategory.toLowerCase(),
          );
        } else {
          if (parentCategory !== 'All') {
            result = result.filter(
              (item) =>
                item.category?.name?.toLowerCase() ===
                parentCategory.toLowerCase(),
            );
          }
        }
        if (search) {
          result = result.filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase()),
          );
        }
        return result;
      }
      return result;
    },
  );

  return (
    <Container sx={{ pt: 4, pb: 12 }}>
      <PageHeader />
      <Box mt={3}>
        <PageProductsPanel products={data ? data : []} loading={isLoading} />
      </Box>
    </Container>
  );
};

export default ProductsPage;
