import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  GBoxIcon,
  GBuildingBankIcon,
  GTruckDeliveryIcon,
  GUserIcon,
  GUserPlusIcon,
} from '../ui/AppIcon';
import { Typography } from '@mui/material';
import colors from 'src/configs/colors';

const ActivityTimeline = ({ activities }) => {
  return (
    <Timeline
      sx={{
        p: 0,
        marginBlockStart: 0,
        marginBlockEnd: 0,
        [`& .MuiTimelineItem-root::before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {activities.map((item, index) => (
        <TimelineItem key={`activity-${index}`}>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                width: 48,
                height: 48,
                m: 0,
                p: 0,
                boxShadow: 'none',
                border: 'none',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: colors['orange.highlight'],
                color: colors.primary,
              }}
            >
              {item.action.includes('trade/') && <GTruckDeliveryIcon />}
              {item.action.includes('profile/account') && <GUserIcon />}
              {item.action.includes('profile/bank') && <GBuildingBankIcon />}
              {item.action.includes('profile/products') && <GBoxIcon />}
              {item.action.includes('profile/team') && <GUserPlusIcon />}
            </TimelineDot>
            {index < activities.length - 1 && (
              <TimelineConnector
                sx={{
                  bgcolor: 'transparent',
                  width: '1px',
                  height: '40px',
                  borderRight: '1px dashed #D4DAD5',
                }}
              />
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              fontSize={14}
              fontWeight={500}
              lineHeight="22px"
              colorscheme="gray.800"
              display="block"
            >
              {item.message}
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={500}
              lineHeight="18px"
              colorscheme="gray.400"
              display="block"
              mt={0.5}
            >
              {moment(item.createdAt).format('MM/DD/YYYY hh:mm A')}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

ActivityTimeline.propTypes = {
  activities: PropTypes.array,
};

export default ActivityTimeline;
