import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';

import { GFileIcon, GUploadIcon } from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';
import { apiUploadFiles } from 'src/apis/upload';

const SPAInput = ({ value, onChange }) => {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
    accept: {
      'application/pdf': [],
    },
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      onChange({
        file: file,
        url: null,
      });

      const formData = new FormData();
      formData.append('files', file);
      formData.append('key', 'trades/spa');

      apiUploadFiles(formData)
        .then((result) => {
          onChange({
            file: file,
            url: result,
          });
        })
        .catch(() => {
          onChange(null);
        });
    }
  }, [acceptedFiles]);

  return (
    <Box mt={0.5}>
      <Stack
        sx={{
          py: 3,
          px: 5,
          borderRadius: '6px',
          border: '2px dashed #CDD2E1',
          cursor: 'pointer',
        }}
        justifyContent="center"
        alignItems="center"
        spacing={1}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <GUploadIcon
          sx={{
            width: 32,
            height: 32,
            color: colors['gray.500'],
          }}
        />
        <Typography fontSize={16} lineHeight={1.5} colorscheme="gray.500">
          {t('Drop your file here, or browse')}
        </Typography>
        <Typography fontSize={14} lineHeight={1.5} colorscheme="gray.500">
          PDF, Max size: 10MB
        </Typography>
      </Stack>
      <Stack mt={2} spacing={1}>
        {value?.file ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <GFileIcon
              sx={{
                color: colors.text,
              }}
            />
            <Stack flex={1}>
              <Typography fontSize={14}>{value?.file?.name}</Typography>
              <Typography fontSize={12}>
                {value?.file?.uploaded
                  ? 'Uploaded'
                  : prettyBytes(value?.file?.size)}
              </Typography>
            </Stack>
            {value?.url ? null : <CircularProgress size={20} />}
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};

SPAInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default SPAInput;
