import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Stack,
  Link,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Swal from 'sweetalert2';

import { GEyeIcon, GEyeOffIcon } from 'src/components/ui/AppIcon';
import PasswordStrengthIndicator from '../../components/ui/PasswordStrengthIndicator';
import colors from 'src/configs/colors';
import { apiResetPassword } from 'src/apis/auth';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const schema = useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .trim()
          .min(12, 'The password should be more than 12 characters')
          .matches(
            /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/,
            t(
              'Please make sure to include at least 1 letter, number and special character.',
            ),
          )
          .required(t('The password is required')),
      }),
    [t],
  );

  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [showing, setShowing] = useState(false);

  const handleResetPassword = useCallback(
    (values) => {
      setLoading(true);
      apiResetPassword({
        email: params.get('email'),
        password: values.password,
        code: params.get('code'),
      })
        .then((result) => {
          if (result) {
            Swal.fire({
              title: t('Password Changed'),
              text: t('Your password has been changed successfully'),
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: t('Done'),
              confirmButtonColor: colors.primary,
            }).then(() => {
              navigate('/auth/login', {
                replace: true,
              });
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading],
  );

  const togglePasswordView = useCallback(() => {
    setShowing((value) => !value);
  }, []);

  return (
    <Stack
      minHeight="100vh"
      py={3}
      px={6}
      boxSizing="border-box"
      alignItems="center"
      justifyContent="center"
    >
      <Box maxWidth={512}>
        <Typography fontSize={48} fontWeight={600} colorscheme="heading">
          {t('Reset Password')}
        </Typography>
        <Typography
          fontSize={16}
          mt={3}
          lineHeight={1.5}
          colorscheme="gray.500"
        >
          {t('Your new password must be different from the old password')}
        </Typography>
        <Controller
          name="password"
          control={control}
          render={({ field, formState: { errors } }) => (
            <>
              <FormControl
                fullWidth
                variant="standard"
                error={!!errors.password}
                disabled={loading}
                sx={{ mt: 4 }}
              >
                <InputLabel>{t('Password')}</InputLabel>
                <OutlinedInput
                  placeholder={t('Your Password')}
                  type={showing ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordView}>
                        {showing ? <GEyeOffIcon /> : <GEyeIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...field}
                />
                {errors.password?.message && (
                  <FormHelperText>{errors.password?.message}</FormHelperText>
                )}
              </FormControl>
              <PasswordStrengthIndicator password={field.value} mt={2} />
            </>
          )}
        />
        <LoadingButton
          fullWidth
          sx={{ mt: 5 }}
          variant="contained"
          loading={loading}
          onClick={handleSubmit(handleResetPassword)}
        >
          {t('Reset')}
        </LoadingButton>
        <Typography fontSize={16} mt={3} display="block" textAlign="center">
          {t('Do you remember your password?')}{' '}
          <Link component={NavLink} to="/auth/login" display="inline">
            {t('Sign In')}
          </Link>
        </Typography>
      </Box>
    </Stack>
  );
};

export default ResetPasswordPage;
