import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  Link,
  Box,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from 'src/components/ui/AppIcon';

export default function PhoneNumberConfirmationModal({
  open,
  phoneNumber,
  onClose,
  onSubmit,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 495,
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          color="#303030"
          variant="h5"
          lineHeight="38px"
          fontWeight={600}
        >
          Confirm your phone number
        </HighlightedTypography>
        <Typography mt={3}>
          Please make sure that your phone number is configured correctly. You
          will need to verify your phone number first before you update your
          Extra Security settings. If you want to use other phone number, please
          update your account in the{' '}
          <Link component={NavLink} to="/profile/account" display="inline">
            Profile
          </Link>{' '}
          page.
        </Typography>
        <Box mt={3}>
          <Typography variant="sm" colorscheme="gray.400" textAlign="center">
            Your Phone Number
          </Typography>
          <Typography
            variant="lg"
            colorscheme="heading"
            fontWeight={700}
            textAlign="center"
          >
            {phoneNumber
              ? formatPhoneNumberIntl('+' + phoneNumber)
              : 'Not configured'}
          </Typography>
        </Box>
        <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              px: 2,
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              px: 2,
            }}
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
}

PhoneNumberConfirmationModal.propTypes = {
  open: PropTypes.bool,
  phoneNumber: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
