import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
  Box,
  Stack,
  OutlinedInput,
  InputAdornment,
  Avatar,
  Autocomplete,
  Link,
  IconButton,
  Divider,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import Scrollbars from 'react-custom-scrollbars-2';
import { useQuery } from 'react-query';
import clone from 'clone';

import {
  GCloseIcon,
  GDollarIcon,
  GPhotoIcon,
  GTestPipeIcon,
} from 'src/components/ui/AppIcon';
import CollapseButton from 'src/components/ui/CollapseButton';

import constants from 'src/configs/constants';
import colors from 'src/configs/colors';
import { apiGetMyProducts } from 'src/apis/profile';

const PageShipmentCard = ({
  index,
  shipment,
  onChangeShipment,
  onRemoveShipment,
  defaultProduct,
}) => {
  const [opened, setOpened] = useState(true);
  const { data: products } = useQuery(['profile', 'products'], async () => {
    return await apiGetMyProducts();
  });

  const handleToggle = () => {
    setOpened((value) => !value);
  };

  const handleChangeItemField = (i, field, value) => {
    const newShipment = clone(shipment);
    newShipment.items[i][field] = value;

    if (field === 'name') {
      let targetProduct = null;
      for (const row of products) {
        if (row.name === value) {
          targetProduct = row;
          break;
        }
      }
      if (targetProduct) {
        newShipment.items[i].image = targetProduct.photos[0];
        newShipment.items[i].price = targetProduct.price;
        newShipment.items[i].unit = targetProduct.unit;
      }
    }

    onChangeShipment(newShipment);
  };

  const handleAddItem = () => {
    const newShipment = clone(shipment);
    newShipment.items.push({
      id: Date.now(),
      image: defaultProduct.photo,
      name: defaultProduct.name,
      price: defaultProduct.price,
      quantity: 0,
      unit: defaultProduct.unit,
      product: defaultProduct.id,
    });
    onChangeShipment(newShipment);
  };

  const handleRemoveItem = (i) => {
    const newShipment = clone(shipment);
    if (newShipment.items.length > 1) {
      newShipment.items.splice(i, 1);
      onChangeShipment(newShipment);
    }
  };

  const handleChangeTesting = (i, field, value) => {
    const newShipment = clone(shipment);
    newShipment.testings[i][field] = value;
    onChangeShipment(newShipment);
  };

  const handleAddTesting = () => {
    const newShipment = clone(shipment);
    newShipment.testings.push({
      name: 'Quality Report',
      stage: 'after-export',
    });
    onChangeShipment(newShipment);
  };

  const handleRemoveTesting = (i) => {
    const newShipment = clone(shipment);
    if (newShipment.testings.length > 0) {
      newShipment.testings.splice(i, 1);
      onChangeShipment(newShipment);
    }
  };

  const handleChangePayments = (i, field, value) => {
    const newShipment = clone(shipment);
    newShipment.payments[i][field] = value;
    onChangeShipment(newShipment);
  };

  const handleAddPayment = () => {
    const newShipment = clone(shipment);
    newShipment.payments.push({
      name: `Shipment ${index} Payment`,
      stage: 'after-export',
      total: 0,
    });
    onChangeShipment(newShipment);
  };

  const handleRemovePayment = (i) => {
    if (shipment.payments.length <= 1) {
      return;
    }

    const newShipment = clone(shipment);
    if (newShipment.payments.length > 0) {
      newShipment.payments.splice(i, 1);
      onChangeShipment(newShipment);
    }
  };

  return (
    <Card
      sx={{
        mb: 2.5,
      }}
    >
      <CardHeader
        title={
          <Typography fontSize={18} fontWeight={600} colorscheme="heading">
            Order {index}
          </Typography>
        }
        emphasis="true"
        action={<CollapseButton opened={opened} onToggle={handleToggle} />}
      />
      <Collapse in={opened}>
        <CardContent
          sx={{
            pb: '16px !important',
          }}
        >
          <Typography fontSize={18} fontWeight={600} colorscheme="heading">
            Cargo
          </Typography>
          <Scrollbars
            style={{
              width: '100%',
            }}
            autoHeight
            autoHeightMax="100vh"
          >
            <Box
              mt={2}
              pb={1.5}
              sx={{
                width: '100%',
                height: 'auto',
                overflow: 'visible',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                overflowX="auto"
                spacing={1.5}
                mb={1}
              >
                <Box flex={1} minWidth={230}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    colorscheme="gray.400"
                    pl={1}
                  >
                    Item
                  </Typography>
                </Box>
                <Box width={150}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    colorscheme="gray.400"
                    textAlign="center"
                  >
                    Qty
                  </Typography>
                </Box>
                <Box width={150}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    colorscheme="gray.400"
                    textAlign="right"
                    pr={1}
                  >
                    Cost
                  </Typography>
                </Box>
                <Box width={32}></Box>
              </Stack>
              <Stack spacing={1.5}>
                {shipment.items.map((item, i) => (
                  <Stack
                    key={`shipment-item-${i}`}
                    direction="row"
                    alignItems="center"
                    spacing={1.5}
                  >
                    <Box flex={1} minWidth={230}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1.5}
                        width="100%"
                      >
                        <Avatar
                          src={constants.getStoragePath(item.image)}
                          sx={{
                            width: 48,
                            height: 48,
                          }}
                          variant="rounded"
                        >
                          <GPhotoIcon />
                        </Avatar>
                        <Autocomplete
                          options={
                            products ? products.map((row) => row.name) : []
                          }
                          value={item.name}
                          onChange={(ev, value) =>
                            handleChangeItemField(i, 'name', value)
                          }
                          renderInput={({ InputProps, inputProps }) => (
                            <OutlinedInput
                              inputProps={inputProps}
                              {...InputProps}
                              fullWidth
                            />
                          )}
                          sx={{
                            flex: 1,
                            display: 'block',
                          }}
                        />
                      </Stack>
                    </Box>
                    <Box width={150} minWidth={150}>
                      <OutlinedInput
                        fullWidth
                        sx={{
                          '& input': {
                            textAlign: 'right',
                            paddingRight: 0,
                          },
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <Typography colorscheme="gray.500">
                              {item.unit}
                            </Typography>
                          </InputAdornment>
                        }
                        value={item.quantity}
                        onChange={(ev) =>
                          handleChangeItemField(
                            i,
                            'quantity',
                            ev.currentTarget.value,
                          )
                        }
                      />
                    </Box>
                    <Box width={150} minWidth={150}>
                      <OutlinedInput
                        fullWidth
                        value={`$${(
                          item.price * item.quantity
                        ).toLocaleString()}`}
                        sx={{
                          '& input': {
                            textAlign: 'right',
                          },
                        }}
                      />
                    </Box>
                    <Box>
                      <IconButton
                        sx={{
                          bgcolor: colors['gray.200'],
                        }}
                        onClick={() => handleRemoveItem(i)}
                      >
                        <GCloseIcon
                          sx={{
                            color: colors['gray.400'],
                            width: 16,
                            height: 16,
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Scrollbars>
          <Box mt={0.5}>
            <Link
              sx={{
                display: 'inline-block',
                cursor: 'pointer',
              }}
              onClick={handleAddItem}
            >
              + Line Item
            </Link>
          </Box>
          <Divider sx={{ my: 2, display: 'none' }} />
          <Typography
            fontSize={18}
            fontWeight={600}
            colorscheme="heading"
            display="none"
          >
            Quality Testing
          </Typography>
          {shipment.testings.length > 0 ? (
            <Scrollbars
              style={{
                display: 'none',
                width: '100%',
              }}
              autoHeight
            >
              <Box
                mt={2}
                pb={1.5}
                sx={{
                  width: '100%',
                }}
              >
                <Stack direction="row" alignItems="center" mb={1}>
                  <Box flex={1} minWidth={230}>
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      colorscheme="gray.400"
                      pl={1}
                    >
                      Report Name
                    </Typography>
                  </Box>
                  <Box flex={1} minWidth={200}>
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      colorscheme="gray.400"
                      pl={1}
                    >
                      Stage
                    </Typography>
                  </Box>
                  <Box width={32}></Box>
                </Stack>
                <Stack spacing={1.5}>
                  {shipment.testings.map((row, i) => (
                    <Stack
                      key={`quality-testing-${i}`}
                      direction="row"
                      alignItems="center"
                      spacing={1.5}
                    >
                      <Box flex={1} minWidth={230}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                        >
                          <Box
                            borderRadius={1}
                            bgcolor={colors['royal.blue']}
                            width={52}
                            height={52}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <GTestPipeIcon sx={{ color: 'white' }} />
                          </Box>
                          <OutlinedInput
                            sx={{
                              flex: 1,
                            }}
                            value={row.name}
                            onChange={(ev) =>
                              handleChangeTesting(
                                i,
                                'name',
                                ev.currentTarget.value,
                              )
                            }
                          />
                        </Stack>
                      </Box>
                      <Box flex={1} minWidth={200}>
                        <Select
                          fullWidth
                          value={row.stage}
                          onChange={(ev) =>
                            handleChangeTesting(
                              i,
                              'stage',
                              ev.currentTarget.value,
                            )
                          }
                        >
                          <MenuItem value="after-export">
                            After Export & Loading
                          </MenuItem>
                          <MenuItem value="after-import">
                            After Import & Delivery
                          </MenuItem>
                          <MenuItem value="after-payment">
                            After Payment
                          </MenuItem>
                        </Select>
                      </Box>
                      <Box>
                        <IconButton
                          sx={{
                            bgcolor: colors['gray.200'],
                          }}
                          onClick={() => handleRemoveTesting(i)}
                        >
                          <GCloseIcon
                            sx={{
                              color: colors['gray.400'],
                              width: 16,
                              height: 16,
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Scrollbars>
          ) : (
            <Box height={12} display="none" />
          )}
          <Box mt={0.5} display="none">
            <Link
              sx={{
                cursor: 'pointer',
                display: 'inline-block',
              }}
              onClick={handleAddTesting}
            >
              + Quality Testing
            </Link>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography fontSize={18} fontWeight={600} colorscheme="heading">
            Payment
          </Typography>
          {shipment.payments.length > 0 ? (
            <Scrollbars
              style={{
                width: '100%',
              }}
              autoHeight
            >
              <Box
                mt={2}
                pb={1.5}
                sx={{
                  width: '100%',
                }}
              >
                <Stack spacing={1.5}>
                  {shipment.payments.map((row, i) => (
                    <Stack
                      key={`quality-testing-${i}`}
                      direction="row"
                      alignItems="center"
                      spacing={1.5}
                    >
                      <Box flex={1} minWidth={230}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                        >
                          <Box
                            borderRadius={1}
                            bgcolor={colors['royal.blue']}
                            width={52}
                            height={52}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <GDollarIcon sx={{ color: 'white' }} />
                          </Box>
                          <OutlinedInput
                            sx={{
                              flex: 1,
                            }}
                            value={row.name}
                            onChange={(ev) =>
                              handleChangePayments(
                                i,
                                'name',
                                ev.currentTarget.value,
                              )
                            }
                          />
                        </Stack>
                      </Box>
                      <Box width={220} minWidth={150} display="none">
                        <Select
                          fullWidth
                          value={row.stage}
                          onChange={(ev) =>
                            handleChangePayments(
                              i,
                              'stage',
                              ev.currentTarget.value,
                            )
                          }
                          disabled
                        >
                          <MenuItem value="after-manufacturing">
                            After Manufacturing
                          </MenuItem>
                          <MenuItem value="after-export">
                            After Export & Loading
                          </MenuItem>
                          <MenuItem value="after-import">
                            After Import & Delivery
                          </MenuItem>
                          <MenuItem value="after-test">
                            After Quality Test
                          </MenuItem>
                        </Select>
                      </Box>
                      <Box width={150} minWidth={150} maxWidth={150}>
                        <NumericFormat
                          customInput={OutlinedInput}
                          prefix="$"
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          fullWidth
                          sx={{
                            flex: 1,
                            '& input': {
                              textAlign: 'right',
                            },
                          }}
                          value={row.total}
                          onValueChange={(values) =>
                            handleChangePayments(i, 'total', values.floatValue)
                          }
                        />
                      </Box>
                      <Box>
                        <IconButton
                          sx={{
                            bgcolor: colors['gray.200'],
                          }}
                          onClick={() => handleRemovePayment(i)}
                        >
                          <GCloseIcon
                            sx={{
                              color: colors['gray.400'],
                              width: 16,
                              height: 16,
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Scrollbars>
          ) : (
            <Box height={12} />
          )}
          <Box mt={0.5} display={shipment.payments.length ? 'none' : 'block'}>
            <Link
              sx={{
                cursor: 'pointer',
                display: 'inline-block',
              }}
              onClick={handleAddPayment}
            >
              + Payment Stage
            </Link>
          </Box>
          {index > 1 && (
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <Button
                size="small"
                variant="contained"
                onClick={onRemoveShipment}
              >
                Remove Order
              </Button>
            </Stack>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

PageShipmentCard.propTypes = {
  index: PropTypes.number,
  shipment: PropTypes.any,
  defaultProduct: PropTypes.any,
  onChangeShipment: PropTypes.func,
  onRemoveShipment: PropTypes.func,
};

export default PageShipmentCard;
