import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';

import ProductImageGallery from '../../../../components/ui/ProductImageGallery';
import constants from 'src/configs/constants';

const PageProductImagesView = ({ images, loading }) => {
  return (
    <Box>
      {loading ? (
        <Skeleton
          variant="rounded"
          width="100%"
          height="auto"
          sx={{
            aspectRatio: 3 / 2,
          }}
        />
      ) : (
        <ProductImageGallery
          items={images.map((item) => ({
            thumbnail: constants.getStoragePath(item),
            original: constants.getStoragePath(item),
          }))}
        />
      )}
    </Box>
  );
};

PageProductImagesView.propTypes = {
  images: PropTypes.array,
  loading: PropTypes.bool,
};

export default PageProductImagesView;
