import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import Statistics from '../../../../components/ui/Statistics';
import {
  GBoxIcon,
  GDollarCircleIcon,
  GFileInvoiceIcon,
  GShoppingCartIcon,
} from '../../../../components/ui/AppIcon';
import { apiGetStatsOverview } from 'src/apis/statistics';
import numeral from 'numeral';

const PageStatsSummary = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(['statistics', 'summary'], async () => {
    return await apiGetStatsOverview();
  });

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <Statistics
            title={t('Products')}
            value={data?.products?.count}
            growth={
              data?.products?.growth
                ? Number(data?.products?.growth).toFixed(0)
                : 0
            }
            growthDescription={t('Since last month')}
            icon={<GBoxIcon />}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Statistics
            title={t('Trades')}
            value={data?.trades?.count}
            growth={
              data?.trades?.growth ? Number(data?.trades?.growth).toFixed(0) : 0
            }
            growthDescription={t('Since last month')}
            icon={<GShoppingCartIcon />}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Statistics
            title={t('Invoices')}
            value={data?.invoices?.count}
            growth={
              data?.invoices?.growth
                ? Number(data?.invoices?.growth).toFixed(0)
                : 0
            }
            growthDescription={t('Since last month')}
            icon={<GFileInvoiceIcon />}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Statistics
            title={t('Earning')}
            value={
              data?.earning?.total
                ? `$ ${numeral(data?.earning?.total).format('0.0a')}`
                : '$ 0'
            }
            growth={
              data?.earning?.growth
                ? Number(data?.earning?.growth).toFixed(0)
                : 0
            }
            growthDescription={t('Since last month')}
            icon={<GDollarCircleIcon />}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageStatsSummary;
