import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import { t } from 'i18next';

const ConfirmationModal = ({ open, onConfirm }) => (
  <Dialog
    open={open}
    sx={{
      '& .MuiPaper-root': {
        borderRadius: '12px',
        maxWidth: 492,
      },
      '& .MuiDialogContent-root': {
        p: 3,
      },
    }}
  >
    <DialogContent>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontSize={36} fontWeight={600} colorscheme="heading">
          {t('Request Submitted')}
        </Typography>
      </Stack>
      <Typography mt={3}>
        Thank you for your trade request. We will carefully review your request,
        and if approved, the Seller will promptly provide you with a Sales
        Purchase Agreement for your consideration and acceptance.
      </Typography>
      <Stack mt={4} direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          size="small"
          sx={{
            width: 90,
            height: 40,
          }}
          onClick={onConfirm}
        >
          {t('OK')}
        </Button>
      </Stack>
    </DialogContent>
  </Dialog>
);

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  seller: PropTypes.any,
  onConfirm: PropTypes.func,
};

export default ConfirmationModal;
