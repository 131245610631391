import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Stack,
  Box,
  Grid,
  Select,
  MenuItem,
  Link,
  OutlinedInput,
  Autocomplete,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import colors from 'src/configs/colors';
import ports from 'src/configs/ports';
import IncotermsModal from './IncotermsModal';

const incoterms = {
  EXW: {
    description: 'Ex Works (place)',
    risk: 'At buyers Disposal',
  },
  FOB: {
    description: 'Free on Board (port)',
    risk: 'On Board Vessel',
  },
  CFR: {
    description: 'Cost & Freight (port)',
    risk: 'On Board Vessel',
  },
  CIF: {
    description: 'Cost, Insurance & Freight (port)',
    risk: 'On Board Vessel',
  },
};

const PageShippingPreferencesCard = ({
  control,
  incoterms: incotermsValue,
  availableIncoterms,
}) => {
  const { t } = useTranslation();

  const [showIncoterms, setShowIncoterms] = React.useState(false);

  const toggleIncotermsModal = () => {
    setShowIncoterms((value) => !value);
  };

  return (
    <Card
      sx={{
        mt: 2,
      }}
    >
      <CardHeader
        title={
          <Typography fontSize={20} fontWeight={500} colorscheme="heading">
            {t('Shipping Preference')}
          </Typography>
        }
        emphasis="true"
      />
      <CardContent
        sx={{
          pt: 0,
          pb: '16px !important',
        }}
      >
        <Box px={3}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="incoterms"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Stack spacing={0.5}>
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        colorscheme="heading"
                      >
                        {t('Incoterms')}
                      </Typography>
                      <Select
                        id="field-incoterms"
                        fullWidth
                        {...field}
                        error={!!error}
                        displayEmpty
                        renderValue={
                          field.value
                            ? undefined
                            : () => (
                                <Typography colorscheme="gray.600">
                                  {t('Select')}
                                </Typography>
                              )
                        }
                      >
                        {['EXW', 'FOB', 'CFR', 'CIF']
                          .filter((item) => availableIncoterms.includes(item))
                          .map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                      </Select>
                    </Stack>
                  </>
                )}
              />
              <Stack spacing={1} mt={2.5}>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  colorscheme="gray.600"
                >
                  {t('Description')}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  colorscheme="heading"
                >
                  {incotermsValue
                    ? incoterms[incotermsValue]?.description
                    : 'No description'}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="port"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Stack spacing={0.5}>
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        colorscheme="heading"
                      >
                        {t('Port')}
                      </Typography>
                      <Autocomplete
                        id="field-port"
                        value={field.value}
                        onChange={(ev, value) => field.onChange(value)}
                        options={ports}
                        getOptionLabel={(item) => item}
                        renderInput={({ InputProps, inputProps }) => (
                          <OutlinedInput
                            {...InputProps}
                            inputProps={inputProps}
                            sx={{
                              py: '7px !important',
                            }}
                            placeholder={t('Enter port')}
                            fullWidth
                            error={!!error}
                          />
                        )}
                      />
                    </Stack>
                  </>
                )}
              />
              <Stack spacing={1} mt={2.5}>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  colorscheme="gray.600"
                >
                  {t('Transfer of Risk')}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  colorscheme="heading"
                >
                  {incotermsValue ? incoterms[incotermsValue]?.risk : 'No risk'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Link
            href="#"
            color={colors.text}
            fontWeight={500}
            onClick={toggleIncotermsModal}
          >
            {t('Learn More about Incoterms')}
          </Link>
        </Box>
        <IncotermsModal open={showIncoterms} onClose={toggleIncotermsModal} />
      </CardContent>
    </Card>
  );
};

PageShippingPreferencesCard.propTypes = {
  control: PropTypes.any,
  incoterms: PropTypes.any,
  availableIncoterms: PropTypes.array,
};

export default PageShippingPreferencesCard;
