import React from 'react';
import { Box, Container, Grid } from '@mui/material';

import PageHeader from './ticket-detail-page/PageHeader';
import PageChatView from './ticket-detail-page/PageChatView';
import PageTicketSummary from './ticket-detail-page/PageTicketSummary';

const TicketDetailPage = () => {
  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <PageHeader />
      <Box>
        <Grid
          container
          spacing={2.5}
        >
          <Grid
            item
            xs={12}
            md={8}
          >
            <PageChatView />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
          >
            <PageTicketSummary />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default TicketDetailPage;
