import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getDatabase, ref, onValue } from 'firebase/database';

import PageHeader from './sale-order-detail-page/PageHeader';
import PageTabs from './sale-order-detail-page/PageTabs';
import PageSummaryTab from './sale-order-detail-page/PageSummaryTab';
import PageTradeChattingTab from './common/PageTradeChattingTab';
import RejectTradeModal from './sale-order-detail-page/RejectTradeDialog';
import CompleteManufacturingModal from './sale-order-detail-page/CompleteManufacturingModal';
import ShippingDocsModal from './sale-order-detail-page/ShippingDocsModal';
import RequestSettlementModal from './sale-order-detail-page/RequestSettlementModal';
import AcceptAgreementModal from './common/AcceptAgreementModal';
import PageSignerViewModal from './purchase-order-detail-page/PageSignerViewModal';
import PageDocumentsTab from './common/PageDocumentsTab';
import PageAuditTrailTab from './common/PageAuditTrailTab';
import {
  apiCompleteShipment,
  apiFinishTradeAgreements,
  apiGetTradeDetails,
  apiRejectTrade,
  apiSendManufacturingDocuments,
  apiSendShippingDocuments,
} from 'src/apis/trades';
import colors from 'src/configs/colors';
import constants from 'src/configs/constants';

export default function SaleOrderDetailPage() {
  const { t } = useTranslation();
  const params = useParams();
  const query = useSearchParams();
  const navigate = useNavigate();
  const tradeId = params.tradeId;

  const { data, isLoading, refetch } = useQuery(
    ['orders', tradeId],
    async () => {
      return await apiGetTradeDetails(tradeId);
    },
  );

  const [tab, setTab] = useState('summary');
  const [modals, setModals] = useState({});
  const [processing, setProcessing] = useState(false);

  const [signerView, setSignerView] = useState('');

  const handleOpenModal = (modalName) => {
    setModals((values) => ({
      ...values,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName) => {
    setModals((values) => ({
      ...values,
      [modalName]: false,
    }));
  };

  const handleRejectTrade = (values) => {
    setProcessing(true);
    apiRejectTrade({
      id: tradeId,
      reason: values.reason,
    })
      .then((result) => {
        if (result) {
          handleCloseModal('reject-trade');
          refetch();
          Swal.fire({
            title: 'Rejection Reason Submitted',
            text: 'Your commentary has successfully been submitted',
            icon: 'success',
            showConfirmButton: true,
            confirmButtonText: 'OK',
            confirmButtonColor: colors.primary,
          });
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleFinishAgreement = () => {
    setProcessing(true);
    apiFinishTradeAgreements({
      id: tradeId,
    })
      .then((result) => {
        if (result) {
          handleCloseModal('finish-agreement');
          refetch();

          if (result.signerView) {
            setSignerView(result.signerView);
          }
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleSendManufacturingDocuments = (documents) => {
    setProcessing(true);
    apiSendManufacturingDocuments({
      id: tradeId,
      documents: documents,
    })
      .then((result) => {
        if (result) {
          handleCloseModal('complete-manufacturing');
          refetch();
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleSendShippingDocuments = (documents) => {
    setProcessing(true);
    apiSendShippingDocuments({
      id: tradeId,
      documents: documents,
    })
      .then((result) => {
        if (result) {
          handleCloseModal('shipping-docs');
          refetch();
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleRequestSettlement = () => {
    setProcessing(true);
    apiCompleteShipment({
      id: tradeId,
    })
      .then((result) => {
        if (result) {
          handleCloseModal('finish-shipment');
          refetch();
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleViewContract = () => {
    if (data?.bcContractAddress) {
      window.open(
        constants.getBlockchainContractPath(data.bcContractAddress),
        'blank',
      );
    }
  };

  const handleViewMessage = () => {
    setTab('messages');
  };

  useEffect(() => {
    const db = getDatabase();
    const tradeRef = ref(db, `trades/${tradeId}`);
    const unsubscribe = onValue(tradeRef, (snapshot) => {
      if (snapshot.exists()) {
        refetch();
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (tab) {
      query[1](
        new URLSearchParams({
          tab: tab,
        }),
      );
    }
  }, [tab]);

  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <PageHeader
        loading={processing}
        data={data}
        onReject={() => handleOpenModal('reject-trade')}
        onFinishAgreement={() => handleOpenModal('finish-agreement')}
        onIssueInvoice={() => navigate('invoice-builder')}
        onCompleteManufacturing={() =>
          handleOpenModal('complete-manufacturing')
        }
        onUploadShippingDocs={() => handleOpenModal('shipping-docs')}
        onRequestSettlement={() => handleOpenModal('finish-shipment')}
        onViewContract={handleViewContract}
        onViewMessage={handleViewMessage}
      />
      <PageTabs tab={tab} onChangeTab={setTab} />
      <Box mt={2.5}>
        {!isLoading && data ? (
          <>
            <PageSummaryTab
              active={tab === 'summary'}
              data={data}
              refetch={refetch}
            />
            <PageDocumentsTab active={tab === 'documentations'} data={data} />
            <PageTradeChattingTab active={tab === 'messages'} data={data} />
            <PageAuditTrailTab active={tab === 'audit-trail'} data={data} />
          </>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={1.5}
            height={300}
          >
            <CircularProgress size={24} />
            <Typography>{t('Loading')}</Typography>
          </Stack>
        )}
      </Box>

      <RejectTradeModal
        open={modals['reject-trade'] ?? false}
        processing={processing}
        onClose={() => handleCloseModal('reject-trade')}
        onFinish={handleRejectTrade}
      />
      <CompleteManufacturingModal
        open={modals['complete-manufacturing'] ?? false}
        processing={processing}
        onClose={() => handleCloseModal('complete-manufacturing')}
        onFinish={handleSendManufacturingDocuments}
      />
      <ShippingDocsModal
        open={modals['shipping-docs'] ?? false}
        processing={processing}
        onClose={() => handleCloseModal('shipping-docs')}
        onFinish={handleSendShippingDocuments}
      />
      <RequestSettlementModal
        open={modals['finish-shipment'] ?? false}
        loading={processing}
        onClose={() => handleCloseModal('finish-shipment')}
        onAccept={handleRequestSettlement}
      />
      <AcceptAgreementModal
        open={modals['finish-agreement'] ?? false}
        loading={processing}
        onClose={() => handleCloseModal('finish-agreement')}
        onAccept={handleFinishAgreement}
      />
      <PageSignerViewModal
        url={signerView}
        open={!!signerView}
        onFinish={() => setSignerView('')}
      />
    </Container>
  );
}
