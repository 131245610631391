import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import OrderStatusChip from 'src/components/chips/OrderStatusChip';
import TableFilter from 'src/components/ui/TableFilter';
import ActionMenu from './ActionMenu';
import { GUserIcon } from 'src/components/ui/AppIcon';
import constants from 'src/configs/constants';

const PageOrdersView = ({
  loading,
  data,
  pageIndex,
  pageSize,
  total,
  filters,
  onChangePagination,
  onChangeSort,
  onChangeSearch,
  onChangeFilters,
  onViewAction,
  onAssignAction,
  onHelpAction,
}) => {
  const { t } = useTranslation();

  const [paginationModel, setPaginationModel] = useState({
    page: pageIndex - 1,
    pageSize: pageSize,
  });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({
    incoterms: '',
    status: '',
  });

  const columns = useMemo(
    () => [
      {
        field: '_id',
        headerName: t('Trade No.'),
        width: 90,
        valueFormatter: ({ value }) => `#${value.substr(-6).toUpperCase()}`,
      },
      {
        field: 'seller',
        headerName: t('Seller'),
        width: 180,
        sortable: false,
        renderCell: ({ value }) => (
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Avatar
              src={constants.getStoragePath(value?.company?.logo)}
              sx={{
                width: 32,
                height: 32,
              }}
            >
              <GUserIcon />
            </Avatar>
            <div className="MuiDataGrid-cellContent">
              {value?.company?.name}
            </div>
          </Stack>
        ),
      },
      {
        field: 'product',
        headerName: t('Product'),
        flex: 1,
        valueFormatter: ({ value }) => value?.name,
        sortable: false,
      },
      {
        field: 'incoterms',
        headerName: t('Incoterms'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'total',
        headerName: t('Value'),
        sortable: false,
        renderCell: ({ row }) => {
          return '$' + row.total.toLocaleString();
        },
        width: 150,
      },
      {
        field: 'status',
        headerName: t('Status'),
        renderCell: ({ row }) => <OrderStatusChip status={row.status} />,
        width: 180,
        sortable: false,
      },
      {
        field: '_',
        headerName: t('Action'),
        sortable: false,
        renderCell: ({ row }) => (
          <ActionMenu
            onView={() => onViewAction(row._id)}
            onHelp={() => onHelpAction(row._id)}
            onAssign={() => onAssignAction(row._id)}
          />
        ),
      },
    ],
    [onViewAction, onHelpAction, onAssignAction],
  );

  useEffect(() => {
    setPaginationModel({
      page: pageIndex - 1,
      pageSize: pageSize,
    });
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (filters.sortBy && filters.sortOrder) {
      setSortModel([
        {
          field: filters.sortBy,
          sort: filters.sortOrder,
        },
      ]);
    } else {
      setSortModel([]);
    }
  }, [filters.sortBy, filters.sortOrder]);

  useEffect(() => {
    setFilterModel({
      incoterms: filters.incoterms ? filters.incoterms : '',
      status: filters.status ? filters.status : '',
    });
  }, [filters.incoterms, filters.status]);

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Typography fontSize={20} fontWeight={500} colorscheme="heading">
            {t('Trade List')}
          </Typography>
        }
        emphasis="true"
        action={
          <TableFilter
            hideSorter
            filterDropdown={
              <Box width={300} p={2.5} boxSizing="border-box">
                <FormControl fullWidth size="small">
                  <InputLabel>{t('Incoterms')}</InputLabel>
                  <Select
                    value={filterModel.incoterms}
                    onChange={(ev) =>
                      setFilterModel((model) => ({
                        ...model,
                        incoterms: ev.target.value,
                      }))
                    }
                    displayEmpty
                    renderValue={
                      filterModel.incoterms
                        ? undefined
                        : () => (
                            <Typography colorscheme="gray.600">
                              {t('Select incoterms')}
                            </Typography>
                          )
                    }
                  >
                    <MenuItem value={undefined}>{t('Empty')}</MenuItem>
                    {[
                      'EXW',
                      'FCA',
                      'FAS',
                      'FOB',
                      'CFR',
                      'CIF',
                      'CPT',
                      'CIP',
                      'DAP',
                      'DPU',
                      'DDP',
                    ].map((item) => (
                      <MenuItem key={`incoterm-${item}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small" sx={{ mt: 2.5 }}>
                  <InputLabel>{t('Status')}</InputLabel>
                  <Select
                    value={filterModel.status}
                    onChange={(ev) =>
                      setFilterModel((model) => ({
                        ...model,
                        status: ev.target.value,
                      }))
                    }
                    displayEmpty
                    renderValue={
                      filterModel.status
                        ? undefined
                        : () => (
                            <Typography colorscheme="gray.600">
                              {t('Select status')}
                            </Typography>
                          )
                    }
                  >
                    <MenuItem value={undefined}>{t('Empty')}</MenuItem>
                    {['New Request'].map((item) => (
                      <MenuItem key={`status-${item}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  sx={{ mt: 2.5 }}
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={() => onChangeFilters(filterModel)}
                >
                  {t('Search')}
                </Button>
              </Box>
            }
            search={filters.search ? filters.search : ''}
            onSearch={onChangeSearch}
          />
        }
      />
      <CardContent sx={{}}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          paginationModel={paginationModel}
          sortingMode="server"
          sortModel={sortModel}
          rowCount={total}
          autoHeight
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          loading={loading}
          onPaginationModelChange={onChangePagination}
          onSortModelChange={onChangeSort}
          getRowId={(row) => row._id}
        />
      </CardContent>
    </Card>
  );
};

PageOrdersView.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  filters: PropTypes.any,
  onChangePagination: PropTypes.func,
  onChangeSort: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onChangeFilters: PropTypes.func,
  onViewAction: PropTypes.func,
  onAssignAction: PropTypes.func,
  onHelpAction: PropTypes.func,
};

export default PageOrdersView;
