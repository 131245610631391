import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import PageHeader from './add-product-page/PageHeader';
import PageContent from './add-product-page/PageContent';
import notify from 'src/configs/notify';

import {
  apiCreateMyProduct,
  apiGetMyProductDetails,
  apiUpdateMyProduct,
} from 'src/apis/profile';
import { apiGetAllCategories } from 'src/apis/categories';

const schema = yup
  .object({
    name: yup.string().required(),
    category: yup.string().required(),
    location: yup.string().required(),
    incoterms: yup.array().min(1).required(),
    description: yup.string().required(),
    specifications: yup
      .array(
        yup
          .object({
            specification: yup.string().required(),
            value: yup.string().required(),
          })
          .required(),
      )
      .required(),
    photos: yup.array().min(1).required(),
    video: yup.string(),
    attachments: yup.array(),
    price: yup.number().moreThan(0).required(),
    unit: yup.string().required(),
    minimumOrderQuantity: yup.number().moreThan(0).required(),
  })
  .required();

const AddProductPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const productId = params.id;

  const { data, isLoading } = useQuery(
    ['profile', 'products', productId],
    async () => {
      if (productId === 'new') {
        return {};
      }
      return await apiGetMyProductDetails(productId);
    },
  );
  const { data: categories } = useQuery(['categories', 'all'], async () => {
    return await apiGetAllCategories();
  });
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      category: '',
      location: '',
      incoterms: [],
      description: '',
      specifications: [],
      photos: [],
      video: '',
      attachments: [],
      price: 0,
      unit: '',
      minimumOrderQuantity: 0,
    },
    resolver: yupResolver(schema),
  });
  const [drafting, setDrafting] = useState(false);
  const [publishing, setPublishing] = useState(false);

  const handlePublish = async (values) => {
    let result = null;

    setPublishing(true);
    if (productId === 'new') {
      result = await apiCreateMyProduct(values);
    } else {
      result = await apiUpdateMyProduct(productId, {
        ...values,
        status: 'Pending',
      });
    }
    setPublishing(false);
    navigate(`/profile/products?tab=draft`);

    if (result?.id) {
      // navigate(`/profile/products/${result.id}`);
    }
  };

  const handleIndicateError = (data) => {
    const requiredKeys = [
      'name',
      'category',
      'location',
      'incoterms',
      'description',
      'specifications',
      'photos',
      'price',
      'unit',
      'minimumOrderQuantity',
    ];
    for (const key of requiredKeys) {
      if (data[key]) {
        const el = document.getElementById(`field-${key}`);
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
        return;
      }
    }
  };

  const handleSaveDraft = async () => {
    const values = control._formValues;
    let result = null;

    if (!values.name) {
      return notify({
        type: 'warning',
        message: 'To save to draft, at least the product name must be defined',
      });
    }

    setDrafting(true);
    if (productId === 'new') {
      result = await apiCreateMyProduct({
        ...values,
        status: 'Draft',
      });
    } else {
      result = await apiUpdateMyProduct(productId, {
        ...values,
        status: 'Draft',
      });
    }
    setDrafting(false);
    navigate(`/profile/products?tab=draft`);

    if (result?.id) {
      // nothing
    }
  };

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('category', data.category);
      setValue('location', data.location);
      setValue('incoterms', data.incoterms ? data.incoterms : []);
      setValue('description', data.description);
      setValue(
        'specifications',
        data.specifications ? data.specifications : [],
      );
      setValue('photos', data.photos ? data.photos : []);
      setValue('video', data.video);
      setValue('attachments', data.attachments ? data.attachments : []);
      setValue('price', data.price);
      setValue('unit', data.unit);
      setValue('minimumOrderQuantity', data.minimumOrderQuantity);
    }
  }, [data]);

  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <PageHeader
        drafting={drafting}
        publishing={publishing}
        onSaveDraft={handleSaveDraft}
        onPublish={handleSubmit(handlePublish, handleIndicateError)}
      />
      <form>
        {isLoading ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            height={300}
            spacing={2}
          >
            <CircularProgress size={24} />
            <Typography>Loading...</Typography>
          </Stack>
        ) : (
          <PageContent
            control={control}
            categories={categories ? categories : []}
            loading={isLoading}
          />
        )}
      </form>
    </Container>
  );
};

export default AddProductPage;
