import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import constants from '../../configs/constants';

const DevHeader = ({ title, subtitle }) => {
  return (
    <Box>
      <Typography
        variant="h2"
        fontWeight={700}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="h6"
          mt={1}
          color={constants.colors.gray[500]}
        >
          {subtitle}
        </Typography>
      )}
      <Divider sx={{ my: 4 }} />
    </Box>
  );
};

DevHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default DevHeader;
