import axios from 'axios';
import { t } from 'i18next';
import notify from 'src/configs/notify';

export const apiGetProducts = async () => {
  try {
    const response = await axios.get('/api/v1/products');
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};

export const apiGetProductDetails = async (id) => {
  try {
    const response = await axios.get('/api/v1/products/' + id);
    return response.data;
  } catch (e) {
    notify({
      message: t(e.response?.data?.error ?? e.message),
      type: 'error',
    });
    return null;
  }
};
