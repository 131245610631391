import React from 'react';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Stack,
  CircularProgress,
} from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line as LineChart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import moment from 'moment';

import colors from '../../../../configs/colors';
import { apiGetBalanceHistory } from 'src/apis/statistics';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const PageBalanceHistory = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(
    ['statistics', 'balance-history'],
    async () => {
      const data = await apiGetBalanceHistory();
      const result = [];

      const start = moment()
        .subtract(3, 'months')
        .startOf('month')
        .startOf('week');
      const end = moment().endOf('week');

      for (const t = start.clone(); t.isSameOrBefore(end); t.add(1, 'week')) {
        const week = t.clone();
        const total = data
          .filter((item) => {
            return moment(item.date).isSame(week, 'week');
          })
          .reduce((acc, item) => {
            return acc + item.total;
          }, 0);
        result.push({
          date: week.format('MM/DD'),
          total: total,
        });
      }

      return result;
    },
  );

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Typography fontSize={20} fontWeight={500} colorscheme="heading">
            {t('Balance History')}
          </Typography>
        }
        emphasis="true"
        action={<Typography>Last 3 months</Typography>}
      />
      <CardContent
        sx={{
          pb: '16px !important',
          height: 350,
        }}
      >
        {isLoading ? (
          <Stack height={200} justifyContent="center" alignItems="center">
            <CircularProgress size={20} />
            <Typography mt={1.5}>Loading</Typography>
          </Stack>
        ) : (
          <>
            {data?.length > 0 ? (
              <LineChart
                data={{
                  labels: data ? data.map((item) => item.date) : [],
                  datasets: [
                    {
                      label: 'Total',
                      data: data ? data.map((item) => item.total) : data,
                      borderColor: colors.primary,
                      backgroundColor: colors.primary + '33',
                      fill: true,
                      radius: 6,
                      pointHoverRadius: 8,
                      pointHoverBorderWidth: 3,
                      pointBackgroundColor: '#ffffff',
                      pointHoverBackgroundColor: '#ffffff',
                      pointBorderWidth: 3,
                      borderWidth: 4,
                      tension: 0.45,
                      xAxisID: 'xAxis',
                      yAxisID: 'yAxis',
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: false,
                    tooltip: {
                      titleFont: {
                        family: 'Montserrat',
                      },
                      bodyFont: {
                        family: 'Montserrat',
                        lineHeight: 1.5,
                      },
                      callbacks: {
                        label: (ctx) => {
                          let label = ctx.dataset.label || '';
                          if (label) {
                            label += ': ';
                          }
                          if (ctx.parsed.y !== null) {
                            label += '$' + ctx.parsed.y;
                          }
                          return label;
                        },
                      },
                    },
                  },
                  interaction: {
                    intersect: false,
                  },
                  scales: {
                    yAxis: {
                      // min: 120,
                      // max: 280,
                      ticks: {
                        font: {
                          family: 'Montserrat',
                        },
                        callback: (label) => {
                          return '$' + label;
                        },
                      },
                    },
                    xAxis: {
                      grid: {
                        display: false,
                      },
                      ticks: {
                        font: {
                          family: 'Montserrat',
                        },
                      },
                    },
                  },
                }}
              />
            ) : (
              <Stack height={350} justifyContent="center" alignItems="center">
                <Typography>No available data</Typography>
              </Stack>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PageBalanceHistory;
