import React, { useCallback, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';

import { GEyeIcon, GEyeOffIcon } from 'src/components/ui/AppIcon';
import Center from '../../components/ui/Center';
import PasswordStrengthIndicator from '../../components/ui/PasswordStrengthIndicator';
import colors from 'src/configs/colors';
import { apiSignUp } from '../../apis/auth';
import { setAuth } from '../../redux/auth/slice';

const schema = yup.object({
  email: yup
    .string()
    .email('Your email address is not a valid one')
    .required('Please provide your email address'),
  password: yup
    .string()
    .trim()
    .min(12, 'The password should be more than 12 characters')
    .matches(
      /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/,
      'Please make sure to include at least 1 letter, number and special character.',
    )
    .required('The password is required'),
  agreed: yup.bool().oneOf([true]),
});

const RegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
      agreed: false,
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [showing, setShowing] = useState(false);

  const handleSignUp = useCallback(
    (values) => {
      setLoading(true);
      apiSignUp({
        email: values.email,
        password: values.password,
      })
        .then((result) => {
          if (result) {
            Swal.fire({
              title: 'Email Sent',
              text: `We’ve sent a verification email to ${values.email}`,
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Done',
              confirmButtonColor: colors.primary,
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then(() => {
              dispatch(
                setAuth({
                  ...result.user,
                }),
              );
              navigate(`/auth/verify?action=register&email=${values.email}`, {
                replace: true,
              });
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, navigate],
  );

  const togglePasswordView = useCallback(() => {
    setShowing((value) => !value);
  }, []);

  return (
    <Center minHeight="100vh " py={3} px={6} boxSizing="border-box">
      <Box maxWidth={512}>
        <Typography variant="h3" weight="bold" colorscheme="gray.900" mb={3}>
          Forge Everlasting Trade Partnerships
        </Typography>
        <Typography variant="xl" colorscheme="gray.500" mb={6}>
          OODL Marketplace, the first blockchain driven physical commodity
          trading platform.
        </Typography>
        <Box>
          <Controller
            name="email"
            control={control}
            render={({ field, formState: { errors } }) => (
              <FormControl
                fullWidth
                variant="standard"
                error={!!errors.email}
                disabled={loading}
              >
                <InputLabel>Email</InputLabel>
                <OutlinedInput placeholder="Your Email Address" {...field} />
                {errors.email?.message && (
                  <FormHelperText>{errors.email?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field, formState: { errors } }) => (
              <>
                <FormControl
                  fullWidth
                  variant="standard"
                  sx={{ mt: 3 }}
                  error={!!errors.password}
                  disabled={loading}
                >
                  <InputLabel>Password</InputLabel>
                  <OutlinedInput
                    placeholder="Your Password"
                    type={showing ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordView}>
                          {showing ? <GEyeOffIcon /> : <GEyeIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    {...field}
                  />
                  {errors.password?.message && (
                    <FormHelperText>{errors.password?.message}</FormHelperText>
                  )}
                </FormControl>
                <PasswordStrengthIndicator password={field.value} mt={2} />
              </>
            )}
          />
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={1.5}
        >
          <Controller
            control={control}
            name="agreed"
            render={({ field, formState: { errors } }) => (
              <FormControl error={!!errors.agreed}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <Typography>
                      I agree with{' '}
                      <Link
                        href="https://www.oodlmarket.com/terms-and-conditions"
                        target="_blank"
                        display="inline"
                      >
                        Terms & Conditions
                      </Link>
                    </Typography>
                  }
                  {...field}
                />
                {errors.agreed?.message && (
                  <FormHelperText>
                    {'Please accept terms & conditions'}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Stack>
        <LoadingButton
          fullWidth
          sx={{ mt: 5 }}
          variant="contained"
          loading={loading}
          onClick={handleSubmit(handleSignUp)}
        >
          Sign Up
        </LoadingButton>
        <Center mt={3}>
          <Typography fontSize={16} fontWeight={500} colorscheme="heading">
            Already have an account?{' '}
            <Link component={NavLink} to="/auth/login" display="inline">
              Sign In
            </Link>
          </Typography>
        </Center>
      </Box>
    </Center>
  );
};

export default RegisterPage;
