import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from 'src/components/ui/AppIcon';
import { apiUploadFiles } from 'src/apis/upload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function FileInput({ type, onChange }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const handleChange = (ev) => {
    if (ev.target.files.length > 0) {
      const file = ev.target.files[0];
      setLoading(true);
      const formData = new FormData();
      formData.append('files', file);
      formData.append('key', getUploadFolderFromType(type));

      apiUploadFiles(formData)
        .then((result) => {
          setValue(file.name);
          onChange(result);
        })
        .catch(() => {
          setValue('');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <OutlinedInput
      fullWidth
      value={value}
      readOnly
      placeholder="Upload document"
      endAdornment={
        <InputAdornment position="end">
          <LoadingButton
            variant="contained"
            size="small"
            component="label"
            loading={loading}
          >
            Upload
            <VisuallyHiddenInput
              type="file"
              accept="application/pdf,image/*,video/*"
              onChange={handleChange}
            />
          </LoadingButton>
        </InputAdornment>
      }
    />
  );
}

FileInput.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
};

export default function UpdateDocumentModal({
  open,
  loading,
  type,
  onSubmit,
  onClose,
}) {
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState('');

  const handleSubmit = () => {
    onSubmit(file);
  };

  useEffect(() => {
    setDisabled(!file);
  }, [file]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 495,
          maxWidth: '100vw',
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          color="#303030"
          fontSize={30}
          lineHeight="38px"
          fontWeight={600}
        >
          Update Document
        </HighlightedTypography>
        <Typography mt={2}>
          If you want to update the selected document, please upload new file.
        </Typography>
        <Box mt={2}>
          <FileInput type={type} onChange={setFile} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mt={3}
          spacing={2}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            size="small"
            sx={{
              px: 3,
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            size="small"
            disabled={disabled}
            loading={loading}
            sx={{
              px: 3,
            }}
          >
            Submit
          </LoadingButton>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
}

function getUploadFolderFromType(type) {
  if (type === 'sales-agreement') {
    return 'trades/spa';
  }
  if (type === 'invoice') {
    return 'trades/invoices';
  }
  if (type === 'receipt') {
    return 'trades/receipts';
  }
  if (type === 'manufacturing-document') {
    return 'trades/manufacturing-documents';
  }
  if (type === 'exporting-document') {
    return 'trades/exporting-documents';
  }
  if (type === 'loading-document') {
    return 'trades/shipping-documents';
  }
  if (type === 'trade-requirement') {
    return 'trades/requirements';
  }
  if (type === 'product-proof') {
    return 'trades/proofs';
  }

  return 'trades/raw';
}

UpdateDocumentModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
