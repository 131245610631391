import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        fontSize={36}
        fontWeight={600}
        colorscheme="heading"
      >
        {t('Product Details')}
      </Typography>
    </>
  );
};

export default PageHeader;
