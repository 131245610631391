import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
  Grid,
  Stack,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import CollapseButton from 'src/components/ui/CollapseButton';
import { GDollarIcon } from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';

const PageTransactionsCard = ({ opened: defaultOpen, data }) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(defaultOpen);

  const handleToggle = () => {
    setOpened((value) => !value);
  };

  if (!data) {
    return null;
  }
  if (
    !data.transactions ||
    data.transactions.filter((item) => item.status === 'completed').length < 1
  ) {
    return null;
  }

  return (
    <>
      <Card sx={{ mb: 2.5 }}>
        <CardHeader
          title={
            <Typography fontSize={18} fontWeight={600} colorscheme="heading">
              {t('Transactions')}
            </Typography>
          }
          emphasis="true"
          action={<CollapseButton opened={opened} onToggle={handleToggle} />}
        />
        <Collapse in={opened}>
          <CardContent
            sx={{
              pb: '16px !important',
            }}
          >
            {data.transactions
              .filter((item) => item.status === 'completed')
              .map((item) => (
                <Box key={`transaction-${item._id}`} mb={1.5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        <Box
                          borderRadius={1}
                          bgcolor={colors['royal.blue']}
                          width={52}
                          height={52}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <GDollarIcon sx={{ color: 'white' }} />
                        </Box>
                        <Stack spacing={0.5}>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            colorscheme="secondary.500"
                          >
                            Bank Transfer
                          </Typography>
                          <Typography
                            fontSize={12}
                            lineHeight={1.5}
                            color="#9A9A9A"
                          >
                            USD
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            colorscheme="secondary.200"
                          >
                            {t('Date')}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            colorscheme="secondary.500"
                          >
                            {moment(item.paidAt).format('MMM DD, YYYY')}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            colorscheme="secondary.200"
                          >
                            {t('Amount')}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            colorscheme="secondary.500"
                          >
                            ${Number(item.total).toLocaleString()}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

PageTransactionsCard.propTypes = {
  opened: PropTypes.bool,
  data: PropTypes.object,
};

export default PageTransactionsCard;
