import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  Stack,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Chip,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import mime from 'mime';

import TableMenu from 'src/components/ui/TableMenu';
import {
  GDotsVerticalIcon,
  GEyeIcon,
  GFileDownloadIcon,
  GHelpIcon,
  GLinkIcon,
} from 'src/components/ui/AppIcon';
import { getFilenameFromUrl } from 'src/utils/functions';
import colors from 'src/configs/colors';

import { ReactComponent as SvgJpg } from 'src/assets/extra/colorful-jpg-icon.svg';
import { ReactComponent as SvgMedia } from 'src/assets/extra/colorful-media-icon.svg';
import { ReactComponent as SvgPdf } from 'src/assets/extra/colorful-pdf-icon.svg';
import constants from 'src/configs/constants';

function DocumentTypeView({ path }) {
  const [type, setType] = useState('');

  useEffect(() => {
    const result = mime.getType(path);
    if (result.includes('image/')) {
      setType('jpg');
      return;
    }
    if (result.includes('video/')) {
      setType('media');
      return;
    }
    if (result.includes('application/pdf')) {
      setType('pdf');
      return;
    }
  }, [path]);

  return (
    <Stack direction="row" alignItems="center" spacing={0.5} py={1.5}>
      {type === 'jpg' && <SvgJpg />}
      {type === 'pdf' && <SvgPdf />}
      {type === 'media' && <SvgMedia />}
      <span>
        {type === 'jpg' ? 'JPG' : null}
        {type === 'word' ? 'DOCX' : null}
        {type === 'pdf' ? 'PDF ' : null}
        {type === 'media' ? 'MEDIA' : null}
      </span>
    </Stack>
  );
}

DocumentTypeView.propTypes = {
  path: PropTypes.string,
};

function ActionMenu({ row, onViewTransaction }) {
  const [anchor, setAnchor] = useState(null);

  return (
    <>
      <IconButton size="small" onClick={(ev) => setAnchor(ev.currentTarget)}>
        <GDotsVerticalIcon
          sx={{
            width: 20,
            height: 20,
          }}
        />
      </IconButton>
      <TableMenu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
      >
        <MenuItem
          component="a"
          href={constants.getStoragePath(row.url)}
          target="_blank"
        >
          <ListItemIcon>
            <GEyeIcon />
          </ListItemIcon>
          <ListItemText>View Document</ListItemText>
        </MenuItem>
        <MenuItem
          component="a"
          href={constants.getStoragePath(row.url)}
          target="_blank"
          download={getFilenameFromUrl(row.url)}
        >
          <ListItemIcon>
            <GFileDownloadIcon />
          </ListItemIcon>
          <ListItemText>Download Document</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onViewTransaction(row.url)}>
          <ListItemIcon>
            <GLinkIcon />
          </ListItemIcon>
          <ListItemText>View on Blockchain</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <GHelpIcon />
          </ListItemIcon>
          <ListItemText>Get Help</ListItemText>
        </MenuItem>
      </TableMenu>
    </>
  );
}

ActionMenu.propTypes = {
  row: PropTypes.any,
  onViewTransaction: PropTypes.func,
};

export default function PageDocumentsTableCard({
  title,
  rows,
  onViewTransaction,
}) {
  const config = useMemo(() => {
    return {
      pending: {
        background: colors['orange.highlight'],
        foreground: colors['status.warning'],
        label: 'Outstanding',
      },
      agreed: {
        background: colors['green.highlight'],
        foreground: colors['status.success'],
        label: 'Agreed',
      },
      completed: {
        background: colors['green.highlight'],
        foreground: colors['status.success'],
        label: 'Completed',
      },
    };
  }, []);

  if (rows.length < 1) {
    return null;
  }

  return (
    <Card sx={{ mb: 2.5 }}>
      <CardHeader
        title={
          <Typography fontSize={18} fontWeight={600} colorscheme="heading">
            {title}
          </Typography>
        }
        emphasis="true"
      />
      <CardContent
        sx={{
          pb: '16px !important',
        }}
      >
        <DataGrid
          rows={rows}
          columns={[
            {
              headerName: 'Type',
              field: 'url',
              renderCell: ({ value }) => <DocumentTypeView path={value} />,
              minWidth: 128,
            },
            {
              headerName: 'Name',
              field: 'name',
              renderCell: ({ row }) => (
                <Box>
                  <Typography fontSize={14}>{row.name}</Typography>
                  <Typography colorscheme="gray.800">
                    {getFilenameFromUrl(row.url)}
                  </Typography>
                </Box>
              ),
              flex: 1,
            },
            {
              headerName: 'Version',
              field: 'version',
            },
            {
              headerName: 'Date & Time',
              field: 'uploadedAt',
              minWidth: 150,
              valueFormatter: ({ value }) =>
                moment(value).format('DD/MM/YYYY HH:mm'),
            },
            {
              headerName: 'Status',
              field: 'status',
              minWidth: 150,
              renderCell: ({ row }) => (
                <Chip
                  component="span"
                  label={config[row.status]?.label}
                  size="small"
                  sx={{
                    ml: 1,
                    bgcolor: config[row.status]?.background,
                    color: config[row.status]?.foreground,
                  }}
                />
              ),
            },
            {
              headerName: 'Action',
              field: 'id',
              renderCell: ({ row }) => (
                <ActionMenu row={row} onViewTransaction={onViewTransaction} />
              ),
            },
          ]}
          getRowHeight={() => 'auto'}
          getRowId={(row) => row._id}
          autoHeight
          hideFooterPagination
          hideFooter
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          pagination={null}
        />
      </CardContent>
    </Card>
  );
}

PageDocumentsTableCard.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.array,
  onViewTransaction: PropTypes.func,
};
