import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField as MuiTextField,
  IconButton,
  Stack,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  GVuesaxEmojiHappyIcon,
  GVuesaxSendIcon,
  GPaperClipIcon,
  GMicIcon,
} from './AppIcon';
import colors from 'src/configs/colors';

const TextField = styled(MuiTextField)(() => ({
  '& .MuiInputBase-root': {
    minHeight: 48,
    bgcolor: '#F7F9FA',
    padding: 0,
    paddingLeft: 8,
    alignItems: 'flex-end',
    borderRadius: '15px',

    '&::after': {
      content: 'none',
    },
    '&::before': {
      content: 'none',
    },
  },
  '& textarea': {
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 4,
    fontSize: 14,
    lineHeight: '22px',
  },
  '& input': {
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 4,
    fontSize: 14,
    lineHeight: '22px',
  },
}));

const ChatMessageInput = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [text, setText] = useState('');

  const handleSubmit = () => {
    if (text) {
      setText('');
      onSubmit(text);
    }
  };

  const handleChange = (ev) => {
    setText(ev.target.value);
  };

  const handleKeydown = (ev) => {
    if (ev.key === 'Enter' && ev.shiftKey === false) {
      handleSubmit();
      ev.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <TextField
      fullWidth
      variant="filled"
      InputProps={{
        startAdornment: (
          <IconButton
            sx={{
              mb: 0.5,
            }}
          >
            <GVuesaxEmojiHappyIcon />
          </IconButton>
        ),
        endAdornment: (
          <Stack direction="row" mr={2} pl={1}>
            <IconButton
              sx={{
                mb: 0.5,
              }}
            >
              <GPaperClipIcon />
            </IconButton>
            <IconButton
              sx={{
                mb: 0.5,
              }}
            >
              <GMicIcon />
            </IconButton>
            <IconButton
              sx={{
                mb: 0.5,
                ml: 0.5,
                bgcolor: colors.primary + ' !important',
                width: 40,
                height: 40,
              }}
              onClick={handleSubmit}
            >
              <GVuesaxSendIcon
                sx={{
                  color: '#FFFFFF',
                  width: 20,
                  height: 20,
                }}
              />
            </IconButton>
          </Stack>
        ),
      }}
      value={text}
      onChange={handleChange}
      onKeyDown={handleKeydown}
      // multiline
      placeholder={t('Write Message')}
    />
  );
};

ChatMessageInput.propTypes = {
  onSubmit: PropTypes.func,
};

export default ChatMessageInput;
