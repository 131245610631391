import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import FullLogo from '../logos/FullLogo';

const AccountSetupLayout = ({ children }) => {
  return (
    <Scrollbars
      style={{
        width: '100%',
        height: '100vh',
        position: 'relative',
        background: 'linear-gradient(271deg, #958257 0.71%, #D4C092 82.13%)',
      }}
    >
      <Box
        style={{
          position: 'fixed',
          left: 40,
          top: 40,
        }}
      >
        <FullLogo isDark />
      </Box>
      {children}
    </Scrollbars>
  );
};

AccountSetupLayout.propTypes = {
  children: PropTypes.any,
};

export default AccountSetupLayout;
