import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import PageHeader from './sub-categories-page/PageHeader';
import PageCategoriesView from './sub-categories-page/PageCategoriesView';
import { apiGetSubCategories } from 'src/apis/categories';

const SubCategoriesPage = () => {
  const params = useParams();
  const [query] = useSearchParams();

  const search = query.get('search') ? query.get('search') : '';

  const { data, isLoading } = useQuery(
    ['categories', params.categoryName, search],
    async () => {
      const result = await apiGetSubCategories(params.categoryName);
      if (result) {
        if (search) {
          return result.filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase()),
          );
        }
        return result;
      }
      return [];
    },
  );

  return (
    <>
      <PageHeader />
      <PageCategoriesView data={data ? data : []} loading={isLoading} />
    </>
  );
};

export default SubCategoriesPage;
