import React, { useCallback, useState } from 'react';
import { Container } from '@mui/material';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AccountSetupLayout from '../../components/layouts/AccountSetupLayout';
import AccountStepper from './components/AccountSetupStepper';
import AccountSetupIntroduction from './components/AccountSetupIntroduction';
import PersonalAccountSetup from './components/PersonalSetup';
import BusinessAccountSetup from './components/BusinessSetup';
import colors from 'src/configs/colors';
import { apiSetupProfile } from 'src/apis/profile';

const AccountSetupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    personal: null,
    business: null,
  });
  const [loading, setLoading] = useState(false);

  const handleNext = useCallback(() => {
    setStep((cur) => cur + 1);
  }, [setStep]);

  const handleBack = useCallback(() => {
    setStep((cur) => cur - 1);
  }, [setStep]);

  const handleSkip = useCallback(() => {
    navigate('/dashboard');
  }, [navigate]);

  const handleChangePersonalData = useCallback(
    (values) => {
      setData((cur) => ({
        ...cur,
        personal: {
          ...values,
        },
      }));
    },
    [setData],
  );

  const handleFinish = useCallback(
    async (values) => {
      const params = {
        ...data.personal,
        dob: data.personal.dob.format('YYYY-MM-DD'),
        identification: data.personal.identification._id,
        bankStatement: data.personal.bankStatement._id,
        proofOfAddress: data.personal.proofOfAddress._id,
        company: {
          ...values,
          documents: values.documents.map((item) => item._id),
        },
      };

      setLoading(true);
      const result = await apiSetupProfile(params);
      if (result) {
        Swal.fire({
          icon: 'success',
          title: t('Profile Created'),
          text: t('Your personal and business profile created successfully.'),
          showConfirmButton: true,
          confirmButtonText: t('Continue'),
          confirmButtonColor: colors.primary,
        }).then(() => {
          navigate('/dashboard', {
            replace: true,
          });
        });
      }
      setLoading(false);
    },
    [data, setData],
  );

  return (
    <AccountSetupLayout>
      <Container maxWidth="lg" sx={{ mt: 22, position: 'relative' }}>
        <AccountStepper step={step} />
        {step === 0 && (
          <AccountSetupIntroduction
            onNextPage={handleNext}
            onSkip={handleSkip}
          />
        )}
        {step === 1 && (
          <PersonalAccountSetup
            defaultData={data.personal}
            onNextPage={handleNext}
            onBackPage={handleBack}
            onChangeData={handleChangePersonalData}
          />
        )}
        {step === 2 && (
          <BusinessAccountSetup
            defaultData={data.business}
            onNextPage={handleFinish}
            onBackPage={handleBack}
            loading={loading}
          />
        )}
      </Container>
    </AccountSetupLayout>
  );
};

export default AccountSetupPage;
