import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Collapse,
  Container,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Chat } from '@pubnub/react-chat-components';
import { useSelector } from 'react-redux';

import PageUsersView from './messages-page/PageUsersView';
import PageChatView from './messages-page/PageChatView';

const MessagesPage = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('lg'));

  const isReady = useSelector((state) => state.messaging.isReady);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen((state) => !state);
  };

  useEffect(() => {
    if (!isSm) {
      setDrawerOpen(false);
    }
  }, [isSm]);

  return (
    <Container
      sx={{
        pt: 5,
        pb: 12,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={24} fontWeight={600} colorscheme="heading">
          My Messages
        </Typography>
      </Stack>
      {isReady ? (
        <>
          <Chat>
            <Stack
              mt={3}
              height="calc(100vh - 220px)"
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Collapse
                orientation="horizontal"
                mountOnEnter={false}
                in={!isSm}
              >
                <PageUsersView />
              </Collapse>
              <PageChatView isSm={isSm} onToggleDrawer={toggleDrawer} />
            </Stack>
          </Chat>
          <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
            <PageUsersView fullWidth />
          </Drawer>
        </>
      ) : (
        <Stack height="50vh" justifyContent="center" alignItems="center">
          <CircularProgress size={24} />
        </Stack>
      )}
    </Container>
  );
};

export default MessagesPage;
