import React from 'react';
import PropTypes from 'prop-types';

import imgWhiteLogo from '../../assets/logos/logo-white.png';
import imgBlackLogo from '../../assets/logos/logo-black.png';

const FullLogo = ({ isDark }) => {
  return (
    <img
      src={isDark ? imgBlackLogo : imgWhiteLogo}
      alt="Logo"
      style={{
        width: 240,
        height: 78,
        objectFit: 'contain',
      }}
    />
  );
};

FullLogo.propTypes = {
  isDark: PropTypes.bool,
};

export default FullLogo;
