import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Skeleton,
  Typography,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Country } from 'country-state-city';

import { GPhotoIcon } from 'src/components/ui/AppIcon';
import colors from 'src/configs/colors';
import constants from 'src/configs/constants';

const NameTypography = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '22px',
  color: colors['gray.500'],
}));

const ValueTypography = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '24px',
  color: colors.heading,
}));

const PageStaticBusinessDetails = ({ loading, profile }) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        mt: 2.5,
      }}
    >
      <CardHeader
        title={
          <Typography
            fontSize={18}
            fontWeight={500}
            lineHeight="28px"
            colorscheme="fonts.heading"
          >
            {t('Business Details')}
          </Typography>
        }
        emphasis="true"
        action={
          <Chip
            label={profile?.isVerified ? 'Verfied' : 'In Review'}
            sx={{
              bgcolor: profile?.isVerified
                ? colors['green.highlight']
                : colors['red.highlight'],
              color: profile?.isVerified
                ? colors['status.success']
                : colors['status.error'],
              fontWeight: 500,
            }}
            variant="mixed"
          />
        }
      />
      <CardContent
        sx={{
          pb: `16px !important`,
        }}
      >
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Company Name</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.name}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Trading Name</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.tradingName}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Registration Number</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.regNumber}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Company Email</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography
                    sx={{
                      wordBreak: 'break-all',
                    }}
                  >
                    {loading ? <Skeleton /> : profile?.email}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Phone Number</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      formatPhoneNumberIntl('+' + profile?.phone)
                    )}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Address</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.address}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>State</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.state}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>City</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.city}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Zip Code</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? <Skeleton /> : profile?.zipCode}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NameTypography>Country</NameTypography>
                </Grid>
                <Grid item xs={6}>
                  <ValueTypography>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      Country.getCountryByCode(profile?.country)?.name
                    )}
                  </ValueTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <NameTypography>Logo</NameTypography>
                </Grid>
                <Grid item xs={12}>
                  <Avatar
                    src={constants.getStoragePath(profile?.logo)}
                    sx={{
                      width: 140,
                      height: 140,
                    }}
                    variant="rounded"
                  >
                    <GPhotoIcon sx={{}} />
                  </Avatar>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

PageStaticBusinessDetails.propTypes = {
  loading: PropTypes.bool,
  profile: PropTypes.any,
};

export default PageStaticBusinessDetails;
