import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import * as yup from 'yup';

import HighlightedTypography from 'src/components/ui/HighlightedTypography';
import { GCloseIcon } from 'src/components/ui/AppIcon';
import { apiRequestPhoneOTP, apiVerifyPhone } from 'src/apis/auth';
import notify from 'src/configs/notify';

const schema = yup.object({
  code: yup.string().length(6).required(),
});

export default function PhoneNumberVerificationModal({
  open,
  phoneNumber,
  onClose,
  onSubmit,
}) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const handleVerify = async ({ code }) => {
    setLoading(true);
    const result = await apiVerifyPhone({
      phone: phoneNumber,
      code: code,
    });
    if (result) {
      onClose();
      onSubmit();
    }
    setLoading(false);
  };

  const handleResendCode = async () => {
    setSending(true);
    await apiRequestPhoneOTP({
      phone: phoneNumber,
    });
    notify({
      type: 'success',
      message: `We've sent your verification code to your phone number successfully`,
    });
    setSending(false);
  };

  useEffect(() => {
    if (open && phoneNumber) {
      handleResendCode();
    } else {
      onClose();
    }
  }, [open, phoneNumber]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 495,
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
        }}
      >
        <HighlightedTypography
          color="#303030"
          variant="h5"
          lineHeight="38px"
          fontWeight={600}
        >
          Verify your phone number
        </HighlightedTypography>
        <Typography mt={3}>
          We have sent your verification code to your phone number (
          {phoneNumber ? formatPhoneNumberIntl('+' + phoneNumber) : ''}).
          <br />
          Please verify that this number is yours.
        </Typography>
        <Box mt={3}>
          <Controller
            control={control}
            name="code"
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={!!error}>
                <InputLabel required>Verification Code</InputLabel>
                <OutlinedInput {...field} placeholder="6 digits code" />
                {error ? (
                  <FormHelperText error>
                    Verification code must be 6 digits
                  </FormHelperText>
                ) : null}
              </FormControl>
            )}
          />
        </Box>
        <Stack direction="row" justifyContent="flex-end" mt={4} spacing={2}>
          <LoadingButton
            variant="outlined"
            size="small"
            sx={{
              px: 2,
            }}
            loading={sending}
            onClick={handleResendCode}
          >
            Resend Code
          </LoadingButton>
          <LoadingButton
            variant="contained"
            size="small"
            sx={{
              px: 2,
            }}
            loading={loading}
            onClick={handleSubmit(handleVerify)}
          >
            Verify
          </LoadingButton>
        </Stack>
        <IconButton
          sx={{
            position: 'absolute',
            right: 24,
            top: 24,
            width: 24,
            height: 24,
            bgcolor: '#EEEEEE',
          }}
          onClick={onClose}
        >
          <GCloseIcon
            sx={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
}

PhoneNumberVerificationModal.propTypes = {
  open: PropTypes.bool,
  phoneNumber: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
