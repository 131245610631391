import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import PageDocumentsTableCard from './PageDocumentsTableCard';
import constants from 'src/configs/constants';
import notify from 'src/configs/notify';

export default function PageDocumentsTab({ active, data }) {
  const handleViewTransaction = (url) => {
    const row = data?.auditTrails?.find((item) => item.documentUrl === url);
    if (row && row.transactionHash) {
      window.open(
        constants.getBlockchainTransactionPath(row.transactionHash),
        '_blank',
      );
    } else {
      notify({
        message: 'This document is not deployed on blockchain',
        type: 'warning',
      });
    }
  };

  return (
    <Box display={active ? 'block' : 'none'}>
      <PageDocumentsTableCard
        title="Manufacturing Documents"
        rows={
          data?.documents?.filter(
            (item) => item.type === 'manufacturing-document',
          ) ?? []
        }
        onViewTransaction={handleViewTransaction}
      />
      <PageDocumentsTableCard
        title="Shipping Documents"
        rows={
          data?.documents?.filter(
            (item) => item.type === 'importing-document',
          ) ?? []
        }
        onViewTransaction={handleViewTransaction}
      />
      <PageDocumentsTableCard
        title="Invoices"
        rows={data?.documents?.filter((item) => item.type === 'invoice') ?? []}
        onViewTransaction={handleViewTransaction}
      />
      <PageDocumentsTableCard
        title="Purchase Agreements"
        rows={
          data?.documents?.filter((item) => item.type === 'sales-agreement') ??
          []
        }
        onViewTransaction={handleViewTransaction}
      />
      <PageDocumentsTableCard
        title="Requirements Documents"
        rows={
          data?.documents?.filter(
            (item) => item.type === 'trade-requirement',
          ) ?? []
        }
        onViewTransaction={handleViewTransaction}
      />
      <PageDocumentsTableCard
        title="Proof of Product"
        rows={
          data?.documents?.filter((item) => item.type === 'product-proof') ?? []
        }
        onViewTransaction={handleViewTransaction}
      />
    </Box>
  );
}

PageDocumentsTab.propTypes = {
  data: PropTypes.any,
  active: PropTypes.bool,
};
